import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MuiInputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import './CalendarDayViewer.scss';

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const InputBase = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
  },
  input: {
    padding: '10.5px 14px;',
  },
})(MuiInputBase);

/**
 * Represents a day viewer for switching duration of the calendar.
 * @param {Object} props The component properties.
 */
const CalendarDayViewer = (props) => {
  const { onDurationChange } = props;
  const { t } = useTranslation();

  return (
    <div className="cal-day-viewer">
      <FormControl className="cal-day-viewer__switcher" variant="outlined">
        <Select
          defaultValue="60"
          input={<InputBase />}
          variant="outlined"
          onChange={(e) => onDurationChange(parseInt(e.target.value))}
        >
          <MenuItem value="60">{t('Hourly')}</MenuItem>
          <MenuItem value="15">15 {t('Minutes')}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

CalendarDayViewer.propTypes = {
  onDurationChange: PropTypes.func.isRequired,
};

export default CalendarDayViewer;
