import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Enums from '../../enums';
import Utils from '../../utils';
import Storage from '../../storage';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import DashboardService from '../../Services/dashboardService';

import './RatingsAndReviews.scss';

/**
 * Represents the customer data component.
 * @param {Object} props The component properties.
 */
class RatingsAndReviews extends Component {
  /**
   * Initializes a new instance of the CustomerData component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {};
    this._dashboardService = new DashboardService();
  }

  /**
   * Renders the component.
   */
  render() {
    return <div className="cust-data">Ratings and Reviews Dashboard</div>;
  }
}

export default withTranslation()(RatingsAndReviews);
