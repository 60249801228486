import React from 'react';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import SortIcon from '@material-ui/icons/Sort';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { AppointmentTooltip } from '@devexpress/dx-react-scheduler-material-ui';
import StellestLogo from '../../Assets/Images/Stellest/SC-Icon.png';
import Events from '../../events';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import './CalendarAppointmentTooltip.scss';
import { connectProps } from '@devexpress/dx-react-core';
import { ConfirmationStatus } from '../../enums';

/**
 * Represents the header for an appointment tooltip.
 * @param {Object} props The component properties.
 */
export const CalendarAppointmentTooltipHeader = (props) => {
  const { appointmentData, onHide } = props;

  const eBuilder = new EventBuilder();

  eBuilder
    .withCategory(eBuilder.Category.Scheduler.clickAppointment)
    .withLabel(
      eBuilder.Label.practiceIdentifierWithArgs,
      momentLocaleWrapper(appointmentData.startDate).format('h:mm A')
    );

  const onEditAppointment = () => {
    Events.emit(Constants.Events.onToggleAddAppointment, appointmentData);

    props.onHide();

    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.ExistingAppointment.edit)
      .post();
  };

  const onDeleteAppointment = () => {
    Events.emit(Constants.Events.deleteAppointment, appointmentData);

    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.ExistingAppointment.delete)
      .post();

    props.onHide();
  };

  const onNoShowAppointment = () => {
    Events.emit(Constants.Events.noShowAppointment, appointmentData);

    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.ExistingAppointment.noShow)
      .post();

    props.onHide();
  };

  const { t } = useTranslation();

  return (
    <AppointmentTooltip.Header
      {...props}
      className="cal-appttooltip__header"
      showCloseButton={false}
      showOpenButton={false}
    >
      <div className="cal-appttooltip__command">
        <IconButton onClick={() => onEditAppointment()}>
          <EditIcon />
        </IconButton>
        <span>{t('Edit')}</span>
      </div>
      <div className="cal-appttooltip__command">
        <IconButton onClick={() => onDeleteAppointment()}>
          <DeleteIcon />
        </IconButton>
        <span>{t('Delete')}</span>
      </div>
      <div className="cal-appttooltip__command">
        <IconButton onClick={() => onNoShowAppointment()}>
          <NotInterestedIcon />
        </IconButton>
        <span>{t('No Show')}</span>
      </div>
      <span className="cal-queuetooltip__divider"></span>
      <div className="cal-queuetooltip__action cal-queuetooltip__action--close">
        <IconButton onClick={onHide}>
          <ClearIcon />
        </IconButton>
      </div>
    </AppointmentTooltip.Header>
  );
};

/**
 * Represents the content for an appointment tooltip.
 * @param {Object} props The component properties.
 */
export const CalendarAppointmentTooltipContent = (props) => {
  const {
    appointmentData,
    appointmentConfirmationUpdate,
    appointmentResources,
    config,
    onHide,
  } = props;
  const {
    appointmentId,
    appointmentType,
    confirmationStatus,
    patient,
    startDate,
    endDate,
    notes,
    voucher,
    isStellest,
  } = appointmentData;
  const { firstName, lastName, phone, email } = patient;

  const isWhatsAppPhoneLinksEnabled =
    config.countrySettings.some(
      (s) =>
        s.settingName ===
          Constants.CountrySettings.settingIsWhatsAppB2CEnabled &&
        s.settingValue === true
    ) &&
    config.locationSettings.some(
      (s) =>
        s.displayName ===
          Constants.LocationSettings.settingIsWhatsAppB2CEnabled &&
        s.settingValue === true
    );

  const { t } = useTranslation();
  const apptTime = `${momentLocaleWrapper(startDate).format(
    'LT'
  )} - ${momentLocaleWrapper(endDate).format('LT')}`;

  const onConfirmationClick = async () => {
    onHide();
    await appointmentConfirmationUpdate(appointmentId);
  };

  const showStellest = isStellest && confirmationStatus !== 0;

  return (
    <div className="cal-appttooltip__body">
      <span
        className="cal-appttooltip__appt-color"
        style={{ backgroundColor: `${appointmentResources[0].color}` }}
      ></span>
      <div className="cal-appttooltip__body-right">
        <p>
          {firstName}&nbsp;{lastName}
        </p>
        <p>{appointmentType.displayName}</p>
        {isWhatsAppPhoneLinksEnabled && (
          <p>
            <a
              href={`${process.env.REACT_APP_WHATSAPP_API}/send?phone=${phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >{`+${phone}`}</a>
          </p>
        )}
        {!isWhatsAppPhoneLinksEnabled && <p>{`+${phone}`}</p>}
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p>{momentLocaleWrapper(startDate).format('LL')}</p>
      </div>
      <p className="cal-appttooltip__time">
        <AccessTimeIcon />
        {apptTime}
      </p>
      <div className="cal-appttooltip__notes">
        <SortIcon />
        <p>{notes}</p>
      </div>
      {voucher && (
        <div className="cal-appttooltip__voucher-cont">
          <LocalOfferIcon className="cal-appttooltip__voucher-icon" />
          <div className="cal-appttooltip__voucher-details">
            <p className="cal-appttooltip__voucher-code">
              {voucher.voucherCode}
            </p>
            <p className="cal-appttooltip__voucher-desc">
              {voucher.description}
            </p>
            <p className="cal-appttooltip__voucher-expire">{`${t(
              'Offer expires'
            )} ${momentLocaleWrapper(voucher.activeDuring.end).format(
              'LL'
            )}`}</p>
          </div>
        </div>
      )}
      {showStellest && (
        <div className="cal-appttooltip__confirmation-parent">
          <div className="cal-appttooltip__confirmation-content-headline">
            <img alt="Stellest Logo" src={StellestLogo} />
            {t('Stellest Appointment')} <span>{t('NOT CONFIRMED')}</span>
          </div>
          <div className="cal-appttooltip__confirmation-content">
            <span></span>
            <InfoOutlinedIcon />
            <span>
              {t(
                'A Preappointment Stellest Appointment has been made for this customer, but the customer has not confirmed this appointment. Please call and manually confirm.'
              )}
            </span>
          </div>

          <button
            className="cal-appttooltip__confirmation-button"
            onClick={() => onConfirmationClick()}
          >
            {t('Confirm Stellest Appointment')}
          </button>
        </div>
      )}
    </div>
  );
};

/**
 * Represents the layout of an apppointment tooltip.
 * @param {Object} props The component properties.
 */
export const CalendarAppointmentTooltipLayout = ({ visible, ...restProps }) => {
  return (
    <AppointmentTooltip.Layout
      {...restProps}
      visible={visible}
      className="cal-appttooltip"
      contentComponent={connectProps(CalendarAppointmentTooltipContent, () => ({
        config: restProps.config,
        appointmentConfirmationUpdate: restProps.appointmentConfirmationUpdate,
        visible: visible,
        onHide: restProps.onHide,
      }))}
    ></AppointmentTooltip.Layout>
  );
};
