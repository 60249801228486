import React, { useEffect, useRef, useState } from 'react';
import '../TwoWayConversation/TwoWayConversation.scss';
import './TwoWayConversationNew.scss';
import TwoWayConversationNewTemplate from './TwoWayConversationNewTemplate';
import TwoWayConversationService from '../../Services/twoWayConversationService';
import ContentManagementService from '../../Services/contentManagementService';
import PatientService from '../../Services/patientService';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Logger from '../../logger';
import Enums from '../../enums';
import Constants from '../../constants';
import { Button } from '@material-ui/core';
import TwoWayModal from '../TwoWayModal/TwoWayModal';
import { withTranslation } from 'react-i18next';
import TwoWayAddPatientModal from '../TwoWayModal/TwoWayAddPatientModal';
import { useHistory } from 'react-router-dom';
import Overlay from '../../Components/Overlay/Overlay';
import ReactHtmlParser from 'react-html-parser';

const TwoWayConversationNew = ({
  defaultMessagingPref,
  isSmsOneWay,
  isWhatsAppNotificationsEnabled,
  countryCode,
  phoneCountryCode,
  t,
  templateList,
  conversationOptOut,
  locationConfigName,
  locationConfigAddress,
  locationConfigPhone,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchFirstName, setSearchFirstName] = useState('');
  const [searchLastName, setSearchLastName] = useState('');
  const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
  const [formattedSearchPhoneNumber, setFormattedSearchPhoneNumber] = useState('');
  const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);
  const [showNewPatientMessage, setShowNewPatientMessage] = useState(false);
  const [showNewPatientModal, setShowNewPatientModal] = useState(false);
  const [patientSearchResults, setPatientSearchResults] = useState([]);
  const [smsToList, setSmsToList] = useState([]);
  const [showSmsWarning, setShowSmsWarning] = useState(
    isSmsOneWay && defaultMessagingPref === Enums.PreferredPlatform.smsText
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);
  const [text, setText] = useState('');
  const [modalContent, setModalContent] = useState({
    open: false,
    heading: '',
    body: '',
    spinner: false,
    buttons: [],
  });
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      handleSearchChange();
    }, searchTermInterval);
    return () => clearTimeout(timer);
  }, [searchFirstName, searchLastName, searchPhoneNumber]);

  let searchTermInterval = 1000;
  const twoWayConversationService = new TwoWayConversationService();
  const contentManagementService = new ContentManagementService();
  const patientService = new PatientService();

  const NO_PATIENT_MODAL_TITLE = t('Unable to add patient');
  const NO_PATIENT_MODAL_BODY = t(
    'Currently unable to add this patient. Please try a different patient.'
  );

  const handleFirstNameChange = (e) => {
    setSearchFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setSearchLastName(e.target.value);
  };
  const handlePhoneChange = (e) => {
    const val = e.target.value.replace(/[^\d+]/, '');
    setSearchPhoneNumber(val);
  };

  const handleClear = () => {
    setPatientSearchResults([]);
    setSearchFirstName('');
    setSearchLastName('');
    setSearchPhoneNumber('');
    setShowNoResultsMessage(false);
    setShowNewPatientMessage(false);
  };

  const handleAddToSmsList = (_patient) => {
    if (_patient?.patientKey) {
      if (!smsToList.some((x) => x.patientKey === _patient.patientKey)) {
        setSmsToList((patient) => [_patient, ...patient]);
      }
      handleClear();
    } else {
      openModalContent(NO_PATIENT_MODAL_TITLE, NO_PATIENT_MODAL_BODY, [
        {
          action: closeModal,
          color: 'primary',
          content: 'Ok',
          key: 1,
        },
      ]);
    }
  };
  const handleRemoveSmsList = (_patient) => () => {
    const array = [...smsToList];
    const newList = array.filter((item) => item.patientKey !== _patient.patientKey);

    setSmsToList(newList);
  };

  const handleSearchChange = () => {
    let isValid = false;

    if (searchFirstName === '' && searchLastName === '' && searchPhoneNumber === '') {
      handleClear();
    }
    if (searchFirstName !== '' || searchLastName !== '' || searchPhoneNumber !== '') {
      isValid = true;
    }

    if (isValid) {
      const payload = {
        firstName: searchFirstName,
        lastName: searchLastName,
        phone: searchPhoneNumber,
      };
      getFindPatient(payload);
    }
  };

  const handleAddNewContact = (e) => {
    e.preventDefault();
    setShowNewPatientModal(true);
  };

  const openModalContent = (heading, body, buttons, spinner) => {
    setModalContent({
      open: true,
      heading,
      body,
      spinner: spinner || false,
      buttons,
    });
  };

  const closeModal = () => {
    setModalContent(() => ({
      open: false,
      heading: '',
      body: '',
      spinner: false,
      buttons: [],
    }));
  };

  const closeAddPatientModal = () => {
    setShowNewPatientModal(false);
  };
  const submitNewPatient = async (messagingPreference) => {
    setShowNewPatientModal(false);
    const patientRequest = {
      firstName: searchFirstName,
      lastName: searchLastName,
      phone: phoneCountryCode.replace(/\D/g, '') + searchPhoneNumber.replace(/\D/g, ''),
      preferredPlatform: messagingPreference,
    };
    try {
      const patientResponse = await patientService.createPatient(patientRequest);
      Logger.log('createPatient', patientResponse);
      handleAddToSmsList(patientResponse);
    } catch (err) {
      Logger.error('createPatient', err, 'error');
      openModalContent(NO_PATIENT_MODAL_TITLE, NO_PATIENT_MODAL_BODY, [
        {
          action: closeModal,
          color: 'primary',
          content: 'Ok',
          key: 1,
        },
      ]);
    }
  };

  const getFindPatient = async (payload) => {
    setIsLoading(true);
    const { firstName, lastName, phone } = payload;
    try {
      const resp = await twoWayConversationService.postFindPatient(firstName, lastName, phone);
      const patients = resp?.listOfPatientSearch;
      if (patients && patients.length > 0) {
        Logger.log('postFindPatient', patients);
        setPatientSearchResults(patients);
        setShowNoResultsMessage(false);
        setShowNewPatientMessage(false);
      } else {
        formatNewPatientNumber(phone);
        setFormattedSearchPhoneNumber(phone);
        setPatientSearchResults([]);
        setShowNoResultsMessage(true);
        setShowNewPatientMessage(
          searchPhoneNumber?.length > 1 && searchFirstName?.length > 1 && searchLastName?.length > 1
        );
      }
    } catch (err) {
      Logger.error('postFindPatient', err, 'error');
      openModalContent(NO_PATIENT_MODAL_TITLE, NO_PATIENT_MODAL_BODY, [
        {
          action: closeModal,
          color: 'primary',
          content: 'Ok',
          key: 1,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const formatNewPatientNumber = async (phoneNumber) => {
    try {
      var formattedNumber = await contentManagementService.getFormattedPhoneNumber(
        phoneNumber,
        countryCode,
        true
      );
      setFormattedSearchPhoneNumber(formattedNumber);
    } catch (err) {
      Logger.error('postFindPatient', err, 'error');
    }
  };

  const handleSend = (text) => {
    setIsLoading(true);
    sendTemplatedMessage([...smsToList], [], text);
  };

  const handleSendWithAttachment = (text, file) => {
    setIsLoading(true);
    sendTemplatedMessageWithAttachment([...smsToList], [], text, file);
  };

  const handleSendWithAutoquote = async (chatAutoquoteRequest) => {
    setIsLoading(true);
    const patient = smsToList[0]; //sending a quote is disabled with more than one sender; need the patient object ofr error/success handling.
    let errors = [];
    await twoWayConversationService
      .sendAutoquoteMessage(chatAutoquoteRequest)
      .then((resp) => {
        if (resp.success === false && resp.reason === Constants.TwoWayMessages.optOutReason) {
          conversationOptOut(chatAutoquoteRequest.patientKey);
        }
        if (resp.success === false) {
          errors.push(patient);
        }

        handleClear();
        setSmsToList([]);
        setIsLoading(false);
      })
      .catch((err) => {
        Logger.error('SendTemplatedMessage', err, 'error');
        errors.push(patient);
      })
      .finally(() => {
        if (errors.length > 0) {
          let message = t('Unable to send a message to') + ':';
          errors.forEach((e) => {
            message += ` ${e.firstName} ${e.lastName} ${e.formattedPhone},`;
          });
          message = message.substr(0, message.length - 1);
          openModalContent(t('Unable to send message'), message, [
            {
              action: closeModal,
              color: 'primary',
              content: 'Ok',
              key: 1,
            },
          ]);
        } else {
          openModalContent(t('Message Sent'), t('The message has been successfully sent.'), [
            {
              action: closeModal,
              color: 'primary',
              content: 'Ok',
              key: 1,
            },
          ]);
        }
      });
    setIsLoading(false);
  };

  const sendTemplatedMessage = (patients, errors, text) => {
    if (patients.length > 0) {
      var patient = patients.pop();
      Logger.log('SendTemplatedMessage', patient);
      twoWayConversationService
        .sendTemplatedMessage(selectedTemplateId, patient.patientKey, text)
        .then((resp) => {
          if (resp.success === false && resp.reason === Constants.TwoWayMessages.optOutReason) {
            conversationOptOut(patient.patientKey);
          }
          if (resp.success === false) {
            errors.push(patient);
          }
          sendTemplatedMessage(patients, errors, text);
        })
        .catch((err) => {
          Logger.error('SendTemplatedMessage', err, 'error');
          errors.push(patient);
          sendTemplatedMessage(patients, errors, text);
        });
    } else {
      handleClear();
      setSmsToList([]);
      setIsLoading(false);
      if (errors.length > 0) {
        let message = t('Unable to send a message to') + ':';
        errors.forEach((e) => {
          message += ` ${e.firstName} ${e.lastName} ${e.formattedPhone},`;
        });
        message = message.substr(0, message.length - 1);
        openModalContent(t('Unable to send message'), message, [
          {
            action: closeModal,
            color: 'primary',
            content: 'Ok',
            key: 1,
          },
        ]);
      } else {
        openModalContent(t('Message Sent'), t('The message has been successfully sent.'), [
          {
            action: closeModal,
            color: 'primary',
            content: 'Ok',
            key: 1,
          },
        ]);
      }
    }
  };

  const sendTemplatedMessageWithAttachment = (patients, errors, text, file) => {
    if (patients.length === 1) {
      var patient = patients.pop();
      Logger.log('SendTemplatedMessageWithAttachment', patient);
      if (
        selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms ||
        selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote
      ) {
        twoWayConversationService
          .sendAttachmentMessage('', patient.patientKey, file, text, selectedTemplateId)
          .then((resp) => {
            if (resp.success === false && resp.reason === Constants.TwoWayMessages.optOutReason) {
              conversationOptOut(patient.patientKey);
            }
            if (resp.success === false) {
              errors.push(patient);
            }
            sendTemplatedMessageWithAttachment(patients, errors, text, file);
          })
          .catch((err) => {
            Logger.error('SendTemplatedMessage', err, 'error');
            errors.push(patient);
            sendTemplatedMessageWithAttachment(patients, errors, text, file);
          });
      } else {
        sendTemplatedMessage([...patients], [], text);
      }
    } else {
      handleClear();
      setSmsToList([]);
      setIsLoading(false);
      if (errors.length > 0) {
        let message = t('Unable to send a message to') + ':';
        errors.forEach((e) => {
          message += ` ${e.firstName} ${e.lastName} ${e.formattedPhone},`;
        });
        message = message.substr(0, message.length - 1);
        openModalContent(t('Unable to send message'), message, [
          {
            action: closeModal,
            color: 'primary',
            content: 'Ok',
            key: 1,
          },
        ]);
      } else {
        openModalContent(t('Message Sent'), t('The message has been successfully sent.'), [
          {
            action: closeModal,
            color: 'primary',
            content: 'Ok',
            key: 1,
          },
        ]);
      }
    }
  };

  const handelCloseResults = () => {
    setPatientSearchResults([]);
  };

  const handleCloseConversation = () => {
    history.push('/chat/');
  };

  const showSmsWarningCheck = (messagePref) => {
    const displaySmsOneWayWarning =
      isSmsOneWay && messagePref === parseInt(Enums.PreferredPlatform.smsText);
    setShowSmsWarning(displaySmsOneWayWarning);
  };

  return (
    <>
      <div className="conversation__holder conversation-new">
        <div className="conversation__container conversation-new__header conversation__header">
          <div className="row row__start conversation-new__header-close">
            <h1>{t('Send a New Message')}</h1>
            <CloseIcon
              className="row row__start conversation-new__header-close--visible"
              fontSize="small"
              onClick={handleCloseConversation}
            />
          </div>
          <div className="conversation-new__content">
            <div className="conversation-new__content-title">
              {t(
                'Find (or create) a customer by searching their name, or phone number. You can send a personalized message to multiple customers at the same time.'
              )}
              <span className="conversation-new__content-tooltip">
                <ErrorOutlineIcon fontSize="small" className="exclamation-icon" />
                <div className="conversation-new__content-tooltip-message">
                  <p>
                    {ReactHtmlParser(
                      t(
                        'Each customer will receive and respond to your message <strong>independently</strong>.'
                      )
                    )}
                  </p>
                </div>
              </span>
            </div>
            <div className="row row__start">
              <input
                className="conversation-new__smsTo-input"
                label={t('First Name')}
                maxLength="20"
                value={searchFirstName}
                onChange={handleFirstNameChange}
                placeholder={t('First Name')}
              />
              <input
                className="conversation-new__smsTo-input"
                label={t('Last Name')}
                maxLength="20"
                value={searchLastName}
                onChange={handleLastNameChange}
                placeholder={t('Last Name')}
              />
              <TextField
                className="conversation-new__smsTo-input-phone"
                label={t('Phone')}
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{phoneCountryCode ?? ''}</InputAdornment>
                  ),
                  className: 'conversation-new__smsTo-input-phone--code',
                }}
                onChange={handlePhoneChange}
                value={searchPhoneNumber}
              />
              {(searchFirstName || searchLastName || searchPhoneNumber) && (
                <Button variant="outlined" className="btn-clear" onClick={handleClear}>
                  {t('CLEAR')}
                </Button>
              )}

              {showNoResultsMessage && (
                <div className="conversation-new__no-results">
                  <span>{t('Your search generated: {{0}} results', { 0: '0' })}</span>
                  {showNewPatientMessage && (
                    <a
                      href="#"
                      className="conversation-new__add-contact"
                      onClick={handleAddNewContact}
                    >
                      {t('Add New Contact')}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>

          {patientSearchResults.length > 0 && (
            <div className={'conversation-new__results'}>
              <div className="conversation-new__results-header">
                <p>{t('Select customer(s) to send message')}</p>
                <div className="btn-close" onClick={handelCloseResults}>
                  <CloseIcon fontSize="small" />
                </div>
              </div>
              <div className={'conversation-new__results-content'}>
                {patientSearchResults.map((patient) => {
                  return (
                    <div
                      key={patient.patientKey}
                      className="row row__start"
                      onClick={(event) => handleAddToSmsList(patient)}
                    >
                      <div>
                        {patient.firstName} {patient.lastName}
                      </div>
                      <div>{patient.formattedPhone}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="conversation__container conversation-new__list">
            {smsToList.map((patient) => (
              <div
                className="conversation__container conversation-new__list-container"
                key={patient.patientKey}
              >
                <div className="conversation-new__smsTo">
                  <div>
                    {patient.firstName} {patient.lastName} {patient.formattedPhone}
                  </div>
                  <span
                    className="conversation-new__smsTo--remove"
                    onClick={handleRemoveSmsList(patient)}
                  >
                    <CancelIcon fontSize="small" />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="conversation__list"></div>

        <TwoWayConversationNewTemplate
          text={text}
          setText={setText}
          smsToList={smsToList}
          onSend={handleSend}
          onSendWithAttachment={handleSendWithAttachment}
          onSendWithAutoquote={handleSendWithAutoquote}
          selectedTemplateId={selectedTemplateId}
          setSelectedTemplateId={setSelectedTemplateId}
          templateList={templateList}
          isReadOnly={true}
          locationName={locationConfigName}
          locationAddress={locationConfigAddress}
          locationPhone={locationConfigPhone}
          patientFirstName={smsToList[0]?.firstName}
          patientLastName={smsToList[0]?.lastName}
          patientKey={smsToList[0]?.patientKey}
          patientPlatformPreference={smsToList[0]?.preferredPlatform}
        />

        <Overlay show={isLoading}>
          <i className="spinner-eclipse"></i>
        </Overlay>

        {modalContent.open && <TwoWayModal {...modalContent} closeModal={closeModal} />}
        {showNewPatientModal && (
          <TwoWayAddPatientModal
            firstName={searchFirstName}
            lastName={searchLastName}
            phone={formattedSearchPhoneNumber}
            show={showNewPatientModal}
            showSmsWarning={showSmsWarning}
            showSmsWarningCheck={showSmsWarningCheck}
            onCancel={closeAddPatientModal}
            onSubmit={submitNewPatient}
            defaultMessagingPref={defaultMessagingPref}
            isWhatsAppNotificationsEnabled={isWhatsAppNotificationsEnabled}
          />
        )}
      </div>
    </>
  );
};

export default withTranslation()(TwoWayConversationNew);
