import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './TwoWayList.scss';
import { Button } from '@material-ui/core';
import TwoWayCard from '../TwoWayCard/TwoWayCard';
import TwoWayConversation from '../TwoWayConversation/TwoWayConversation';
import { Route } from 'react-router';
import '../TwoWayCard/TwoWayCard.scss';
import '../TwoWayModal/TwoWayMultiRecipientModal.scss';
import Constants from '../../constants';
import TwoWayCardSkeleton from '../TwoWayCard/TwoWayCardSkeleton';
import TwoWayConversationNew from '../TwoWayConversationNew/TwoWayConversationNew';
import { withTranslation } from 'react-i18next';

class TwoWayList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countUnread: 0,
      countUnanswered: 0,
      countFlagged: 0,
      messageListScrollHeight: null,
      patient: null,
      currentFilter: Constants.TwoWayMessages.viewStatus.all,
      skeletonEnabled: true,
      enableSearch: false,
      searchResults: [],
      currentSwippedConversation: null,
    };

    this.windowHeightAdjustment = 175;
  }

  handleConversationClick = (item) => {
    this.setState(() => ({
      patient: {
        firstName: item.firstName,
        lastName: item.lastName,
        smsTo: item.smsTo,
      },
    }));
    this.props.conversationClick(item);
  };

  handleSwipe = (conversation) => {
    this.setState(() => ({
      currentSwippedConversation: conversation,
    }));
  };

  handleFilterList = (viewStatus) => () => {
    this.setState(() => ({
      enableSearch: false,
      skeletonEnabled: true,
      currentFilter: viewStatus,
    }));
    this.props.filterList(viewStatus);
  };

  handleLoadMoreToList = () => {
    this.setState((prevState) => ({
      skeletonEnabled: false,
    }));
    this.props.loadMoreToList();
  };

  handleSearchList = () => {
    // check if a scroll to top is need it here
    this.setState((prevState) => ({
      enabledSearch: !prevState.enableSearch,
      searchResults: [],
    }));
    this.props.searchList();
  };

  setFilterToUnread = () =>
    this.setState(() => ({
      currentFilter: Constants.TwoWayMessages.viewStatus.unread,
    }));

  render() {
    const { patient, currentFilter, currentSwippedConversation } = this.state;
    const {
      conversation,
      conversations,
      windowHeight,
      refreshList,
      newMessage,
      conversationContinuationToken,
      loadingConversations,
      defaultMessagingPref,
      isSmsOneWay,
      isWhatsAppNotificationsEnabled,
      countryCode,
      phoneCountryCode,
      t,
      templateList,
      conversationStatusCounts,
      errorModal,
      conversationOptOut,
      locationConfigName,
      locationConfigAddress,
      locationConfigPhone,
    } = this.props;

    const { flagged, unread, unanswered } = conversationStatusCounts;

    let cards = <TwoWayCardSkeleton />;
    if (
      this.props.conversations &&
      this.props.conversations.length >= 1 &&
      !this.props.loadingConversations
    ) {
      cards = this.props.conversations.map((item, key) => {
        return (
          <TwoWayCard
            conversation={item}
            swippedConversation={currentSwippedConversation}
            onConversationSwipped={this.handleSwipe}
            status={item.status}
            key={key}
            active={conversation === item}
            conversationClick={this.handleConversationClick}
            updateConversationStatus={this.props.updateConversationStatus}
          />
        );
      });
    } else if (!this.props.loadingConversations) {
      cards = <div className="card--holder"></div>;
    }

    return (
      <>
        <div
          className={
            window.location.pathname === `${Constants.Routes.chat}/`
              ? 'message-list'
              : 'message-list message-list--hide'
          }
        >
          <div className="message-list__header">
            <div className="btn-row">
              <Button variant="contained" onClick={newMessage}>
                {t('New Message')}
              </Button>
            </div>

            <ul className="row">
              <li
                className={
                  currentFilter === Constants.TwoWayMessages.viewStatus.all
                    ? 'active'
                    : ''
                }
                onClick={this.handleFilterList(
                  Constants.TwoWayMessages.viewStatus.all
                )}
              >
                {t('ALL')}
              </li>
              <li
                className={
                  currentFilter === Constants.TwoWayMessages.viewStatus.unread
                    ? 'active'
                    : ''
                }
                onClick={this.handleFilterList(
                  Constants.TwoWayMessages.viewStatus.unread
                )}
              >
                <span className="dot dot--blue"></span>
                {t('Unread')} ({unread})
              </li>
              <li
                className={
                  currentFilter ===
                  Constants.TwoWayMessages.viewStatus.unanswered
                    ? 'active'
                    : ''
                }
                onClick={this.handleFilterList(
                  Constants.TwoWayMessages.viewStatus.unanswered
                )}
              >
                <span className="dot dot--green"></span>
                {t('Unanswered')} ({unanswered})
              </li>
              <li
                className={
                  currentFilter === Constants.TwoWayMessages.viewStatus.flagged
                    ? 'active'
                    : ''
                }
                onClick={this.handleFilterList(
                  Constants.TwoWayMessages.viewStatus.flagged
                )}
              >
                <span className="dot dot--purple"></span>
                {t('Flagged')} ({flagged}){' '}
              </li>
            </ul>
          </div>

          {/* TODO: Conversation Filtering & sorting {currentFilter === Constants.TwoWayMessages.viewStatus.all && <TwoWayListSort setFilterToUnread={this.setFilterToUnread}/>} */}

          <div
            className="message-list__scroll"
            style={{
              height: windowHeight - this.windowHeightAdjustment + 'px',
            }}
          >
            {/* TODO: Search {enableSearch &&
              <div className='message-list__input--search' >
                <div>
                  <span className='btn-search'><FiSearch /></span>
                  <input type='text' placeholder='search name or phone number'/>
                  {searchResults.length <=0 &&<span className='txt--small txt--alert'>Under development</span>}
                  <a className='btn-search--close fr' onClick={(event) => this.handleSearchList()}><FiXCircle /></a>
                </div>
              </div>
            } */}

            {cards}

            <div className="message-list__scroll--loadmore">
              {conversations &&
                conversationContinuationToken &&
                !loadingConversations && (
                  <Button
                    className="btn-loadmore"
                    variant="outlined"
                    color="primary"
                    onClick={(event) => this.handleLoadMoreToList()}
                  >
                    {t('Load More')}
                  </Button>
                )}
              {loadingConversations && (
                <Button
                  className="btn-loadmore"
                  variant="outlined"
                  color="primary"
                  onClick={(event) => this.handleLoadMoreToList()}
                >
                  Loading
                </Button>
              )}
            </div>
          </div>
        </div>

        <Route
          path={`/chat/message/:convKey`}
          render={(props) => {
            if (conversation) {
              return (
                <TwoWayConversation
                  {...props}
                  conversation={conversation}
                  patient={patient}
                  refreshConversationList={refreshList}
                  templateList={templateList}
                  errorModal={errorModal}
                  conversationOptOut={conversationOptOut}
                  locationConfigName={locationConfigName}
                  locationConfigAddress={locationConfigAddress}
                  locationConfigPhone={locationConfigPhone}
                />
              );
            }
          }}
        />
        <Route
          path={`/chat/new`}
          render={(props) => {
            return (
              <TwoWayConversationNew
                {...props}
                defaultMessagingPref={defaultMessagingPref}
                isSmsOneWay={isSmsOneWay}
                isWhatsAppNotificationsEnabled={isWhatsAppNotificationsEnabled}
                countryCode={countryCode}
                phoneCountryCode={phoneCountryCode}
                templateList={templateList}
                conversationOptOut={conversationOptOut}
                locationConfigName={locationConfigName}
                locationConfigAddress={locationConfigAddress}
                locationConfigPhone={locationConfigPhone}
              />
            );
          }}
        />
      </>
    );
  }
}

TwoWayList.propTypes = {
  conversation: PropTypes.object,
  conversations: PropTypes.array,
  conversationClick: PropTypes.func,
  windowHeight: PropTypes.object,
  multiRecipient: PropTypes.func,
  refreshList: PropTypes.func,
  filterList: PropTypes.func,
  loadMoreToList: PropTypes.func,
  newMessage: PropTypes.func,
  conversationContinuationToken: PropTypes.string,
  loadingConversations: PropTypes.bool,
  searchList: PropTypes.func,
  lastRefreshed: PropTypes.any,
  onStoreHoursChange: PropTypes.func,
  phoneCountryCode: PropTypes.string,
  conversationStatusCounts: PropTypes.object,
  errorModal: PropTypes.func,
};

export default withTranslation()(TwoWayList);
