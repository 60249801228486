import Constants from '../constants';
import axios from 'axios';
import Storage from '../storage';
import Logger from '../logger';
import ErrorReportingService from './errorReportingService';

class TwoWayConversationService {
  /**
   * Returns chat location metadata
   */
  async getChatLocationMetadata() {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.getChatLocationMetadata +
          '/' +
          locationId;
        var res = await axios.get(api);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(error);
        reject(error);
      }
    });
  }
  /**
   * Set chat location notification
   */
  async updateLocationChatNotificiation(hasChatNotifications) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.updateLocationChatNotificiation;
        let payload = {
          hasChatNotifications: hasChatNotifications,
          locationId: locationId,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.updateLocationChatNotificiation
        );
        reject(error);
      }
    });
  }
  /**
   * Returns the list of conversations
   */
  async getConversations(filter, continuationToken) {
    return new Promise(async (resolve, reject) => {
      try {
        const pageMax = Constants.TwoWayMessages.twoWayCardCountMax;
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.messageList +
          '?locationId=' +
          locationId +
          '&pageSize=' +
          pageMax;
        if (filter !== null && filter >= 0) {
          api += '&viewStatus=' + filter;
        }
        if (continuationToken !== null) {
          api += '&continuationToken=' + encodeURIComponent(continuationToken);
        }
        var res = await axios.get(api);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        const pageMax = Constants.TwoWayMessages.twoWayCardCountMax;
        const locationId = Storage.getItem(Constants.currLocIdKey);
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.messageList +
            '?locationId=' +
            locationId +
            '&pageSize=' +
            pageMax
        );
        reject(error);
      }
    });
  }
  /**
   * Returns the count of conversation statuses
   */
  async getConversationStatusCounts() {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.conversationStatusCounts +
          '?locationId=' +
          locationId;
        var res = await axios.get(api);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        const locationId = Storage.getItem(Constants.currLocIdKey);
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.conversationStatusCounts +
            '?locationId=' +
            locationId
        );
        reject(error);
      }
    });
  }
  /**
   * Returns the list of messages in a conversation
   */
  async getMessages(conversation) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.messages +
          '?locationId=' +
          locationId +
          '&conversationKey=' +
          conversation.conversationKey;
        var res = await axios.get(api);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        const locationId = Storage.getItem(Constants.currLocIdKey);
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.messages +
            '?locationId=' +
            locationId +
            '&conversationKey=' +
            conversation.conversationKey
        );
        reject(error);
      }
    });
  }
  /**
   * Updates the viewStatus of a conversation
   */
  async updateConversationStatus(conversation) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.updateMessageStatus;
        let payload = {
          viewStatus: conversation.conversationStatus,
          conversationKey: conversation.conversationKey,
          locationId: locationId,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.updateMessageStatus
        );
        reject(error);
      }
    });
  }
  /**
   * Posts a response in a conversation
   */
  async postMessage(conversationKey, message) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.messageSend;
        let payload = {
          message: message,
          conversationKey: conversationKey,
          locationId: locationId,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.messageSend
        );
        reject(error);
      }
    });
  }
  /**
   * Finds a patient
   */
  async postFindPatient(firstName, lastName, phone, patientKey) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.findPatient;
        let payload = {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          patientKey: patientKey,
          locationId: locationId,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.findPatient
        );
        reject(error);
      }
    });
  }
  /**
   * Posts a new conversation
   */
  async postNewConversation(patientKey, message) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        let api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.newConversation;
        let payload = {
          message: message,
          patientKey: patientKey,
          locationId: locationId,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.newConversation
        );
        reject(error);
      }
    });
  }

  /**
   * returns the list of templates
   */
  async getTemplateList(firstName) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const api =
          process.env.REACT_APP_CONTENT_API +
          Constants.TwoWayMessages.api.content.templateList;
        let payload = {
          placeholders: {
            firstName,
          },
          locationId,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data.listOfTemplateDefinitions);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CONTENT_API +
            Constants.TwoWayMessages.api.content.templateList
        );
        reject(error);
      }
    });
  }

  /**
   * returns the template for a single patient
   */
  async getTemplateForPatient(chatTemplateType, firstName) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const api =
          process.env.REACT_APP_CONTENT_API +
          Constants.TwoWayMessages.api.content.templateForPatient;
        let payload = {
          chatTemplateType,
          placeholders: {
            firstName,
          },
          locationId,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data.content);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CONTENT_API +
            Constants.TwoWayMessages.api.content.templateForPatient
        );
        reject(error);
      }
    });
  }

  /**
   * sends a templated message to a patient
   */
  async sendTemplatedMessage(templateType, patientKey, freeFormContent) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const api =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.sendTemplatedMessage;
        let payload = {
          templateType,
          patientKey,
          locationId,
          freeFormContent,
        };
        var res = await axios.post(api, payload);
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.sendTemplatedMessage
        );
        reject(error);
      }
    });
  }

  async sendAttachmentMessage(
    conversationKey,
    patientKey,
    file,
    text,
    selectedTemplateId
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const url =
          process.env.REACT_APP_CHAT_API +
          Constants.TwoWayMessages.api.uploadAttachment +
          '/' +
          locationId;
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
            maxContentLength: 1048576 * 6,
            maxBodyLength: 1048576 * 6,
          },
        };

        if (!selectedTemplateId) {
          selectedTemplateId = '';
        }

        const fileData = new FormData();
        fileData.append('fileData', file);
        fileData.append('text', text);
        fileData.append('patientKey', patientKey);
        fileData.append('selectedTemplateId', selectedTemplateId);
        if (!conversationKey) {
          fileData.append('newConversation', true);
        }
        var res = await axios.post(url, fileData, config).catch((err) => {
          Logger.log('Error uploading attachment', err);
        });
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.uploadAttachment
        );
        reject(error);
      }
    });
  }

  async sendAutoquoteMessage(chatAutoquoteRequest) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const url = `${process.env.REACT_APP_CHAT_API}${Constants.TwoWayMessages.api.autoquote}/${locationId}`;
        const config = {
          headers: {
            'content-type': 'application/json',
            maxContentLength: 1048576 * 6,
            maxBodyLength: 1048576 * 6,
          },
        };

        var res = await axios
          .post(url, chatAutoquoteRequest, config)
          .catch((err) => {
            Logger.log('Error uploading attachment', err);
          });
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          process.env.REACT_APP_CHAT_API +
            Constants.TwoWayMessages.api.autoquote
        );
        reject(error);
      }
    });
  }
}

export default TwoWayConversationService;
