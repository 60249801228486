import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Utils from '../../utils';
import { HolidayRecurrenceType } from '../../enums';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import SettingsService from '../../Services/settingsService';
import './StoreHolidays.scss';

/**
 * Represents a component that renders location store holidays.
 */
class StoreHolidays extends Component {
  /**
   * Initializes a new instance of the StoreHolidays component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    const { holidays } = props;

    this.state = {
      areHolidaysDirty: false,
      canAddHoliday: true,
      canSaveHolidays: true,
      currHoliday: {
        name: '',
      },
      holidays: cloneDeep(holidays),
      isEditingHolidays: false,
      showErrorModal: false,
      showRemoveHolidayModal: false,
      showSaveAlert: false,
    };
    this._initialHolidays = cloneDeep(holidays);
    this._settingsService = new SettingsService();
  }

  _onAddHoliday = () => {
    this.setState((prevState) => {
      const { holidays } = prevState;
      let newId = 0;

      if (holidays?.length > 0) {
        // Find the max id from the resource ids and increment by 1.
        newId = holidays.reduce((a, b) => (a.id > b.id ? a : b)).id + 1;
      }

      // We want to default the start date to midnight and end
      // date to one minute before midnight to signify a full day.
      const startDate = momentLocaleWrapper().toDate();
      startDate.setHours(0, 0);

      const endDate = momentLocaleWrapper().toDate();
      endDate.setHours(23, 59);

      let updatedHolidays = holidays.map((holiday) => {
        holiday.enabled = false;
        return holiday;
      });
      updatedHolidays = holidays.concat({
        id: newId,
        name: '',
        disabled: false,
        locationHolidayId: 0,
        startDate: startDate,
        endDate: endDate,
        enabled: true,
        locationId: this.props.locationId,
        recurrenceId: HolidayRecurrenceType.none,
      });

      const canSaveHolidays = this._verifyHolidays(updatedHolidays);

      return {
        areHolidaysDirty: true,
        canAddHoliday: false,
        canSaveHolidays: canSaveHolidays,
        holidays: updatedHolidays,
        isEditingHolidays: true,
      };
    });
  };

  _onCancelEditHoliday = (holidayId) => {
    this.setState((prevState) => {
      const updatedHolidays = prevState.holidays.map((holiday) => {
        // Reset the holiday to its original state.
        if (holiday.id === holidayId) {
          const originalHoliday = this._initialHolidays.find(
            (h) => h.id === holidayId
          );

          if (originalHoliday) {
            holiday = cloneDeep(originalHoliday);
            holiday.startDate = momentLocaleWrapper(
              holiday.startDate,
              '(YYYY, MM, DD, HH, mm)'
            ).toDate();
            holiday.endDate = momentLocaleWrapper(
              holiday.endDate,
              '(YYYY, MM, DD, HH, mm)'
            ).toDate();
          }
        }

        holiday.enabled = false;
        holiday.disabled = false;

        return holiday;
      });

      return {
        canAddHoliday: true,
        areHolidaysDirty: false,
        isEditingHolidays: false,
        holidays: updatedHolidays,
      };
    });
  };

  _onCloseSaveAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showSaveAlert: false }));
  };

  _onEditHoliday = (id, path, value) => {
    this.setState((prevState) => {
      const updatedHolidays = prevState.holidays.map((holiday) => {
        if (holiday.id === id) {
          const propertyToUpdate = path[0];

          // We want to default the start date to midnight and end
          // date to one minute before midnight to signify a full day.
          if (propertyToUpdate === 'startDate') {
            value.setHours(0, 0);
          } else if (propertyToUpdate === 'endDate') {
            value.setHours(23, 59);
          }

          Utils.update(holiday, path, value);
        }

        return holiday;
      });

      const canSaveHolidays = this._verifyHolidays(updatedHolidays);

      return {
        areHolidaysDirty: true,
        canSaveHolidays: canSaveHolidays,
        holidays: updatedHolidays,
        isEditingHolidays: true,
      };
    });
  };

  _onEnableHoliday = (holidayId, enabled, disabled) => {
    this.setState((prevState) => {
      const updatedHolidays = prevState.holidays.map((holiday) => {
        if (!disabled) {
          if (holiday.id === holidayId) {
            holiday.enabled = enabled;
          } else {
            holiday.enabled = false;
          }
        }

        return holiday;
      });

      const canSaveHolidays = this._verifyHolidays(updatedHolidays);

      return {
        areHolidaysDirty: false,
        canAddHoliday: !enabled,
        canSaveHolidays: canSaveHolidays,
        holidays: updatedHolidays,
        isEditingHolidays: enabled,
      };
    });
  };

  _onRemoveHoliday = async (holiday) => {
    if (holiday) {
      let canRemove = false;

      if (holiday.locationHolidayId > 0) {
        try {
          this.setState(() => ({ showRemoveHolidayModal: false }));

          await this._settingsService.deleteLocationHoliday({
            locationId: holiday.locationId,
            locationHolidayId: holiday.locationHolidayId,
          });

          canRemove = true;
        } catch (error) {
          this._toggleErrorModal(
            error,
            true,
            this.props.t(
              'There was an unexpected issue with removing the resource'
            )
          );
        }
      } else {
        canRemove = true;
      }

      if (canRemove) {
        this.setState((prevState) => {
          const updatedHolidays = prevState.holidays.filter((h) => {
            h.enabled = false;
            h.disabled = false;

            return h.id !== holiday.id;
          });
          const canSaveHolidays = this._verifyHolidays(updatedHolidays);
          const { onHolidaysChange } = this.props;
          onHolidaysChange && onHolidaysChange(updatedHolidays);

          return {
            areHolidaysDirty: false,
            canSaveHolidays: canSaveHolidays,
            holidays: updatedHolidays,
            isEditingHolidays: false,
            showRemoveHolidayModal: false,
          };
        });
      }
    }
  };

  _onSaveHoliday = async (holiday) => {
    if (holiday) {
      try {
        const savedHoliday = await this._settingsService.saveLocationHoliday({
          holiday: holiday,
        });
        savedHoliday.id = holiday.id;

        this.setState((prevState) => {
          const updatedHolidays = prevState.holidays.map((holiday) => {
            if (holiday.id === savedHoliday.id) {
              holiday.locationHolidayId = savedHoliday.locationHolidayId;

              // Update the initial holiday, so we have an fresh copy for later cancelling.
              this._initialHolidays = this._initialHolidays.map((h) => {
                if (h.id === savedHoliday.id) {
                  h = cloneDeep(savedHoliday);
                }

                return h;
              });
            }

            holiday.enabled = false;
            holiday.disabled = false;

            return holiday;
          });
          const { onHolidaysChange } = this.props;
          onHolidaysChange && onHolidaysChange(updatedHolidays);

          return {
            areHolidaysDirty: false,
            canAddHoliday: true,
            holidays: updatedHolidays,
            isEditingHolidays: false,
            showSaveAlert: true,
          };
        });
      } catch (error) {
        this._toggleErrorModal(
          error,
          true,
          this.props.t('There was an unexpected issue with saving holidays.')
        );
      }
    }
  };

  /**
   * Executes when its time to toggle the remove holiday modal.
   * @param {Object} holiday The holiday to remove.
   */
  _onToggleRemoveHolidayModal = (holiday) => {
    if (holiday) {
      this.setState((prevState) => ({
        currHoliday: holiday,
        showRemoveHolidayModal: !prevState.showRemoveHolidayModal,
      }));
    }
  };

  _verifyHolidays = (holidays) => {
    let canSaveHolidays = true;
    let startDateError = '';

    if (holidays?.length > 0) {
      for (let index = 0; index < holidays.length; ++index) {
        const holiday = holidays[index];

        if (!holiday.name) {
          canSaveHolidays = false;
        }

        if (!holiday.startDate) {
          canSaveHolidays = false;
        }

        if (!holiday.endDate) {
          canSaveHolidays = false;
        }

        // Need to zero out the hours and minutes for end date before comparing.
        // Normally we want end date to be 23:59 to represent a full day for
        // a holiday, but that causes the compare to fail below.
        const endDate = new Date(holiday.endDate);
        endDate.setHours(0, 0);

        if (holiday.startDate > endDate) {
          canSaveHolidays = false;
          startDateError = this.props.t('Start date must be before end date');
        }

        holiday.startDateError = startDateError;

        if (
          holiday.recurrenceId !== HolidayRecurrenceType.none &&
          holiday.recurrenceId !== HolidayRecurrenceType.monthly &&
          holiday.recurrenceId !== HolidayRecurrenceType.annually
        ) {
          canSaveHolidays = false;
        }
      }
    }

    return canSaveHolidays;
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      areHolidaysDirty,
      canAddHoliday,
      canSaveHolidays,
      currHoliday,
      holidays,
      isEditingHolidays,
      showRemoveHolidayModal,
      showSaveAlert,
    } = this.state;
    const { t } = this.props;

    let currEditingHoliday = holidays.find((holiday) => holiday.enabled);

    if (!currEditingHoliday) {
      const currDate = momentLocaleWrapper().toDate();

      currEditingHoliday = {
        id: -1,
        name: '',
        disabled: false,
        locationHolidayId: 0,
        recurrenceId: HolidayRecurrenceType.none,
        startDate: currDate,
        endDate: currDate,
        enabled: false,
      };
    }

    const holidayItems = holidays.map((holiday, key) => {
      const { enabled, id, name } = holiday;
      const isDisabled =
        currEditingHoliday.id !== id && currEditingHoliday.enabled;

      if (isDisabled) {
        holiday.disabled = true;
      }

      return (
        <li
          className={`store-holidays__item ${
            enabled ? 'store-holidays__item--selected' : ''
          } ${isDisabled ? 'store-holidays__item--disabled' : ''}`}
          key={key}
          onClick={() => this._onEnableHoliday(id, true, holiday.disabled)}
        >
          <span className="store-holidays__item-text">
            {name ? name : `<${t('New Holiday')}>`}
          </span>
          {enabled && (
            <button
              className="store-holidays__delete"
              onClick={() =>
                this._onToggleRemoveHolidayModal(currEditingHoliday)
              }
            >
              <DeleteIcon className="store-holidays__icon" />
            </button>
          )}
        </li>
      );
    });

    return (
      <div className="store-holidays">
        <div className="store-holidays__list-cont">
          <h3 className="store-holidays__list-title">{t('Saved Holidays')}</h3>
          <ul className="store-holidays__list">{holidayItems}</ul>
          <button
            className="store-holidays__add"
            disabled={!canAddHoliday}
            onClick={this._onAddHoliday}
          >
            <AddIcon />
            &nbsp;{t('Add')}
          </button>
        </div>
        <form className="store-holidays__form">
          <div className="store-holidays__title-cont">
            <h2 className="store-holidays__form-title">{t('Holidays')}</h2>
            <span className="store-holidays__form-subtitle">
              {t('Add, edit or delete special days')}
            </span>
          </div>
          <div className="store-holidays__row">
            <div className="store-holidays__group">
              <TextField
                className="store-holidays__input"
                disabled={!isEditingHolidays}
                label={t('Holiday Name')}
                type="text"
                value={currEditingHoliday.name}
                variant="outlined"
                onChange={(e) =>
                  this._onEditHoliday(
                    currEditingHoliday.id,
                    ['name'],
                    e.target.value
                  )
                }
              />
            </div>
            <span className="store-holidays__spacer store-holidays__spacer--spacer">
              {t('To')}
            </span>
            <div className="store-holidays__group store-holidays__group--spacer">
              <label>&nbsp;</label>
              <input type="text" />
            </div>
          </div>
          <div className="store-holidays__row">
            <div className="store-holidays__group">
              <MuiPickersUtilsProvider
                libInstance={momentLocaleWrapper}
                utils={MomentUtils}
              >
                <DatePicker
                  cancelLabel={t('Cancel')}
                  className="store-holidays__date"
                  disabled={!isEditingHolidays}
                  disableToolbar
                  format="ddd, L"
                  id="start-date"
                  label={t('Start Date')}
                  margin="normal"
                  okLabel={t('OK')}
                  required
                  value={currEditingHoliday.startDate}
                  onChange={(date) =>
                    this._onEditHoliday(
                      currEditingHoliday.id,
                      ['startDate'],
                      date.toDate()
                    )
                  }
                />
              </MuiPickersUtilsProvider>
              <span
                className={`store-holidays__error ${
                  currEditingHoliday.startDateError
                    ? 'store-holidays__error--visible'
                    : ''
                }`}
              >
                {currEditingHoliday.startDateError || '&nbsp'}
              </span>
            </div>
            <span className="store-holidays__spacer">{t('To')}</span>
            <div className="store-holidays__group">
              <MuiPickersUtilsProvider
                libInstance={momentLocaleWrapper}
                utils={MomentUtils}
              >
                <DatePicker
                  cancelLabel={t('Cancel')}
                  className="store-holidays__date"
                  disabled={!isEditingHolidays}
                  disableToolbar
                  format="ddd, L"
                  id="end-date"
                  label={t('End Date')}
                  margin="normal"
                  okLabel={t('OK')}
                  required
                  value={currEditingHoliday.endDate}
                  onChange={(date) =>
                    this._onEditHoliday(
                      currEditingHoliday.id,
                      ['endDate'],
                      date.toDate()
                    )
                  }
                />
              </MuiPickersUtilsProvider>
              <span className="store-holidays__error">&nbsp;</span>
            </div>
          </div>
          <div className="store-holidays__row">
            <div className="store-holidays__group">
              <FormControl
                className={`store-holidays__holidays ${
                  !isEditingHolidays ? 'store-holidays__holidays--disabled' : ''
                }`}
                variant="outlined"
              >
                <InputLabel id="store-holidays-holidays">
                  {t('Recurrence')}
                </InputLabel>
                <Select
                  disabled={!isEditingHolidays}
                  value={currEditingHoliday.recurrenceId}
                  onChange={(e) =>
                    this._onEditHoliday(
                      currEditingHoliday.id,
                      ['recurrenceId'],
                      parseInt(e.target.value)
                    )
                  }
                >
                  <MenuItem value={HolidayRecurrenceType.none}>
                    {t('None')}
                  </MenuItem>
                  <MenuItem value={HolidayRecurrenceType.monthly}>
                    {t('Monthly')}
                  </MenuItem>
                  <MenuItem value={HolidayRecurrenceType.annually}>
                    {t('Annually')}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <span className="store-holidays__spacer store-holidays__spacer--spacer">
              {t('To')}
            </span>
            <div className="store-holidays__group store-holidays__group--spacer">
              <label>&nbsp;</label>
              <input type="text" />
            </div>
          </div>
          <div className="store-holidays__footer">
            <button
              className="store-holidays__button store-holidays__button--cancel"
              disabled={!isEditingHolidays}
              type="button"
              onClick={() => this._onCancelEditHoliday(currEditingHoliday.id)}
            >
              {t('Cancel')}
            </button>
            <button
              className="store-holidays__button"
              disabled={!(canSaveHolidays && areHolidaysDirty)}
              type="button"
              onClick={() => this._onSaveHoliday(currEditingHoliday)}
            >
              {t('Save')}
            </button>
          </div>
        </form>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showSaveAlert}
          onClose={this._onCloseSaveAlert}
        >
          <Alert
            className="admin-settings__alert"
            severity="success"
            onClose={this._onCloseSaveAlert}
          >
            {t('Saved successfully')}
          </Alert>
        </Snackbar>
        <Dialog open={showRemoveHolidayModal}>
          <DialogTitle>{t('Confirm')}</DialogTitle>
          <DialogContent>
            <p className="appt-types__modal-body">
              {t('Remove?')}&nbsp;
              <span className="appt-types__appt-type">{currHoliday.name}?</span>
            </p>
          </DialogContent>
          <DialogActions>
            <button
              className="appt-types__modal-button appt-types__modal-button--cancel"
              onClick={() => this._onToggleRemoveHolidayModal(currHoliday)}
            >
              {t('Cancel')}
            </button>
            <button
              className="appt-types__modal-button appt-types__modal-button--confirm"
              onClick={() => this._onRemoveHoliday(currHoliday)}
            >
              {t('Remove')}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

StoreHolidays.propTypes = {
  holidays: PropTypes.array.isRequired,
  locationId: PropTypes.number.isRequired,
  onHolidaysChange: PropTypes.func,
};

export default withTranslation()(StoreHolidays);
