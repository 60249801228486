import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import Popover from '@material-ui/core/Popover';
import CalendarQueueItem from './CalendarQueueItem';
import CalendarQueueItemTooltip from './CalendarQueueItemTooltip';
import Enums from '../../enums';
import { withTranslation } from 'react-i18next';
import EventBuilder from '../../eventBuilder';
import './CalendarQueue.scss';

const eBuilder = new EventBuilder();

/**
 * Represents the calendar queue for customers.
 */
class CalendarQueue extends Component {
  /**
   * Initializes a new instance of the CalendarQueue component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this._initialCurrApptStructure = {
      date: '',
      examType: '',
      patient: {
        firstName: '',
        lastName: '',
        phone: '',
      },
    };
    this.state = {
      currItem: null,
      showItemPopover: false,
      currAppt: {
        created: '',
        patient: {
          firstName: '',
          lastName: '',
        },
        appointmentType: {
          length: 0,
          displayName: '',
        },
      },
    };

    eBuilder
      .withCategory(eBuilder.Category.Scheduler.experience)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .withAction(eBuilder.Action.Scheduler.Click.Waitlist.appointment);
  }

  /**
   * Executes when an appointment is clicked.
   * @param {Object} target The target element.
   * @param {Object} currAppt The current appointment.
   */
  onOpenTooltip = (target, currAppt) => {
    this.setState((prevState) => ({
      currItem: target,
      currAppt: cloneDeep(currAppt),
      showItemPopover: !prevState.showItemPopover,
    }));
    eBuilder.post();
  };

  /**
   * Executes when the tooltip closes.
   */
  onCloseTooltip = () => {
    this.setState(() => ({ showItemPopover: false }));
  };

  /**
   * Renders the component.
   */
  render() {
    const { currAppt, currItem, showItemPopover } = this.state;
    const { appointments, countrySettings, locationSettings } = this.props;
    const items = appointments.map(
      (appointment, key) =>
        appointment.currentStatus === Enums.AppointmentStatus.queued && (
          <CalendarQueueItem
            appointment={appointment}
            key={key}
            onClick={this.onOpenTooltip}
          />
        )
    );

    const { t } = this.props;

    return (
      <div className="cal-queue">
        <div className="cal-queue__header">{t('Waitlist')}</div>
        <div className="cal-queue__queue" onScroll={this._onScroll}>
          {items}
        </div>
        <Popover anchorEl={currItem} open={showItemPopover}>
          <CalendarQueueItemTooltip
            countrySettings={countrySettings}
            item={currAppt}
            locationSettings={locationSettings}
            onCloseTooltip={this.onCloseTooltip}
          />
        </Popover>
      </div>
    );
  }
}

CalendarQueue.propTypes = {
  appointments: PropTypes.array.isRequired,
  countrySettings: PropTypes.array.isRequired,
  locationSettings: PropTypes.array.isRequired,
};

export default withTranslation()(CalendarQueue);
