import React, { Component } from 'react';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';
import {
  StellestOrderType,
  StellestEdgingOptions,
  StellestCoatingOptions,
} from '../../enums';

//MUI form elements
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import PrescriptionUpload from '../PrescriptionUpload/PrescriptionUpload';
import './OrderDetails.scss';
const CharacterCount = ({ value, limit, ...restProps }) => {
  value = value.substring(0, limit);
  return (
    <div className="character-count" {...restProps}>
      {value ? value.length : 0} / {limit}
    </div>
  );
};

class OrderDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPhoto: false,
      dataComplete: false,
    };
  }

  componentDidMount = async () => {};

  onFieldChange = (field, value) => {
    this.props.onFieldChange(field, value);
  };

  showDecimal = (field, value) => {
    var num = parseFloat(value);
    var clean = num.toFixed(2);
    this.props.onFieldChange(field, clean);
  };

  handleFirstOrderDateChange = (date) => {
    this.props.handleFirstOrderDateChange(date);
  };

  _getOrderTypeName = (orderType) => {
    switch (orderType) {
      case StellestOrderType.StandardLens:
        return 'Stellest Lenses (Standalone)';
      case StellestOrderType.CarePackage:
        return 'Stellest Care Program';
      case StellestOrderType.CarePackageSecondPair:
        return 'Stellest Care Program Second Pair';
      case StellestOrderType.Assurance:
        return 'Assurance for Stellest';
      default:
        return '';
    }
  };

  _rightColInputComplete = () => {
    const { parentState } = this.props;
    const { axisR, axisL, cylR, cylL, htL, htR, pdR, pdL, sphereR, sphereL } =
      parentState;

    if (
      axisR &&
      axisL &&
      cylR &&
      cylL &&
      htL &&
      htR &&
      pdR &&
      pdL &&
      sphereR &&
      sphereL
    ) {
      if (
        axisL !== '' &&
        axisR !== '' &&
        cylL !== '' &&
        cylR !== '' &&
        htL !== '' &&
        htR !== '' &&
        pdR !== '' &&
        pdL !== '' &&
        sphereL !== '' &&
        sphereR !== ''
      ) {
        return true;
      }
    }
    return false;
  };

  handleDatePickerError = (picker, error, value) => {
    if (this.props.handleDatePickerError) {
      this.props.handleDatePickerError(picker, error, value);
    }
  };

  renderOrderTypeMenuItem = (orderType) => {
    const { enabledOrderTypes, t } = this.props;
    if (enabledOrderTypes.some((x) => x == orderType)) {
      return (
        <MenuItem key={orderType} value={orderType}>
          {t(this._getOrderTypeName(orderType))}
        </MenuItem>
      );
    }
  };

  render() {
    const { dataPresent, id, parentState, print, t, enabledOrderTypes } =
      this.props;

    const {
      axisR,
      axisL,
      comments,
      cylR,
      cylL,
      dateFormat,
      edgingOption,
      errors,
      firstOrderDate,
      frameModel,
      htL,
      htR,
      lensCoating,
      orderReferenceNumber,
      orderSubmissionMedium,
      orderType,
      pdL,
      pdR,
      prescriptionImageUrl,
      sasToken,
      sphereR,
      sphereL,
    } = parentState;

    //evaluate disableInput here
    const rightColInputComplete = this._rightColInputComplete();
    const showPhoto = prescriptionImageUrl;
    const disableInput = dataPresent && rightColInputComplete;
    return (
      <div className="stellest-appointment-form-section medical-details">
        {!dataPresent && (
          <h2>
            {t('New Order')}{' '}
            <span className="required">
              <span className="astrisk">*</span> {t('REQUIRED')}
            </span>
          </h2>
        )}
        <div className="left-col">
          <FormControl className="product-picker" required variant="outlined">
            <InputLabel>{t('Essilor Order Type')}</InputLabel>
            <Select
              disabled={disableInput}
              error={errors.orderType}
              id={`essilor-order-type${id}`}
              labelId={`cal-newappt-appttype-label${id}`}
              onChange={(e) => this.onFieldChange('orderType', e.target.value)}
              value={orderType}
              variant="outlined"
            >
              {this.renderOrderTypeMenuItem(StellestOrderType.CarePackage)}
              {this.renderOrderTypeMenuItem(StellestOrderType.StandardLens)}
              {this.renderOrderTypeMenuItem(StellestOrderType.Assurance)}
            </Select>
          </FormControl>
          <FormControl
            className="order-submission-medium"
            required
            variant="outlined"
          >
            <InputLabel>{t('Order Submission Medium')}</InputLabel>
            <Select
              disabled={disableInput}
              error={errors.orderSubmissionMedium}
              id={`order-submission-medium${id}`}
              labelId={`order-submission-medium-label${id}`}
              onChange={(e) =>
                this.onFieldChange('orderSubmissionMedium', e.target.value)
              }
              value={orderSubmissionMedium}
              variant="outlined"
            >
              <MenuItem key={1} value={1}>
                {t('Enter Digital Prescription')}
              </MenuItem>
              <MenuItem key={2} value={2}>
                {t('Upload Photo of Prescription')}
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            disabled={disableInput}
            error={errors.orderReferenceNumber}
            id={`order-ref${id}`}
            label={t('Order Reference Number')}
            onChange={(e) =>
              this.onFieldChange('orderReferenceNumber', e.target.value)
            }
            required
            helperText={
              errors.orderReferenceNumber
                ? t(
                    'Duplicate order reference number entered. Order Reference Number must be unique across all orders for this location.'
                  )
                : t(
                    'Order Reference Number must be unique across all orders for this location.'
                  )
            }
            value={orderReferenceNumber}
            variant="outlined"
            inputProps={{ maxLength: 20 }}
          />
          <MuiPickersUtilsProvider
            libInstance={momentLocaleWrapper}
            utils={MomentUtils}
          >
            <KeyboardDatePicker
              disabled={disableInput}
              error={errors.firstOrderDate}
              className="date-picker"
              format={dateFormat}
              id={`order-date-picker${id}`}
              label={t('Order Date')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onChange={this.handleFirstOrderDateChange}
              onError={(error, value) =>
                this.handleDatePickerError('firstOrderDate', error, value)
              }
              required
              value={firstOrderDate}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
          <FormControl className="lens-coating" required variant="outlined">
            <InputLabel>{t('Lens Coating')}</InputLabel>
            <Select
              disabled={disableInput}
              error={errors.lensCoating}
              id={`lens-coating${id}`}
              labelId={`lens-coating-label${id}`}
              onChange={(e) =>
                this.onFieldChange('lensCoating', e.target.value)
              }
              value={lensCoating}
              variant="outlined"
            >
              <MenuItem key={1} value={StellestCoatingOptions.Prevencia}>
                {t('Crizal Prevencia')}
              </MenuItem>
              <MenuItem key={2} value={StellestCoatingOptions.CrizalAlizeUv}>
                {t('Crizal Alize UV')}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className="edging-option" required variant="outlined">
            <InputLabel>{t('Edging Option')}</InputLabel>
            <Select
              disabled={disableInput}
              error={errors.edgingOption}
              id={`edging-option${id}`}
              labelId={`edging-option-label${id}`}
              onChange={(e) =>
                this.onFieldChange('edgingOption', e.target.value)
              }
              value={edgingOption}
              variant="outlined"
            >
              <MenuItem key={1} value={StellestEdgingOptions.Edging}>
                {t('With Edging')}
              </MenuItem>
              <MenuItem key={2} value={StellestEdgingOptions.NoEdging}>
                {t('Without Edging')}
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            disabled={edgingOption !== 1 || disableInput}
            className={
              edgingOption !== 1 || disableInput
                ? 'frame-model frame-model-disabled'
                : 'frame-model'
            }
            error={errors.frameModel}
            id={`frame-model${id}`}
            InputProps={{
              startAdornment: (
                <CharacterCount value={frameModel} limit={500}></CharacterCount>
              ),
              maxLength: 500,
            }}
            label={t('Frame Model')}
            onChange={(e) => this.onFieldChange('frameModel', e.target.value)}
            multiline
            rows={3}
            value={frameModel ? frameModel.substring(0, 500) : ''}
            variant="outlined"
          />
          <TextField
            disabled={disableInput}
            error={errors.comments}
            className="cs-comments"
            id={`cs-comments${id}`}
            InputProps={{
              startAdornment: (
                <CharacterCount value={comments} limit={500}></CharacterCount>
              ),
              maxLength: 500,
            }}
            label={t('CS Comments')}
            onChange={(e) => this.onFieldChange('comments', e.target.value)}
            multiline
            rows={3}
            value={comments ? comments.substring(0, 500) : ''}
            variant="outlined"
          />
        </div>

        <div className="right-col">
          {showPhoto && (
            <div style={{ width: '100%' }}>
              <PrescriptionUpload
                id={id}
                t={t}
                sasToken={sasToken}
                photo={prescriptionImageUrl}
                mode={'ACCEPTED'}
                error={errors.prescriptionImage}
              ></PrescriptionUpload>
            </div>
          )}
          {orderSubmissionMedium === 2 && !showPhoto && (
            <div style={{ width: '100%' }}>
              <PrescriptionUpload
                id={id}
                t={t}
                onPhotoCropped={(imageUrlData) => {
                  this.onFieldChange('prescriptionImage', imageUrlData);
                }}
                onPhotoDeleted={() => {
                  this.onFieldChange('prescriptionImage', null);
                }}
                error={errors.prescriptionImage}
              ></PrescriptionUpload>
            </div>
          )}
          {orderSubmissionMedium === 1 && (
            <div className="prescription-table">
              <span className="label-text">
                {t('Prescription Details')} <span className="required">*</span>
              </span>
              <div className="label">
                <span className="product-label">{t('Rx')}</span>
                <span>{t('Sph')}</span>
                <span>{t('Cyl')}</span>
                <span>{t('Axis')}</span>
                <span>{t('Pd')}</span>
                <span>{t('Height')}</span>
              </div>
              <div className="fields">
                <div className="field-row">
                  <span>
                    <b>{t('R(OD)')}</b>
                  </span>
                  <TextField
                    disabled={disableInput}
                    error={errors.sphereR}
                    id={`sphere-right${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) =>
                      this.onFieldChange('sphereR', e.target.value)
                    }
                    onBlur={(e) => this.showDecimal('sphereR', e.target.value)}
                    required
                    type="number"
                    value={sphereR}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.25, min: -9, max: 0 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.cylR}
                    id={`cylinder-right${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) => this.onFieldChange('cylR', e.target.value)}
                    onBlur={(e) => this.showDecimal('cylR', e.target.value)}
                    required
                    type="number"
                    value={cylR}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.25, min: -4, max: 0 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.axisR}
                    id={`axis-right${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) =>
                      this.onFieldChange('axisR', e.target.value)
                    }
                    required
                    type="number"
                    value={axisR}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 1, min: 0, max: 180 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.pdR}
                    id={`pd-right${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) => this.onFieldChange('pdR', e.target.value)}
                    onBlur={(e) => this.showDecimal('pdR', e.target.value)}
                    required
                    type="number"
                    value={pdR}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.5, min: 10, max: 40 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.htR}
                    id={`ht-right${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) => this.onFieldChange('htR', e.target.value)}
                    onBlur={(e) => this.showDecimal('htR', e.target.value)}
                    required
                    type="number"
                    value={htR}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.5, min: 10, max: 40 }}
                  />
                </div>
                <div className="field-row">
                  <span>
                    <b>{t('L(OS)')}</b>
                  </span>
                  <TextField
                    disabled={disableInput}
                    error={errors.sphereL}
                    id={`sphere-left${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) =>
                      this.onFieldChange('sphereL', e.target.value)
                    }
                    onBlur={(e) => this.showDecimal('sphereL', e.target.value)}
                    required
                    type="number"
                    value={sphereL}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.25, min: -9, max: 0 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.cylL}
                    id={`cylinder-left${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) => this.onFieldChange('cylL', e.target.value)}
                    onBlur={(e) => this.showDecimal('cylL', e.target.value)}
                    required
                    type="number"
                    value={cylL}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.25, min: -4, max: 0 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.axisL}
                    id={`axis-left${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) =>
                      this.onFieldChange('axisL', e.target.value)
                    }
                    required
                    type="number"
                    value={axisL}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 1, min: 0, max: 180 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.pdL}
                    id={`pdL-left${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) => this.onFieldChange('pdL', e.target.value)}
                    onBlur={(e) => this.showDecimal('pdL', e.target.value)}
                    required
                    type="number"
                    value={pdL}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.5, min: 10, max: 40 }}
                  />
                  <TextField
                    disabled={disableInput}
                    error={errors.htL}
                    id={`ht-left${id}`}
                    onWheel={(event) => {
                      event.target.blur();
                    }}
                    onChange={(e) => this.onFieldChange('htL', e.target.value)}
                    onBlur={(e) => this.showDecimal('htL', e.target.value)}
                    required
                    type="number"
                    value={htL}
                    variant="outlined"
                    inputProps={{ maxLength: 20, step: 0.5, min: 10, max: 40 }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(OrderDetails);
