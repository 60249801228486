import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the patient service.
 */
class PatientService {
  /**
   * Returns an array of patients.
   * @returns A Promise.
   */
  async getPatientList(searchFields) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const url = `${process.env.REACT_APP_PATIENT_API}?locationId=${locationId}&firstName=${searchFields.firstName}&lastName=${searchFields.lastName}&phone=${searchFields.phone}&email=${searchFields.email}&appointmentDate=${searchFields.apptDate}&offset=${searchFields.offset}&limit=20`;
        const res = await axios.get(url);

        let patientList = null;

        if (res?.data) {
          patientList = { ...res.data };
        }

        resolve(patientList);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        const locationId = Storage.getItem(Constants.currLocIdKey);
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_PATIENT_API}?locationId=${locationId}&firstName=${searchFields.firstName}&lastName=${searchFields.lastName}&phone=${searchFields.phone}&email=${searchFields.email}&appointmentDate=${searchFields.apptDate}&offset=${searchFields.offset}&limit=20`
        );
        reject(error);
      }
    });
  }

  async createPatient(patient) {
    return new Promise(async (resolve, reject) => {
      try {
        patient.locationId = Storage.getItem(Constants.currLocIdKey);
        const url = `${process.env.REACT_APP_PATIENT_API}/Create`;
        const res = await axios.post(url, patient);

        let resPatient = null;

        if (res?.data) {
          resPatient = { ...res.data };
        }

        resolve(resPatient);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_PATIENT_API}/Create`
        );
        reject(error);
      }
    });
  }

  async updatePatient(patient) {
    return new Promise(async (resolve, reject) => {
      try {
        patient.locationId = Storage.getItem(Constants.currLocIdKey);
        const url = `${process.env.REACT_APP_PATIENT_API}/Update`;
        const res = await axios.post(url, patient);

        let resPatient = null;

        if (res?.data) {
          resPatient = { ...res.data };
        }

        resolve(resPatient);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export default PatientService;
