import React, { Component } from 'react';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import TextField from '@material-ui/core/TextField';
import RegEx from '../../regex';
import Enums from '../../enums';
import Utils from '../../utils';
import Storage from '../../storage';
import Constants from '../../constants';
import LoginHeader from '../Login/LoginHeader';
import LoginFooter from '../Login/LoginFooter';
import PageviewBuilder from '../../pageviewBuilder';
import Illustration from '../../Assets/Images/Reset-Password-Screen-Art.jpg';
import ContentManagementService from '../../Services/contentManagementService';
import './ForgotPassword.scss';

const pBuilder = new PageviewBuilder();

/**
 * Represents the forgot password component.
 */
class ForgotPassword extends Component {
  /**
   * Initializes a new instance of the ForgotPassword component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this._formErrorSetClean = {
      email: '',
      serverError: '',
    };
    this.state = {
      disableSubmit: false,
      email: '',
      formErrors: cloneDeep(this._formErrorSetClean),
      submitSuccessful: false,
      siteId: 1001,
      languageTag: 'en-US',
    };

    this._isFormValid = this._isFormValid.bind(this);
    this._onEmailChange = this._onEmailChange.bind(this);
    this._onKeyPress = this._onKeyPress.bind(this);
    this._onSubmit = this._onSubmit.bind(this);

    this.logoUrl = Utils.getTaglineLogoUrl();

    this._contentManagementService = new ContentManagementService();

    pBuilder.pageview(pBuilder.Page.forgotPassword);
  }

  async componentDidMount() {
    let siteConfig = Storage.getItem(Constants.siteConfig);

    if (!siteConfig) {
      await this._getMarketConfig();
    }

    siteConfig = Storage.getItem(Constants.siteConfig);

    if (siteConfig?.siteId && siteConfig?.languageTag) {
      const { languageTag } = siteConfig;
      await this._contentManagementService.loadLocalizations(languageTag);
      await this.props.i18n.changeLanguage(languageTag);

      this.setState({
        siteId: siteConfig.siteId,
        languageTag: languageTag,
      });
      this.forceUpdate();
    }
  }

  _getMarketConfig = async () => {
    const url = `${
      process.env.REACT_APP_SETTINGS_API
    }/getSiteConfigByUrl?url=${encodeURI(window.location.href)}`;
    const res = await axios.get(url);

    if (res?.data) {
      const { i18n } = this.props;
      const data = { ...res.data };
      const { languageTag } = data;

      await this._contentManagementService.loadLocalizations(languageTag);

      await i18n.changeLanguage(data.languageTag, () => {
        const { t } = this.props;
        document.title = `${t(data.siteName)}`;
        Storage.setItem(Constants.siteConfig, data);
      });
    }
  };

  _isFormValid() {
    let isValid = true;
    const { email } = this.state;
    const formErrors = cloneDeep(this._formErrorSetClean);
    const { t } = this.props;

    if (!email) {
      isValid = false;
      formErrors.email = t('Email is required');
    }

    if (email && !RegEx.email.test(email)) {
      isValid = false;
      formErrors.email = t('Please enter a valid email');
    }

    this.setState(() => ({ formErrors: formErrors }));

    return isValid;
  }

  _onEmailChange(e) {
    const { value } = e.target;

    this.setState(() => ({ email: value }));
  }

  _onKeyPress(e) {
    const { key } = e;

    if (key === Enums.KeyboardKeys.enterKey) {
      this._onSubmit();
    }
  }

  async _onSubmit() {
    const { t } = this.props;

    if (this._isFormValid()) {
      try {
        this.setState(() => ({ disableSubmit: true }));

        const { email, languageTag, siteId } = this.state;

        const url = `${
          process.env.REACT_APP_ADMIN_API
        }/resetPassword?username=${encodeURIComponent(
          email
        )}&languageTag=${languageTag}&siteId=${siteId}`;
        axios.post(url);

        this.setState(() => ({
          email: '',
          submitSuccessful: true,
        }));


        // Redirect the user after 3 seconds to the login page.
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          window.location = '/';
        }, 5000);
      } catch (error) {
        if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusInternalServerError)
        ) {
          this.setState(() => ({
            disableSubmit: false,
            formErrors: {
              serverError: t('An unexpected issue occurred while submitting'),
            },
          }));
        } else {
          this.setState(() => ({
            disableSubmit: false,
            formErrors: { serverError: t('Invalid email') },
          }));
        }
      }
    }
  }

  /**
   * Renders the component.
   */
  render() {
    const { disableSubmit, email, formErrors, submitSuccessful } = this.state;

    const { t } = this.props;

    return (
      <div className="forgot-pw">
        <section className="forgot-pw__header">
          <LoginHeader />
        </section>
        <section className="forgot-pw__content">
          <h1 className="forgot-pw__title">{t('Forgot Password')}</h1>
          <p className="forgot-pw__statement">
            {t(
              'Enter your email so we can send instructions on how to reset your password.'
            )}
          </p>
          <form className="forgot-pw__form">
            {/* Having only one input causes a form submission, so adding a hidden input fixes it. */}
            <input style={{ display: 'none' }} type="text" />
            <TextField
              className="forgot-pw__input"
              inputProps={{
                style: {
                  padding: '12px 14px',
                },
              }}
              label={t('Email')}
              value={email}
              variant="outlined"
              onChange={this._onEmailChange}
              onKeyPress={this._onKeyPress}
            />
            <span
              className={`forgot-pw__error ${
                formErrors.email ? 'forgot-pw__error--visible' : ''
              }`}
            >
              {t(formErrors.email)}
            </span>
            <span
              className={`forgot-pw__success ${
                submitSuccessful ? 'forgot-pw__success--visible' : ''
              }`}
            >
              {t(
                'Thank you for your submission. We will send you a temporary password if we find a login registered to this email address.'
              )}
            </span>
            <button
              className="forgot-pw__submit"
              disabled={disableSubmit}
              type="button"
              onClick={this._onSubmit}
            >
              {t('Submit')}
            </button>
          </form>
          <div className="forgot-pw__illustration-cont">
            <img
              alt="Illustration"
              className="forgot-pw__illustration"
              src={Illustration}
            />
          </div>
        </section>
        <section className="forgot-pw__footer">
          <LoginFooter />
        </section>
      </div>
    );
  }
}

export default withTranslation()(ForgotPassword);
