import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { connectProps } from '@devexpress/dx-react-core';
import {
  ViewState,
  EditingState,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Toolbar,
  DayView,
  Appointments,
  TodayButton,
  AppointmentTooltip,
  EditRecurrenceMenu,
  CurrentTimeIndicator,
  DragDropProvider,
  Resources,
  GroupingPanel,
} from '@devexpress/dx-react-scheduler-material-ui';
import Breakpoint from '../../breakpoint';
import CalendarTableCell from './CalendarTableCell';
import CalendarDayCell from './CalendarDayCell';
import {
  CalendarAppointment,
  CalendarAppointmentContainer,
} from './CalendarAppointment';
import CalendarAppointmentContent from './CalendarAppointment';
import {
  CalendarAppointmentTooltipHeader,
  CalendarAppointmentTooltipLayout,
} from './CalendarAppointmentTooltip';
import CalendarDateNavigator from './CalendarDateNavigator';
import CalendarConfirmationModal from './CalendarConfirmationModal';
import CalendarGroupingCell from './CalendarGroupingCell';
import { withTranslation } from 'react-i18next';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import AppointmentService from '../../Services/appointmentService';
import Utils from '../../utils';
import Enums from '../../enums';
import Events from '../../events';
import Constants from '../../constants';

/**
 * Represents the calendar scheduler component.
 */
class CalendarScheduler extends Component {
  /**
   * Initializes a new instance of the CalendarScheduler component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      appointmentResource: {},
      appointmentStartDate: {},
      confirmModalAppt: {},
      confirmModalType: '',
      showConfirmModal: false,
      tooltipVisibility: false,
    };

    this.appointmentService = new AppointmentService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  componentDidMount() {
    this._setupSubscriptions();
    this._setupEventHandlers();
  }

  /**
   * Executes when the component has unmounted from the DOM.
   */
  componentWillUnmount() {
    Events.removeListener(Constants.Events.scheduleAppointment);
    Events.removeListener(Constants.Events.deleteAppointment);
    Events.removeListener(Constants.Events.noShowAppointment);
    window.removeEventListener('resize', this._safeWindowResize);
  }

  /**
   * Executes when its time to toggle the confirmation modal.
   * @param {Object} appt The appointment.
   * @param {String} confirmType The confirmation type.
   * @param {Object} startDate The start date of the appointment.
   * @param {Object} resource The resource the appointment is assigned to.
   */
  onToggleConfirmModal = (appt, confirmType, startDate, resource) => {
    this.setState((prevState) => ({
      appointmentResource: resource || {},
      appointmentStartDate: startDate || {},
      confirmModalAppt: appt || {},
      confirmModalType: confirmType || '',
      showConfirmModal: !prevState.showConfirmModal,
    }));
  };

  _onSchedulerCommitChange = ({ added, changed, deleted }) => {
    if (changed) {
      const { appointments, resources } = this.props;
      // Convert object to appt format.
      const apptId = parseInt(Object.keys(changed)[0]);
      const foundAppt = appointments.find((appt) => appt.id === apptId);

      if (foundAppt) {
        const updatedAppt = {
          ...foundAppt,
          ...changed[apptId],
        };
        updatedAppt.resource = resources.find(
          (res) => res.resourceId === updatedAppt.resourceId
        );

        this.onToggleConfirmModal(updatedAppt, 'update');
      }
    } else if (deleted) {
      this.onToggleConfirmModal(deleted, 'delete');
    }
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.scheduleAppointment, (bookingData) => {
      if (bookingData) {
        const foundAppt = this.props.appointments.find(
          (appt) => appt.appointmentId === bookingData.apptId
        );

        this.onToggleConfirmModal(
          foundAppt,
          'book',
          bookingData.startDate,
          bookingData.resource
        );
      }
    });
    Events.on(Constants.Events.deleteAppointment, (appointment) => {
      if (appointment) {
        this.onToggleConfirmModal(appointment, 'delete');
      }
    });
    Events.on(Constants.Events.noShowAppointment, (appointment) => {
      if (appointment) {
        this.onToggleConfirmModal(appointment, 'noshow');
      }
    });
  };

  _setupEventHandlers = () => {
    const quarterOfASecond = 250;
    this._safeWindowResize = Utils.debounce(() => {
      this.forceUpdate();
    }, quarterOfASecond);

    window.addEventListener('resize', this._safeWindowResize);
  };

  _onConfirmationUpdate = async (apptId) => {
    this._toggleTooltip();
    await this.appointmentService.UpdateConfirmation(apptId);
    Events.emit(Constants.Events.appointmentConfirmed);
  };

  _toggleTooltip = () => {
    const { tooltipVisibility } = this.state;
    this.setState({ tooltipVisibility: !tooltipVisibility });
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      appointmentResource,
      appointmentStartDate,
      confirmModalAppt,
      confirmModalType,
      showConfirmModal,
      tooltipVisibility,
    } = this.state;
    const {
      appointments,
      dayDuration,
      currDateTime,
      holidays,
      resources,
      onCurrDateTimeChange,
      onDeleteAppointment,
      onMarkAppointmentAsNoShow,
      onMoveAppointmentToScheduled,
      onUpdateAppointment,
      t,
      i18n,
      locationConfig,
      appointmentTypes,
    } = this.props;
    let resourceItems = resources.sort((res1, res2) =>
      res1.sortOrder > res2.sortOrder ? 1 : -1
    );

    if (Breakpoint.value === Constants.Breakpoints.mobile) {
      resourceItems = resourceItems.filter((res) => res.isSelected);
    } else {
      resourceItems = resourceItems.filter((res) => res.isChecked);
    }

    resourceItems = resourceItems.map((res, n) => {
      // If the default resource has not had its default displayName value changed, assign the translated value
      if (
        res.isDefault &&
        res.displayName === Constants.defaultResourceDisplayName
      ) {
        res.displayName = t('Appointments');
      }

      return {
        text: res.displayName,
        id: res.resourceId,
        color: res.htmlColor,
      };
    });

    const resourcesArr = [
      {
        fieldName: 'resourceId',
        title: 'Resource',
        instances: resourceItems,
      },
    ];

    const grouping = [
      {
        resourceName: 'resourceId',
      },
    ];

    const visibleAppointments = appointments.filter(
      (appt) =>
        appt.currentStatus === Enums.AppointmentStatus.scheduled &&
        appt.isVisible
    );
    const stellestMappedAppointments = visibleAppointments.map((appt) => {
      appt.isStellest =
        appointmentTypes.filter(
          (type) =>
            appt.appointmentType.id === type.locationAppointmentTypeId &&
            type.isStellest
        ).length > 0;

      return appt;
    });

    return (
      <div className="cal-scheduler">
        <Paper>
          <Scheduler
            locale={i18n.language}
            data={stellestMappedAppointments}
            locationAppointmentTypes={appointmentTypes}
          >
            <EditingState onCommitChanges={this._onSchedulerCommitChange} />
            <ViewState
              currentDate={momentLocaleWrapper(currDateTime).format(
                'YYYY-MM-DD'
              )}
              onCurrentDateChange={(currDate) =>
                onCurrDateTimeChange(momentLocaleWrapper(currDate))
              }
            />
            <GroupingState grouping={grouping} />
            <DayView
              startDayHour={8}
              endDayHour={23}
              cellDuration={dayDuration}
              intervalCount={1}
              timeTableCellComponent={CalendarTableCell}
              dayScaleCellComponent={CalendarDayCell}
            />
            <Appointments
              appointmentComponent={CalendarAppointment}
              appointmentContentComponent={CalendarAppointmentContent}
              containerComponent={CalendarAppointmentContainer}
              // toggleVisibility={this._toggleTooltip}
            />
            <Resources data={resourcesArr} mainResourceName="resourceId" />
            <Toolbar
              flexibleSpaceComponent={connectProps(
                CalendarDateNavigator,
                () => ({
                  currDate: currDateTime,
                  holidays: holidays,
                  onDateChange: onCurrDateTimeChange,
                })
              )}
            />
            <TodayButton messages={{ today: t('Today') }} />
            <EditRecurrenceMenu />
            <IntegratedGrouping />
            <GroupingPanel cellComponent={CalendarGroupingCell} />
            <AppointmentTooltip
              headerComponent={CalendarAppointmentTooltipHeader}
              layoutComponent={connectProps(
                CalendarAppointmentTooltipLayout,
                () => ({
                  appointmentConfirmationUpdate: this._onConfirmationUpdate,
                  config: locationConfig,
                })
              )}
            />
            <DragDropProvider
              allowResize={() => false}
              draftAppointmentComponent={CalendarAppointment}
              sourceAppointmentComponent={CalendarAppointment}
            />
            <CurrentTimeIndicator
              shadePreviousAppointments
              shadePreviousCells
            />
          </Scheduler>
        </Paper>
        <CalendarConfirmationModal
          appointment={confirmModalAppt}
          appointmentResource={appointmentResource}
          appointmentStartDate={appointmentStartDate}
          confirmType={confirmModalType}
          open={showConfirmModal}
          onDeleteAppointment={onDeleteAppointment}
          onMarkAppointmentAsNoShow={onMarkAppointmentAsNoShow}
          onMoveAppointmentToScheduled={onMoveAppointmentToScheduled}
          onToggleConfirmModal={this.onToggleConfirmModal}
          onUpdateAppointment={onUpdateAppointment}
        />
      </div>
    );
  }
}

CalendarScheduler.propTypes = {
  appointmentTypes: PropTypes.array.isRequired,
  appointments: PropTypes.array.isRequired,
  dayDuration: PropTypes.number,
  currDateTime: PropTypes.object.isRequired,
  holidays: PropTypes.array,
  resources: PropTypes.array.isRequired,
  onDeleteAppointment: PropTypes.func.isRequired,
  onCurrDateTimeChange: PropTypes.func.isRequired,
  onMarkAppointmentAsNoShow: PropTypes.func.isRequired,
  onMoveAppointmentToScheduled: PropTypes.func.isRequired,
  onUpdateAppointment: PropTypes.func.isRequired,
};

CalendarScheduler.defaultProps = {
  dayDuration: 60,
};

export default withTranslation()(CalendarScheduler);
