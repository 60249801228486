import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Events from '../../events';
import Constants from '../../constants';
import DashboardService from '../../Services/dashboardService';
import Utils from '../../utils';
import Enums from '../../enums';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Storage from '../../storage';
import './AppointmentDetails.scss';

class AppointmentDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationId: props.locationId,
      start: props.start,
      end: props.end,
      ecpGenerated: true,
      consumerGenerated: false,
      noShows: false,
      cancelled: false,
      total: false,
      appointmentTypeId: 0,
      appointmentType: false,
      apointmentTypeName: '',
      shouldRender: false,
      appointmentDetails: {
        appointments: [],
      },
      detailTotal: 0,
    };
    this._dashboardService = new DashboardService();
  }

  async componentDidMount() {
    this._setDateTimeLocale();
    await this._setI18nLanguage();
    this._setupSubscriptions();
    this._setupEventListeners();
  }

  _setDateTimeLocale() {
    momentLocaleWrapper.locale(Storage.getItem(Constants.langTag));
  }

  _setI18nLanguage = async () => {
    return this.props.i18n.changeLanguage(Storage.getItem(Constants.langTag));
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.noShowDashboardClicked, async () => {
      const result = await this._dashboardService.getDashboardDetailsForNoShows(
        {
          locationId: this.state.locationId,
          start: this.props.start,
          end: this.props.end,
          take: 20,
          skip: 0,
        }
      );
      this.setState({
        appointmentDetails: result,
        shouldRender: true,
        noShows: true,
        cancelled: false,
        total: false,
        ecpGenerated: false,
        consumerGenerated: false,
        detailTotal: this.props.numNoShows,
        appointmentTypeId: 0,
        appointmentType: false,
        apointmentTypeName: '',
      });
    });

    Events.on(Constants.Events.cancelledDashboardClicked, async () => {
      const result =
        await this._dashboardService.getDashboardDetailsForCanceled({
          locationId: this.state.locationId,
          start: this.props.start,
          end: this.props.end,
          take: 20,
          skip: 0,
        });
      this.setState({
        appointmentDetails: result,
        shouldRender: true,
        noShows: false,
        cancelled: true,
        total: false,
        ecpGenerated: false,
        consumerGenerated: false,
        detailTotal: this.props.numCanceled,
        appointmentTypeId: 0,
        appointmentType: false,
        apointmentTypeName: '',
      });
    });

    Events.on(Constants.Events.totalsDashboardClicked, async () => {
      const result = await this._dashboardService.getDashboardDetailsForAll({
        locationId: this.state.locationId,
        start: this.props.start,
        end: this.props.end,
        take: 20,
        skip: 0,
        targetSource: 'ConsumerApp',
      });
      this.setState({
        appointmentDetails: result,
        shouldRender: true,
        noShows: false,
        cancelled: false,
        total: true,
        ecpGenerated: false,
        consumerGenerated: true,
        totalAdminGenerated: result.totalAdminGenerated,
        totalConsumerGenerated: result.totalConsumerGenerated,
        adminGeneratedBookedPercent: result.adminGeneratedBookedPercent,
        adminGeneratedWalkInPercent: result.adminGeneratedWalkInPercent,
        consumerGeneratedBookedPercent: result.consumerGeneratedBookedPercent,
        consumerGeneratedWalkInPercent: result.consumerGeneratedWalkInPercent,
        detailTotal: result.totalConsumerGenerated,
        appointmentTypeId: 0,
        appointmentType: false,
        apointmentTypeName: '',
      });
    });

    Events.on(Constants.Events.typesDashboardClicked, async (params) => {
      const result =
        await this._dashboardService.getDashboardDetailsByServiceType(
          {
            locationId: this.state.locationId,
            start: this.props.start,
            end: this.props.end,
            take: 20,
            skip: 0,
          },
          params.appointmentTypeId
        );
      this.setState({
        appointmentDetails: result,
        shouldRender: true,
        noShows: false,
        cancelled: false,
        total: false,
        ecpGenerated: false,
        consumerGenerated: false,
        totalAdminGenerated: result.totalAdminGenerated,
        totalConsumerGenerated: result.totalConsumerGenerated,
        adminGeneratedBookedPercent: result.adminGeneratedBookedPercent,
        adminGeneratedWalkInPercent: result.adminGeneratedWalkInPercent,
        consumerGeneratedBookedPercent: result.consumerGeneratedBookedPercent,
        consumerGeneratedWalkInPercent: result.consumerGeneratedWalkInPercent,
        detailTotal: params.appointmentCount,
        appointmentTypeId: params.appointmentTypeId,
        appointmentType: true,
        apointmentTypeName: params.appointmentTypeName,
      });
    });

    Events.on(Constants.Events.dashboardDateChange, async () => {
      this.setState({ shouldRender: false });
    });
  };

  _setupEventListeners = () => {
    // Setup window scroll event, so that we can add infinite loading.
    this._safeScroll = Utils.debounce(this._onWindowScroll, 500);

    window.addEventListener('scroll', this._safeScroll);
  };

  _onWindowScroll = async () => {
    const {
      appointmentDetails,
      detailTotal,
      locationId,
      ecpGenerated,
      consumerGenerated,
      noShows,
      cancelled,
      total,
      appointmentType,
      appointmentTypeId,
    } = this.state;

    const { start, end } = this.props;

    // If the user hasn't scrolled to the bottom of the window and
    // we haven't retrieved the total number of notifications yet.
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
        document.body.offsetHeight &&
      appointmentDetails.appointments.length < detailTotal
    ) {
      this.setState(() => ({ showLoadingOverlay: true }));

      try {
        const data = {
          locationId: locationId,
          skip: appointmentDetails.appointments.length,
          start: start,
          end: end,
          take: 20,
        };

        let result = {};
        if (noShows) {
          result = await this._dashboardService.getDashboardDetailsForNoShows(
            data
          );
        }
        if (cancelled) {
          result = await this._dashboardService.getDashboardDetailsForCanceled(
            data
          );
        }
        if (total) {
          if (ecpGenerated) {
            data.targetSource = 'AdminApp';
          } else if (consumerGenerated) {
            data.targetSource = 'ConsumerApp';
          }
          result = await this._dashboardService.getDashboardDetailsForAll(data);
        }
        if (appointmentType) {
          result =
            await this._dashboardService.getDashboardDetailsByServiceType(
              data,
              appointmentTypeId
            );
        }

        this.setState((prevState) => {
          let newState = prevState.appointmentDetails;
          newState.appointments =
            prevState.appointmentDetails.appointments.concat(
              result.appointments
            );
          return { appointmentDetails: newState };
        });
      } catch (error) {
        if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusInternalServerError)
        ) {
          console.error(error);
        }
      } finally {
        this.setState(() => ({ showLoadingOverlay: false }));
      }
    }
  };

  _getDetails() {
    const { t } = this.props;

    const { ecpGenerated, consumerGenerated, appointmentDetails, total } =
      this.state;

    let itemList = appointmentDetails.appointments;

    if (ecpGenerated) {
      itemList = appointmentDetails.appointments.filter(
        (item) => item.appointmentSource === 'AdminApp'
      );
    }

    if (consumerGenerated) {
      itemList = appointmentDetails.appointments.filter(
        (item) => item.appointmentSource === 'ConsumerApp'
      );
    }

    const detailItems = itemList.map((detailItem, key) => {
      const {
        appointmentStart,
        appointmentTypeName,
        patientFirstName,
        patientLastName,
        appointmentTypeDuration,
        patientPhone,
        patientEmail,
        currentStatus,
      } = detailItem;

      return (
        <tr key={key} className="appointment-perf--details-row">
          <td className="appointment-perf--details-index">{key + 1}</td>
          <td className="appointment-perf--details-date">
            <div>{t('Appointment Details')}</div>
            <div>
              {appointmentStart.toString() !== '0001-01-01T00:00:00' &&
                momentLocaleWrapper(appointmentStart.toString())
                  .format('LL')
                  .toString()}
              <br />
              {appointmentStart.toString() !== '0001-01-01T00:00:00' &&
                momentLocaleWrapper(appointmentStart.toString())
                  .format('LT')
                  .toString()}
            </div>
          </td>
          <td className="appointment-perf--details-services">
            <div>
              {t('Service Type')}: {appointmentTypeName}
            </div>
            <div>
              {t('Customer')}: {patientFirstName} {patientLastName}
            </div>
            <div>
              {t('Service Duration')}: {appointmentTypeDuration}
            </div>
          </td>
          {total && (
            <td className="appointment-perf--details-source">
              <div>
                {t('Appointment Source')}:{' '}
                {currentStatus === Enums.AppointmentStatus.queued
                  ? t('Waitlist')
                  : t('Reserved')}
              </div>
            </td>
          )}
          <td className="appointment-perf--details-contact">
            <div>
              {t('SMS Contact')}:{' '}
              <a href={'tel:' + patientPhone}>{patientPhone}</a>
            </div>
            <div>
              {t('Email Contact')}:{' '}
              <a href={'mailto:' + patientEmail}>{patientEmail}</a>
            </div>
          </td>
        </tr>
      );
    });

    return detailItems;
  }

  _onConsumerGeneratedClick = async () => {
    const result = await this._dashboardService.getDashboardDetailsForAll({
      locationId: this.state.locationId,
      start: this.props.start,
      end: this.props.end,
      take: 20,
      skip: 0,
      targetSource: 'ConsumerApp',
    });
    this.setState((prevState) => ({
      appointmentDetails: result,
      consumerGenerated: true,
      ecpGenerated: false,
      detailTotal: prevState.totalConsumerGenerated,
    }));
  };

  _onEcpGeneratedClick = async () => {
    const result = await this._dashboardService.getDashboardDetailsForAll({
      locationId: this.state.locationId,
      start: this.props.start,
      end: this.props.end,
      take: 20,
      skip: 0,
      targetSource: 'AdminApp',
    });
    this.setState((prevState) => ({
      appointmentDetails: result,
      consumerGenerated: false,
      ecpGenerated: true,
      detailTotal: prevState.totalAdminGenerated,
    }));
  };

  _sanitizePercentage(percentage) {
    if (percentage === 'NaN') {
      return 0;
    }
    return (percentage * 100).toFixed(1);
  }

  render() {
    const {
      ecpGenerated,
      consumerGenerated,
      noShows,
      cancelled,
      total,
      shouldRender,
      totalAdminGenerated,
      totalConsumerGenerated,
      adminGeneratedBookedPercent,
      adminGeneratedWalkInPercent,
      consumerGeneratedBookedPercent,
      consumerGeneratedWalkInPercent,
      appointmentType,
      apointmentTypeName,
    } = this.state;

    const { t } = this.props;

    if (!shouldRender) {
      return false;
    }

    return (
      <div className="appointments-details">
        {/* populate with list of items */}
        <div className="appointments-details--header">
          {noShows && <div>{t('Appointment No-shows')}</div>}
          {cancelled && <div>{t('Appointments Cancelled')}</div>}
          {total && ecpGenerated && (
            <div>
              {t('Total Appointments')}{' '}
              <span>
                {' '}
                - {t('ECP Generated')}{' '}
                {this._sanitizePercentage(adminGeneratedWalkInPercent)}%{' '}
                {t('Waitlist')}/{' '}
                {this._sanitizePercentage(adminGeneratedBookedPercent)}%{' '}
                {t('Reserved')}
              </span>
            </div>
          )}
          {total && consumerGenerated && (
            <div>
              {t('Total Appointments')}{' '}
              <span>
                {' '}
                - {t('Consumer Generated')}{' '}
                {this._sanitizePercentage(consumerGeneratedWalkInPercent)}%{' '}
                {t('Waitlist')}/{' '}
                {this._sanitizePercentage(consumerGeneratedBookedPercent)}%{' '}
                {t('Reserved')}
              </span>
            </div>
          )}
          {total && (
            <div>
              <button
                onClick={() => this._onConsumerGeneratedClick()}
                active={consumerGenerated.toString()}
              >
                {totalConsumerGenerated} {t('Consumer Generated')}
              </button>
              <button
                onClick={() => this._onEcpGeneratedClick()}
                active={ecpGenerated.toString()}
              >
                {totalAdminGenerated} {t('ECP Generated')}
              </button>
            </div>
          )}
          {appointmentType && <div>{apointmentTypeName}</div>}
        </div>
        <table className="appointments-details--table">
          <thead></thead>
          <tbody>{this._getDetails()}</tbody>
        </table>
      </div>
    );
  }
}

AppointmentDetails.propTypes = {
  locationId: PropTypes.number,
  start: PropTypes.object,
  end: PropTypes.object,
};

export default withTranslation()(AppointmentDetails);
