import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Constants from '../../constants';
import Breakpoint from '../../breakpoint';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import './CalendarDateNavigator.scss';

/**
 * Represents a custom date navigator that replaces the default DateNavigator from devexpress.
 * This version displays the current date, current holiday, and allows changing the date via calendar.
 */
class CalendarDateNavigator extends Component {
  /**
   * Initializes a new instance of the CalendarDateNavigator component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showCalendar: false,
    };

    this._dateButton = createRef();
    this._iconDateButton = createRef();
  }

  _getCurrentHoliday = () => {
    const { currDate, holidays } = this.props;
    const date = currDate.toDate();
    const currDateDay = date.getDate();
    const currDateMonth = date.getMonth() + 1; // Months are zero based.
    const currDateYear = date.getFullYear();
    const currHoliday = holidays.find((holiday) => {
      const { startDate, endDate } = holiday;

      const startDateValid =
        startDate.getDate() <= currDateDay &&
        startDate.getMonth() + 1 <= currDateMonth &&
        startDate.getFullYear() <= currDateYear;
      const endDateValid =
        endDate.getDate() >= currDateDay &&
        endDate.getMonth() + 1 >= currDateMonth &&
        endDate.getFullYear() >= currDateYear;
      return startDateValid && endDateValid;
    });

    return currHoliday;
  };

  _onCalendarChange = (date) => {
    this.setState(() => ({
      showCalendar: false,
    }));

    const { onDateChange } = this.props;

    onDateChange && onDateChange(date);
  };

  _onHideCalendar = () => {
    this.setState(() => ({ showCalendar: false }));
  };

  _onChangeDate = (dir) => {
    const { currDate } = this.props;
    const updatedCurrDate =
      dir === 'next' ? currDate.add(1, 'days') : currDate.subtract(1, 'days');
    const { onDateChange } = this.props;

    onDateChange && onDateChange(updatedCurrDate);
  };

  _onShowCalendar = () => {
    this.setState(() => ({ showCalendar: true }));
  };

  /**
   * Renders the component.
   */
  render() {
    const { showCalendar } = this.state;
    const { currDate, showDateOnMobile, i18n } = this.props;
    const currHoliday = this._getCurrentHoliday();
    const isMobile = Breakpoint.value === Constants.Breakpoints.mobile;

    return (
      <div className="cal-date-nav">
        <IconButton
          aria-label="previous day"
          className="cal-date-nav__prev"
          onClick={() => this._onChangeDate('prev')}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          aria-label="next day"
          className="cal-date-nav__next"
          onClick={() => this._onChangeDate('next')}
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          aria-label="mobile cal"
          className="cal-date-nav__mobile-icon"
          ref={this._iconDateButton}
          onClick={this._onShowCalendar}
        >
          <CalendarTodayIcon />
        </IconButton>
        <Button
          className="cal-date-nav__date-btn"
          ref={this._dateButton}
          onClick={this._onShowCalendar}
        >
          {currDate.format('LL')}
        </Button>
        <div>
          {showDateOnMobile && (
            <span className="cal-date-nav__date-label">
              {currDate.format('LL')}
            </span>
          )}
          {currHoliday && (
            <div className="cal-date-nav__holiday-cont">
              <span className="cal-date-nav__label-divider">-&nbsp;&nbsp;</span>
              <span className="cal-date-nav__holiday">{currHoliday.name}</span>
            </div>
          )}
        </div>
        <Popover
          anchorEl={
            isMobile ? this._iconDateButton.current : this._dateButton.current
          }
          open={showCalendar}
        >
          <MuiPickersUtilsProvider
            libInstance={momentLocaleWrapper}
            locale={i18n.language}
            utils={MomentUtils}
          >
            <ClickAwayListener onClickAway={this._onHideCalendar}>
              <div>
                <DatePicker
                  autoOk
                  disableToolbar={true}
                  openTo="date"
                  variant="static"
                  value={currDate}
                  onChange={(date) => this._onCalendarChange(date)}
                />
              </div>
            </ClickAwayListener>
          </MuiPickersUtilsProvider>
        </Popover>
      </div>
    );
  }
}

CalendarDateNavigator.propTypes = {
  currDate: PropTypes.object.isRequired,
  holidays: PropTypes.array.isRequired,
  showDateOnMobile: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
};

CalendarDateNavigator.defaultProps = {
  showDateOnMobile: true,
};

export default withTranslation()(CalendarDateNavigator);
