import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Overlay from '../../Components/Overlay/Overlay';
import Storage from '../../storage';
import Constants from '../../constants';
import ContentManagementService from '../../Services/contentManagementService';
import LocationConfigService from '../../Services/locationConfigService';

class IframeContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this._contentMgmtService = new ContentManagementService();
    this._locationConfigService = new LocationConfigService();
  }

  async componentDidMount() {
    this._toggleLoadingOverlay();
    const siteConfig = Storage.getItem(Constants.siteConfig);
    let language = siteConfig?.languageTag ?? 'en-US';
    const langTag = Storage.getItem(Constants.langTag);
    if (langTag) {
      language = langTag;
    }

    const locationConfig =
      await this._locationConfigService.getLocationConfig();
    const { locationSettings } = locationConfig;
    const stellestSettings = locationSettings.find(
      (e) =>
        e.displayName === Constants.LocationSettings.settingIsStellestEnabled
    );
    const isStellestEnabled = stellestSettings
      ? stellestSettings.settingValue
      : false;
    this._link = await this._contentMgmtService.getTermsAndConditions(
      locationConfig.storeInformation.languageTag,
      isStellestEnabled
    );
    this._toggleLoadingOverlay();
  }

  _toggleLoadingOverlay = () => {
    this.setState((prevState) => ({ loaded: !prevState.showLoadingOverlay }));
  };

  _handleLoad = () => {
    this.setState({ loaded: false });
  };

  render() {
    const { loaded } = this.state;

    const { t, isFromModal } = this.props;

    return (
      <React.Fragment>
        <Overlay show={loaded}>
          <i className="spinner-eclipse"></i>
        </Overlay>
        <iframe
          title={t('Terms of Use')}
          id="terms-use-iframe"
          src={this._link}
          style={{
            height: isFromModal ? '300px' : '445px',
            display: 'block',
            overflow: 'hidden',
            border: isFromModal ? 'none' : '1px solid #979797',
            fontFamily: '',
          }}
          onLoad={this._handleLoad}
        ></iframe>
      </React.Fragment>
    );
  }
}
export default withTranslation()(IframeContent);
