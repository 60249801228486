import moment from 'moment';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Events from '../events';
import Logger from '../logger';
import Storage from '../storage';
import Constants from '../constants';

/**
 * Represents the signalr hub service for setting up various hubs when called.
 */
class SignalRHubService {
  /**
   * Sets up the communication hub.
   */
  _hubConnection = null;

  static setupCommunicationHub() {
    const url = `${process.env.REACT_APP_COMMUNICATION_HUB}`;
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => {
          const user = Storage.getItem('cusr');
          const token = user.jwt;
          return token;
        },
      })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 90000, 120000, 300000])
      .build();
    const locationId = Storage.getItem(Constants.currLocIdKey);

    this._hubConnection
      .start()
      .then(() => {
        Logger.log('connection started for communication');
        this._hubConnection
          .invoke('AddSelfToGroup', locationId.toString())
          .then(() => Logger.log('added to communication group'))
          .catch((error) => console.error(error));
      })
      .catch((error) => console.error(error));

    this._hubConnection.onreconnected(() => {
      Logger.log('reconnection started for communication');
      this._hubConnection
        .invoke('AddSelfToGroup', locationId.toString())
        .then(() => Logger.log('reconnected to communication group'))
        .catch((error) => console.error(error));
    });

    this._hubConnection.on(
      Constants.SignalRMessages.newNotification,
      (receivedMessage) => {
        Logger.log('incoming communication message');

        const notification = JSON.parse(receivedMessage);

        Events.emit(Constants.Events.newNotification, notification);
      }
    );

    this._hubConnection.on(
      Constants.SignalRMessages.appointmentUpdated,
      (receivedMessage, newAppt) => {
        Logger.log('incoming appt message');

        const appointment = JSON.parse(receivedMessage);
        console.log(appointment);

        this._reformatAppointment(appointment);

        if (newAppt) {
          Events.emit(Constants.Events.newAppointment, appointment);
        } else {
          Events.emit(Constants.Events.appointmentUpdated, appointment);
        }
      }
    );

    this._hubConnection.on(
      Constants.SignalRMessages.locationUpdatedEvent,
      (receivedMessage) => {
        Logger.log('incoming loc config message');

        const locationConfig = JSON.parse(receivedMessage);

        Events.emit(Constants.Events.locationConfigUpdate, locationConfig);
      }
    );

    this._hubConnection.on(
      Constants.SignalRMessages.conversationUpdated,
      (receivedMessage) => {
        Logger.log('incoming chat conversation updated message');

        const conversation = JSON.parse(receivedMessage);

        Events.emit(Constants.Events.chatConversationUpdated, conversation);
      }
    );

    this._hubConnection.on(
      Constants.SignalRMessages.chatNotificationUpdated,
      (receivedMessage) => {
        Logger.log('incoming chat notification updated message');

        const notificationUpdated = JSON.parse(receivedMessage);

        Events.emit(
          Constants.Events.chatNotificationUpdated,
          notificationUpdated
        );
      }
    );
  }

  static closeCommunicationHub = () => {
    if (this._hubConnection) {
      this._hubConnection.stop();
    }
    this._hubConnection = null;
  };

  static _reformatAppointment = (appt) => {
    appt.id = appt.appointmentId;
    appt.isVisible = true;
    appt.created = appt.created
      ? moment.utc(appt.created, '(YYYY, MM, DD, HH, mm)').toDate()
      : '';
    appt.startDate = appt.startTime
      ? moment(appt.startTime, '(YYYY, MM, DD, HH, mm)').toDate()
      : ''; //(2020, 06, 03, 09, 00)
    appt.endDate = appt.endTime
      ? moment(appt.endTime, '(YYYY, MM, DD, HH, mm)').toDate()
      : '';
    appt.title = `${appt.patient.firstName} ${appt.patient.lastName}`;
    appt.resourceId = appt.resource.resourceId;

    //account for model changes til latest code is deployed
    if (appt.appointmentType) {
      if (appt.appointmentType.locationAppointmentTypeId)
        appt.appointmentType.id =
          appt.appointmentType.locationAppointmentTypeId;

      if (appt.appointmentType.duration)
        appt.appointmentType.length = appt.appointmentType.duration;
    }
  };
}

export default SignalRHubService;
