import MomentUtils from '@date-io/moment';
import { ClickAwayListener } from '@material-ui/core';
import MuiCheckbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MuiTooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Breakpoint from '../../breakpoint';
import Constants from '../../constants';
import Enums from '../../enums';
import EventBuilder from '../../eventBuilder';
import Events from '../../events';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import RegEx from '../../regex';
import AppointmentService from '../../Services/appointmentService';
import VoucherService from '../../Services/voucherService';
import LocationConfigService from '../../Services/locationConfigService';
import ContentManagementService from '../../Services/contentManagementService';
import Utils from '../../utils';
import Storage from '../../storage';
import Session from '../../session';
import './CalendarAddAppointmentDrawer.scss';
import SignalRHubService from '../../Services/signalRHubService';

const eBuilder = new EventBuilder();

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Checkbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#979797',
    },
  },
  checked: {},
})(MuiCheckbox);

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);

/**
 * Represents the main calendar drawer for additional actions.
 */
class CalendarAddAppointmentDrawer extends Component {
  /**
   * Initializes a new instance of the CalendarAddAppointmentDrawer component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this._newAppt = {
      id: 0,
      notes: '',
      title: '',
      patient: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        preferredPlatform: 0,
      },
      created: null,
      isVisible: true,
      appointmentType: {
        id: '',
        length: 0,
        displayName: '',
      },
      locationId: 0,
      currentStatus: Enums.AppointmentStatus.scheduled,
      startDate: momentLocaleWrapper().add(15, 'minutes').toDate(),
      showSmsWarning: false,
      endDate: null,
      resource: {
        resourceId: -1,
        displayName: '',
      },
      voucherCode: '',
    };

    this.state = {
      apptToEdit: null,
      canBook: false,
      isEditing: false,
      isVoucherValid: true,
      newAppt: cloneDeep(this._newAppt),
      showAddAppointment: false,
      showVoucherTooltip: false,
      isWhatsAppNotificationsEnabled: false,
      locationConfig: {
        appointmentTypes: [],
        countrySettings: [],
        locationSettings: [],
        locationId: 0,
        resources: [],
        schedule: {
          schedules: [],
        },
        storeInformation: {
          countryCode: '',
          languageTag: '',
          name: '',
        },
      },
      appointmentTypes: [],
      phoneCountryCode: '',
      resources: [],
    };
    this._currApptDateTime = null;
    this._voucherService = new VoucherService();
    this._appointmentService = new AppointmentService();
    this._locationConfigService = new LocationConfigService();
    this._contentManagementService = new ContentManagementService();

    eBuilder.withCategory(eBuilder.Category.Scheduler.bookAppointment);
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  componentDidMount = async () => {
    await this._getLocationConfig();
    await this._getPhoneCountryCode();
    const { resources } = this.state;
    const defaultResource = Utils.getDefaultResource(resources);

    if (defaultResource) {
      this.setState({
        isWhatsAppNotificationsEnabled:
          this._getSettingValueIsWhatsAppNotificationsEnabled(),
        newAppt: {
          ...this._newAppt,
          patient: {
            ...this._newAppt.patient,
            preferredPlatform: this._getDefaultMessagingSmsType(),
          },
          resourceId: defaultResource.resourceId,
          resource: {
            htmlColor: defaultResource.htmlColor,
            resourceId: defaultResource.resourceId,
            displayName: defaultResource.displayName,
          },
        },
      });
    }

    this._setupSubscriptions();
  };

  componentWillUnmount() {
    Events.removeAllListeners(Constants.Events.locationConfigUpdate);
    SignalRHubService.closeCommunicationHub();
  }

  /**
   * Executes when the state of the component changes.
   */
  componentDidUpdate() {
    const { apptToEdit, isEditing } = this.state;

    if (apptToEdit && !isEditing) {
      // We need to always remove the country code from
      // the phone for appointments being edited.
      const phoneAsString = apptToEdit.patient.phone.toString();
      const { phoneCountryCode } = this.state;
      const phoneCountryCodeDigits = phoneCountryCode.slice(
        1,
        phoneCountryCode.length
      );

      if (Utils.hasCountryCodeInPhone(phoneAsString, phoneCountryCode)) {
        apptToEdit.patient.phone = parseInt(
          phoneAsString.slice(
            phoneCountryCodeDigits.length,
            phoneAsString.length
          )
        );
      }

      this.setState(() => ({
        isEditing: true,
        newAppt: cloneDeep(apptToEdit),
      }));
    }
  }

  _checkCanBook = (newAppt) => {
    let canBook = true;

    if (newAppt) {
      const { firstName, lastName, phone, email } = newAppt.patient;

      const { startDate } = newAppt;

      const isWaitlist =
        newAppt.currentStatus === Enums.AppointmentStatus.queued;

      if (!firstName) {
        canBook = false;
      }

      if (!lastName) {
        canBook = false;
      }

      if (!phone) {
        canBook = false;
      }

      if (email && !RegEx.email.test(email)) {
        canBook = false;
      }

      if (!isWaitlist && !startDate) {
        canBook = false;
      }

      if (!newAppt.appointmentType.id || newAppt.appointmentType.id === 0) {
        canBook = false;
      }
    }

    return canBook;
  };

  _convertFiveMinutesPassedMidnightToTime = (
    apptDate,
    fiveMinutesPassedMidnight
  ) => {
    const min = fiveMinutesPassedMidnight * 5;
    const date = momentLocaleWrapper(apptDate)
      .set('hour', 0)
      .set('minute', 0)
      .add(min, 'minutes');

    return date.toDate();
  };

  _handleClose = () => {
    this.setState(() => ({
      apptToEdit: null,
      canBook: false,
      isEditing: false,
      isVoucherValid: true,
      newAppt: cloneDeep(this._newAppt),
      showAddAppointment: false,
    }));
  };

  _onAppointmentFieldChange = (path, value) => {
    this.setState((prevState) => {
      const newAppt = cloneDeep(prevState.newAppt);
      let showSmsWarning = prevState.showSmsWarning;

      if (path[1] === 'phone') {
        value = isNaN(parseInt(value)) ? '' : parseInt(value);
        Utils.update(newAppt, path, value);
      } else {
        Utils.update(newAppt, path, value);
      }

      // We need to concat the first and last name of the patient for the title.
      if (path[0] === 'patient') {
        const { firstName, lastName } = newAppt.patient;
        newAppt.title = `${firstName} ${lastName}`;
        if (path[1] === 'preferredPlatform') {
          showSmsWarning = this._showSmsWarningCheck(value);
        }
      }

      const canBook = this._checkCanBook(newAppt);

      return {
        canBook: canBook,
        showSmsWarning: showSmsWarning,
        newAppt: newAppt,
      };
    });
  };

  _onAppointmentTypeChange = (value) => {
    this.setState((prevState) => {
      const apptType = this.state.appointmentTypes.find(
        (at) => at.locationAppointmentTypeId === value
      );

      if (apptType) {
        const newAppt = cloneDeep(prevState.newAppt);
        newAppt.appointmentType = {
          length: apptType.duration,
          displayName: apptType.displayName,
          id: apptType.locationAppointmentTypeId,
        };
        newAppt.endDate = momentLocaleWrapper(newAppt.startDate)
          .add(newAppt.appointmentType.length, 'minutes')
          .toDate();

        eBuilder
          .withAction(
            eBuilder.Action.Scheduler.Click.AddAppointment.reason,
            apptType.displayName
          )
          .withLabel(eBuilder.Label.practiceIdentifier)
          .post();

        return {
          canBook: this._checkCanBook(newAppt),
          newAppt: newAppt,
        };
      }
    });
  };

  _showSmsWarningCheck(preferredPlatform) {
    const isSmsOneWay =
      this.state.locationConfig?.countrySettings?.some(
        (cs) =>
          cs.settingName === Constants.CountrySettings.settingIsSmsOneWay &&
          cs.settingValue === true
      ) || false;
    const showSmsOneWayWarning =
      isSmsOneWay && preferredPlatform === Enums.PreferredPlatform.smsText;
    return showSmsOneWayWarning;
  }

  _onBookAppointment = async () => {
    const { newAppt } = this.state;
    const { voucherCode } = newAppt;
    const { locationGroup } = this.state;
    const scheduleAppointment = () => {
      if (newAppt.currentStatus === Enums.AppointmentStatus.queued) {
        newAppt.startDate = momentLocaleWrapper().toDate();
      } else {
        newAppt.startDate = this._currApptDateTime;
      }

      this._onScheduleAppointment(newAppt);

      this.setState(() => ({
        canBook: false,
        isEditing: false,
        apptToEdit: null,
        newAppt: cloneDeep(this._newAppt),
        showAddAppointment: false,
      }));
    };

    this.setState(() => ({ isVoucherValid: true }));

    if (Utils.isVoucherAllowed(locationGroup) && voucherCode) {
      const isVoucherValid = await this._voucherService.verifyVoucher({
        voucherCode: voucherCode?.toUpperCase(),
        locationGroup: locationGroup,
        appointmentTime: newAppt.startDate,
      });

      if (isVoucherValid) {
        scheduleAppointment();
      } else {
        this.setState(() => ({ isVoucherValid: false }));
      }
    } else {
      scheduleAppointment();
    }
  };

  _onCloseVoucherTooltip = () => {
    this.setState(() => ({ showVoucherTooltip: false }));
  };

  _onResourceChange = (value) => {
    this.setState((prevState) => {
      const resource = this.state.resources.find((r) => r.resourceId === value);

      if (resource) {
        const newAppt = cloneDeep(prevState.newAppt);
        newAppt.resource = {
          displayName: resource.displayName,
          resourceId: resource.resourceId,
        };
        newAppt.resourceId = resource.resourceId;

        eBuilder
          .withAction(
            eBuilder.Action.Scheduler.Click.AddAppointment.providerName
          )
          .withLabel(eBuilder.Label.alternateLabel, resource.displayName)
          .post();

        eBuilder.withLabel(eBuilder.Label.practiceIdentifier).post();

        return {
          canBook: this._checkCanBook(newAppt),
          newAppt: newAppt,
        };
      }
    });
  };

  _onStartTimeChangeSelect = (dateString) => {
    const min = dateString * 5;
    const date = momentLocaleWrapper()
      .set('hour', 0)
      .set('minute', 0)
      .add(min, 'minutes');
    this.setState((prevState) => {
      const newAppt = cloneDeep(prevState.newAppt);
      const newStartDay = date
        ? momentLocaleWrapper(newAppt.startDate)
            .startOf('day')
            .add(date.hour(), 'hours')
            .add(date.minutes(), 'minutes')
        : '';
      newAppt.startDate = newStartDay ? newStartDay.toDate() : '';

      this._currApptDateTime = newAppt.startDate;

      if (prevState.apptToEdit) {
        newAppt.endDate = momentLocaleWrapper(newAppt.startDate)
          .add(newAppt.appointmentType.length, 'minutes')
          .toDate();
      }

      return {
        canBook: this._checkCanBook(newAppt),
        newAppt: newAppt,
      };
    });
  };

  _onStartDayChange = (date) => {
    this.setState((prevState) => {
      // Need to keep the start day in sync with the start time just in case
      // the user decides to change the start time first, then the day and vice versa.
      const newAppt = cloneDeep(prevState.newAppt);
      const startDate = momentLocaleWrapper(newAppt.startDate);
      const newStartDay = date
        ? momentLocaleWrapper(date)
            .startOf('day')
            .add(startDate.hour(), 'hours')
            .add(startDate.minutes(), 'minutes')
        : '';
      newAppt.startDate = newStartDay ? newStartDay.toDate() : '';
      const canBook = this._checkCanBook(newAppt);

      return {
        canBook: canBook,
        newAppt: newAppt,
      };
    });
  };

  _onStartTimeChange = (date) => {
    this.setState((prevState) => {
      const newAppt = cloneDeep(prevState.newAppt);
      const newStartDay = date
        ? momentLocaleWrapper(newAppt.startDate)
            .startOf('day')
            .add(date.hours(), 'hours')
            .add(date.minutes(), 'minutes')
        : '';
      newAppt.startDate = newStartDay ? newStartDay.toDate() : '';

      if (prevState.apptToEdit) {
        newAppt.endDate = momentLocaleWrapper(newAppt.startDate)
          .add(newAppt.appointmentType.length, 'minutes')
          .toDate();
      }

      return {
        canBook: this._checkCanBook(newAppt),
        newAppt: newAppt,
      };
    });
  };

  _onToggleVoucherTooltip = () => {
    this.setState((prevState) => ({
      showVoucherTooltip: !prevState.showVoucherTooltip,
    }));
  };

  _onWaitlistChange = (checked) => {
    this.setState((prevState) => {
      const newAppt = cloneDeep(prevState.newAppt);
      newAppt.currentStatus = checked
        ? Enums.AppointmentStatus.queued
        : Enums.AppointmentStatus.scheduled;

      if (
        newAppt.currentStatus !== Enums.AppointmentStatus.queued &&
        !this.state.isEditing
      ) {
        newAppt.startDate = momentLocaleWrapper().add(30, 'minutes').toDate();
      }

      const isWaitlist = checked ? 'Yes' : 'No';

      eBuilder
        .withAction(
          eBuilder.Action.Scheduler.Click.AddAppointment.waitlistToggle,
          isWaitlist
        )
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      return {
        canBook: this._checkCanBook(newAppt),
        newAppt: newAppt,
      };
    });
  };

  _getPhoneCountryCode = async () => {
    try {
      const phoneCountryCode =
        await this._contentManagementService.getPhoneCountryCode(
          this.state.locationConfig.storeInformation.countryCode
        );
      this.setState(() => ({ phoneCountryCode: phoneCountryCode }));
    } catch (error) {
      console.error(error);
    }
  };

  _getLocationConfig = async () => {
    try {
      const locationConfig =
        await this._locationConfigService.getLocationConfig();
      this._setupLocationConfig(locationConfig);
    } catch (error) {
      console.error(error);
    }
  };

  _setupLocationConfig = (locationConfig) => {
    if (locationConfig) {
      const resources = cloneDeep(locationConfig.resources);
      const appointmentTypes = cloneDeep(locationConfig.appointmentTypes);
      const appliedHolidays = locationConfig.appliedHolidays.map((holiday) => {
        holiday.startDate = Utils.convertApiDateFormatToDate(holiday.startDate);
        holiday.endDate = Utils.convertApiDateFormatToDate(holiday.endDate);

        return holiday;
      });

      this._appliedHolidays = appliedHolidays;

      const locationGroup = locationConfig.locationGroup;
      const vouchers = locationConfig.vouchers;
      const showVoucherField = this._voucherService.showVoucherField(
        vouchers,
        locationGroup
      );

      Utils.formatResources(resources);
      Utils.formatAppointmentTypes(appointmentTypes);
      Session.setItem(Constants.currResources, locationConfig.resources);
      Session.setItem(
        Constants.currCountry,
        locationConfig.storeInformation.countryCode
      );

      this.setState(() => ({
        appointmentTypes: appointmentTypes,
        locationConfig: cloneDeep(locationConfig),
        resources: resources,
        locationGroup: locationConfig.locationGroup,
        showVoucherField: showVoucherField,
      }));
    }
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.onToggleAddAppointment, (apptToEdit) => {
      if (apptToEdit) {
        this.setState((prevState) => ({
          apptToEdit: cloneDeep(apptToEdit),
          newAppt: cloneDeep(apptToEdit),
          showAddAppointment: !prevState.showAddAppointment,
        }));
      } else {
        this.setState((prevState) => ({
          showAddAppointment: !prevState.showAddAppointment,
        }));
      }
    });

    Events.on(Constants.Events.locationChanged, async () => {
      //refresh location config and storage values
      await this._getLocationConfig();
      await this._getPhoneCountryCode();
      const { resources } = this.state;
      const defaultResource = Utils.getDefaultResource(resources);

      this._newAppt = {
        id: 0,
        notes: '',
        title: '',
        patient: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          patient: {
            preferredPlatform: this._getDefaultMessagingSmsType(),
          },
        },
        created: null,
        isVisible: true,
        appointmentType: {
          id: '',
          length: 0,
          displayName: '',
        },
        locationId: 0,
        currentStatus: Enums.AppointmentStatus.scheduled,
        startDate: momentLocaleWrapper().add(15, 'minutes').toDate(),
        endDate: null,
        resource: {
          resourceId: -1,
          displayName: '',
        },
        voucherCode: '',
      };

      if (defaultResource) {
        this.setState({
          isWhatsAppNotificationsEnabled:
            this._getSettingValueIsWhatsAppNotificationsEnabled(),
          newAppt: {
            ...this._newAppt,
            resourceId: defaultResource.resourceId,
            resource: {
              htmlColor: defaultResource.htmlColor,
              resourceId: defaultResource.resourceId,
              displayName: defaultResource.displayName,
            },
          },
        });
      }
    });

    Events.on(Constants.Events.locationConfigUpdate, async () => {
      await this._getLocationConfig();
      const { resources } = this.state;
      const defaultResource = Utils.getDefaultResource(resources);

      if (defaultResource) {
        this.setState({
          isWhatsAppNotificationsEnabled:
            this._getSettingValueIsWhatsAppNotificationsEnabled(),
          newAppt: {
            ...this._newAppt,
            patient: {
              ...this._newAppt.patient,
              preferredPlatform: this._getDefaultMessagingSmsType(),
            },
            resourceId: defaultResource.resourceId,
            resource: {
              htmlColor: defaultResource.htmlColor,
              resourceId: defaultResource.resourceId,
              displayName: defaultResource.displayName,
            },
          },
        });
      }
    });
  };

  /**
   * Executes when an appointment is scheduled.
   * @param {Object} appointment The appointment.
   */
  _onScheduleAppointment = (appointment) => {
    if (appointment) {
      try {
        const { phoneCountryCode } = this.state;

        appointment.created = new Date();
        appointment.locationId = Storage.getItem(Constants.currLocIdKey);
        appointment.voucherCode = appointment.voucherCode?.toUpperCase();

        const concatenatedPhone = `${phoneCountryCode.substring(1)}${
          appointment.patient.phone
        }`;
        appointment.patient.phone = concatenatedPhone;

        eBuilder.withCategory(eBuilder.Category.Scheduler.bookAppointment);
        // Need to null out the start/end times for waitlist appointments.
        if (appointment.currentStatus === Enums.AppointmentStatus.queued) {
          appointment.startTime = null;
          appointment.endDate = null;
          eBuilder.withAction(
            eBuilder.Action.Scheduler.Click.AddAppointment.confirmWaitlist
          );
        } else {
          eBuilder.withAction(
            eBuilder.Action.Scheduler.Click.AddAppointment.confirmBook
          );
        }

        //saves appt
        appointment.patient.preferredPlatform ===
        Enums.PreferredPlatform.smsText
          ? eBuilder.withLabel(
              eBuilder.Label.practiceIdentifierAndMessagingTypeSMS
            )
          : eBuilder.withLabel(
              eBuilder.Label.practiceIdentifierAndMessagingTypeWhatsApp
            );
        eBuilder.post();
        this._appointmentService.saveAppointment(appointment);
      } catch (error) {
        console.error(error);
      }
    }
  };

  _getSettingValueIsWhatsAppNotificationsEnabled = () => {
    return (
      this.state.locationConfig.countrySettings?.some(
        (cs) =>
          cs.settingName ===
            Constants.CountrySettings.settingIsWhatsAppNotificationsEnabled &&
          cs.settingValue === true
      ) || false
    );
  };

  _getDefaultMessagingSmsType = () => {
    const isWhatsAppNotificationsEnabled =
      this._getSettingValueIsWhatsAppNotificationsEnabled();

    if (isWhatsAppNotificationsEnabled) {
      return (
        this.state.locationConfig.countrySettings?.find(
          (cs) =>
            cs.settingName ===
            Constants.CountrySettings.settingDefaultMessagingPlatform
        )?.settingValue || Enums.PreferredPlatform.whatsApp
      );
    } else {
      return Enums.PreferredPlatform.smsText;
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      apptToEdit,
      canBook,
      isVoucherValid,
      newAppt,
      showAddAppointment,
      showSmsWarning,
      showVoucherField,
      showVoucherTooltip,
      isEditing,
      isWhatsAppNotificationsEnabled,
      appointmentTypes,
      phoneCountryCode,
      resources,
      locationConfig,
    } = this.state;

    const { locationGroup } = locationConfig;

    const { t } = this.props;

    if (newAppt.patient.preferredPlatform === 0) {
      newAppt.patient.preferredPlatform = this._getDefaultMessagingSmsType();
    }
    const { startDate, voucherCode } = newAppt;
    const { firstName, lastName, phone, email, preferredPlatform } =
      newAppt.patient;
    const appointmentTypeItems = appointmentTypes.map((at, key) => (
      <MenuItem key={key} value={at.locationAppointmentTypeId}>
        {at.displayName}
      </MenuItem>
    ));
    const resourceItems = resources.map((r, key) => (
      <MenuItem key={key} value={r.resourceId}>
        {t(r.displayName)}
      </MenuItem>
    ));
    const isWaitlist = newAppt.currentStatus === Enums.AppointmentStatus.queued;

    if (!newAppt.resource || newAppt.resource.resourceId < 0) {
      newAppt.resource = Utils.getDefaultResource(resources);
    }

    if (resources?.length === 0) {
      return null;
    }

    let defaultStartTime = 0;

    if (startDate) {
      let startMoment = momentLocaleWrapper(startDate);
      defaultStartTime = (startMoment.hours() * 60 + startMoment.minutes()) / 5;
      defaultStartTime = parseInt(defaultStartTime);
      defaultStartTime = defaultStartTime - (defaultStartTime % 3); //to keep it to one of the defined values being selected. we can add more to content files.

      // We need to keep the current start time saved. This covers both scenarios where the
      // user leaves the default start time provided (15 minutes from current time) and if
      // they select a new time from the dropdown.
      this._currApptDateTime = this._convertFiveMinutesPassedMidnightToTime(
        startDate,
        defaultStartTime
      );
    }

    return (
      <Drawer
        anchor={Breakpoint.value === 'mobile' ? 'bottom' : 'right'}
        open={showAddAppointment}
      >
        <div className="cal-newappt">
          <div className="cal-newappt__commands">
            <h2 className="cal-newappt__title">
              {!apptToEdit
                ? t('New Appointment Details')
                : t('Edit Appointment')}
            </h2>
            <button className="cal-newappt__close" onClick={this._handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="cal-newappt__group">
            <TextField
              className="cal-newappt__input"
              label={t('First Name')}
              variant="outlined"
              value={firstName}
              onChange={(e) =>
                this._onAppointmentFieldChange(
                  ['patient', 'firstName'],
                  e.target.value
                )
              }
            />
            <TextField
              className="cal-newappt__input"
              label={t('Last Name')}
              variant="outlined"
              value={lastName}
              onChange={(e) =>
                this._onAppointmentFieldChange(
                  ['patient', 'lastName'],
                  e.target.value
                )
              }
            />
          </div>
          <div className="cal-newappt__group">
            <TextField
              className="cal-newappt__input"
              label={t('Phone')}
              type="phone"
              variant="outlined"
              value={phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {phoneCountryCode}
                  </InputAdornment>
                ),
              }}
              onChange={(e) =>
                this._onAppointmentFieldChange(
                  ['patient', 'phone'],
                  e.target.value
                )
              }
            />
            <TextField
              className="cal-newappt__input"
              label={t('Email')}
              variant="outlined"
              value={email}
              onChange={(e) =>
                this._onAppointmentFieldChange(
                  ['patient', 'email'],
                  e.target.value
                )
              }
            />
          </div>
          {isWhatsAppNotificationsEnabled && (
            <div className="cal-newappt__group cal-newappt__sms">
              <label className="cal-newappt__sms-label">
                {t('Receive your appointment reminders on')}:
              </label>
              <RadioGroup
                row
                value={preferredPlatform}
                onChange={(e) =>
                  this._onAppointmentFieldChange(
                    ['patient', 'preferredPlatform'],
                    parseInt(e.target.value)
                  )
                }
              >
                <FormControlLabel
                  control={<Radio />}
                  label={t('WhatsApp')}
                  labelPlacement="end"
                  value={Enums.PreferredPlatform.whatsApp}
                  disabled={isEditing}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t('SMS Texting')}
                  labelPlacement="end"
                  value={Enums.PreferredPlatform.smsText}
                  disabled={isEditing}
                />
                {showSmsWarning && (
                  <p className="cal-newappt_sms-warning">
                    {t(
                      'Notice: Please note that you are choosing SMS as the preferred channel of communication for this customer, this will prohibit you from communicating back and forth with them and limits you to 1 way messages only due to local restrictions.'
                    )}
                  </p>
                )}
              </RadioGroup>
            </div>
          )}

          <div className="cal-newappt__group">
            <FormControl className="cal-newappt__ctrl" variant="outlined">
              <InputLabel id="cal-newappt-appttype-label">
                {t('Service')}
              </InputLabel>
              <Select
                className="cal-newappt__input cal-newappt__input--select"
                id="cal-newappt-appttype"
                labelId="cal-newappt-appttype-label"
                variant="outlined"
                value={newAppt.appointmentType.id}
                onChange={(e) =>
                  this._onAppointmentTypeChange(parseInt(e.target.value))
                }
              >
                {appointmentTypeItems}
              </Select>
            </FormControl>
          </div>
          <div
            className="cal-newappt__group cal-newappt__group--waitlist"
            style={{ display: apptToEdit ? 'none' : 'flex' }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={isWaitlist}
                  onChange={(e) => this._onWaitlistChange(e.target.checked)}
                />
              }
              label={t('Waitlist')}
            />
          </div>
          {!isWaitlist && (
            <div>
              <div className="cal-newappt__group">
                <FormControl className="cal-newappt__ctrl" variant="outlined">
                  <InputLabel id="cal-newappt-resource-label">
                    {t('Resource')}
                  </InputLabel>
                  <Select
                    className="cal-newappt__input cal-newappt__input--select"
                    id="cal-newappt-resource"
                    required
                    labelId="cal-newappt-resource-label"
                    variant="outlined"
                    value={newAppt.resource.resourceId}
                    onChange={(e) =>
                      this._onResourceChange(parseInt(e.target.value))
                    }
                  >
                    {resourceItems}
                  </Select>
                </FormControl>
              </div>
              <div className="cal-newappt__group cal-newappt__group--datetime">
                <MuiPickersUtilsProvider
                  libInstance={momentLocaleWrapper}
                  utils={MomentUtils}
                >
                  <DatePicker
                    cancelLabel={t('Cancel')}
                    disableToolbar
                    required
                    className="cal-newappt__input"
                    format="ddd, L"
                    label={t('Date')}
                    margin="normal"
                    okLabel={t('OK')}
                    value={startDate}
                    onChange={this._onStartDayChange}
                  />
                </MuiPickersUtilsProvider>
                <FormControl className="cal-newappt__ctrl cal-newappt__ctrl--time cal-newappt__input">
                  <InputLabel id="cal-newappt-resource-label">
                    {t('Time')}
                  </InputLabel>
                  <Select
                    value={defaultStartTime}
                    onChange={(e) => {
                      this._onStartTimeChangeSelect(parseInt(e.target.value));
                    }}
                  >
                    <MenuItem value="0">{t('12:00 AM')}</MenuItem>
                    <MenuItem value="3">{t('12:15 AM')}</MenuItem>
                    <MenuItem value="6">{t('12:30 AM')}</MenuItem>
                    <MenuItem value="9">{t('12:45 AM')}</MenuItem>

                    <MenuItem value="12">{t('1:00 AM')}</MenuItem>
                    <MenuItem value="15">{t('1:15 AM')}</MenuItem>
                    <MenuItem value="18">{t('1:30 AM')}</MenuItem>
                    <MenuItem value="21">{t('1:45 AM')}</MenuItem>

                    <MenuItem value="24">{t('2:00 AM')}</MenuItem>
                    <MenuItem value="27">{t('2:15 AM')}</MenuItem>
                    <MenuItem value="30">{t('2:30 AM')}</MenuItem>
                    <MenuItem value="33">{t('2:45 AM')}</MenuItem>

                    <MenuItem value="36">{t('3:00 AM')}</MenuItem>
                    <MenuItem value="39">{t('3:15 AM')}</MenuItem>
                    <MenuItem value="42">{t('3:30 AM')}</MenuItem>
                    <MenuItem value="45">{t('3:45 AM')}</MenuItem>

                    <MenuItem value="48">{t('4:00 AM')}</MenuItem>
                    <MenuItem value="51">{t('4:15 AM')}</MenuItem>
                    <MenuItem value="54">{t('4:30 AM')}</MenuItem>
                    <MenuItem value="57">{t('4:45 AM')}</MenuItem>

                    <MenuItem value="60">{t('5:00 AM')}</MenuItem>
                    <MenuItem value="63">{t('5:15 AM')}</MenuItem>
                    <MenuItem value="66">{t('5:30 AM')}</MenuItem>
                    <MenuItem value="69">{t('5:45 AM')}</MenuItem>

                    <MenuItem value="72">{t('6:00 AM')}</MenuItem>
                    <MenuItem value="75">{t('6:15 AM')}</MenuItem>
                    <MenuItem value="78">{t('6:30 AM')}</MenuItem>
                    <MenuItem value="81">{t('6:45 AM')}</MenuItem>

                    <MenuItem value="84">{t('7:00 AM')}</MenuItem>
                    <MenuItem value="87">{t('7:15 AM')}</MenuItem>
                    <MenuItem value="90">{t('7:30 AM')}</MenuItem>
                    <MenuItem value="93">{t('7:45 AM')}</MenuItem>

                    <MenuItem value="96">{t('8:00 AM')}</MenuItem>
                    <MenuItem value="99">{t('8:15 AM')}</MenuItem>
                    <MenuItem value="102">{t('8:30 AM')}</MenuItem>
                    <MenuItem value="105">{t('8:45 AM')}</MenuItem>

                    <MenuItem value="108">{t('9:00 AM')}</MenuItem>
                    <MenuItem value="111">{t('9:15 AM')}</MenuItem>
                    <MenuItem value="114">{t('9:30 AM')}</MenuItem>
                    <MenuItem value="117">{t('9:45 AM')}</MenuItem>

                    <MenuItem value="120">{t('10:00 AM')}</MenuItem>
                    <MenuItem value="123">{t('10:15 AM')}</MenuItem>
                    <MenuItem value="126">{t('10:30 AM')}</MenuItem>
                    <MenuItem value="129">{t('10:45 AM')}</MenuItem>

                    <MenuItem value="132">{t('11:00 AM')}</MenuItem>
                    <MenuItem value="135">{t('11:15 AM')}</MenuItem>
                    <MenuItem value="138">{t('11:30 AM')}</MenuItem>
                    <MenuItem value="141">{t('11:45 AM')}</MenuItem>

                    <MenuItem value="144">{t('12:00 PM')}</MenuItem>
                    <MenuItem value="147">{t('12:15 PM')}</MenuItem>
                    <MenuItem value="150">{t('12:30 PM')}</MenuItem>
                    <MenuItem value="153">{t('12:45 PM')}</MenuItem>

                    <MenuItem value="156">{t('1:00 PM')}</MenuItem>
                    <MenuItem value="159">{t('1:15 PM')}</MenuItem>
                    <MenuItem value="162">{t('1:30 PM')}</MenuItem>
                    <MenuItem value="165">{t('1:45 PM')}</MenuItem>

                    <MenuItem value="168">{t('2:00 PM')}</MenuItem>
                    <MenuItem value="171">{t('2:15 PM')}</MenuItem>
                    <MenuItem value="174">{t('2:30 PM')}</MenuItem>
                    <MenuItem value="177">{t('2:45 PM')}</MenuItem>

                    <MenuItem value="180">{t('3:00 PM')}</MenuItem>
                    <MenuItem value="183">{t('3:15 PM')}</MenuItem>
                    <MenuItem value="186">{t('3:30 PM')}</MenuItem>
                    <MenuItem value="189">{t('3:45 PM')}</MenuItem>

                    <MenuItem value="192">{t('4:00 PM')}</MenuItem>
                    <MenuItem value="195">{t('4:15 PM')}</MenuItem>
                    <MenuItem value="198">{t('4:30 PM')}</MenuItem>
                    <MenuItem value="201">{t('4:45 PM')}</MenuItem>

                    <MenuItem value="204">{t('5:00 PM')}</MenuItem>
                    <MenuItem value="207">{t('5:15 PM')}</MenuItem>
                    <MenuItem value="210">{t('5:30 PM')}</MenuItem>
                    <MenuItem value="213">{t('5:45 PM')}</MenuItem>

                    <MenuItem value="216">{t('6:00 PM')}</MenuItem>
                    <MenuItem value="219">{t('6:15 PM')}</MenuItem>
                    <MenuItem value="222">{t('6:30 PM')}</MenuItem>
                    <MenuItem value="225">{t('6:45 PM')}</MenuItem>

                    <MenuItem value="228">{t('7:00 PM')}</MenuItem>
                    <MenuItem value="231">{t('7:15 PM')}</MenuItem>
                    <MenuItem value="234">{t('7:30 PM')}</MenuItem>
                    <MenuItem value="237">{t('7:45 PM')}</MenuItem>

                    <MenuItem value="240">{t('8:00 PM')}</MenuItem>
                    <MenuItem value="243">{t('8:15 PM')}</MenuItem>
                    <MenuItem value="246">{t('8:30 PM')}</MenuItem>
                    <MenuItem value="249">{t('8:45 PM')}</MenuItem>

                    <MenuItem value="252">{t('9:00 PM')}</MenuItem>
                    <MenuItem value="255">{t('9:15 PM')}</MenuItem>
                    <MenuItem value="258">{t('9:30 PM')}</MenuItem>
                    <MenuItem value="261">{t('9:45 PM')}</MenuItem>

                    <MenuItem value="264">{t('10:00 PM')}</MenuItem>
                    <MenuItem value="267">{t('10:15 PM')}</MenuItem>
                    <MenuItem value="270">{t('10:30 PM')}</MenuItem>
                    <MenuItem value="273">{t('10:45 PM')}</MenuItem>

                    <MenuItem value="276">{t('11:00 PM')}</MenuItem>
                    <MenuItem value="279">{t('11:15 PM')}</MenuItem>
                    <MenuItem value="282">{t('11:30 PM')}</MenuItem>
                    <MenuItem value="285">{t('11:45 PM')}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {showVoucherField && (
                <div className="cal-newappt__group cal-newappt__group--voucher">
                  <div className="cal-newappt__voucher-cont">
                    <TextField
                      className="cal-newappt__input cal-newappt__input--voucher"
                      label={t('Voucher Code')}
                      variant="outlined"
                      value={voucherCode}
                      onChange={(e) =>
                        this._onAppointmentFieldChange(
                          ['voucherCode'],
                          e.target.value
                        )
                      }
                    />
                    <ClickAwayListener
                      onClickAway={this._onCloseVoucherTooltip}
                    >
                      <button
                        className="cal-newappt__voucher-tooltip"
                        onClick={this._onToggleVoucherTooltip}
                      >
                        <Tooltip
                          open={showVoucherTooltip}
                          placement="top-end"
                          title={t('Description coming soon!!!')}
                        >
                          <LocalOfferIcon className="cal-newappt__voucher-icon" />
                        </Tooltip>
                      </button>
                    </ClickAwayListener>
                  </div>
                  <span
                    className={`cal-newappt__error ${
                      !isVoucherValid ? 'cal-newappt__error--visible' : ''
                    }`}
                  >
                    {t('Invalid or expired voucher code')}
                  </span>
                </div>
              )}
              <div className="cal-newappt__group cal-newappt__group--notes">
                <TextField
                  className="cal-newappt__input cal-newappt__input--notes"
                  inputProps={{ maxLength: 100 }}
                  label={t('Notes')}
                  multiline
                  rows={5}
                  variant="outlined"
                  value={newAppt.notes}
                  onChange={(e) =>
                    this._onAppointmentFieldChange(['notes'], e.target.value)
                  }
                />
              </div>
            </div>
          )}
          <div className="cal-newappt__group">
            <button
              className="cal-newappt__book"
              disabled={!canBook}
              onClick={this._onBookAppointment}
            >
              {!apptToEdit ? t('Book') : t('Save')}
            </button>
          </div>
        </div>
      </Drawer>
    );
  }
}

CalendarAddAppointmentDrawer.propTypes = {};

export default withTranslation()(CalendarAddAppointmentDrawer);
