import MuiBadge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import MuiDrawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiInputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { Component, createRef, default as React } from 'react';
import { withTranslation } from 'react-i18next';
import EyeBookNowLogo from '../../Assets/Images/EBN-Eye-Icon.png';
import Breakpoint from '../../breakpoint';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import Events from '../../events';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import LocationConfigService from '../../Services/locationConfigService';
import NotificationService from '../../Services/notificationsService';
import TwoWayConversationService from '../../Services/twoWayConversationService';
import Session from '../../session';
import Storage from '../../storage';
import Utils from '../../utils';
import './AdminMenu.scss';

const eBuilder = new EventBuilder();

const IconButton = withStyles({
  root: {
    padding: 0,
    color: '#FFFFFF',
  },
})(MuiIconButton);

const Drawer = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: '11 !important',
  },
  paper: {
    backgroundColor: '#303859',
    padding: '4em 0 0 0',
  },
})(MuiDrawer);

const InputBase = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
  },
  input: {
    backgroundColor: 'white',
    padding: '10.5px 14px;',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
})(MuiInputBase);

const AutoComplete = withStyles({
  input: {
    fontWeight: '600',
  },
})(Autocomplete);

const Badge = withStyles({
  badge: {
    backgroundColor: '#4fa660',
  },
})(MuiBadge);

/**
 * Represents the admin menu.
 */
class AdminMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLocation: { value: 0, display: '' },
      disableNavButton: false,
      isPaneCollapsed: true,
      locations: [],
      showNav: false,
      systemMessageCount: 0,
      isChatEnabled: false,
      hasChatNotifications: false,
      featureConfiguration: {},
    };
    this._adminMenu = createRef();
    this._locationConfigService = new LocationConfigService();
    this._notificationService = new NotificationService();
    this._twoWayConversationService = new TwoWayConversationService();
    this._featureConfigurationService = new FeatureConfigurationService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    this._setupLocationData();
    this._setupSubscriptions();
    this._setupEventHandlers();
    const featureConfiguration =
      await this._featureConfigurationService.getFeatureConfiguration(
        process.env.REACT_APP_ENVIRONMENT
      );
    this.setState(() => ({
      featureConfiguration: featureConfiguration,
    }));
  }

  /**
   * Executes when the component has unmounted from the DOM.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this._safeWindowResize);
  }

  _getLocationConfig = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const locationConfig =
          await this._locationConfigService.getLocationConfig();
        const { countrySettings, locationSettings } = locationConfig;

        const stellestSettings = locationSettings.find(
          (e) =>
            e.displayName ===
            Constants.LocationSettings.settingIsStellestEnabled
        );
        const isStellestEnabled = stellestSettings
          ? stellestSettings.settingValue
          : false;

        const isCountryChatEnabled = countrySettings?.some(
          (cs) =>
            cs.settingName === Constants.CountrySettings.settingIsChatEnabled &&
            cs.settingValue === true
        );
        const isLocationChatEnabled = locationSettings?.some(
          (cs) =>
            cs.displayName ===
              Constants.LocationSettings.settingIsChatEnabled &&
            cs.settingValue === true
        );
        this.setState(() => ({
          isChatEnabled: isCountryChatEnabled || isLocationChatEnabled,
          isStellestEnabled: isStellestEnabled,
        }));
        this._checkForUnreadNotifications();
        this._checkForChatNotifications();
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  _setupLocationData = () => {
    const curId = Storage.getItem(Constants.currLocIdKey);
    const user = Storage.getItem(Constants.currUserKey);
    const jwtData = Utils.decodeJwt(user.jwt);
    const locationIds = jwtData.locations;
    const locationNames = jwtData.locationNames;

    let locations = [];
    let currentLocation = { value: 0, display: '' };

    if (Array.isArray(locationIds)) {
      for (let i = 0; i < locationIds.length; i++) {
        locations = locations.concat({
          value: locationIds[i],
          display: locationNames[i],
        });
      }
    } else {
      locations = locations.concat({
        value: locationIds,
        display: locationNames,
      });
    }

    if (locations.length > 0) {
      currentLocation = locations.find(
        (loc) => parseInt(loc.value) === parseInt(curId)
      );
    }

    this.setState(() => ({
      locations: locations,
      currentLocation: currentLocation,
      isChatEnabled: false,
    }));

    this._getLocationConfig();
  };

  _changeLocation = (value) => {
    const locId = parseInt(value.value);
    const locName = value.display;
    const curLoc = { value: locId, display: locName };

    if (locId !== this.state.currentLocation.value) {
      Storage.setItem(Constants.currLocIdKey, locId);

      Events.emit(Constants.Events.locationChanged);

      this.setState(() => ({ currentLocation: curLoc }));
    }
  };

  _getOptionSelected = (option, value) => {
    const optionToTest = parseInt(option.value);
    const valueToTestAgainst = parseInt(value.value);
    return optionToTest === valueToTestAgainst;
  };

  _onAddAppointment = () => {
    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.AddAppointment.add)
      .post();

    Events.emit(Constants.Events.onToggleAddAppointment);
  };

  _onAddStellest = () => {
    eBuilder.withAction(eBuilder.Action.Scheduler.Click.AddStellest.add).post();

    Events.emit(Constants.Events.onToggleAddStellest);
  };

  _onLogout = () => {
    eBuilder.withAction(eBuilder.Action.Scheduler.Click.logout).post();

    Events.emit(Constants.Events.onLogout);
  };

  _onMenuClick = () => {
    eBuilder.withAction(eBuilder.Action.Scheduler.Click.toggleDrawer).post();

    this.setState((prevState) => ({ showPane: !prevState.showPane }));

    Events.emit(Constants.Events.onToggleCalendarPane);
  };

  _onToggleAdminSettings = () => {
    Events.emit(Constants.Events.onToggleAdminSettings);
  };

  _onToggleNavigation = (isClosing) => {
    // Disable the nav button to prevent quick
    // clicks. We need to give the drawer time
    // to close and time for us to change the
    // z-index back to normal for the menu.
    if (isClosing) {
      this.setState(() => ({ disableNavButton: true }));
    }

    this.setState((prevState) => {
      // We need to delay changing the z-index
      // of the menu, so that there is not a
      // flash on the UI.
      if (isClosing) {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);

          this._adminMenu.current.style.zIndex = 1;

          this.setState(() => ({ disableNavButton: false }));
        }, 1000);
      } else {
        this._adminMenu.current.style.zIndex = 15;
      }

      return {
        showNav: !prevState.showNav,
      };
    });
  };

  _setupEventHandlers() {
    const quarterOfASecond = 250;
    this._safeWindowResize = Utils.debounce(() => {
      if (
        Breakpoint.value === Constants.Breakpoints.tablet ||
        Breakpoint.value === Constants.Breakpoints.desktop
      ) {
        this.setState(() => ({ showNav: false }));
      }
    }, quarterOfASecond);

    window.addEventListener('resize', this._safeWindowResize);
  }

  _setupSubscriptions() {
    Events.on(
      Constants.Events.onToggleEditResourceSystemMessage,
      (systemMessageCount) => {
        this.setState(() => ({ systemMessageCount: systemMessageCount }));
      }
    );
    Events.on(
      Constants.Events.onToggleEditStoreHoursSystemMessage,
      (systemMessageCount) => {
        this.setState(() => ({ systemMessageCount: systemMessageCount }));
      }
    );
    Events.on(
      Constants.Events.onToggleEditServiceTypesSystemMessage,
      (systemMessageCount) => {
        this.setState(() => ({ systemMessageCount: systemMessageCount }));
      }
    );
    Events.on(
      Constants.Events.onResetSystemMessageCount,
      (systemMessageCount) => {
        this.setState(() => ({ systemMessageCount: systemMessageCount }));
      }
    );
    Events.on(Constants.Events.newNotification, () => {
      this.setState(() => ({ hasUnreadNotifications: true }));
    });
    Events.on(Constants.Events.readAllNotifications, () => {
      this.setState(() => ({ hasUnreadNotifications: false }));
    });
    Events.on(Constants.Events.chatNotificationUpdated, (chatNotification) => {
      this.setState(() => ({
        hasChatNotifications: chatNotification.hasNotifications,
      }));
    });
    Events.on(Constants.Events.locationChanged, async () => {
      this._checkForUnreadNotifications();
      this._setupLocationData();
    });
    Events.on(Constants.Events.locationReset, (locationId) => {
      const location = this.state.locations.find(
        (loc) => parseInt(loc.value) === locationId
      );
      if (location) {
        this._changeLocation(location);
      }
    });
  }

  _checkForUnreadNotifications = async () => {
    try {
      const hasUnreadNotifications =
        await this._notificationService.checkForUnreadNotifications({
          locationId: this.state.currentLocation.value,
        });
      this.setState(() => ({ hasUnreadNotifications: hasUnreadNotifications }));
    } catch (error) {
      console.error(error);
    }
  };

  _checkForChatNotifications = async () => {
    try {
      const chatLocationMetadata =
        await this._twoWayConversationService.getChatLocationMetadata();
      this.setState(() => ({
        hasChatNotifications: chatLocationMetadata.hasNotifications,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  _chatNavigation = () => {
    eBuilder
      .withCategory(eBuilder.Category.Chat)
      .withAction(eBuilder.Action.Chat.Click.clickChatFeature)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();
    //Changing the window.location will cancel the GA event posting above, so give it a brief moment to post
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      window.location = Constants.Routes.chat;
    }, 200);
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      disableNavButton,
      isStellestEnabled,
      locations,
      showPane,
      showNav,
      systemMessageCount,
      currentLocation,
      isChatEnabled,
      hasUnreadNotifications,
      hasChatNotifications,
      featureConfiguration,
    } = this.state;

    const { showHamburgerMenu, t } = this.props;

    const isDashboardEnabled =
      Session.getItem(Constants.currCountry) === 'CO' || isStellestEnabled;

    eBuilder
      .withCategory(eBuilder.Category.Scheduler.experience)
      .withLabel(eBuilder.Label.practiceIdentifier);

    return (
      <div
        className={`admin-menu admin-menu__dashboard-menu`}
        ref={this._adminMenu}
      >
        {showHamburgerMenu && (
          <div className="admin-menu__hamb-cont">
            <IconButton
              className={`admin-menu__hamb admin-menu__hamb--menu ${
                showPane ? 'admin-menu__hamb--expanded' : ''
              }`}
              onClick={this._onMenuClick}
            >
              <DateRangeIcon />
            </IconButton>
          </div>
        )}
        <div className="admin-menu__brand">
          <img
            alt="EyeBookNow Logo"
            className="admin-menu__logo admin-menu__logo--mobile"
            src={EyeBookNowLogo}
          />
          <img
            alt="EyeBookNow Logo"
            className="admin-menu__logo admin-menu__logo--desktop"
            src={Utils.getLogoUrl()}
          />
        </div>
        <div className="admin-menu__menu">
          <FormControl className="admin-menu__locations" variant="outlined">
            <AutoComplete
              options={locations}
              disableClearable
              value={currentLocation}
              size="medium"
              getOptionLabel={(option) => option.display}
              getOptionSelected={(option, value) =>
                this._getOptionSelected(option, value)
              }
              onChange={(e, value) => this._changeLocation(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={t('Search Locations')}
                />
              )}
            />
          </FormControl>
          <div className="admin-menu__nav">
            <div className="admin-menu__badges">
              {(systemMessageCount || hasChatNotifications) > 0 && !showNav && (
                <span className="admin-menu__badge admin-menu__badge--success"></span>
              )}
            </div>
            <IconButton
              className={`admin-menu__hamb ${
                showNav ? 'admin-menu__hamb--expanded' : ''
              }`}
              disabled={disableNavButton}
              onClick={() => this._onToggleNavigation(showNav === true)}
            >
              <div className="admin-menu__hamb-icon">
                <div></div>
              </div>
            </IconButton>
            <ul className="admin-menu__items">
              {isDashboardEnabled && (
                <li>
                  <button
                    id="menu-dashboard"
                    className="admin-menu__item"
                    onClick={() =>
                      (window.location = Constants.Routes.dashboard)
                    }
                  >
                    {t('Dashboard')}
                  </button>
                </li>
              )}
              <li>
                <button
                  id="menu-calendar"
                  className="admin-menu__item"
                  onClick={() => (window.location = Constants.Routes.schedule)}
                >
                  {t('Calendar')}
                </button>
              </li>
              <li>
                <button
                  id="menu-notifications"
                  className="admin-menu__item"
                  onClick={() =>
                    (window.location = Constants.Routes.notifications)
                  }
                >
                  <Badge
                    color="error"
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    invisible={!hasUnreadNotifications}
                    variant="dot"
                  >
                    {t('Notifications')}
                  </Badge>
                </button>
              </li>
              {isChatEnabled && (
                <li>
                  <button
                    id="menu-chat"
                    className="admin-menu__item admin-menu__item--new"
                    onClick={this._chatNavigation}
                  >
                    <Badge
                      color="error"
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      invisible={!hasChatNotifications}
                      variant="dot"
                    >
                      {t('Chat')}
                    </Badge>
                  </button>
                </li>
              )}
              <li>
                <IconButton
                  id="menu-settings"
                  onClick={() => (window.location = Constants.Routes.settings)}
                >
                  <SettingsIcon />
                </IconButton>
              </li>
              {
                <li>
                  {featureConfiguration.EnableAddPlusMenu && (
                    <Button
                      id="menu-add-appointment"
                      className="admin-menu__add-appt"
                      onClick={() => this._onAddAppointment()}
                      startIcon={
                        <ControlPointIcon
                          style={{ fontSize: 30 }}
                        ></ControlPointIcon>
                      }
                      size="large"
                    >
                      {t('PlusAddButton')}
                    </Button>
                  )}
                  {!featureConfiguration.EnableAddPlusMenu && (
                    <Button
                      id="menu-add-appointment"
                      className="admin-menu__add-appt"
                      onClick={() => this._onAddAppointment()}
                      size="large"
                    >
                      {t('Add Appointment')}
                    </Button>
                  )}
                </li>
              }
              {isStellestEnabled && (
                <li>
                  <Button
                    id="menu-add-stellest"
                    className="admin-menu__add-stellest"
                    onClick={() => this._onAddStellest()}
                    size="large"
                  >
                    {t('Stellest')}
                  </Button>
                </li>
              )}
              <li>
                <button
                  id="menu-logout"
                  className="admin-menu__item"
                  onClick={this._onLogout}
                >
                  {t('Log Out')}
                </button>
              </li>
            </ul>
          </div>
        </div>
        {systemMessageCount > 0 && (
          <div className="admin-menu__notifications">
            <ErrorOutlineIcon />
            <p>
              {systemMessageCount} {t('system updates')}
            </p>
          </div>
        )}
        <Drawer anchor="top" open={showNav}>
          <ul className="admin-menu__items admin-menu__items--mobile">
            {isDashboardEnabled && (
              <li className="admin-menu__item-cont">
                <button
                  id="menu-dashboard"
                  className="admin-menu__item admin-menu__item--mobile"
                  onClick={() => (window.location = Constants.Routes.dashboard)}
                >
                  {t('Dashboard')}
                </button>
              </li>
            )}
            <li className="admin-menu__item-cont">
              <button
                id="menu-calendar"
                className="admin-menu__item admin-menu__item--mobile"
                onClick={() => (window.location = Constants.Routes.schedule)}
              >
                {t('Calendar')}
              </button>
            </li>
            {isChatEnabled && (
              <li className="admin-menu__item-cont">
                {hasChatNotifications && (
                  <span className="admin-menu__badge__subMenu admin-menu__badge admin-menu__badge--success"></span>
                )}
                <button
                  id="menu-chat"
                  className="admin-menu__item admin-menu__item--mobile admin-menu__item--new"
                  onClick={this._chatNavigation}
                >
                  {t('Chat')}
                </button>
              </li>
            )}
            <li className="admin-menu__item-cont">
              <button
                id="menu-logout"
                className="admin-menu__item admin-menu__item--mobile"
                onClick={this._onLogout}
              >
                {t('Log Out')}
              </button>
            </li>
          </ul>
        </Drawer>
      </div>
    );
  }
}

AdminMenu.propTypes = {
  showPane: PropTypes.bool,
};

AdminMenu.defaultProps = {
  showHamburgerMenu: true,
};

export default withTranslation()(AdminMenu);
