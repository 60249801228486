/**
 * Represents a collection of enum values.
 */
const Enums = {
  /**
   * Represents the supported PreferredPlatform values.
   */
  PreferredPlatform: {
    /**
     * Indicates a reminder message of type WhatsApp.
     */
    get whatsApp() {
      return 2;
    },
    /**
     * Indicates a reminder message of type SMS.
     */
    get smsText() {
      return 1;
    },
  },

  /**
   * Represents the supported appointment statuses.
   */
  AppointmentStatus: {
    /**
     * Indicates a scheduled status.
     */
    get scheduled() {
      return 1;
    },

    /**
     * Indicates a queued status.
     */
    get queued() {
      return 0;
    },

    /**
     * Indicates a no show status.
     */
    get noShow() {
      return 5;
    },

    /**
     * Indicates a cancelled status.
     */
    get cancelled() {
      return 6;
    },

    /**
     * Indicates a feedback status.
     */
    get feedback() {
      return 7;
    },
  },

  /**
   * Represents the supported http status codes.
   */
  HttpStatusCodes: {
    /**
     * Returns the HTTP status ok value.
     */
    get httpStatusOk() {
      return 200;
    },

    /**
     * Returns the HTTP status server error value.
     */
    get httpStatusInternalServerError() {
      return 500;
    },

    get httpStatusUnauthorizedError() {
      return 401;
    },
  },

  /**
   * Represents the supported Vacation recurrence types.
   */
  VacationRecurrenceType: {
    /**
     * Indicates a holiday that can occur multiple times a year with in a date range, not necessarily monthly or anually.
     */
    get none() {
      return 0;
    },

    /**
     * Indicates a Vacation that occurs annually.
     */
    get annually() {
      return 1;
    },

    /**
     * Indicates a Vacation that occurs monthly.
     */
    get monthly() {
      return 2;
    },

    /**
     * Indicates a Vacation that occurs weekly.
     */
    get weekly() {
      return 3;
    },
  },

  /**
   * Represents the supported keyboard keys.
   */
  KeyboardKeys: {
    /**
     * Indicates the Enter key.
     */
    get enterKey() {
      return 'Enter';
    },
  },

  /**
   * Represents the supported user statuses.
   */
  UserStatus: {
    /**
     * Indicates the onboarding status.
     */
    get onboarding() {
      return 'Onboarding';
    },

    /**
     * Indicates the active status.
     */
    get active() {
      return 'Active';
    },

    /**
     * Indicates the password reset status.
     */
    get pwReset() {
      return 'PwReset';
    },
  },

  /**
   * Represents the supported appointment interval.
   */
  AppointmentIntervals: {
    /**
     * Indicates 15 minute appointment interval
     */
    get quarterHour() {
      return 15;
    },

    /**
     * Indicates 30 minute appointment interval
     */
    get halfHour() {
      return 30;
    },

    /**
     * Indicates 60 minute appointment interval
     */
    get fullHour() {
      return 60;
    },
  },
};

export class TemplateType {
  static get freeFormSMS() {
    return {
      title: 'FreeFormSMS',
      chatTemplateType: -1,
    };
  }
  static get recallReminder() {
    return {
      chatTemplateType: 1059,
      title: 'RecallReminder',
    };
  }
  static get walkInPostVisitFeedback() {
    return {
      chatTemplateType: 1057,
      title: 'WalkInPostVisitFeedback',
    };
  }
  static get preAppointmentQuestionnaire() {
    return {
      chatTemplateType: 1058,
      title: 'PreAppointmentQuestionnaire',
    };
  }
  static get productAvailableForPickup() {
    return {
      chatTemplateType: 1056,
      title: 'ProductAvailableForPickup',
    };
  }
  static get bookingLink() {
    return {
      chatTemplateType: 1060,
      title: 'BookingLink',
    };
  }
  static get customerQuote() {
    return {
      chatTemplateType: 1083,
      title: 'CustomerQuote',
    };
  }
}

/**
 * Represents the supported holiday recurrence types.
 */
export class HolidayRecurrenceType {
  /**
   * Indicates a holiday that can occur multiple times a year with in a date range, not necessarily monthly or anually.
   */
  static none = 0;

  /**
   * Indicates a holiday that occurs monthly.
   */
  static monthly = 1;

  /**
   * Indicates a holiday that occurs annually.
   */
  static annually = 2;
}

/**
 * Represents the supported notification types.
 */
export class NotificationType {
  /**
   * Indicates an appointment updated notification.
   */
  static appointmentUpdated = 1;

  /**
   * Indicates an appointment booked notification.
   */
  static appointmentBooked = 2;

  /**
   * Indicates an appointment feedback notification.
   */
  static appointmentFeedbackReceived = 3;
}

/**
 * Represents the supported rating values.
 */
export class RatingValue {
  /**
   * Indicates no rating.
   */
  static none = 0;

  /**
   * Indicates a not likely rating.
   */
  static notLikely = 1;

  /**
   * Indicates a likely rating.
   */
  static likely = 2;

  /**
   * Indicates a neutral rating.
   */
  static neutral = 3;

  /**
   * Indicates a most likely rating.
   */
  static mostLikely = 4;

  /**
   * Indicates a very likely rating.
   */
  static veryLikely = 5;
}

/**
 * Represents supported Stellest order types
 */
export class StellestOrderType {
  /**
   * Indicates a standalone lens package
   */
  static StandardLens = 0;

  /**
   * Indicates a care package
   */
  static CarePackage = 1;

  /**
   * Indicates a second standard care package order
   */
  static CarePackageSecondPair = 2;

  /**
   * Indicates an Assurance care package
   */
  static Assurance = 3;
}

/**
 * Represents Stellest order frame edging options
 */
export class StellestEdgingOptions {
  /**
   * Represents the no-edging option
   */
  static NoEdging = 0;

  /**
   * Represents an options with edging
   */
  static Edging = 1;
}

/**
 * Represents Stellest lens coating options for orders
 */
export class StellestCoatingOptions {
  /**
   * Represents no coating option
   */
  static None = 0;

  /**
   * Represents a Crizal Alize UV coating
   */
  static CrizalAlizeUv = 1;

  /**
   * Represents a Prevencia coating
   */
  static Prevencia = 2;
}

/**
 * Represents Stellest order gender options
 */
export class StellestGenderOptions {
  /**
   * Represents unspecified gender
   */
  static PreferNotToSpecify = 1;

  /**
   * Represents male gender
   */
  static Male = 2;

  /**
   * Represents female gender
   */
  static Female = 3;
}

/**
 * Represents Stellest ethnicity options
 */
export class StellestEthnicityOptions {
  /**
   * Represents Other ethnicity
   */
  static Other = 1;

  /**
   * Represents Chinese nationality
   */
  static Chinese = 2;

  /**
   * Represents Malay ethnicity
   */
  static Malay = 3;

  /**
   * Represents Indian ethnicity
   */
  static Indian = 4;
}

export class OrderType {
  static stellestStandAloneLens = 0;
  static stellestCarePackage = 1;
  static stellestAssurance = 3;
}

export class OrderStatus {
  static submitted = 0;
  static delivered = 1;
  static canceled = 2;
}

export class ConfirmationStatus {
  static confirmed = 0;
  static unconfirmed = 1;
}

// Lock object to prevent modification (true static).
Object.freeze(Enums);
Object.freeze(HolidayRecurrenceType);
Object.freeze(NotificationType);
Object.freeze(RatingValue);
Object.freeze(StellestOrderType);
Object.freeze(StellestEdgingOptions);
Object.freeze(StellestCoatingOptions);
Object.freeze(StellestGenderOptions);
Object.freeze(StellestEthnicityOptions);
Object.freeze(OrderType);
Object.freeze(OrderStatus);
Object.freeze(ConfirmationStatus);

export default Enums;
