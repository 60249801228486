import axios from 'axios';
import TagManager from 'react-gtm-module';
import Events from './events';
import Storage from './storage';
import Session from './session';
import { v4 as uuidv4 } from 'uuid';
import Constants from './constants';
import Breakpoint from './breakpoint';

(() => {
  // Setup axios to pass the jwt for each request.
  axios.interceptors.request.use((config) => {
    const currUser = Storage.getItem(Constants.currUserKey);
    let sessId = Session.getItem(Constants.sessIdKey);

    if (!sessId) {
      sessId = uuidv4();
      Session.setItem(Constants.sessIdKey, sessId);
    }
    config.headers['x-SessionId'] = sessId;

    if (currUser && currUser.jwt) {
      config.headers['Authorization'] = `Bearer ${currUser.jwt}`;
    }

    return config;
  });

  Events.on(Constants.Events.pageReady, () => {
    console.log('value:', Breakpoint.value);
    // Need to disable the GTM Help button for mobile.
    if (Breakpoint.value && Breakpoint.value !== Constants.Breakpoints.mobile) {
      const tagManagerArgs = {
        // trevor's test container
        gtmId: 'GTM-NRHHTNL',
      };

      TagManager.initialize(tagManagerArgs);
    }
  });
})();
