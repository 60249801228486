import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';
import momentLocaleWrapper from '../momentLocaleWrapper';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the settings service.
 */
class SettingsService {
  _constructCommonSettingsApiUrl(endpoint) {
    const locationId = Storage.getItem(Constants.currLocIdKey);
    const url = `${process.env.REACT_APP_SETTINGS_API}/${endpoint}/${locationId}`;

    return url;
  }

  _constructCommonDashboardApiUrl(endpoint) {
    const url = `${process.env.REACT_APP_DASHBOARD_API}/${endpoint}`;
    return url;
  }

  /**
   * Deletes an appointment type.
   * @param {Object} params The params.
   * @param {Object} params.appointmentType The appointment type to delete.
   * @returns A Promise.
   */
  async deleteAppointmentType(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { appointmentType } = params;
        const url = `${this._constructCommonSettingsApiUrl(
          'deleteAppointmentType'
        )}/${appointmentType.locationAppointmentTypeId}`;
        const data = {
          appointmentTypes: appointmentType,
        };

        await axios.post(url, data);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        const { appointmentType } = params;
        errorReporting.reportError(
          error,
          `${this._constructCommonSettingsApiUrl('deleteAppointmentType')}/${
            appointmentType.locationAppointmentTypeId
          }`
        );
        reject(error);
      }
    });
  }

  /**
   * Deletes a holiday.
   * @param {Object} params The params.
   * @param {Number} params.locationId The location id.
   * @param {Number} params.locationHolidayId The location holiday id.
   * @returns A Promise.
   */
  async deleteLocationHoliday(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_SETTINGS_API}/deleteLocationHoliday`;
        const data = {
          locationId: params.locationId,
          locationHolidayId: params.locationHolidayId,
        };

        await axios.post(url, data);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_SETTINGS_API}/deleteLocationHoliday`
        );
        reject(error);
      }
    });
  }

  /**
   * Deletes a resource.
   * @param {Object} params The params.
   * @param {Number} params.resourceId The id of the resource to delete.
   * @returns A Promise.
   */
  async deleteResource(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${this._constructCommonSettingsApiUrl('deleteResource')}/${
          params.resourceId
        }`;
        await axios.post(url);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${this._constructCommonSettingsApiUrl('deleteResource')}/${
            params.resourceId
          }`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the holidays for a location.
   * @param {Object} params The params.
   * @param {Number} params.locationId The location id.
   * @returns A Promise.
   */
  async getLocationHolidays(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_SETTINGS_API}/getLocationHolidays`;
        const data = {
          locationId: params.locationId,
        };

        const res = await axios.post(url, data);

        resolve(res?.data?.holidays);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_SETTINGS_API}/getLocationHolidays`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the patient information list.
   * @param {Object} data The data.
   * @returns A Promise.
   */
  async getPatientInfoList(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl('getPatientInfoList');
        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('getPatientInfoList')
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the patient information list.
   * @param {Object} data The data.
   * @returns A Promise.
   */
  async getPatientInfoListAsExcel(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl(
          'getPatientInfoListAsExcel'
        );
        const res = await axios.post(url, data, {
          responseType: 'arraybuffer',
        });

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('getPatientInfoListAsExcel')
        );
        reject(error);
      }
    });
  }

  /**
   *
   * @param {Object} params The params.
   * @param {Array} params.appointmentTypes The appointment types to save.
   * @returns A Promise.
   */
  async saveAppointmentTypes(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl('saveAppointmentTypes');
        const data = {
          appointmentTypes: params.appointmentTypes,
        };

        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('saveAppointmentTypes')
        );
        reject(error);
      }
    });
  }

  /**
   * Saves the provided holiday.
   * @param {Object} params The params.
   * @param {Object} params.holiday The holiday to save.
   * @returns A Promise.
   */
  async saveLocationHoliday(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_SETTINGS_API}/saveLocationHoliday`;
        const { holiday } = params;
        const data = {
          holiday: {
            ...params.holiday,
            startDate: momentLocaleWrapper(holiday.startDate).format(
              '(YYYY, MM, DD, HH, mm)'
            ),
            endDate: momentLocaleWrapper(holiday.endDate).format(
              '(YYYY, MM, DD, HH, mm)'
            ),
          },
        };
        const res = await axios.post(url, data);

        resolve(res?.data?.holiday);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_SETTINGS_API}/saveLocationHoliday`
        );
        reject(error);
      }
    });
  }

  async saveLocationResourceVacation(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_SETTINGS_API}/saveVacation`;
        const { vacation, locationId, resourceId } = params;
        const data = {
          vacationId: vacation.vacationId,
          vacationDisplayName: vacation.vacationDisplayName,
          locationId: locationId,
          resourceId: resourceId,
          startDate: momentLocaleWrapper(vacation.startDate).format(
            '(YYYY, MM, DD, HH, mm)'
          ),
          endDate: momentLocaleWrapper(vacation.endDate).format(
            '(YYYY, MM, DD, HH, mm)'
          ),
          recurrence: vacation.recurrence,
        };

        const res = await axios.post(url, data);

        //clean data for FE
        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_SETTINGS_API}/saveVacation`
        );
        reject(error);
      }
    });
  }

  async deleteLocationResourceVacation(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const { locationId, vacationId } = params;
        const url = `${process.env.REACT_APP_SETTINGS_API}/deleteVacation`;
        const data = {
          locationId: locationId,
          vacationId: vacationId,
        };

        await axios.post(url, data);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_SETTINGS_API}/deleteVacation`
        );
        reject(error);
      }
    });
  }

  /**
   *
   * @param {Object} params The params.
   * @param {Array} params.resources The resources to save.
   * @returns A Promise.
   */
  async saveResources(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl('saveResources');
        const data = {
          resources: params.resources,
        };

        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('saveResources')
        );
        reject(error);
      }
    });
  }

  /**
   * Saves the location store hours.
   * @param {Object} params The params.
   * @param {Array} params.storeHours The store hours to save.
   * @returns A Promise.
   */
  async saveStoreHours(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl('saveStoreHours');
        const data = {
          storeHours: params.storeHours,
          appointmentInterval: params.appointmentInterval,
        };

        await axios.post(url, data);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('saveStoreHours')
        );
        reject(error);
      }
    });
  }

  /**
   * Saves the location mobile number.
   * @param {Object} params The params.
   * @returns A Promise.
   */
  async saveLocationMobileNumber(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl(
          'saveLocationMobileNumber'
        );
        const data = {
          mobileNumber: params.smsPhone,
        };

        await axios.post(url, data);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('saveLocationMobileNumber')
        );
        reject(error);
      }
    });
  }

  /**
   * Saves the location admin alerts setting.
   * @param {Object} params The params.
   * @returns A Promise.
   */
  async saveLocationSettings(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl('saveLocationSettings');
        const data = {
          locationSettings: [...params.locationSettings],
        };

        await axios.post(url, data);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('saveLocationSettings')
        );
        reject(error);
      }
    });
  }

  /**
   * Saves the Google review info.
   * @param {Object} params The params.
   * @returns A Promise.
   */
  async saveGoogleReviewInfo(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonSettingsApiUrl('saveGoogleReviewInfo');
        const data = {
          placeId: params.updatedGoogleReviewInfo.placeId,
          isDefinedByECP: params.updatedGoogleReviewInfo.isDefinedByECP,
        };

        await axios.post(url, data);

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonSettingsApiUrl('saveGoogleReviewInfo')
        );
        reject(error);
      }
    });
  }
}

export default SettingsService;
