import React from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarResources from './CalendarResources';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import CalendarAppointmentTypes from './CalendarAppointmentTypes';
import TermsConditionsModal from '../TermsConditions/TermsConditionsModal';
import './CalendarPane.scss';

/**
 * Represents the left calendar pane.
 */
const CalendarPane = (props) => {
  const {
    appointmentTypes,
    currDateTime,
    resources,
    onAppointmentTypeChange,
    onCurrDateTimeChange,
    onResourceChange,
    eventBuilder,
  } = props;
  const { i18n } = useTranslation();
  momentLocaleWrapper.locale(i18n.language);

  return (
    <div className="cal-pane">
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        libInstance={momentLocaleWrapper}
        locale={i18n.language}
      >
        <DatePicker
          autoOk
          disableToolbar={true}
          openTo="date"
          variant="static"
          value={currDateTime}
          onChange={onCurrDateTimeChange}
        />
      </MuiPickersUtilsProvider>
      <CalendarAppointmentTypes
        appointmentTypes={appointmentTypes}
        onAppointmentTypeChange={onAppointmentTypeChange}
      />
      <CalendarResources
        resources={resources}
        onResourceChange={onResourceChange}
      />
      <TermsConditionsModal
        isFromSettings={false}
        eventBuilder={eventBuilder}
      />
    </div>
  );
};

CalendarPane.propTypes = {
  appointmentTypes: PropTypes.array.isRequired,
  currDateTime: PropTypes.object.isRequired,
  resources: PropTypes.array.isRequired,
  onAppointmentTypeChange: PropTypes.func.isRequired,
  onCurrDateTimeChange: PropTypes.func.isRequired,
  onResourceChange: PropTypes.func.isRequired,
};

export default CalendarPane;
