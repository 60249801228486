import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import Breakpoint from '../../breakpoint';
import EventBuilder from '../../eventBuilder';
import './CalendarAppointmentTypes.scss';

const eBuilder = new EventBuilder();

const Checkbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#979797',
    },
  },
  checked: {},
})(MuiCheckbox);

const FormControlLabel = withStyles({
  label: {
    fontSize: '0.8rem',
  },
})(MuiFormControlLabel);

/**
 * Represents the calendar appointment types list.
 */
class CalendarAppointmentTypes extends Component {
  /**
   * Initializes a new instance of the CalendarAppointmentTypes component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showMoreApptTypes: false,
    };
    this._maxApptTypesToShow = 4;

    eBuilder
      .withCategory(eBuilder.Category.Scheduler.experience)
      .withAction(eBuilder.Action.Scheduler.Click.appointmentTypesFilter);
  }

  _onAppointmentTypeChange = (id, path, value) => {
    this.props.onAppointmentTypeChange(id, path, value);

    const foundApptType = this.props.appointmentTypes.find(
      (at) => at.id === id
    );
    const appointmentLabel = foundApptType?.displayName;

    eBuilder.withLabel(eBuilder.Label.alternateLabel, appointmentLabel).post();
  };

  _onToggleMoreAppointmentTypes = () => {
    this.setState((prevState) => ({
      showMoreApptTypes: !prevState.showMoreApptTypes,
    }));
  };

  /**
   * Renders the component.
   */
  render() {
    const { showMoreApptTypes } = this.state;
    const { appointmentTypes, t } = this.props;
    const hasMoreApptTypes = appointmentTypes.length > this._maxApptTypesToShow;
    const appointmentTypeItems = appointmentTypes.map((at, key) => (
      <li
        className={`cal-appt-types__item ${
          Breakpoint.value !== 'mobile' &&
          hasMoreApptTypes &&
          !showMoreApptTypes &&
          key >= this._maxApptTypesToShow
            ? 'cal-appt-types__item--hidden'
            : ''
        }`}
        key={key}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={at.isChecked}
              name={`checked${key}`}
              onChange={(e) =>
                this._onAppointmentTypeChange(
                  at.id,
                  ['isChecked'],
                  e.target.checked
                )
              }
            />
          }
          label={at.displayName}
        />
      </li>
    ));

    return (
      <div className="cal-appt-types">
        <h3 className="cal-appt-types__title">{t('Service Types')}</h3>
        <ul className="cal-appt-types__list">{appointmentTypeItems}</ul>
        {Breakpoint.value !== 'mobile' && showMoreApptTypes ? (
          <button
            className="cal-appt-types__more"
            onClick={this._onToggleMoreAppointmentTypes}
          >
            {t('Show Less')}
          </button>
        ) : (
          Breakpoint.value !== 'mobile' &&
          hasMoreApptTypes && (
            <button
              className="cal-appt-types__less"
              onClick={this._onToggleMoreAppointmentTypes}
            >
              {t('Show More')}
            </button>
          )
        )}
      </div>
    );
  }
}

CalendarAppointmentTypes.propTypes = {
  appointmentTypes: PropTypes.array.isRequired,
  onAppointmentTypeChange: PropTypes.func.isRequired,
};

export default withTranslation()(CalendarAppointmentTypes);
