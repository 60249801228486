import React from 'react';
import moment from 'moment';
import { DayView } from '@devexpress/dx-react-scheduler-material-ui';
import Events from '../../events';
import Session from '../../session';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';

const eBuilder = new EventBuilder();

/**
 * Represents a custom table cell for a calendar time.
 * @param {Object} props The component properties.
 */
const CalendarTableCell = (props) => {
  const { classNames, currentTimeIndicatorPosition, ...restProps } = props;
  const { startDate, groupingInfo, isShaded } = props;

  const bookAppointment = (apptId) => {
    const resourceData = groupingInfo.find(
      (gi) => gi.fieldName === 'resourceId'
    );
    const bookingData = {
      apptId: apptId,
      resource: {
        displayName: resourceData.text,
        resourceId: resourceData.id,
      },
      startDate: startDate,
    };

    Events.emit(Constants.Events.scheduleAppointment, bookingData);

    eBuilder.withCategory(eBuilder.Category.Scheduler.experience);
    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.Waitlist.confirm)
      .withLabel(
        eBuilder.Label.practiceIdentifier,
        `${resourceData.displayName}`
      );
  };

  const onTableCellClick = () => {
    const currSelectedQueueAppt = Session.getItem(
      Constants.currSelectedQueueAppt
    );

    if (currSelectedQueueAppt) {
      bookAppointment(currSelectedQueueAppt.appointmentId);

      Session.setItem(Constants.currSelectedQueueAppt, null);
    }
  };

  const onTableCellDragEnter = (e) => {
    const { target } = e;
    target.classList.add('cal-tablecell--dragenter');
  };

  const onTableCellDragLeave = (e) => {
    e.target.classList.remove('cal-tablecell--dragenter');
  };

  const onTableCellDrop = (e) => {
    e.preventDefault();
    e.target.classList.remove('cal-tablecell--dragenter');

    const apptId = parseInt(e.dataTransfer.getData('text'));

    bookAppointment(apptId);
  };

  const timeBlockCheck = () => {
    const resources = Session.getItem(Constants.currResources);
    const cellResourceId = props.groupingInfo[0].id;
    const cellResource = resources?.find(
      (resource) => resource.resourceId === cellResourceId
    );

    if (cellResource && cellResource.availableHours) {
      const dayOfWeek = parseInt(moment().format('d')) + 1;
      const availableHours = cellResource.availableHours.find(
        (hours) => hours.dayOfWeekId === dayOfWeek
      );

      if (
        availableHours &&
        availableHours.breakOutTime > -1 &&
        availableHours.breakInTime > -1
      ) {
        const startTimeBlock =
          parseInt(
            moment(props.startDate).diff(moment().startOf('day'), 'minutes')
          ) / 5;
        const endTimeBlock =
          parseInt(
            moment(props.endDate).diff(moment().startOf('day'), 'minutes')
          ) / 5;

        if (
          startTimeBlock >= availableHours.breakOutTime &&
          endTimeBlock <= availableHours.breakInTime
        ) {
          return true;
        }
      }
    }

    return false;
  };

  return (
    <DayView.TimeTableCell
      className={`${classNames} cal-tablecell`}
      currentTimeIndicatorPosition={currentTimeIndicatorPosition}
      onClick={onTableCellClick}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={onTableCellDragEnter}
      onDragLeave={onTableCellDragLeave}
      onDrop={onTableCellDrop}
      {...restProps}
      isShaded={isShaded ? isShaded : timeBlockCheck()}
    ></DayView.TimeTableCell>
  );
};

export default CalendarTableCell;
