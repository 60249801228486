import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './TwoWayModal.scss';
import Spinner from '../Spinner/Spinner';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const TwoWayModal = ({
  open,
  heading,
  body,
  spinner,
  buttons = [],
  closeModal,
}) => {
  return (
    <>
      <Dialog
        disableBackdropClick={true}
        open={open}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {heading}
          <IconButton
            aria-label="close"
            className="alert-dialog-title-close"
            onClick={closeModal}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {body}
          </DialogContentText>
        </DialogContent>

        {spinner && (
          <span className="spinner-centered">
            <Spinner />
          </span>
        )}

        <DialogActions>
          {buttons.map((button) => (
            <Button
              onClick={button.action}
              color={button.color}
              key={button.key}
            >
              {button.content}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TwoWayModal;
