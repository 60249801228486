import React from 'react';
import Router from './Router/router';
import './App.css';
import { StylesProvider } from '@material-ui/core/styles';

const App = () => {
  return (
    <div className="App">
      <StylesProvider injectFirst>
        <React.Fragment>
          <Router />
        </React.Fragment>
      </StylesProvider>
    </div>
  );
};

export default App;
