import React, { useEffect, useRef, useState } from 'react';
import './TwoWayCard.scss';
import FlagIcon from '@material-ui/icons/Flag';
import DeleteIcon from '@material-ui/icons/Delete';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Constants from '../../constants';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Utils from '../../utils';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withTranslation } from 'react-i18next';
import useWindowSize from './useWindowSize';

const TwoWayCard = ({
  conversation,
  swippedConversation,
  onConversationSwipped,
  conversationClick,
  updateConversationStatus,
  active,
  t,
}) => {
  const cardId = 'card--id--' + conversation.conversationKey;
  const [classStatus, setClassStatus] = useState('dot');
  const [isOnDelete, setIsOnDelete] = useState(false);
  const [touchX, setTouchX] = useState(0);
  const [isSwipped, setIsSwipped] = useState(false);
  const [isFlag, setIsFlag] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const windowSize = useWindowSize();
  let message = '';

  useEffect(() => {
    switchStatus(conversation);
  }, [conversation]);

  useEffect(() => {
    if (
      swippedConversation?.conversationKey !== conversation?.conversationKey &&
      isSwipped
    ) {
      setIsSwipped(false);
      setIsDelete(false);
      setIsFlag(false);
    }
  }, [swippedConversation]);

  useEffect(() => {
    if (windowSize.width > 900) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [windowSize.width]);

  if (conversation && conversation.message) {
    message = conversation.message.substring(
      0,
      Constants.TwoWayMessages.cardBlurbCountMax
    );
  }
  if (message.length >= Constants.TwoWayMessages.cardBlurbCountMax) {
    message += '...';
  }

  const switchStatus = (conversation) => {
    switch (conversation.conversationStatus) {
      case Constants.TwoWayMessages.viewStatus.unread:
        setClassStatus('dot dot--blue');
        break;
      case Constants.TwoWayMessages.viewStatus.none:
        setClassStatus('');
        break;
      case Constants.TwoWayMessages.viewStatus.unanswered:
        setClassStatus('dot dot--green');
        break;
      case Constants.TwoWayMessages.viewStatus.flagged:
        setClassStatus('dot dot--purple');
        break;
    }
  };

  const handleMessageStatus = (viewStatus, _conversation, event) => {
    event.stopPropagation();
    _conversation.conversationStatus = viewStatus;
    switchStatus(_conversation);
    updateConversationStatus(_conversation);
    setIsFlag(false);
    setIsOnDelete(false);
  };

  const handleConversationClick = () => {
    if (
      conversation.conversationStatus ===
      Constants.TwoWayMessages.viewStatus.unread
    ) {
      conversation.conversationStatus =
        Constants.TwoWayMessages.viewStatus.unanswered;
      switchStatus(conversation);
      updateConversationStatus(conversation);
    }
    switchStatus(conversation);
    conversationClick(conversation);
    setIsFlag(false);
    setIsDelete(false);
    setIsOnDelete(false);
  };

  const getTouches = (e) => {
    return e.touches || e.originalEvent.touches;
  };

  const handleTouchStart = (e) => {
    if (isFlag) {
      return;
    }
    const firstTouch = getTouches(e)[0];
    setTouchX(firstTouch.clientX);
  };

  const handleTouchMove = (e) => {
    if (!touchX) return;
    const xUp = e.touches[0].clientX;
    const xDiff = touchX - xUp;

    if (Math.abs(xDiff) > 20) {
      if (xDiff > 0) {
        /* left swipe */
        setIsSwipped(true);
        onConversationSwipped(conversation);
      } else {
        /* right swipe */
        setIsSwipped(false);
      }
      setTouchX(null);
    }
  };

  const handleBack = (event) => {
    event.stopPropagation();
    setIsSwipped(false);
    setIsDelete(false);
    setIsFlag(false);
  };

  const toggleDelete = (event) => {
    event.stopPropagation();
    setIsOnDelete(!isOnDelete);
  };

  const handleDeleteLeave = (event) => {
    event.stopPropagation();
    setIsOnDelete(false);
  };

  const toggleFlag = (event) => {
    event.stopPropagation();
    setIsFlag(!isFlag);
  };

  const handleShowFlag = (event) => {
    event.stopPropagation();
    setIsFlag(true);
  };

  const handleShowDelete = (event) => {
    event.stopPropagation();
    setIsDelete(true);
  };

  const handleMobileMessageStatus = (viewStatus, _conversation, event) => {
    handleMessageStatus(viewStatus, _conversation, event);
    setIsDelete(false);
    setIsFlag(false);
    setIsSwipped(false);
  };

  const lastTransmission = momentLocaleWrapper(conversation.lastTransmission);
  const isToday = lastTransmission.isSame(
    momentLocaleWrapper(new Date()),
    'day'
  );
  const lastTransmissionFormatted = isToday
    ? lastTransmission.format('LT')
    : lastTransmission.format('L');

  const holderClassName = () => {
    const className = ['card--holder'];
    if (isMobile && isSwipped) {
      className.push('is-mobile-active');
    }
    if (active) {
      className.push('active');
    }
    return className.join(' ');
  };

  let cardClass = 'card';
  if (conversation.isOptedOut) {
    cardClass = 'card card--optout';
  } else if (active === true) {
    cardClass = 'card active';
  }

  return (
    <div
      className={holderClassName()}
      id={cardId}
      onClick={handleConversationClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <div className={cardClass}>
        <span className={classStatus}></span>
        <div className="row">
          <span>
            {conversation.firstName} {conversation.lastName}
          </span>
        </div>
        {conversation.isOptedOut && (
          <p>{t('No message sent.  Customer opted out of messaging.')}</p>
        )}
        {!conversation.isOptedOut && (
          <>
            <p>{message}</p>
            <span>{lastTransmissionFormatted}</span>
          </>
        )}
      </div>
      {isMobile && (
        <div className="back" onClick={handleBack}>
          <ArrowForwardIosIcon fontSize="small" />
        </div>
      )}
      <div className={isMobile ? 'card--options mobile' : 'card--options'}>
        {!isMobile && (
          <>
            <div className="card--option card--options--flag--holder">
              <div className="card--options--flag">
                <FlagIcon className="react-icon" />
              </div>
              <div className="card--options--flags">
                <div
                  onClick={(e) =>
                    handleMessageStatus(
                      Constants.TwoWayMessages.viewStatus.unread,
                      conversation,
                      e
                    )
                  }
                >
                  <span className="dot dot--blue" />
                </div>
                <div
                  onClick={(e) =>
                    handleMessageStatus(
                      Constants.TwoWayMessages.viewStatus.unanswered,
                      conversation,
                      e
                    )
                  }
                >
                  <span className="dot dot--green" />
                </div>
                <div
                  onClick={(e) =>
                    handleMessageStatus(
                      Constants.TwoWayMessages.viewStatus.flagged,
                      conversation,
                      e
                    )
                  }
                >
                  <span className="dot dot--purple" />
                </div>
                <div
                  onClick={(e) =>
                    handleMessageStatus(
                      Constants.TwoWayMessages.viewStatus.none,
                      conversation,
                      e
                    )
                  }
                >
                  <span className="dot">
                    <NotInterestedIcon className="react-icon dot--clear" />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="card--option card--options--trash"
              onClick={toggleDelete}
            >
              <DeleteIcon className="react-icon" />
              <div
                className={
                  isOnDelete
                    ? 'card--options--trash-confirm active'
                    : 'card--options--trash-confirm'
                }
                onMouseLeave={handleDeleteLeave}
              >
                <div
                  className="confirm"
                  onClick={(e) =>
                    handleMessageStatus(
                      Constants.TwoWayMessages.viewStatus.trash,
                      conversation,
                      e
                    )
                  }
                >
                  {t('Confirm Delete')}
                </div>
                <div className="cancel" onClick={toggleDelete}>
                  {t('Cancel')}
                </div>
              </div>
            </div>
          </>
        )}
        {isMobile && (
          <>
            {!isFlag && !isDelete && (
              <>
                <FlagIcon className="react-icon" onClick={handleShowFlag} />
                <DeleteIcon className="react-icon" onClick={handleShowDelete} />
              </>
            )}
            {isFlag && (
              <>
                <div className="mobile__flag">
                  <FlagIcon
                    className="react-icon"
                    onClick={toggleFlag}
                    className="active"
                  />
                  <span>{t('Flag')}</span>
                </div>
                <div className="mobile__flag-options">
                  <div
                    onClick={(e) =>
                      handleMobileMessageStatus(
                        Constants.TwoWayMessages.viewStatus.unread,
                        conversation,
                        e
                      )
                    }
                  >
                    <span className="dot dot--blue" />
                    {t('Unread')}
                  </div>
                  <div
                    onClick={(e) =>
                      handleMobileMessageStatus(
                        Constants.TwoWayMessages.viewStatus.unanswered,
                        conversation,
                        e
                      )
                    }
                  >
                    <span className="dot dot--green" />
                    {t('Unanswered')}
                  </div>
                  <div
                    onClick={(e) =>
                      handleMobileMessageStatus(
                        Constants.TwoWayMessages.viewStatus.flagged,
                        conversation,
                        e
                      )
                    }
                  >
                    <span className="dot dot--purple" />
                    {t('Flagged')}
                  </div>
                  <div
                    onClick={(e) =>
                      handleMobileMessageStatus(
                        Constants.TwoWayMessages.viewStatus.none,
                        conversation,
                        e
                      )
                    }
                  >
                    <span className="dot dot--clear dot--clear--mobile">
                      <NotInterestedIcon className="react-icon" />
                    </span>
                    {t('Clear')}
                  </div>
                </div>
              </>
            )}
            {isDelete && (
              <div
                className="mobile__delete"
                onClick={(e) =>
                  handleMobileMessageStatus(
                    Constants.TwoWayMessages.viewStatus.trash,
                    conversation,
                    e
                  )
                }
              >
                <DeleteIcon className="active" />
                <span>{t('Delete')}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(TwoWayCard);
