import { ClickAwayListener } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MuiTooltip from '@material-ui/core/Tooltip';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import React, { useState } from 'react';
import './VoucherInputComponent.scss';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

const VoucherInputComponent = (props) => {
  const { locationGroup, isVoucherValid, isVoucherAllowed } = props;
  const [showVoucherTooltip, setShowVoucherTooltip] = useState(false);

  const { t, voucherCode } = props;
  return (
    <div className="add-drawer__group add-drawer__group--voucher">
      <div className="add-drawer__voucher-cont">
        <TextField
          className="add-drawer__input add-drawer__input--voucher"
          label={t('Voucher Code')}
          variant="outlined"
          value={voucherCode}
          onChange={(e) =>
            this._onAppointmentUpdateValues({
              voucherCode: e.target.value,
            })
          }
        />
        <ClickAwayListener onClickAway={() => setShowVoucherTooltip(false)}>
          <button
            className="add-drawer__voucher-tooltip"
            onClick={() => setShowVoucherTooltip(!showVoucherTooltip)}
          >
            <Tooltip
              open={showVoucherTooltip}
              placement="top-end"
              title={t('Description coming soon!!!')}
            >
              <LocalOfferIcon className="add-drawer__voucher-icon" />
            </Tooltip>
          </button>
        </ClickAwayListener>
      </div>
      <span
        className={`add-drawer__error ${
          !isVoucherValid ? 'add-drawer__error--visible' : ''
        }`}
      >
        {t('Invalid or expired voucher code')}
      </span>
    </div>
  );
};
export default VoucherInputComponent;
