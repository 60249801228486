import GoogleAnalytics from './googleAnalytics';

class Pages {
  static login = 'admin/login';
  static forgotPassword = 'admin/forgot-pw';
  static Registration = {
    initialLogin: 'admin/registration/login-initial',
    termsUse: 'admin/registration/termsOfUse',
    resetPassword: 'admin/registration/reset-password',
    settings: 'admin/registration/onboarding-setup',
  };
  static portal = 'admin/portal';
}

Object.freeze(Pages.Waitlist);
Object.freeze(Pages.Booking);
Object.freeze(Pages);

/**
 * Wrapper class around GA Pageviews.
 */
class PageviewBuilder {
  constructor() {
    this.Page = Pages;
  }
  /**
   * Fires off a pageview.
   * @param {string} Page Set with this.Page
   */
  pageview = (page) => {
    // do we want the hostname as well?
    GoogleAnalytics.pageview(`/${page}`);
  };
}

Object.freeze(PageviewBuilder);

export default PageviewBuilder;
