/**
 * Represents a collection of strongly typed constants.
 */
const Constants = {
  LocationSettings: {
    /**
     * Represents the current Email Customer Origin key
     */
    get settingEmailCustomerOrigin() {
      return 'Email Customer Origin';
    },

    /**
     * Represents the current Email Store Origin key
     */
    get settingEmailStoreOrigin() {
      return 'Email Store Origin';
    },

    /**
     * Represents the IsWhatsAppB2CEnabled key
     */
    get settingIsWhatsAppB2CEnabled() {
      return 'IsWhatsAppB2CEnabled';
    },

    /**
     * Represents the RequestCustomerFeedbackEnabled key
     */
    get settingIsRequestCustomerFeedbackEnabled() {
      return 'IsRequestCustomerFeedbackEnabled';
    },

    /**
     * Represents the IsDisplayRatingOnAAVLocatorEnabled key
     */
    get settingIsDisplayRatingOnAAVLocatorEnabled() {
      return 'IsDisplayRatingOnAAVLocatorEnabled';
    },

    /**
     * Represents the IsSmsNotificationsEnabled key.
     */
    get settingIsSmsNotificationsEnabled() {
      return 'IsSmsNotificationsEnabled';
    },

    /**
     * Represents the IsStellestEnabled key.
     */
    get settingIsStellestEnabled() {
      return 'IsStellestEnabled';
    },

    /**
     * Represents the Sms Customer Origin key.
     */
    get settingSmsCustomerOrigin() {
      return 'Sms Customer Origin';
    },

    /**
     * Represents the Sms Store Origin key.
     */
    get settingSmsStoreOrigin() {
      return 'Sms Store Origin';
    },

    /**
     * Represents the IsChatEnabled key.
     */
    get settingIsChatEnabled() {
      return 'IsChatEnabled';
    },
  },

  CountrySettings: {
    /**
     * Represents the IsWhatsAppB2CEnabled key
     */
    get settingIsWhatsAppB2CEnabled() {
      return 'IsWhatsAppB2CEnabled';
    },

    /**
     * Represents the RequestCustomerFeedbackEnabled key
     */
    get settingIsRequestCustomerFeedbackEnabled() {
      return 'IsRequestCustomerFeedbackEnabled';
    },

    /**
     * Represents the IsWhatsAppNotificationsEnabled key
     */
    get settingIsWhatsAppNotificationsEnabled() {
      return 'IsWhatsAppNotificationsEnabled';
    },

    /**
     * Represents the IsWhatsAppNotificationsEnabled key
     */
    get settingDefaultMessagingPlatform() {
      return 'DefaultMessagingPlatform';
    },

    /**
     * Represents the IsSmsNotificationsEnabled key.
     */
    get settingIsSmsNotificationsEnabled() {
      return 'IsSmsNotificationsEnabled';
    },

    /**
     * Represents the IsSmsOneWay Country Settings key
     */
    get settingIsSmsOneWay() {
      return 'IsSmsOneWay';
    },

    /**
     * Represents the IsGMBGuideEnabled key.
     */
    get settingIsGMBGuideEnabled() {
      return 'IsGMBGuideEnabled';
    },

    /**
     * Represents the IsChatEnabled key.
     */
    get settingIsChatEnabled() {
      return 'IsChatEnabled';
    },
  },

  /**
   * Represents strongly type breakpoint types.
   */
  Breakpoints: {
    /**
     * Represents a mobile breakpoint.
     */
    get mobile() {
      return 'mobile';
    },

    /**
     * Represents a tablet breakpoint.
     */
    get tablet() {
      return 'tablet';
    },

    /**
     * Represents a desktop breakpoint;
     */
    get desktop() {
      return 'desktop';
    },
  },

  /**
   * Represents the current onboarding info key.
   */
  get currOnboardingInfoKey() {
    return 'coi';
  },

  /**
   * Represents the current collection of resources.
   */
  get currResources() {
    return 'res';
  },

  /**
   * Represents the current collection of resources.
   */
  get currCountry() {
    return 'country';
  },

  /**
   * Represents the current translation url key.
   */
  get currTranslationUrlKey() {
    return 'ctu';
  },

  /**
   * Represents the current user key.
   */
  get currUserKey() {
    return 'cusr';
  },

  /**
   * Represents the session key
   */
  get sessIdKey() {
    return 'sessId';
  },

  /**
   * Represents the current location id key
   */
  get currLocIdKey() {
    return 'clid';
  },

  /**
   * Represents the current selected queued appointment from the mobile queue.
   */
  get currSelectedQueueAppt() {
    return 'csqa';
  },

  /**
   * Represents the siteConfig
   */
  get siteConfig() {
    return 'siteConfig';
  },

  /**
   * Represents the language tag from the location
   */
  get langTag() {
    return 'langTag';
  },

  /**
   * Represents the support info key.
   */
  get supportInfoKey() {
    return 'si';
  },

  get signalRLocationConfigMessage() {
    return 'LocationUpdatedEvent';
  },

  /**
   * Represents the resource filter change event
   */
  get resourceFilterUpdatedEmitter() {
    return 'resourceFilterUpdated';
  },

  /**
   * Represents the default display name for the default resource
   */
  get defaultResourceDisplayName() {
    return 'Appointments';
  },

  /**
   * Represents the Spanish language tag.
   */
  get spanishLanguageTag() {
    return 'es';
  },

  /**
   * Represents the Portuguese language tag.
   */
  get portugueseLanguageTag() {
    return 'pt';
  },

  /**
   * Gets the turkey language tag.
   */
  get turkeyLanguageTag() {
    return 'tr-TR';
  },

  /**
   * Represents strongly typed date formats.
   */
  DateFormats: {
    /**
     * Represents the notification page date format.
     */
    get notificationAppointmentDateFormat() {
      return 'ddd MMM D - LT';
    },

    /**
     * Represents the search customer / appointment date page date format.
     */
    get searchCustomerAppointmentDateFormat() {
      return 'YYYY-MMM-D';
    },
  },

  /**
   * Represents strongly typed event names.
   */
  Events: {
    /**
     * Represents an updated appointment event.
     */
    get appointmentUpdated() {
      return 'appointment:updated';
    },

    /**
     * Represents a deleted appointment event.
     */
    get deleteAppointment() {
      return 'appointment:delete';
    },

    /**
     * Represents a new appointment event.
     */
    get newAppointment() {
      return 'appointment:new';
    },

    /**
     * Represents a new notification event.
     */
    get newNotification() {
      return 'notification:new';
    },

    /**
     * Represents the page ready event.
     */
    get pageReady() {
      return 'page:ready';
    },

    /**
     * Represents the resource change event.
     */
    get resourceChange() {
      return 'resource:change';
    },

    /**
     * Represents the resources change event.
     */
    get resourcesUpdated() {
      return 'resources:updated';
    },

    /**
     * Represents a scheduled appointment event.
     */
    get scheduleAppointment() {
      return 'appointment:schedule';
    },

    /**
     * Represents the logout event.
     */
    get onLogout() {
      return 'user:logout';
    },

    /**
     * Represents the location changed event.
     */
    get locationChanged() {
      return 'locationconfig:changed';
    },

    /**
     * Represents the location reset event.
     */
    get locationReset() {
      return 'locationconfig:reset';
    },

    /**
     * represents the appointment confirmed event
     */
    get appointmentConfirmed() {
      return 'appointment:confirmed';
    },

    /**
     * Represents the location configuration update event.
     */
    get locationConfigUpdate() {
      return 'locationconfig:update';
    },

    /**
     * Represents the toggle add appointment event.
     */
    get onToggleAddAppointment() {
      return 'appointment:add';
    },

    /**
     * Represents the toggle admin settings event.
     */
    get onToggleAdminSettings() {
      return 'admin:settings';
    },

    /**
     * Represents the toggle calendar pane event.
     */
    get onToggleCalendarPane() {
      return 'calendar:pane';
    },

    /**
     * Represents system message reset event
     */
    get onResetSystemMessageCount() {
      return 'systemmessage:reset';
    },

    /**
     * Represents the toggle edit resource system message event.
     */
    get onToggleEditResourceSystemMessage() {
      return 'resources:edit:systemmessage';
    },

    /**
     * Represents the toggle edit service types system message.
     */
    get onToggleEditServiceTypesSystemMessage() {
      return 'servicetypes:edit:systemmessage';
    },

    /**
     * Represents the toggle edit store hours system message.
     */
    get onToggleEditStoreHoursSystemMessage() {
      return 'storehourse:edit:systemmessage';
    },

    /**
     * Represents the read all notifications event.
     */
    get readAllNotifications() {
      return 'notification:readall';
    },

    /**
     * Represents the unread notifications event.
     */
    get unreadNotifications() {
      return 'notification:unread';
    },

    /**
     * Represents the conversation update event.
     */
    get chatConversationUpdated() {
      return 'chat:conversationUpdated';
    },

    /**
     * Represents the conversation update event.
     */
    get chatNotificationUpdated() {
      return 'chat:notification';
    },

    /**
     * Represents a temp password login event
     */
    get tempLogin() {
      return 'login:tempPassword';
    },

    get vacationDrawerOpen() {
      return 'settings:resources:vacationDrawer';
    },

    get vacationDrawerClose() {
      return 'settings:resources:vacationDrawer:close';
    },

    get vacationDrawerAddEnabled() {
      return 'settings:resources:vacationDrawer:addEnabled';
    },

    get vacationDrawerAddDisabled() {
      return 'settings:resources:vacationDrawer:addDisabled';
    },

    get vacationItemRemoved() {
      return 'settings:resources:vacation:removed';
    },

    get vacationItemAdded() {
      return 'settings:resources:vacation:added';
    },

    get noShowDashboardClicked() {
      return 'settings:dashboard:appointments:noshow';
    },

    get cancelledDashboardClicked() {
      return 'settings:dashboard:appointments:cancelled';
    },

    get totalsDashboardClicked() {
      return 'settings:dashboard:appointments:totals';
    },

    get typesDashboardClicked() {
      return 'settings:dashboard:appointments:types';
    },

    get dashboardDateChange() {
      return 'settings:dashboard:dateChange';
    },

    get onToggleAddStellest() {
      return 'calendar:stellest:open';
    },

    get clickConversation() {
      return 'chat:click:conversation';
    },
    get ConversationNewTemplateLoaded() {
      return 'twoway:templatecomponentloaded';
    },
  },

  /**
   * Represents strongly typed signalR messages.
   */
  SignalRMessages: {
    /**
     * Represents an updated appointment event.
     */
    get appointmentUpdated() {
      return 'AppointmentUpdate';
    },

    /**
     * Represents a location updated event.
     */
    get locationUpdatedEvent() {
      return 'LocationUpdatedEvent';
    },

    /**
     * Represents a new notification message.
     */
    get newNotification() {
      return 'NewNotification';
    },

    /**
     * Represents a chat conversation was updated..
     */
    get conversationUpdated() {
      return 'ConversationUpdated';
    },

    /**
     * Represents a chat notification was updated..
     */
    get chatNotificationUpdated() {
      return 'ChatNotificationUpdated';
    },
  },

  /**
   * Represents strongly typed routes.
   */
  Routes: {
    /**
     * Represents the onboarding route.
     */
    get onboarding() {
      return '/onboarding';
    },

    /**
     * Represents the English onboarding route.
     */
    get onboardingEnglish() {
      return '/welcome';
    },

    /**
     * Represents the Spanish onboarding route.
     */
    get onboardingSpanish() {
      return '/bienvenidos';
    },

    /**
     * Represents the Portuguese onboarding route.
     */
    get onboardingPortuguese() {
      return '/bem-vinda';
    },

    /**
     * Represents the Turkish onboarding route.
     */
    get onboardingTurkish() {
      return '/Hoşgeldiniz';
    },

    /**
     * Represents the notifications route.
     */
    get notifications() {
      return '/notifications';
    },

    /**
     * Represents the schedule route.
     */
    get schedule() {
      return '/schedule';
    },

    /**
     * Represents the settings route.
     */
    get settings() {
      return '/settings';
    },

    /**
     * Represents the dashboard route.
     */
    get dashboard() {
      return '/dashboard';
    },

    get chat() {
      return '/chat';
    },
  },
  AgeRange: {
    /**
     * Represents the age range that is less than 40 key
     */
    get lessThan40() {
      return 'lessthan40';
    },

    /**
     * Represents the age range that is over 40 key
     */
    get over40() {
      return 'over40';
    },
  },
  FrequencyOfUse: {
    /**
     * Represents the Frequency Of Use - All the time key
     */
    get allTheTime() {
      return 'allthetime';
    },
    /**
     * Represents the Frequency Of Use - Sometimes key
     */
    get sometimes() {
      return 'sometimes';
    },
    /**
     * Represents the Frequency Of Use - Only for far distance key
     */
    get farDistance() {
      return 'onlyforfardistance';
    },
    /**
     * Represents the Frequency Of Use - Only for reading key
     */
    get reading() {
      return 'onlyforereading';
    },
    /**
     * Represents the Frequency Of Use - Only for computer key
     */
    get computer() {
      return 'onlyforthecomputer';
    },
  },

  TwoWayMessages: {
    /**
     * Card count max
     */
    get twoWayCardCountMax() {
      return 10;
    },
    /**
     * message count maximum
     */
    get messageCountMax() {
      return 320;
    },
    /**
     * Number of card blurbs to show max
     */
    get cardBlurbCountMax() {
      return 30;
    },
    get optOutReason() {
      return 'Patient has opted out of messaging';
    },
    get viewStatus() {
      return {
        all: -1,
        none: 0,
        unread: 1,
        unanswered: 2,
        flagged: 4,
        trash: 8,
      };
    },
    get errorCodeNotFound() {
      return '63003';
    },
    get messageStatusFailed() {
      return 'failed';
    },
    get api() {
      return {
        base: process.env.REACT_APP_CHAT_API,
        getChatLocationMetadata: '/getChatLocationMetadata',
        updateLocationChatNotificiation: '/updateLocationChatNotificiation',
        messageList: '/List',
        messages: '/Messages',
        messageSend: '/Messages/Send',
        updateMessageStatus: '/UpdateStatus',
        findPatient: '/FindPatient',
        conversationStatusCounts: '/getConversationStatusCounts',
        newConversation: '/NewConversation',
        sendTemplatedMessage: '/Messages/sendTemplatedMessage',
        content: {
          templateList: '/getChatTemplateDefinitions',
          templateForPatient: '/getChatTemplateContent',
        },
        uploadAttachment: '/uploadAttachment',
        autoquote: '/autoquote',
      };
    },
    get fourPC() {
      return '4PC';
    },
    get messageTemplates() {
      return {
        freeFormSms: -1,
        customerQuote: 1083,
        recallReminder: 1059,
        walkInPostVisitFeedback: 1057,
        preAppointmentQuestionnaire: 1058,
        productAvailableForPickup: 1056,
        bookingLink: 1060,
      };
    },
  },

  MessagingPlatforms: {
    get SMS() {
      return 1;
    },
    get WhatsApp() {
      return 2;
    },
  },

  environments: {
    get development() {
      return 'development';
    },
    get qa() {
      return 'qa';
    },
    get staging() {
      return 'staging';
    },
    get production() {
      return 'production';
    },
  },
};

// Lock object to prevent modification (true static).
Object.freeze(Constants);

export default Constants;
