import React, { Component } from 'react';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Enums from '../../enums';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import Illustration from '../../Assets/Images/Reset-Password-Screen-Art.jpg';
import './PasswordReset.scss';

const pBuilder = new PageviewBuilder();
const eBuilder = new EventBuilder();

/**
 * Represents the password reset component
 */
class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this._formErrorSetClean = {
      isValid: true,
      newPassword: '',
      retypedNewPassword: '',
      serverError: '',
      unauthorized: '',
    };
    this.state = {
      canSubmit: true,
      formErrors: cloneDeep(this._formErrorSetClean),
      newPassword: '',
      newPasswordLengthMet: false,
      newPasswordContainOneLowercaseChar: false,
      newPasswordContainOneUppercaseChar: false,
      newPasswordContainOneSymbolChar: false,
      newPasswordContainOneDigitChar: false,
      retypedNewPassword: '',
      showNewPasswordUnmasked: false,
      showRetypedPasswordUnmasked: false,
      submitSuccess: false,
      username: props.username,
    };
    this._newPasswordMinLength = 10;
    this._newPasswordOneSymbolRegex = /[~`!#$%^&@*+=\-[\]\\';,/{}|\\":<>?]/;
    this._newPasswordOneDigitRegex = /[0-9]/;

    pBuilder.pageview(pBuilder.Page.Registration.resetPassword);
    eBuilder.withCategory(eBuilder.Category.Onboarding.registration);
  }

  _isFormValid = () => {
    let isValid = true;
    const { newPassword, retypedNewPassword } = this.state;
    const formErrors = cloneDeep(this._formErrorSetClean);

    if (!newPassword) {
      isValid = false;
      formErrors.newPassword = 'New Password is required';
    }

    if (!retypedNewPassword) {
      isValid = false;
      formErrors.retypedNewPassword = 'Re-enter New Password is required';
    } else if (retypedNewPassword !== newPassword) {
      isValid = false;
      formErrors.retypedNewPassword = 'Please make sure your passwords match';
    }

    this.setState(() => ({ formErrors: formErrors }));

    return isValid;
  };

  _onKeyPress = (e) => {
    const { key } = e;

    if (key === Enums.KeyboardKeys.enterKey) {
      this._onSubmit();
    }
  };

  _onNewPasswordChange = (value) => {
    this.setState(() => {
      const updatedNewPassword = value;
      let newPasswordLengthMet = false;

      if (updatedNewPassword.length >= this._newPasswordMinLength) {
        newPasswordLengthMet = true;
      }

      let newPasswordContainOneLowercaseChar = false;

      if (updatedNewPassword.toUpperCase() !== updatedNewPassword) {
        newPasswordContainOneLowercaseChar = true;
      }

      let newPasswordContainOneUppercaseChar = false;

      if (updatedNewPassword.toLowerCase() !== updatedNewPassword) {
        newPasswordContainOneUppercaseChar = true;
      }

      let newPasswordContainOneSymbolChar = false;

      if (this._newPasswordOneSymbolRegex.test(updatedNewPassword)) {
        newPasswordContainOneSymbolChar = true;
      }

      let newPasswordContainOneDigitChar = false;

      if (this._newPasswordOneDigitRegex.test(updatedNewPassword)) {
        newPasswordContainOneDigitChar = true;
      }

      return {
        newPassword: updatedNewPassword,
        newPasswordLengthMet: newPasswordLengthMet,
        newPasswordContainOneLowercaseChar: newPasswordContainOneLowercaseChar,
        newPasswordContainOneUppercaseChar: newPasswordContainOneUppercaseChar,
        newPasswordContainOneSymbolChar: newPasswordContainOneSymbolChar,
        newPasswordContainOneDigitChar: newPasswordContainOneDigitChar,
      };
    });
  };

  _onRetypedNewPasswordChange(value) {
    this.setState(() => ({ retypedNewPassword: value }));
  }

  _onSubmit = async () => {
    if (this._isFormValid()) {
      try {
        this.setState(() => ({ canSubmit: false }));

        const { username, newPassword } = this.state;
        const url = `${process.env.REACT_APP_ADMIN_API}/updatePassword`;
        const data = {
          username: username,
          password: newPassword,
        };
        await axios.post(url, data).then(() => {
          localStorage.removeItem(Constants.currUserKey);
        });

        this.setState(() => ({
          newPassword: '',
          retypedNewPassword: '',
          submitSuccess: true,
        }));

        eBuilder
          .withAction(eBuilder.Action.Onboarding.Click.resetPassword)
          .withLabel(eBuilder.Label.practiceIdentifier)
          .post();

        // Redirect the user after 3 seconds to the login page.
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          window.location = '/';
        }, 3000);
      } catch (error) {
        if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusInternalServerError)
        ) {
          this.setState(() => ({
            formErrors: { serverError: 'An unexpected error has occurred' },
            isVisible: true,
            redirectToNormalLogin: true,
          }));
        }
      }
    }
  };

  _onToggleNewPasswordMask = () => {
    this.setState((prevState) => ({
      showNewPasswordUnmasked: !prevState.showNewPasswordUnmasked,
    }));
  };

  _onToggleRetypedPasswordMask = () => {
    this.setState((prevState) => ({
      showRetypedPasswordUnmasked: !prevState.showRetypedPasswordUnmasked,
    }));
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      canSubmit,
      formErrors,
      newPassword,
      newPasswordLengthMet,
      newPasswordContainOneLowercaseChar,
      newPasswordContainOneUppercaseChar,
      newPasswordContainOneSymbolChar,
      newPasswordContainOneDigitChar,
      retypedNewPassword,
      showNewPasswordUnmasked,
      showRetypedPasswordUnmasked,
      submitSuccess,
      username,
    } = this.state;

    const { t } = this.props;

    return (
      <div className="pw-reset">
        <h1 className="pw-reset__title">{t(`We're almost there...`)}</h1>
        <p className="pw-reset__statement">
          {t('Please choose a password to begin onboarding')}
        </p>
        <p className="pw-reset__username">
          {t('Email')}: {username}
        </p>
        <form className="pw-reset__form">
          <div className="pw-reset__group">
            <TextField
              className="pw-reset__input"
              inputProps={{
                style: {
                  padding: '12px 14px',
                },
              }}
              label={t('New Password')}
              value={newPassword}
              variant="outlined"
              type={`${!showNewPasswordUnmasked ? 'password' : 'text'}`}
              onChange={(e) => this._onNewPasswordChange(e.target.value)}
              onKeyPress={this._onKeyPress}
            />
            <button
              className="pw-reset__show-pw"
              type="button"
              onClick={this._onToggleNewPasswordMask}
            >
              <VisibilityIcon
                className={`pw-reset__icon ${
                  showNewPasswordUnmasked ? 'pw-reset__icon--hide' : ''
                }`}
              />
              <VisibilityOffIcon
                className={`pw-reset__icon ${
                  !showNewPasswordUnmasked ? 'pw-reset__icon--hide' : ''
                }`}
              />
            </button>
            <span
              className={`pw-reset__error ${
                formErrors.newPassword ? 'pw-reset__error--visible' : ''
              }`}
            >
              {t(formErrors.newPassword)}
            </span>
          </div>
          <div className="pw-reset__group">
            <TextField
              className="pw-reset__input"
              inputProps={{
                style: {
                  padding: '12px 14px',
                },
              }}
              label={t('Re-enter New Password')}
              value={retypedNewPassword}
              variant="outlined"
              type={`${!showRetypedPasswordUnmasked ? 'password' : 'text'}`}
              onChange={(e) => this._onRetypedNewPasswordChange(e.target.value)}
              onKeyPress={this._onKeyPress}
            />
            <button
              className="pw-reset__show-pw"
              type="button"
              onClick={this._onToggleRetypedPasswordMask}
            >
              <VisibilityIcon
                className={`pw-reset__icon ${
                  showRetypedPasswordUnmasked ? 'pw-reset__icon--hide' : ''
                }`}
              />
              <VisibilityOffIcon
                className={`pw-reset__icon ${
                  !showRetypedPasswordUnmasked ? 'pw-reset__icon--hide' : ''
                }`}
              />
            </button>
            <span
              className={`pw-reset__error ${
                formErrors.retypedNewPassword ? 'pw-reset__error--visible' : ''
              }`}
            >
              {t(formErrors.retypedNewPassword)}
            </span>
            <span
              className={`pw-reset__error ${
                formErrors.serverError ? 'pw-reset__error--visible' : ''
              }`}
            >
              {t(formErrors.serverError)}
            </span>
          </div>
          <ul className="pw-reset__rules">
            <li>
              {t('Password Requirements')}
              <br />
              <br />
            </li>
            <li
              className={`pw-reset__rule ${
                newPasswordLengthMet ? 'pw-reset__rule--valid' : ''
              }`}
            >
              <CloseIcon
                className={`pw-reset__rule-icon ${
                  newPasswordLengthMet ? 'pw-reset__rule-icon--hide' : ''
                }`}
              />
              <CheckIcon
                className={`pw-reset__rule-icon ${
                  !newPasswordLengthMet ? 'pw-reset__rule-icon--hide' : ''
                }`}
              />
              {t('MUST contain at least 10 characters')}
            </li>
            <li
              className={`pw-reset__rule ${
                newPasswordContainOneLowercaseChar
                  ? 'pw-reset__rule--valid'
                  : ''
              }`}
            >
              <CloseIcon
                className={`pw-reset__rule-icon ${
                  newPasswordContainOneLowercaseChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              <CheckIcon
                className={`pw-reset__rule-icon ${
                  !newPasswordContainOneLowercaseChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              {t('MUST contain at least 1 lowercase letter')}
            </li>
            <li
              className={`pw-reset__rule ${
                newPasswordContainOneUppercaseChar
                  ? 'pw-reset__rule--valid'
                  : ''
              }`}
            >
              <CloseIcon
                className={`pw-reset__rule-icon ${
                  newPasswordContainOneUppercaseChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              <CheckIcon
                className={`pw-reset__rule-icon ${
                  !newPasswordContainOneUppercaseChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              {t('MUST contain 1 uppercase letter')}
            </li>
            <li
              className={`pw-reset__rule ${
                newPasswordContainOneSymbolChar ? 'pw-reset__rule--valid' : ''
              }`}
            >
              <CloseIcon
                className={`pw-reset__rule-icon ${
                  newPasswordContainOneSymbolChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              <CheckIcon
                className={`pw-reset__rule-icon ${
                  !newPasswordContainOneSymbolChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              {t('MUST contain 1 symbol')}
            </li>
            <li
              className={`pw-reset__rule ${
                newPasswordContainOneDigitChar ? 'pw-reset__rule--valid' : ''
              }`}
            >
              <CloseIcon
                className={`pw-reset__rule-icon ${
                  newPasswordContainOneDigitChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              <CheckIcon
                className={`pw-reset__rule-icon ${
                  !newPasswordContainOneDigitChar
                    ? 'pw-reset__rule-icon--hide'
                    : ''
                }`}
              />
              {t('MUST contain 1 digit')}
            </li>
          </ul>
          <p
            className={`pw-reset__success ${
              submitSuccess ? 'pw-reset__success--visible' : ''
            }`}
          >
            {t(
              'Your password has been changed successfully. Please login using your new password. We are redirecting you to the login screen now...'
            )}
          </p>
          <button
            className="pw-reset__submit"
            disabled={!canSubmit}
            type="button"
            onClick={this._onSubmit}
          >
            {t('Submit')}
          </button>
        </form>
        <div className="pw-reset__illustration-cont">
          <img
            alt="Illustration"
            className="pw-reset__illustration"
            src={Illustration}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(PasswordReset);
