import React from 'react';
import './AddDrawerTitleComponent.scss';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const AddDrawerTitleComponent = (props) => {
  const { handleClose, titleText, t } = props;
  return (
    <div className="add-drawer-title-component">
      <section>
        <span className="title">{titleText}</span>
        <span className="required">
          <span className="MuiFormLabel-root MuiFormLabel-asterisk MuiInputLabel-asterisk astrisk">
            *
          </span>
          {t('Required')}
        </span>
        <IconButton className="close-button" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </section>
    </div>
  );
};

export default AddDrawerTitleComponent;
