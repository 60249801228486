import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Enums from '../../enums';
import Constants from '../../constants';
import SettingsService from '../../Services/settingsService';
import './LocationSettings.scss';

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Checkbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#979797',
    },
  },
  checked: {},
})(MuiCheckbox);

/**
 * Represents settings related to locations.
 */
class LocationSettings extends Component {
  /**
   * Initializes a new instance  of the LocationSettings component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      errorModalMessage: '',
      locationSettings: cloneDeep(props.locationSettings),
      showErrorModal: false,
      showSaveAlert: false,
    };
    this._settingsService = new SettingsService();
  }

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  _onCloseSaveAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showSaveAlert: false }));
  };

  _onLocationSettingsChange = (name, value) => {
    this.setState((prevState) => {
      const updatedLocationSettings = prevState.locationSettings.map(
        (locSetting) => {
          if (locSetting.displayName === name) {
            locSetting.settingValue = value;
          }

          return locSetting;
        }
      );

      return {
        locationSettings: updatedLocationSettings,
      };
    });
  };

  _onSaveLocationSettings = async () => {
    try {
      const { locationSettings } = this.state;

      await this._settingsService.saveLocationSettings({
        locationSettings: locationSettings,
      });

      const { onLocationSettingsChange } = this.props;
      onLocationSettingsChange && onLocationSettingsChange(locationSettings);

      this.setState(() => ({ showSaveAlert: true }));
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        this.setState(() => ({
          errorModalMessage: this.props.t(
            'There was an unexpected issue with saving location settings.'
          ),
          showErrorModal: true,
        }));
      }
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      errorModalMessage,
      locationSettings,
      showErrorModal,
      showSaveAlert,
    } = this.state;
    const { countrySettings, hasSmsPhone, t } = this.props;
    const emailCustomerOriginSetting = locationSettings.find(
      (e) =>
        e.displayName === Constants.LocationSettings.settingEmailCustomerOrigin
    );
    const emailStoreOriginSetting = locationSettings.find(
      (e) =>
        e.displayName === Constants.LocationSettings.settingEmailStoreOrigin
    );
    const isSmsNotificationsEnabled =
      countrySettings.find(
        (cs) =>
          cs.settingName ===
          Constants.CountrySettings.settingIsSmsNotificationsEnabled
      )?.settingValue === true;
    const smsCustomerOriginSetting = locationSettings.find(
      (e) =>
        e.displayName === Constants.LocationSettings.settingSmsCustomerOrigin
    );
    const smsStoreOriginSetting = locationSettings.find(
      (e) => e.displayName === Constants.LocationSettings.settingSmsStoreOrigin
    );

    return (
      <div className="loc-settings">
        <h2 className="loc-settings__heading">{t('Notifications')}</h2>
        <section className="loc-settings__section">
          <h3 className="loc-settings__subheading">
            {t('Email Notifications')}
          </h3>
          <FormControlLabel
            className="loc-settings__checkbox"
            control={
              <Checkbox
                checked={emailCustomerOriginSetting?.settingValue === true}
                onChange={(e) =>
                  this._onLocationSettingsChange(
                    Constants.LocationSettings.settingEmailCustomerOrigin,
                    e.target.checked
                  )
                }
              />
            }
            label={t('Customer originated bookings')}
          />
          <FormControlLabel
            className="loc-settings__checkbox"
            control={
              <Checkbox
                checked={emailStoreOriginSetting?.settingValue === true}
                onChange={(e) =>
                  this._onLocationSettingsChange(
                    Constants.LocationSettings.settingEmailStoreOrigin,
                    e.target.checked
                  )
                }
              />
            }
            label={t('Store originated bookings')}
          />
        </section>
        {isSmsNotificationsEnabled && (
          <section className="loc-settings__section loc-settings__section--sms">
            <h3 className="loc-settings__subheading">
              {t('SMS Notifications')}
            </h3>
            <FormControlLabel
              className="loc-settings__checkbox"
              control={
                <Checkbox
                  checked={smsCustomerOriginSetting?.settingValue === true}
                  onChange={(e) =>
                    this._onLocationSettingsChange(
                      Constants.LocationSettings.settingSmsCustomerOrigin,
                      e.target.checked
                    )
                  }
                />
              }
              disabled={!hasSmsPhone}
              label={t('Customer originated bookings')}
            />
            <FormControlLabel
              className="loc-settings__checkbox"
              control={
                <Checkbox
                  checked={smsStoreOriginSetting?.settingValue === true}
                  onChange={(e) =>
                    this._onLocationSettingsChange(
                      Constants.LocationSettings.settingSmsStoreOrigin,
                      e.target.checked
                    )
                  }
                />
              }
              disabled={!hasSmsPhone}
              label={t('Store originated bookings')}
            />
          </section>
        )}
        <div className="loc-settings__footer">
          <button
            className="loc-settings__save"
            type="button"
            onClick={this._onSaveLocationSettings}
          >
            {t('Save')}
          </button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showSaveAlert}
          onClose={this._onCloseSaveAlert}
        >
          <Alert
            className="admin-settings__alert"
            severity="success"
            onClose={this._onCloseSaveAlert}
          >
            {t('Saved successfully')}
          </Alert>
        </Snackbar>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="loc-settings__modal-button"
              onClick={this._onCloseErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

LocationSettings.propTypes = {
  countrySettings: PropTypes.array.isRequired,
  hasSmsPhone: PropTypes.bool.isRequired,
  locationSettings: PropTypes.array.isRequired,
  onLocationSettingsChange: PropTypes.func,
};

export default withTranslation()(LocationSettings);
