import React from 'react';
import PropTypes from 'prop-types';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import MuiInputBase from '@material-ui/core/InputBase';
import './VacationItem.scss';

const InputBase = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
  },
  input: {
    backgroundColor: 'white',
    padding: '10.5px 14px;',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
})(MuiInputBase);

const VacationItem = (props) => {
  const _onChangeName = (name) => {
    props.onChangeName(props.vacation, name);
  };

  const _onStartDayChange = (date) => {
    props.onStartDayChange(props.vacation, date, true, true);
  };

  const _onStartTimeChange = (date) => {
    props.onStartTimeChange(props.vacation, date, true, false);
  };

  const _onEndDayChange = (date) => {
    props.onEndDayChange(props.vacation, date, false, true);
  };

  const _onEndTimeChange = (date) => {
    props.onEndTimeChange(props.vacation, date, false, false);
  };

  const _cancelEdit = () => {
    if (props.vacation && props.vacation.vacationId === 0) {
      //remove from item from list
      _onRemoveVacation();
    } else {
      //set temp values back to stored values
      props.onCancelEdit(props.vacation);
    }
  };

  const _saveVacation = async () => {
    //update main list
    await props.handleVacationItemSave(props.vacation);
  };

  const _onClickEdit = () => {
    props.onEditItemClick(props.vacation);
  };

  const _onRemoveVacation = () => {
    props.handleVacationItemRemoval(props.vacation);
  };

  const { t, vacation, showSummary } = props;

  //const isLatamMarket = Utils.isLatamMarket(languageTag);
  const start = momentLocaleWrapper(vacation.startDate);
  const end = momentLocaleWrapper(vacation.endDate);
  const valid = end > start;
  return (
    <div className="vac-item__main">
      {showSummary && (
        <div className="vac-item__summary">
          <div className="vac-item__title">
            {t('Vacation Details')}
            <div>
              {
                <button
                  className="vac-item__action"
                  onClick={() => _onClickEdit()}
                >
                  <CreateIcon />
                </button>
              }
              {
                <button
                  className="vac-item__action"
                  onClick={() => _onRemoveVacation()}
                >
                  <CancelIcon />
                </button>
              }
            </div>
          </div>
          <div className="vac-item__summary-data">
            {vacation.vacationDisplayName}
          </div>
          <div className="vac-item__summary-data">{start.format('llll')}</div>
          <div className="vac-item__summary-data">{end.format('llll')}</div>
        </div>
      )}
      {!showSummary && (
        <div className="vac-item__editable">
          <InputLabel className="vac-item-label">
            {t('Vacation Name')}
          </InputLabel>
          <input
            className="vac-item__form-input"
            type="text"
            defaultValue={vacation.vacationDisplayName}
            onChange={(e) => _onChangeName(e.target.value)}
          />
          <div className="vac-item__group--datetime">
            <MuiPickersUtilsProvider
              libInstance={momentLocaleWrapper}
              utils={MomentUtils}
            >
              <DatePicker
                cancelLabel={t('Cancel')}
                disableToolbar
                required
                className="vac-item__input"
                format="ddd, L"
                label={t('From Date')}
                margin="normal"
                okLabel={t('OK')}
                value={vacation.startDate}
                onChange={_onStartDayChange}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider
              libInstance={momentLocaleWrapper}
              utils={MomentUtils}
            >
              <TimePicker
                cancelLabel={t('Cancel')}
                className="vac-item__input"
                format={'LT'}
                required
                label={t('From Time')}
                margin="normal"
                okLabel={t('OK')}
                value={vacation.startDate}
                onChange={_onStartTimeChange}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="vac-item__group--datetime">
            <MuiPickersUtilsProvider
              libInstance={momentLocaleWrapper}
              utils={MomentUtils}
            >
              <DatePicker
                cancelLabel={t('Cancel')}
                disableToolbar
                required
                className="vac-item__input"
                format="ddd, L"
                label={t('To Date')}
                margin="normal"
                okLabel={t('OK')}
                value={vacation.endDate}
                onChange={_onEndDayChange}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider
              libInstance={momentLocaleWrapper}
              utils={MomentUtils}
            >
              <TimePicker
                cancelLabel={t('Cancel')}
                className="vac-item__input"
                format={'LT'}
                required
                label={t('To Time')}
                margin="normal"
                okLabel={t('OK')}
                value={vacation.endDate}
                onChange={_onEndTimeChange}
              />
            </MuiPickersUtilsProvider>
          </div>
          {!valid && (
            <span className="vac-item__error vac-item__error--visible">
              {t('Start date must be before end date')}
            </span>
          )}
          <div className="vac-item__buttons">
            <div>
              <button className="vac-item__button" onClick={_cancelEdit}>
                {t('Cancel')}
              </button>
              <button
                className="vac-item__submit"
                disabled={!valid}
                onClick={_saveVacation}
              >
                {t('Submit')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

VacationItem.propTypes = {
  showSummary: PropTypes.bool.isRequired,
  vacation: PropTypes.any,
  handleVacationItemRemoval: PropTypes.func.isRequired,
  handleVacationItemSave: PropTypes.func.isRequired,
  onCancelEdit: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onStartDayChange: PropTypes.func.isRequired,
  onStartTimeChange: PropTypes.func.isRequired,
  onEndDayChange: PropTypes.func.isRequired,
  onEndTimeChange: PropTypes.func.isRequired,
  onEditItemClick: PropTypes.func.isRequired,
};

export default withTranslation()(VacationItem);
