// Dependencies
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Components
import DataProvider from '../Contexts/DataProvider';
import Constants from '../constants';
import Login from '../Pages/Login/Login.js';
import Settings from '../Pages/Settings/Settings';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Calendar from '../Pages/NewScheduler/Calendar';
import Onboarding from '../Pages/Onboarding/Onboarding';
import TermsConditions from '../Pages/TermsConditions/TermsConditions';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import Notifications from '../Pages/Notifications/Notifications';
import TwoWayMessagePage from '../Pages/TwoWayMessagePage/TwoWayMessagePage';

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />

        <Route
          exact
          path={Constants.Routes.onboardingEnglish}
          component={Login}
        />

        <Route
          exact
          path={Constants.Routes.onboardingSpanish}
          component={Login}
        />

        <Route
          exact
          path={Constants.Routes.onboardingPortuguese}
          component={Login}
        />

        <Route
          exact
          path={Constants.Routes.onboardingTurkish}
          component={Login}
        />

        <Route exact path="/forgot-pw" component={ForgotPassword} />

        <Route path="/termsuse" component={TermsConditions} />

        <Route path={Constants.Routes.schedule} component={Calendar} />

        <Route path={Constants.Routes.settings} component={Settings} />

        <Route path={Constants.Routes.dashboard} component={Dashboard} />

        <Route path={Constants.Routes.onboarding} component={Onboarding} />

        <Route
          path={Constants.Routes.notifications}
          component={Notifications}
        />

        <Route path={Constants.Routes.chat} component={TwoWayMessagePage} />
      </Switch>
    </Router>
  );
}
