import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './PatientDetailsView.scss';
import { StellestEthnicityOptions, StellestGenderOptions } from '../../enums';

class PatientDetailsView extends Component {
  onFieldChange = (field, value) => {
    this.props.onFieldChange(field, value);
  };

  handleDobChange = (date) => {
    this.props.handleDobChange(date);
  };

  renderGender = (genderEnum) => {
    switch (genderEnum) {
      case StellestGenderOptions.PreferNotToSpecify:
        return 'Prefer not to answer';
      case StellestGenderOptions.Male:
        return 'Male';
      case StellestGenderOptions.Female:
        return 'Female';
      default:
        return 'N/A';
    }
  };

  renderEthnicity = (ethnicityEnum) => {
    switch (ethnicityEnum) {
      case StellestEthnicityOptions.Other:
        return 'Other';
      case StellestEthnicityOptions.Chinese:
        return 'Chinese';
      case StellestEthnicityOptions.Malay:
        return 'Malay';
      case StellestEthnicityOptions.Indian:
        return 'Indian';
      default:
        return 'N/A';
    }
  };

  render() {
    const { parentState, t } = this.props;

    const {
      dateFormat,
      dob,
      email,
      ethnicity,
      firstName,
      gender,
      guardian,
      lastName,
      phone,
      phoneCountryCode,
      requireGuardian,
      locationConfig,
    } = parentState;

    return (
      <div className="stellest-appointment-form-section patient-details-view">
        <div className="practice-details">
          <h2>{t('Practice Details')}</h2>
          <p>{locationConfig ? locationConfig.storeInformation.name : ''}</p>
          <p>{locationConfig ? locationConfig.stellestCustomerCode : ''}</p>
        </div>
        <h2>{t('Customer Details')} </h2>
        <div className="fields">
          <div className="field print">
            <div className="field-label">{t('Customer First Name')}</div>
            <div className="field-value">{firstName}</div>
          </div>

          <div className="field print">
            <div className="field-label">{t('Customer Last Name')}</div>
            <div className="field-value">{lastName}</div>
          </div>

          <div className="field">
            <div className="field-label">{t('Customer Date of Birth')}</div>
            <div className="field-value">{dob.format(dateFormat)}</div>
          </div>

          {requireGuardian && (
            <div className="field">
              <div className="field-label">{t('Parent or Guardian')}</div>
              <div className="field-value">{guardian.trim() || t('N/A')}</div>
            </div>
          )}

          <div className="field">
            <div className="field-label">{t('Parent or Guardian Email')}</div>
            <div className="field-value">{email}</div>
          </div>

          <div className="field print">
            <div className="field-label">{t('Parent or Guardian Phone')}</div>
            <div className="field-value">
              {phoneCountryCode} {phone}
            </div>
          </div>

          <div className="field">
            <div className="field-label">{t('Gender')}</div>
            <div className="field-value">{t(this.renderGender(gender))}</div>
          </div>

          <div className="field">
            <div className="field-label">{t('Ethnicity')}</div>
            <div className="field-value">
              {t(this.renderEthnicity(ethnicity))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PatientDetailsView);
