import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Enums from '../../enums';
import Utils from '../../utils';
import Events from '../../events';
import Storage from '../../storage';
import Constants from '../../constants';
import { withTranslation } from 'react-i18next';
import PageviewBuilder from '../../pageviewBuilder';
import EventBuilder from '../../eventBuilder';
import Illustration from '../../Assets/Images/Login-Screen-Art.jpg';
import './NormalLogin.scss';

const pBuilder = new PageviewBuilder();
const eBuilder = new EventBuilder();

/**
 * Represents the normal admin dashboard login component.
 */
class NormalLogin extends Component {
  constructor(props) {
    super(props);

    this._formErrorSetClean = {
      isValid: true,
      username: '',
      password: '',
      serverError: '',
      unauthorized: '',
    };
    this.state = {
      disableLoginBtn: false,
      formErrors: cloneDeep(this._formErrorSetClean),
      isVisible: false,
      username: '',
      password: '',
      redirectToTermsCond: false,
      redirectToAdminSettings: false,
      redirectToAdminSchedule: false,
      redirectToDashboard: false,
      showPasswordUnmasked: false,
    };

    pBuilder.pageview(pBuilder.Page.login);
    eBuilder.withCategory(eBuilder.Category.Scheduler.experience);
  }

  _isFormValid = () => {
    let isValid = true;
    const { username, password } = this.state;
    const formErrors = cloneDeep(this._formErrorSetClean);

    if (!username) {
      isValid = false;
      formErrors.username = 'Email is required';
    }

    if (!password) {
      isValid = false;
      formErrors.password = 'Password is required';
    }

    this.setState(() => ({ formErrors: formErrors }));

    return isValid;
  };

  _onInputChange = (path, value) => {
    const state = {};
    Utils.update(state, path, value);

    this.setState(() => state);
  };

  _onKeyPress = (e) => {
    const { key } = e;

    if (key === Enums.KeyboardKeys.enterKey) {
      this._onLogin();
    }
  };

  _onLogin = async () => {
    if (this._isFormValid()) {
      try {
        this.setState(() => ({ disableLoginBtn: true }));

        const url = `${process.env.REACT_APP_ADMIN_API}/login`;
        const { username, password } = this.state;
        const data = {
          username: username,
          password: password,
        };
        const res = await axios.post(url, data);
        const jwt = res.data;
        const jwtData = Utils.decodeJwt(jwt);
        const isTempPassword = jwtData.isTempPassword;
        let user = Storage.getItem(Constants.currUserKey);

        if (!user) {
          user = {
            jwt: '',
            agreementSigned: false,
          };
        }

        user.jwt = jwt;
        Storage.setItem(Constants.currUserKey, user);

        if (isTempPassword) {
          // Here, it'd be nice to navigate to the reset password page
          Events.emit(Constants.Events.tempLogin, username);
          //this.setState(() => ({ redirectToPasswordReset: true }));
        } else {
          const onboardingInfoUrl = `${process.env.REACT_APP_SETTINGS_API}/getLocationConfig/${jwtData.defaultLocation}`;
          const onboardingInfoRes = await axios.get(onboardingInfoUrl);
          let onboardingInfo = null;

          if (onboardingInfoRes && onboardingInfoRes.data) {
            onboardingInfo = onboardingInfoRes.data;
            Storage.setItem(Constants.currLocIdKey, onboardingInfo.locationId);
          }

          eBuilder
            .withAction(eBuilder.Action.Scheduler.Click.login)
            .withLabel(
              eBuilder.Label.alternateLabel,
              onboardingInfo.uniqueLocationId
            )
            .post();

          user.uniqueLocationId = onboardingInfo.uniqueLocationId;
          Storage.setItem(Constants.currUserKey, user);
          if (onboardingInfo.storeInformation.languageTag) {
            Storage.setItem(
              Constants.langTag,
              onboardingInfo.storeInformation.languageTag
            );
          }

          /**
           * If location does not have a signed agreement, they go through, sign the agreement, and set the settings. This marks the agreement as signed.
           */
          if (!onboardingInfo.agreementSigned) {
            this.setState(() => ({ redirectToTermsCond: true }));
          } else if (onboardingInfo.agreementSigned) {
            user.agreementSigned = true;
            Storage.setItem(Constants.currUserKey, user);

            const returnUrl = Utils.getFullReturnUrl();

            const stellestSettings = onboardingInfo.locationSettings.find(
              (e) =>
                e.displayName ===
                Constants.LocationSettings.settingIsStellestEnabled
            );
            const isStellestEnabled = stellestSettings
              ? stellestSettings.settingValue
              : false;

            const isColumbia =
              onboardingInfo.storeInformation.countryCode === 'CO';

            if (returnUrl) {
              window.location = returnUrl;
            } else if (isStellestEnabled || isColumbia) {
              this.setState(() => ({ redirectToDashboard: true }));
            } else {
              this.setState(() => ({ redirectToAdminSchedule: true }));
            }
          }
        }
      } catch (error) {
        if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusUnauthorizedError)
        ) {
          this.setState(() => ({
            disableLoginBtn: false,
            formErrors: {
              serverError: 'Invalid email or password',
            },
          }));
        } else if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusInternalServerError)
        ) {
          this.setState(() => ({
            disableLoginBtn: false,
            formErrors: { serverError: 'Unable to login at this time' },
          }));
        }
      }
    }
  };

  _onTogglePasswordMask = () => {
    this.setState((prevState) => ({
      showPasswordUnmasked: !prevState.showPasswordUnmasked,
    }));
  };

  redirectToForgotPassword = () => {
    eBuilder.withAction(eBuilder.Action.Scheduler.Click.forgotPassword).post();
    window.location = '/forgot-pw';
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      disableLoginBtn,
      formErrors,
      username,
      password,
      redirectToTermsCond,
      redirectToAdminSchedule,
      redirectToDashboard,
      redirectToPasswordReset,
      showPasswordUnmasked,
    } = this.state;

    if (redirectToTermsCond) {
      return <Redirect to={{ pathname: `/termsuse` }} />;
    }

    if (redirectToAdminSchedule) {
      return <Redirect to={{ pathname: `/schedule` }} />;
    }

    if (redirectToPasswordReset) {
      return <Redirect to={{ pathname: `/schedule` }} />;
    }

    if (redirectToDashboard) {
      return <Redirect to={{ pathname: `/dashboard` }} />;
    }

    const { t } = this.props;

    return (
      <div className="login-norm">
        <h1 className="login-norm__title">{t('Welcome Back!')}</h1>
        <h3 className="login-norm__subtitle">{t('Schedule Manager')}</h3>
        <form className="login-norm__form">
          <TextField
            className="login-norm__input"
            inputProps={{
              style: {
                padding: '12px 14px',
              },
            }}
            label={t('Email')}
            value={username}
            variant="outlined"
            onChange={(e) => this._onInputChange(['username'], e.target.value)}
            onKeyPress={this._onKeyPress}
          />
          <span
            className={`login-norm__error ${
              formErrors.username ? 'login-norm__error--visible' : ''
            }`}
          >
            {t(formErrors.username)}
          </span>
          <div className="login-norm__group">
            <TextField
              className="login-norm__input"
              inputProps={{
                style: {
                  padding: '12px 14px',
                },
              }}
              label={t('Password')}
              type={`${!showPasswordUnmasked ? 'password' : 'text'}`}
              value={password}
              variant="outlined"
              onChange={(e) =>
                this._onInputChange(['password'], e.target.value)
              }
              onKeyPress={this._onKeyPress}
            />
            <button
              className="login-norm__show-pw"
              type="button"
              onClick={this._onTogglePasswordMask}
            >
              <VisibilityIcon
                className={`login-norm__icon ${
                  showPasswordUnmasked ? 'login-norm__icon--hide' : ''
                }`}
              />
              <VisibilityOffIcon
                className={`login-norm__icon ${
                  !showPasswordUnmasked ? 'login-norm__icon--hide' : ''
                }`}
              />
            </button>
            <span
              className={`login-norm__error login-norm__error--no-margin ${
                formErrors.password ? 'login-norm__error--visible' : ''
              }`}
            >
              {t(formErrors.password)}
            </span>
            <span
              className={`login-norm__error login-norm__error--no-margin${
                formErrors.unauthorized ? 'login-norm__error--visible' : ''
              }`}
            >
              {t(formErrors.unauthorized)}
            </span>
            <span
              className={`login-norm__error login-norm__error--no-margin ${
                formErrors.serverError ? 'login-norm__error--visible' : ''
              }`}
            >
              {t(formErrors.serverError)}
            </span>
          </div>
          <button
            className="login-norm__forgotpw"
            type="button"
            onClick={this.redirectToForgotPassword}
          >
            {t('Forgot password?')}
          </button>
          <button
            className="login-norm__login"
            disabled={disableLoginBtn}
            type="button"
            onClick={this._onLogin}
          >
            {t('Login')}
          </button>
        </form>
        <div className="login-norm__illustration-cont">
          <img
            alt="Illustration"
            className="login-norm__illustration"
            src={Illustration}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(NormalLogin);
