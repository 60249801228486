import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DashboardService from '../../Services/dashboardService';
import Events from '../../events';
import './KpiSummary.scss';

/**
 * Represents the customer data component.
 * @param {Object} props The component properties.
 */
class KpiSummary extends Component {
  /**
   * Initializes a new instance of the CustomerData component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {};
    this._dashboardService = new DashboardService();
  }

  _onDetailsClick() {
    Events.emit(this.props.detailsEvent);
  }

  /**
   * Renders the component.
   */
  render() {
    const { t, isActive } = this.props;
    return (
      <div className="kpi-summary">
        <img className="kpi-summary__icon" src={this.props.iconSrc}></img>
        <div className="kpi-summary__value">{this.props.value}</div>
        <div className="kpi-summary__description">{this.props.description}</div>
        <button
          className="kpi-summary__details-btn"
          onClick={() => this._onDetailsClick()}
          active={isActive}
        >
          {t('See Details')}
        </button>
      </div>
    );
  }
}

KpiSummary.propTypes = {
  detailsEvent: PropTypes.string,
};

export default withTranslation()(KpiSummary);
