import React, { memo } from 'react';
import Utils from '../../utils';
import './LoginHeader.scss';

/**
 * Represents the header for the login pages.
 */
const LoginHeader = memo(() => {
  const logoUrl = Utils.getTaglineLogoUrl();

  return (
    <div className="login-header">
      <div className="login-header__logo-cont">
        <img alt="Site Logo" src={logoUrl} />
      </div>
    </div>
  );
});

export default LoginHeader;
