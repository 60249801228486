import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import ReactHtmlParser from 'react-html-parser';
import Snackbar from '@material-ui/core/Snackbar';
import Utils from '../../utils';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import StorefrontSampleImage1 from '../../Assets/Images/Storefront Sample 1.png';
import StorefrontSampleImage2 from '../../Assets/Images/Storefront Sample 2.png';
import './CustomerStoreFront.scss';

/**
 * Represents the customer store front component.
 * @param {Object} props The component properties.
 */
class CustomerStoreFront extends Component {
  /**
   * Initializes a new instance of the CustomerStoreFront component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showCopyCustomerUrlAlert: false,
    };
  }

  _onCloseCopyCustomerUrlAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showCopyCustomerUrlAlert: false }));
  };

  _onViewStoreFront = () => {
    const eBuilder = new EventBuilder();
    eBuilder
      .withCategory(eBuilder.Category.settings)
      .withAction(eBuilder.Action.Settings.Click.viewConsumerStorefront)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();

    window.open(
      `${this.props.uniqueLocationUrl}?utm_source=storefront&utm_medium=linking&utm_campaign=booking`,
      '_blank'
    );
  };

  _onCopyCustomerUrl = async () => {
    const { uniqueLocationUrl, onToggleErrorModal } = this.props;

    try {
      const url = `${uniqueLocationUrl}?utm_source=storefront&utm_medium=linking&utm_campaign=booking`;
      await navigator.clipboard.writeText(url);

      this.setState(() => ({ showCopyCustomerUrlAlert: true }));
    } catch {
      onToggleErrorModal(
        true,
        'There was an unexpected issue copying the storefront url.'
      );
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const { showCopyCustomerUrlAlert } = this.state;
    const { countrySettings, hasPlaceId, t } = this.props;
    const isGMBGuideEnabled =
      countrySettings.find(
        (cs) =>
          cs.settingName === Constants.CountrySettings.settingIsGMBGuideEnabled
      )?.settingValue === true;
    const langTag = Utils.convertUrlToLocale();

    return (
      <div className="cust-storefront">
        <h3 className="cust-storefront__heading">
          {t('See what your customer storefront looks like')}
        </h3>
        <div className="cust-storefront__view-cont">
          <button
            className="cust-storefront__view"
            type="button"
            onClick={this._onViewStoreFront}
          >
            {t('View Customer Storefront')}
          </button>
          <button
            className="cust-storefront__copy"
            type="button"
            onClick={this._onCopyCustomerUrl}
          >
            {t('Copy Customer URL')}
          </button>
        </div>
        {!isGMBGuideEnabled ? (
          <div className="cust-storefront__samples">
            <div className="cust-storefront__sample1-cont">
              <img
                alt="Sample 1"
                className="cust-storefront__sample1-img"
                src={StorefrontSampleImage1}
              />
            </div>
            <div className="cust-storefront__sample2-cont">
              <img
                alt="Sample 2"
                className="cust-storefront__sample2-img"
                src={StorefrontSampleImage2}
              />
            </div>
            <span className="cust-storefront__disclosure">
              {t('Sample data presented live on Google')}
            </span>
          </div>
        ) : !hasPlaceId ? (
          <div>
            <br />
            <h3 className="cust-storefront__heading">
              {t(
                'Convert Google searches to appointments by creating a Google My Business Listing'
              )}
            </h3>
            <h3>{t('What is Google My Business (GMB)?')}</h3>
            <p>
              {t(
                'A free tool that lets you manage how your store appears on Google Search and Maps for people who are searching for your services. It includes business name, hours, online appointment booking link as your GMB “listing”; GMB also allows you to monitor and reply to customer reviews.'
              )}
            </p>
            <h3>{t('Why do I create a Google My Business listing?')}</h3>
            <p>
              {ReactHtmlParser(
                t(
                  'When people search for a product or service near them, they\'re usually very close to making a purchase. According to <a href="https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-search-trends-consumers-to-stores/">Google</a>, 76% of people who search on their smartphone (think "eye care provider near me") visit a store within a day. Therefore, it\'s critical that the information about your store that shows up when people search Google is as accurate, complete, and optimized as possible.'
                )
              )}
            </p>
            <h3>{t('How do I create a Google My Business listing?')}</h3>
            <ol>
              <li>
                {t(
                  'Log into the Google Account you want associated with your business (or create a Google Account if you don’t already have one).'
                )}
                <div>
                  <img
                    alt="Google My Business Name"
                    className="cust-storefront__img cust-storefront__img-sm"
                    src={Utils.getContentImage(
                      'gmb_business_name.png',
                      langTag
                    )}
                  />
                </div>
              </li>
              <li>
                {ReactHtmlParser(
                  t(
                    'Go to <a href="//google.com/business" target="_blank" rel="noopener noreferrer">google.com/business</a> and select “Start now” in the top right-hand corner.'
                  )
                )}
              </li>
              <li>{t('Enter your business name.')}</li>
              <li>
                {t('Enter your business address.')}
                <div>
                  <img
                    alt="Google My Business Location"
                    className="cust-storefront__img cust-storefront__img-sm"
                    src={Utils.getContentImage('gmb_location.png', langTag)}
                  />
                </div>
              </li>
              <li>
                {ReactHtmlParser(
                  t(
                    'Choose your business category. Try to choose the most accurate category possible -- you’re essentially telling Google which type of customers should see your business listing. E.g. <strong>Optometrist, Optician</strong>'
                  )
                )}
              </li>
              <li>
                {t(
                  'Choose a verification option. There are several ways to verify your GMB listing:'
                )}
                <ul>
                  <li>{t('By postcard')}</li>
                  <li>{t('By phone')}</li>
                  <li>{t('By email')}</li>
                  <li>{t('Instant verification')}</li>
                </ul>
              </li>
              <li>
                {t(
                  'Add customer storefront link in the Appointment URL field of the URLs section.'
                )}
                <div>
                  <img
                    alt="Google My Business Info"
                    className="cust-storefront__img cust-storefront__img-md"
                    src={Utils.getContentImage('gmb_info.png', langTag)}
                  />
                </div>
              </li>
            </ol>
            <p>
              {t(
                'Here is how the Customer Storefront link will appear on your GMB listing:'
              )}
            </p>
            <div>
              <img
                alt="Google My Business Location"
                className="cust-storefront__img cust-storefront__img-lg"
                src={Utils.getContentImage('gmb_search_results.png', langTag)}
              />
            </div>
          </div>
        ) : (
          <div>
            <br />
            <h3 className="cust-storefront__heading">
              {t(
                'Convert Google searches to appointments by posting customer storefront link on your Google My Business Listing'
              )}
            </h3>
            <p>
              {t(
                'Add customer storefront link (from copied link above) in the Appointment URL field of the URLs section.'
              )}
            </p>
            <div>
              <img
                alt="Google My Business Name"
                className="cust-storefront__img cust-storefront__img-md"
                src={Utils.getContentImage('gmb_info.png', langTag)}
              />
            </div>
            <p>
              {t(
                'Here is how the Customer Storefront link will appear on your GMB listing:'
              )}
            </p>
            <div>
              <img
                alt="Google My Business Location"
                className="cust-storefront__img cust-storefront__img-lg"
                src={Utils.getContentImage('gmb_search_results.png', langTag)}
              />
            </div>
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showCopyCustomerUrlAlert}
          onClose={this._onCloseCopyCustomerUrlAlert}
        >
          <Alert
            className="cust-storefront__alert"
            severity="success"
            onClose={this._onCloseCopyCustomerUrlAlert}
          >
            {t('Customer Storefront URL has been copied.')}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

CustomElementRegistry.propTypes = {
  hasPlaceId: PropTypes.bool.isRequired,
  uniqueLocationUrl: PropTypes.string.isRequired,
};

export default withTranslation()(CustomerStoreFront);
