import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import './TwoWayHeader.scss';
import { format } from 'date-fns';
import momentLocaleWrapper from '../../momentLocaleWrapper';

/**
 * Represents two way conversation page header
 */
class TwoWayHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  /**
   * Renders the component.
   */
  render() {
    const { t, today } = this.props;

    return (
      <section className="submenu">
        <h2 className="submenu__title">{t('Chat')}</h2>
        <div className="submenu__cont">
          <div className="submenu__today">{t('Today')}:</div>
          <div className="submenu__date">{today.format('dddd [-] MMMM D, YYYY')}</div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(TwoWayHeader);
