import React, { Component } from 'react';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Enums from '../enums';
import Utils from '../utils';
import Events from '../events';
import Logger from '../logger';
import Session from '../session';
import Storage from '../storage';
import Constants from '../constants';
import DataContext from './DataContext';
import Overlay from '../Components/Overlay/Overlay';
import momentLocaleWrapper from '../momentLocaleWrapper';
import SignalRHubService from '../Services/signalRHubService';
import ContentManagementService from '../Services/contentManagementService';

/**
 * Represents the data provider used throughout the application.
 */
class DataProvider extends Component {
  /**
   * Initializes a new instance of the DataProvider class.
   * @param {Object} props The compoonent properties
   */
  constructor(props) {
    super(props);

    let user = Storage.getItem(Constants.currUserKey);

    if (!user) {
      user = {
        agreementSigned: false,
        jwt: '',
      };
    }

    this.state = {
      //THIS IS THE CONTEXT
      currDateTime: momentLocaleWrapper(),
      locale: 'en-us',
      user: user,
      location: '',
      locationInfo: {
        locationId: 0,
        schedule: {
          schedules: [],
        },
        appointmentTypes: [],
      },
      supportInfo: {
        email: '',
        phone: '',
      },
      redirectToLogin: false,
      systemMessages: 0,
      hasEditResourceMessage: false,
      hasEditStoreHoursMessage: false,
      hasEditServiceTypesMessage: false,
      bookedAppointments: [],
      queuedAppointments: [],
      showAddAppointment: false,
      showAdminSettings: false,
      appointmentToDelete: null,
      calendarConfirmationType: '',
      calendarConfirmationChanged: '',
      calendarConfirmationStartDate: '',
      calendarConfirmationResourceData: '',
      filteredBookedAppointments: [],
      isInitialLoad: true,
      appointmentTypes: [],
      appointmentInEdit: null,
      showLoadingOverlay: false,
      showCalendarConfirmationModal: false,
      createNewAppointment: this._createNewAppointment,
      navigateTo: this._navigateTo,
      onAddAppointment: this._onAddAddpointment,
      onBookAppointment: this._onBookAppointment,
      onDeleteAppointment: this._onDeleteAppointment,
      getAppointments: this._getAppointments,
      getOnboardingInfo: this._getOnboardingInfo,
      getSupportInfo: this._getSupportInfo,
      onEditAppointment: this._onEditAppointment,
      onLogOut: this._onLogOut,
      getSystemMessages: this._getSystemMessages,
      onIncreaseSystemMessage: this._onIncreaseSystemMessage,
      onDecreaseSystemMessage: this._onDecreaseSystemMessage,
      onToggleEditResourceMessage: this._onToggleEditResourceMessage,
      onToggleEditStoreHoursMessage: this._onToggleEditStoreHoursMessage,
      onToggleEditServiceTypesMessage: this._onToggleEditServiceTypesMessage,
      onMarkAppointmentAsNoShow: this._onMarkAppointmentAsNoShow,
      onSetCurrDateTime: this._onSetCurrDateTime,
      onSetAppointmentToDelete: this._onSetAppointmentToDelete,
      onToggleAddAppointment: this._onToggleAddAppointment,
      onToggleAdminSettings: this._onToggleAdminSettings,
      onToggleCalendarConfirmationModal:
        this._onToggleCalendarConfirmationModal,
      onToggleLoadingOverlay: this._onToggleLoadingOverlay,
      onUpdateAppointment: this._onUpdateAppointment,
      onUpdateAppointmentTypes: this._onUpdateAppointmentTypes,
      onUpdateBookedAppointments: this._onUpdateBookedAppointments,
      saveAppointment: this._saveAppointment,
      saveAppointmentTypes: this._saveAppointmentTypes,
      deleteAppointmentTypes: this._deleteAppointmentTypes,
      saveResources: this._saveResources,
      deleteResource: this._deleteResource,
      updateResources: this._updateResources,
      saveStoreHours: this._saveStoreHours,
      update: this._updateState,
    };
    this._contentManagmentService = new ContentManagementService();
  }

  _shouldLogError = (error) => {
    const shouldLogError =
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusInternalServerError);
    return shouldLogError;
  };

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      this._onToggleLoadingOverlay();

      await this._verifyJwt();
      await this._getSiteConfig();
      await this._getOnboardingInfo();
      await this._getSupportInfo();

      momentLocaleWrapper.locale(
        this.state.locationInfo.storeInformation.languageTag
      );

      await this._setI18nLanguage();
      await this._getAppointments();
      this._onToggleLoadingOverlay();
      this._getSystemMessages();
      SignalRHubService.setupCommunicationHub();

      this.setState(() => ({ isInitialLoad: false }));
      // The appointments will sometimes not show, so forcing a
      // state update causes the scheduler to redraw successfully.
      this.forceUpdate();
    } catch (error) {
      if (this._shouldLogError(error)) {
        Logger.error(error);
      }
    }
  }

  async componentWillUnmount() {
    SignalRHubService.closeCommunicationHub();
  }

  _constructCommonSettingsApiUrl = (endPoint) => {
    const currLocId = Storage.getItem(Constants.currLocIdKey);
    const url = `${process.env.REACT_APP_SETTINGS_API}/${endPoint}/${currLocId}`;

    return url;
  };

  _createNewAppointment = () => {
    const startDate = new Date();
    // TODO: Need to get a better new id.
    const newId = Math.floor(Math.random() * 100);
    let apptInfo = this.state.appointmentTypes[0];
    const { locationInfo } = this.state;
    const defaultResource = Utils.getDefaultResource(locationInfo.resources);

    if (!apptInfo) {
      apptInfo = {};
    }

    return {
      id: newId,
      notes: '',
      title: '',
      patient: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      created: null,
      isVisible: true,
      startDate: startDate,
      swimlane: {
        description: '',
        swinLaneId: 0,
        isUnscheduled: true,
      },
      appointmentType: {
        length: apptInfo.duration,
        displayName: apptInfo.displayName,
        id: apptInfo.locationAppointmentTypeId,
      },
      locationId: locationInfo.locationId,
      currentStatus: Enums.AppointmentStatus.queued,
      endDate: momentLocaleWrapper(startDate)
        .add(apptInfo.duration, 'minutes')
        .toDate(),
      resourceId: defaultResource.resourceId,
      resource: {
        resourceId: defaultResource.resourceId,
        displayName: defaultResource.displayName,
      },
    };
  };

  _getAppointment = (id) => {
    const { bookedAppointments, queuedAppointments } = this.state;
    let appt = bookedAppointments.find((ba) => ba.id === id);

    if (appt) {
      return appt;
    }

    appt = queuedAppointments.find((qa) => qa.id === id);

    return appt;
  };

  _getOnboardingInfo = async () => {
    // const user = Storage.getItem(Constants.currUserKey);
    // const jwtData = Utils.decodeJwt(user.jwt);
    const locId = Storage.getItem(Constants.currLocIdKey);
    const locConfigUrl = `${process.env.REACT_APP_SETTINGS_API}/getLocationConfig/${locId}`;
    const res = await axios.get(locConfigUrl);
    let locationInfo = null;

    if (res && res.data) {
      locationInfo = res.data;
    }

    if (!locationInfo.agreementSigned) {
      window.location.href = Constants.Routes.onboarding;
    }

    const getPhoneCountryCodeUrl = `${process.env.REACT_APP_CONTENT_API}/getPhoneCountryCode`;
    const phoneCountryCode = await axios.post(getPhoneCountryCodeUrl, {
      countryCode: locationInfo.storeInformation.countryCode,
    });

    Utils.formatLocationInfoApptTypes(locationInfo);
    Utils.formatLocationInfoResources(locationInfo);

    Storage.setItem(Constants.currLocIdKey, locationInfo.locationId);

    this.setState(() => ({
      locationInfo: { ...locationInfo },
      phoneCountryCode: phoneCountryCode.data,
      location: locationInfo.storeInformation.name,
      appointmentTypes: locationInfo.appointmentTypes.map((at) => {
        at.isChecked = true;
        return at;
      }),
    }));
  };

  _getSiteConfig = async () => {
    try {
      var siteConfig = Storage.getItem(Constants.siteConfig);
      if (siteConfig == null) {
        const url = `${
          process.env.REACT_APP_SETTINGS_API
        }/getSiteConfigByUrl?url=${encodeURI(window.location.href)}`;
        const res = await axios.get(url);
        if (res && res.data) {
          siteConfig = res.data;
          Storage.setItem(Constants.siteConfig, res.data);
        }
      }
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.log(error);
      }
    }
  };

  _getSupportInfo = async () => {
    try {
      const url = `${process.env.REACT_APP_CONTENT_API}/getSupportInfo`;
      const data = {
        countryCode: this.state.locationInfo.storeInformation.countryCode,
      };
      const res = await axios.post(url, data);

      if (res && res.data) {
        this.setState({
          supportInfo: { ...res.data },
        });
      }
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        console.log(error);
      }
    }
  };

  _getAppointments = async (currDateObject = momentLocaleWrapper().local()) => {
    try {
      let currDate = currDateObject.format('YYYY-MM-DD');
      const locationId = Storage.getItem(Constants.currLocIdKey);
      const url = `${process.env.REACT_APP_CALENDAR_API}/getAppointments`;
      const data = {
        locationId: parseInt(locationId),
        startDay: currDate,
        numDaysToRetrieve: 1,
      };
      const res = await axios.post(url, data);

      if (res && res.data) {
        const appointments = res.data.appointments;
        // Split out booked/scheduled and queued appointments and format them for the scheduler.
        const bookedAppointments = this._getBookedAppointments(appointments);
        const queuedAppointments = this._getQueuedAppointments(appointments);

        this.setState(() => ({
          filteredBookedAppointments: cloneDeep(bookedAppointments),
          bookedAppointments: bookedAppointments,
          queuedAppointments: queuedAppointments,
        }));
      }
    } catch (error) {
      if (this._shouldLogError(error)) {
        Logger.error(error);
      }
    }
  };

  _getBookedAppointments = (appointments) => {
    let bookedAppointments = [];

    if (appointments && appointments.length > 0) {
      bookedAppointments = appointments
        .filter(
          (appt) => appt.currentStatus === Enums.AppointmentStatus.scheduled
        )
        .map((appt) => {
          const apptType = this.state.appointmentTypes.find(
            (at) => at.locationAppointmentTypeId === appt.appointmentType.id
          );
          return {
            ...appt,
            isVisible: apptType && apptType.isChecked,
            id: appt.appointmentId,
            title: `${appt.patient.firstName} ${appt.patient.lastName}`,
            created: appt.created
              ? momentLocaleWrapper(
                  appt.created,
                  '(YYYY, MM, DD, HH, mm)'
                ).toDate()
              : null,
            endDate: appt.endTime
              ? momentLocaleWrapper(
                  appt.endTime,
                  '(YYYY, MM, DD, HH, mm)'
                ).toDate()
              : null,
            startDate: appt.startTime
              ? momentLocaleWrapper(
                  appt.startTime,
                  '(YYYY, MM, DD, HH, mm)'
                ).toDate()
              : null,
            resourceId: appt.resource.resourceId,
          };
        });
    }

    return bookedAppointments;
  };

  _getQueuedAppointments = (appointments) => {
    let queuedAppointments = [];

    if (appointments && appointments.length > 0) {
      queuedAppointments = appointments
        .filter((appt) => appt.currentStatus === Enums.AppointmentStatus.queued)
        .map((appt) => ({
          ...appt,
          isVisible: true,
          notes: appt.notes,
          id: appt.appointmentId,
          title: `${appt.patient.firstName} ${appt.patient.lastName}`,
          created: appt.created
            ? momentLocaleWrapper
                .utc(appt.created, '(YYYY, MM, DD, HH, mm)')
                .toDate()
            : null,
          startDate: appt.startTime
            ? momentLocaleWrapper(
                appt.startTime,
                '(YYYY, MM, DD, HH, mm)'
              ).toDate()
            : null,
          endDate: appt.endTime
            ? momentLocaleWrapper(
                appt.endTime,
                '(YYYY, MM, DD, HH, mm)'
              ).toDate()
            : null,
          resourceId: appt.resource.resourceId,
        }));
    }

    return queuedAppointments;
  };

  _navigateTo = (route, history) => {
    history.push(`${route}${window.location.search}`);
  };

  //use this for 'new appt'
  _onAddAddpointment = async (newAppt, signalR, isNew) => {
    if (newAppt) {
      let appointments = [];
      let isBooked = false;
      let isQueued = false;
      let that = this;

      if (
        newAppt.currentStatus === Enums.AppointmentStatus.scheduled &&
        !this.state.bookedAppointments.some((appt) => appt.id === newAppt.id)
      ) {
        newAppt.endDate = momentLocaleWrapper(newAppt.startDate)
          .add(newAppt.appointmentType.length, 'minutes')
          .toDate();
        isBooked = true;
      } else if (
        newAppt.currentStatus === Enums.AppointmentStatus.queued &&
        !this.state.queuedAppointments.some((appt) => appt.id === newAppt.id)
      ) {
        isQueued = true;
        // Need to null out the start/end times for waitlist appointments.
        // Doesn't make sense to have those while in the queue.
        newAppt.startTime = null;
        newAppt.endTime = null;
      }

      let concatenatedPhone = '';
      if (signalR) {
        concatenatedPhone = `${newAppt.patient.phone}`;
      } else {
        // Strip the plus from the country code
        concatenatedPhone = `${this.state.phoneCountryCode.substring(1)}${
          newAppt.patient.phone
        }`;
      }

      let formattedNewAppt = {
        ...newAppt,
        patient: {
          ...newAppt.patient,
          phone: concatenatedPhone,
        },
      };

      if (!signalR) {
        const savedAppt = await this._saveAppointment(formattedNewAppt);
        if (savedAppt) {
          formattedNewAppt.id = savedAppt.appointmentId;
        }
      }

      if (isNew) {
        formattedNewAppt.appointmentId = formattedNewAppt.id;
      }

      if (isBooked) {
        appointments = this.state.bookedAppointments.concat(formattedNewAppt);
        that.setState({ bookedAppointments: appointments });
      } else if (isQueued) {
        appointments = this.state.queuedAppointments.concat(formattedNewAppt);
        that.setState({ queuedAppointments: appointments });
      }
    }
  };

  _onBookAppointment = (appt, startDate, signalR, resourceData) => {
    if (appt && startDate) {
      this.setState((prevState) => {
        appt.created = new Date();
        appt.startDate = startDate;
        appt.currentStatus = Enums.AppointmentStatus.scheduled;
        appt.endDate = momentLocaleWrapper(startDate)
          .add(appt.appointmentType.length, 'minutes')
          .toDate();

        const queuedAppointments = prevState.queuedAppointments.filter(
          (qa) => qa.id !== appt.id
        );
        const bookedAppointments = prevState.bookedAppointments.concat(appt);

        if (!appt.appointmentId) {
          appt.appointmentId = appt.id;
        }

        if (!signalR) {
          this._saveAppointment(appt);
        }

        return {
          queuedAppointments: queuedAppointments,
          bookedAppointments: bookedAppointments,
        };
      });
    }
  };

  _onDeleteAppointment = (appt, signalR) => {
    //use the appointment set by appointment tooltip
    if (!appt && !signalR) {
      appt = this.state.appointmentToDelete;
    }

    if (appt) {
      let state = null;

      const bookedAppointments = this.state.bookedAppointments.filter(
        (ba) => ba.id !== appt.id
      );
      const queuedAppointments = this.state.queuedAppointments.filter(
        (qa) => qa.id !== appt.id
      );
      state = {
        bookedAppointments: bookedAppointments,
        queuedAppointments: queuedAppointments,
      };
      appt.currentStatus = Enums.AppointmentStatus.cancelled;
      if (!signalR) {
        this._saveAppointment(appt);
      }
      this.setState(() => state);
    }
  };

  _onFilterBookedAppointments = (func) => {
    func && this.setState(() => ({ bookedAppointments: func() }));
  };

  _onMarkAppointmentAsNoShow = async (appt) => {
    if (appt) {
      let state = null;

      if (appt.currentStatus === Enums.AppointmentStatus.scheduled) {
        const bookedAppointments = this.state.bookedAppointments.filter(
          (ba) => ba.id !== appt.id
        );
        state = {
          bookedAppointments: bookedAppointments,
        };
      } else if (appt.currentStatus === Enums.AppointmentStatus.queued) {
        const queuedAppointments = this.state.queuedAppointments.filter(
          (ba) => ba.id !== appt.id
        );
        state = {
          queuedAppointments: queuedAppointments,
        };
      }

      appt.currentStatus = Enums.AppointmentStatus.noShow;

      await this._saveAppointment(appt);

      this.setState(() => state);
    }
  };

  _onSetAppointmentToDelete = (appt) => {
    this.setState(() => ({ appointmentToDelete: { ...appt } }));
  };

  _onLogOut = () => {
    Storage.removeItem(Constants.currUserKey);
    this.setState(() => ({ redirectToLogin: true }));
  };

  // Check criteria to display system message notification
  // This was built to be able to incorporate additional logic for other system messages
  _getSystemMessages = () => {
    const {
      locationInfo,
      onIncreaseSystemMessage,
      onToggleEditResourceMessage,
      onToggleEditServiceTypesMessage,
      onToggleEditStoreHoursMessage,
    } = this.state;
    const locationResources = locationInfo.resources;
    const resourceCount = locationInfo.resources.length;
    const defaultResource = Utils.getDefaultResource(locationResources);
    // If the user has not added new resources types OR has not editied the default resource name, add a notification
    if (
      resourceCount === 1 &&
      defaultResource.displayName === Constants.defaultResourceDisplayName
    ) {
      onIncreaseSystemMessage();
      onToggleEditResourceMessage();

      onIncreaseSystemMessage();
      onToggleEditStoreHoursMessage();

      onIncreaseSystemMessage();
      onToggleEditServiceTypesMessage();
    }
  };

  _onIncreaseSystemMessage = () => {
    this.setState((prevState) => ({
      systemMessages: ++prevState.systemMessages,
    }));
  };

  _onDecreaseSystemMessage = () => {
    this.setState((prevState) => ({
      systemMessages: --prevState.systemMessages,
    }));
  };

  _onToggleEditResourceMessage = () => {
    this.setState((prevState) => ({
      hasEditResourceMessage: !prevState.hasEditResourceMessage,
    }));
  };

  _onToggleEditStoreHoursMessage = () => {
    this.setState((prevState) => ({
      hasEditStoreHoursMessage: !prevState.hasEditStoreHoursMessage,
    }));
  };

  _onToggleEditServiceTypesMessage = () => {
    this.setState((prevState) => ({
      hasEditServiceTypesMessage: !prevState.hasEditServiceTypesMessage,
    }));
  };

  _onSetCurrDateTime = async (currDateTime) => {
    this.setState(() => ({ currDateTime: currDateTime }));
  };

  _onToggleAddAppointment = (appointment) => {
    this.setState((prevState) => ({
      showAddAppointment: !prevState.showAddAppointment,
      appointmentInEdit: cloneDeep(appointment),
    }));
  };

  _onToggleAdminSettings = async () => {
    const {
      showAdminSettings,
      locationInfo,
      saveResources,
      onToggleEditServiceTypesMessage,
      onToggleEditStoreHoursMessage,
      onToggleEditResourceMessage,
    } = this.state;

    const locationResources = locationInfo.resources;
    const defaultResource = Utils.getDefaultResource(locationResources);
    const resourceCount = locationInfo.resources.length;

    if (
      resourceCount === 1 &&
      showAdminSettings &&
      defaultResource.displayName === Constants.defaultResourceDisplayName
    ) {
      defaultResource.displayName = defaultResource.displayName.concat(' '); //This is to get around logic in getSystemMessages, this way the notifications will only be showed once
      const resources = [defaultResource];
      await saveResources(resources);

      onToggleEditResourceMessage();
      onToggleEditServiceTypesMessage();
      onToggleEditStoreHoursMessage();

      this.setState((prevState) => ({
        showAdminSettings: !prevState.showAdminSettings,
        systemMessages: 0,
      }));
    } else {
      this.setState((prevState) => ({
        showAdminSettings: !prevState.showAdminSettings,
      }));
    }
  };

  _onToggleLoadingOverlay = () => {
    this.setState((prevState) => ({
      showLoadingOverlay: !prevState.showLoadingOverlay,
    }));
  };

  _onToggleLoadingOverlayOn = () => {
    this.setState((prevState) => ({ showLoadingOverlay: true }));
  };

  _onToggleLoadingOverlayOff = () => {
    this.setState((prevState) => ({ showLoadingOverlay: false }));
  };

  _onToggleCalendarConfirmationModal = (
    confirmationType,
    changed,
    startDate
  ) => {
    this.setState((prevState) => ({
      showCalendarConfirmationModal: !prevState.showCalendarConfirmationModal,
      calendarConfirmationType: confirmationType,
      calendarConfirmationChanged: changed,
      calendarConfirmationStartDate: startDate,
    }));
  };

  _onUpdateAppointment = (changed, signalR) => {
    // The first property of the provided objects is the appointment id.
    if (changed) {
      // Find and update the appropriate appointment.
      let updatedAppt = null;
      let bookedAppointments = this.state.bookedAppointments.map((ba) => {
        if (changed.id === ba.id) {
          ba = { ...ba, ...changed };
          if (changed.resourceId) {
            const resources = this.state.locationInfo.resources;
            resources.map((resource, n) => {
              if (resource.resourceId === changed.resourceId) {
                ba.resource = {
                  resourceId: resource.resourceId,
                  displayName: resource.displayName,
                };
              }

              return resource;
            });
          }
          updatedAppt = ba;
        }
        return ba;
      });
      let queuedAppointments = this.state.queuedAppointments.map((ba) => {
        if (changed.id === ba.id) {
          ba = { ...ba, ...changed };
          updatedAppt = ba;
        }
        return ba;
      });

      if (updatedAppt && !signalR) {
        this._saveAppointment(updatedAppt);
      }

      if (changed.currentStatus === Enums.AppointmentStatus.noShow) {
        bookedAppointments = bookedAppointments.filter(
          (ba) => ba.id !== updatedAppt.id
        );
        queuedAppointments = queuedAppointments.filter(
          (ba) => ba.id !== updatedAppt.id
        );
      }

      this.setState(() => ({
        bookedAppointments: bookedAppointments,
        queuedAppointments: queuedAppointments,
      }));
    }
  };

  _onUpdateAppointmentTypes = (appointmentTypes) => {
    if (appointmentTypes && appointmentTypes.length > 0) {
      this.setState((prevState) => ({
        locationInfo: {
          ...prevState.locationInfo,
          appointmentTypes: [...appointmentTypes],
        },
      }));

      Storage.setItem(Constants.currOnboardingInfoKey, this.state.locationInfo);
    }
  };

  _onUpdateBookedAppointments = (bookedAppointments) => {
    if (bookedAppointments && bookedAppointments.length > 0) {
      this.setState({ bookedAppointments: bookedAppointments });
    }
  };

  _onUpdateLocationConfig = (locationConfig) => {
    if (locationConfig) {
      Utils.formatLocationInfoApptTypes(locationConfig);
      Utils.formatLocationInfoResources(locationConfig);
      Events.emit(
        Constants.Events.locationConfigUpdate,
        cloneDeep(locationConfig)
      );
      this.setState({ locationInfo: { ...locationConfig } });
    }
  };

  // Consume the appointment object API and remformat it to fit with the Scheduler library data model
  _reformatAppointment = (appt) => {
    appt.id = appt.appointmentId;
    appt.isVisible = true;
    appt.created = appt.created
      ? momentLocaleWrapper.utc(appt.created, '(YYYY, MM, DD, HH, mm)').toDate()
      : '';
    appt.startDate = appt.startTime
      ? momentLocaleWrapper(appt.startTime, '(YYYY, MM, DD, HH, mm)').toDate()
      : ''; //(2020, 06, 03, 09, 00)
    appt.endDate = appt.endTime
      ? momentLocaleWrapper(appt.endTime, '(YYYY, MM, DD, HH, mm)').toDate()
      : '';
    appt.title = `${appt.patient.firstName} ${appt.patient.lastName}`;
    appt.resourceId = appt.resource.resourceId;
    return appt;
  };

  _apptHub = null;
  _setupAppointmentSignalRConnection = async () => {
    var url = `${process.env.REACT_APP_APPOINTMENT_HUB}`;
    const { locationId } = this.state.locationInfo;
    const hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => {
          const user = Storage.getItem('cusr');
          const token = user.jwt;
          return token;
        },
      })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 90000, 120000, 300000])
      .build();

    try {
      await hubConnection.start();
      Logger.log('connection started for appt');
    } catch (error) {
      console.error(error);
    }

    try {
      await hubConnection.invoke('AddSelfToGroup', locationId.toString());
      Logger.log('added to appt group');
    } catch (error) {
      console.error(error);
    }

    try {
      hubConnection.onreconnected(async () => {
        Logger.log('reconnection started for appt');
        await hubConnection.invoke('AddSelfToGroup', locationId.toString());
        Logger.log('reconnected to appt group');
      });
    } catch (error) {
      console.error(error);
    }

    hubConnection.on('AppointmentUpdate', (receivedMessage, newAppt) => {
      Logger.log('incoming appt message');

      //get incoming appt data
      const appt = JSON.parse(receivedMessage);
      const appointment = this._reformatAppointment(appt);

      if (newAppt) {
        //call new
        this._onAddAddpointment(appointment, true);
      } else {
        if (appt.currentStatus === Enums.AppointmentStatus.cancelled) {
          //call deleted
          this._onDeleteAppointment(appointment, true);
        } else {
          //call updated, but need to check if new appointment booking
          if (
            this.state.queuedAppointments.some(
              (a) => a.id === appointment.id
            ) &&
            appointment.currentStatus === Enums.AppointmentStatus.scheduled
          ) {
            this._onBookAppointment(appointment, appointment.startDate, true);
          } else {
            this._onUpdateAppointment(appointment, true);
          }
        }
      }
    });

    this._apptHub = hubConnection;
  };

  _locHub = null;
  _setupLocationConfigSignalRConnection = async () => {
    const { locationId } = this.state.locationInfo;
    const url = `${process.env.REACT_APP_LOCATIONCONFIG_HUB}`;
    const hubConnection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: () => {
          const user = Storage.getItem('cusr');
          const token = user.jwt;
          return token;
        },
      })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 90000, 120000, 300000])
      .build();

    try {
      await hubConnection.start();
      Logger.log('connection started for loc config');
    } catch (error) {
      console.error(error);
    }

    try {
      await hubConnection.invoke('AddSelfToGroup', locationId.toString());
      Logger.log('added to loc config group');
    } catch (error) {
      console.error(error);
    }

    try {
      hubConnection.onreconnected(async () => {
        Logger.log('reconnection started for loc config');
        await hubConnection.invoke('AddSelfToGroup', locationId.toString());
        Logger.log('reconnected to loc config group');
      });
    } catch (error) {
      console.error(error);
    }

    hubConnection.on(
      Constants.signalRLocationConfigMessage,
      (receivedMessage) => {
        Logger.log('incoming loc config message');

        const locationConfig = JSON.parse(receivedMessage);

        if (locationConfig) {
          this._onUpdateLocationConfig(locationConfig);
        }
      }
    );

    this._locHub = hubConnection;
  };

  _verifyJwt = async () => {
    const { jwt } = this.state.user;

    if (jwt) {
      try {
        const url = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
        await axios.get(url);

        if (!this.state.user.agreementSigned) {
          window.location.href = '/termsuse';
        }
      } catch {
        this._onToggleLoadingOverlay();
        window.location.href = '/';
        return;
      }
    } else {
      this._onToggleLoadingOverlay();
      window.location.href = '/';
      return;
    }
  };

  _saveAppointment = async (appt) => {
    let savedAppt = null;
    if (appt) {
      const locationResources = this.state.locationInfo.resources;
      let resourceData;
      locationResources.forEach((resource) => {
        if (resource.resourceId === appt.resourceId) {
          resourceData = resource;
        }
      });
      resourceData = resourceData
        ? resourceData
        : Utils.getDefaultResource(locationResources);
      try {
        const url = `${process.env.REACT_APP_CALENDAR_API}/saveAppointment`;
        const data = {
          appointment: {
            ...appt,
            created: momentLocaleWrapper(appt.created).format(
              '(YYYY, MM, DD, HH, mm)'
            ),
            startTime: momentLocaleWrapper(appt.startDate).format(
              '(YYYY, MM, DD, HH, mm)'
            ),
            endTime: momentLocaleWrapper(appt.endDate).format(
              '(YYYY, MM, DD, HH, mm)'
            ),
            resource: {
              resourceId: resourceData.resourceId,
              displayName: resourceData.displayName,
            },
          },
        };

        let res = await axios.post(url, data);

        if (res && res.data) {
          savedAppt = { ...res.data.appointment };
        }
      } catch (error) {
        if (this._shouldLogError(error)) {
          Logger.error(error);
        }
      }
    }

    return savedAppt;
  };

  _deleteAppointmentTypes = (appointmentTypes) => {
    return new Promise(async (resolve) => {
      if (appointmentTypes && appointmentTypes.length > 0) {
        for (let index = 0; index < appointmentTypes.length; ++index) {
          const deletedType = appointmentTypes[index];
          const url =
            this._constructCommonSettingsApiUrl('deleteAppointmentType') +
            `/${deletedType.locationAppointmentTypeId}`;
          const data = {
            appointmentTypes: deletedType,
          };

          await axios.post(url, data);
        }

        resolve();
      }
    });
  };

  _saveAppointmentTypes = (appointmentTypes) => {
    return new Promise(async (resolve) => {
      if (appointmentTypes && appointmentTypes.length > 0) {
        const url = this._constructCommonSettingsApiUrl('saveAppointmentTypes');
        const data = {
          appointmentTypes: appointmentTypes,
        };

        let input = cloneDeep(appointmentTypes);
        const resp = await axios.post(url, data);
        const newAppts = resp.data.map((x) => {
          let matchingOldAppt = input.filter(
            (y) => y.locationAppointmentTypeId === x.locationAppointmentTypeId
          );
          if (!matchingOldAppt || !matchingOldAppt.length) {
            matchingOldAppt = input.filter(
              (y) =>
                y.duration === x.duration && y.displayName === x.displayName
            );
          }
          matchingOldAppt = matchingOldAppt[0];

          const newAppt = {
            ...matchingOldAppt,
            locationAppointmentTypeId: x.locationAppointmentTypeId,
            isEditing: false,
            isNew: false,
          };

          input = input.filter((y) => y !== matchingOldAppt);
          return newAppt;
        });

        this.setState((prevState) => {
          const { locationInfo } = prevState;
          locationInfo.appointmentTypes = newAppts;

          return {
            locationInfo: { ...locationInfo },
          };
        });

        resolve();
      }
    });
  };

  _deleteResource = (resource) => {
    return new Promise(async (resolve) => {
      if (resource && resource.resourceId) {
        const url = `${this._constructCommonSettingsApiUrl('deleteResource')}/${
          resource.resourceId
        }`;
        await axios.post(url);
        resolve();
      }
    });
  };

  _saveResources = (resources) => {
    return new Promise(async (resolve) => {
      if (resources && resources.length > 0) {
        const url = this._constructCommonSettingsApiUrl('saveResources');
        const data = {
          resources: resources,
        };
        let input = cloneDeep(resources);
        const resp = await axios.post(url, data);
        const newResources = resp.data.map((x) => {
          let matchingOldResource = input.filter(
            (y) => y.resourceId === x.resourceId
          );
          if (!matchingOldResource || !matchingOldResource.length) {
            matchingOldResource = input.filter(
              (y) =>
                y.htmlColor === x.htmlColor && y.displayName === x.displayName
            );
          }
          matchingOldResource = matchingOldResource[0];

          const newResource = {
            ...matchingOldResource,
            resourceId: x.resourceId,
            isEditing: false,
            isNew: false,
          };

          input = input.filter((y) => y !== matchingOldResource);
          return newResource;
        });

        this.setState((prevState) => {
          const { locationInfo } = prevState;
          locationInfo.resources = [...newResources];

          return {
            locationInfo: { ...locationInfo },
          };
        });

        resolve();
      }
    });
  };

  _updateResources = async (resources) => {
    this.setState((prevState) => {
      const { locationInfo } = prevState;
      locationInfo.resources = [...resources];

      return {
        locationInfo: { ...locationInfo },
      };
    });
  };

  _saveStoreHours = (storeHours) => {
    return new Promise(async (resolve) => {
      if (storeHours && storeHours.length > 0) {
        const url = this._constructCommonSettingsApiUrl('saveStoreHours');
        const data = {
          storeHours: storeHours,
        };

        await axios.post(url, data);

        this.setState((prevState) => {
          const { locationInfo } = prevState;
          locationInfo.storeHours = [...storeHours];

          return {
            locationInfo: { ...locationInfo },
          };
        });

        resolve();
      }
    });
  };

  _setI18nLanguage = async () => {
    const { languageTag } = this.state.locationInfo.storeInformation;

    await this._contentManagmentService.loadLocalizations(languageTag);

    return this.props.i18n.changeLanguage(languageTag);
  };

  _updateState = (values) => {
    this.setState(() => values);
  };

  /**
   * Renders the context children.
   */
  render() {
    const { isInitialLoad, showLoadingOverlay } = this.state;

    return (
      <DataContext.Provider value={this.state}>
        {!isInitialLoad && this.props.children}
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </DataContext.Provider>
    );
  }
}

export default withTranslation()(DataProvider);
