import axios from 'axios';
import Constants from '../constants';
import Storage from '../storage';

/**
 * Represents the front end API error reporting service.
 */
class ErrorReportingService {
  /**
   * Reports an API error.
   * @param {String} apiRoute The API route that was attempted to access
   * @param {String} errorMessage The error message returned by the API
   * @returns a Promise.
   */
  async reportError(errorMessage, apiRoute) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = process.env.REACT_APP_ERROR_REPORTING;
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const data = {
          apiRoute: apiRoute,
          errorMessage: errorMessage,
          locationId: locationId,
        };

        axios.post(url, data);

        resolve();
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }
}

export default ErrorReportingService;
