/**
 * Represents a wrapper around the console logger.
 */
class Logger {
  static shouldLog =
    process.env.REACT_APP_ENVIRONMENT === 'development' ||
    process.env.REACT_APP_ENVIRONMENT === 'qa';

  /**
   * Logs an item to the console.
   * @param {Object} obj The object to log.
   * @param {Object[]} optionalParams The parameters to log.
   */
  static log(obj, ...optionalParams) {
    if (!this.shouldLog) {
      return;
    } else if (optionalParams && optionalParams.length) {
      console.log(obj, optionalParams);
    } else {
      console.log(obj);
    }
  }

  /**
   * Logs an item to the error console.
   * @param {Object} obj The object to log.
   * @param {Object[]} optionalParams The parameters to log.
   */
  static error(obj, ...optionalParams) {
    if (!this.shouldLog) {
      return;
    } else if (optionalParams && optionalParams.length) {
      console.error(obj, optionalParams);
    } else {
      console.error(obj);
    }
  }
}

// Lock object to prevent modification (true static).
Object.freeze(Logger);

export default Logger;
