import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';
import ErrorReportingService from './errorReportingService';
/**
 * Represents the site configuration service.
 */
class SiteConfigService {
  /**
   * Returns the site configuration.
   * @returns A Promise.
   */
  async getSiteConfig() {
    return new Promise(async (resolve, reject) => {
      try {
        let siteConfig = Storage.getItem(Constants.siteConfig);

        if (siteConfig == null) {
          const url = `${
            process.env.REACT_APP_SETTINGS_API
          }/getSiteConfigByUrl?url=${encodeURI(window.location.href)}`;
          const res = await axios.get(url);
          siteConfig = res?.data;

          Storage.setItem(Constants.siteConfig, siteConfig);
        }

        resolve(siteConfig);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${
            process.env.REACT_APP_SETTINGS_API
          }/getSiteConfigByUrl?url=${encodeURI(window.location.href)}`
        );
        reject(error);
      }
    });
  }
}

export default SiteConfigService;
