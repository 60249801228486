import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import MuiSwitch from '@material-ui/core/Switch';
import MuiTooltip from '@material-ui/core/Tooltip';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import { ClickAwayListener } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Enums from '../../enums';
import Utils from '../../utils';
import Events from '../../events';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import Storage from '../../storage';
import ResourceHours from '../ResourceHours/ResourceHours';
import SettingsService from '../../Services/settingsService';
import VacationDrawer from '../VacationDrawer/VacatationDrawer';
import './Resources.scss';

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Switch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#477ef1',
    },
    '&$checked + $track': {
      backgroundColor: '#477ef1',
    },
  },
  checked: {},
  track: {},
})(MuiSwitch);

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

const eBuilder = new EventBuilder();

/**
 * Represents the calendar resources (lanes).
 */
class Resources extends Component {
  /**
   * Initializes a new instance of the Resources component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    const { locationId, resources, storeHours } = props;

    this.state = {
      areResourcesDirty: false,
      canSaveResources: true,
      canSetupVacation: true,
      currResource: {
        id: -1,
        displayName: '',
        availableHours: [],
      },
      errorModalMessage: '',
      maxResourcesReached: false,
      resources: cloneDeep(resources),
      showSaveAlert: false,
      showErrorModal: false,
      showPublicViewTooltip: false,
      showRemoveResourceModal: false,
      showResNameTooltip: false,
      showResourceHoursModal: false,
    };
    this._maxResources = 10;
    this._locationId = locationId;
    this._initialStoreHours = cloneDeep(storeHours);
    this._settingsService = new SettingsService();
    this._defaultResource = cloneDeep(Utils.getDefaultResource(resources));
    this._defaultMarketHours = cloneDeep(Utils.getDefaultFormattedStoreHours());
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  componentDidMount() {
    this._setupSubscriptions();
  }

  /**
   * Executes when the component has unmounted from the DOM.
   */
  componentWillUnmount() {
    Events.removeAllListeners(Constants.Events.vacationDrawerClose);
  }

  _getResourceItems = () => {
    const { canSetupVacation, resources, showPublicViewTooltip } = this.state;
    const { t } = this.props;

    return resources.map((resource, key) => (
      <tr key={key}>
        <td>
          <input
            className="res__form-input"
            disabled={!resource.isEditing}
            type="text"
            value={
              resource.isDefault &&
              resource.displayName === Constants.defaultResourceDisplayName
                ? t(resource.displayName)
                : resource.displayName
            }
            onChange={(e) =>
              this._onEditResource(resource.id, ['displayName'], e.target.value)
            }
          />
        </td>
        <td>
          <FormControl
            className="res__res-color-cont"
            disabled={!resource.isEditing}
            variant="outlined"
          >
            <Select
              value={resource.htmlColor}
              onChange={(e) =>
                this._onEditResource(resource.id, ['htmlColor'], e.target.value)
              }
            >
              <MenuItem value="#D50000">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#D50000' }}
                ></span>
              </MenuItem>
              <MenuItem value="#F5511F">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#F5511F' }}
                ></span>
              </MenuItem>
              <MenuItem value="#D8A822">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#D8A822' }}
                ></span>
              </MenuItem>
              <MenuItem value="#0C8044">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#0C8044' }}
                ></span>
              </MenuItem>
              <MenuItem value="#049CE5">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#049CE5' }}
                ></span>
              </MenuItem>
              <MenuItem value="#4051B6">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#4051B6' }}
                ></span>
              </MenuItem>
              <MenuItem value="#8E24AA">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#8E24AA' }}
                ></span>
              </MenuItem>
              <MenuItem value="#616161">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#616161' }}
                ></span>
              </MenuItem>
              <MenuItem value="#F015FF">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#F015FF' }}
                ></span>
              </MenuItem>
              <MenuItem value="#81B129">
                <span
                  className="res__res-color"
                  style={{ backgroundColor: '#81B129' }}
                ></span>
              </MenuItem>
            </Select>
          </FormControl>
        </td>
        <td>
          <button
            className="res__button"
            disabled={!resource.isEditing}
            onClick={() => this._onToggleResourceHoursModal(resource)}
          >
            {resource.hasHours ? t('Edit Hours') : t('Add Hours')}
          </button>
        </td>
        <td>
          <button
            className="res__button res__button--vac"
            disabled={!(resource.isEditing && canSetupVacation)}
            onClick={() => this._onSetupVacation(resource)}
          >
            {t('Setup Vacation')}
          </button>
        </td>
        <td>
          <div className="res__actions">
            {!resource.isDefault && (
              <button
                className="res__action"
                onClick={() => this._onMoveResource(key, key - 1)}
              >
                <ArrowUpwardIcon />
              </button>
            )}
            {!resource.isDefault && (
              <button
                className="res__action res__action--move-down"
                onClick={() => this._onMoveResource(key, key + 1)}
              >
                <ArrowUpwardIcon />
              </button>
            )}
            {(resource.isDefault && (
              <button
                className="res__action"
                onClick={() => this._onEnableResource(resource)}
              >
                <CreateIcon />
              </button>
            )) ||
              (!resource.isDefault && (
                <button
                  className="res__action"
                  onClick={() => this._onEnableResource(resource)}
                >
                  <CreateIcon />
                </button>
              ))}
            {!resource.isDefault && (
              <button
                className="res__action"
                onClick={() => this._onToggleRemoveResourceModal(resource)}
              >
                <CancelIcon />
              </button>
            )}
            {!resource.isDefault && (
              <Switch
                checked={resource.publicView}
                inputProps={{ 'aria-label': 'checkbox' }}
                onChange={(e) =>
                  this._onEditResource(
                    resource.id,
                    ['publicView'],
                    e.target.checked
                  )
                }
              />
            )}
            <strong>
              <span>{t('Public View')}</span>&nbsp;&nbsp;
            </strong>
            {key === 0 && (
              <ClickAwayListener onClickAway={this._onClosePublicViewTooltip}>
                <button
                  className="res__info-icon"
                  type="button"
                  onClick={this._onTogglePublicViewTooltip}
                >
                  <Tooltip
                    open={showPublicViewTooltip}
                    placement="top-end"
                    title={t(
                      'Turning this to "ON" (blue state) makes each "Resource Name" visible to the end consumer'
                    )}
                  >
                    <span className="res__info-tooltip">i</span>
                  </Tooltip>
                </button>
              </ClickAwayListener>
            )}
          </div>
        </td>
      </tr>
    ));
  };

  _onAddResource = () => {
    eBuilder
      .withAction(eBuilder.Action.Settings.Click.addResource)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();

    this.setState((prevState) => {
      const { resources } = prevState;
      const closed = -1;
      let newId = 0;
      let newSortOrder = 1;

      if (resources?.length > 0) {
        // Find the max id from the resource ids and increment by 1 and do the same to sort order.
        newId = resources.reduce((a, b) => (a.id > b.id ? a : b)).id + 1;
        newSortOrder =
          resources.reduce((res1, res2) =>
            res1.sortOrder > res2.sortOrder ? res1 : res2
          ).sortOrder + 1;
      }

      const updatedResources = resources.concat({
        id: newId,
        isEditing: true,
        hasHours: false,
        displayName: this.props.t('Resource'),
        htmlColor: '',
        sortOrder: newSortOrder,
        publicView: false,
        isDefault: false,
        numConcurrentAppointments: 0,
        availableHours: [
          {
            dayOfWeekId: 1,
            startTime: this._initialStoreHours[0].startTime,
            breakOutTime: closed,
            breakInTime: closed,
            endTime: this._initialStoreHours[0].endTime,
          },
          {
            dayOfWeekId: 2,
            startTime: this._initialStoreHours[1].startTime,
            breakOutTime: closed,
            breakInTime: closed,
            endTime: this._initialStoreHours[1].endTime,
          },
          {
            dayOfWeekId: 3,
            startTime: this._initialStoreHours[2].startTime,
            breakOutTime: closed,
            breakInTime: closed,
            endTime: this._initialStoreHours[2].endTime,
          },
          {
            dayOfWeekId: 4,
            startTime: this._initialStoreHours[3].startTime,
            breakOutTime: closed,
            breakInTime: closed,
            endTime: this._initialStoreHours[3].endTime,
          },
          {
            dayOfWeekId: 5,
            startTime: this._initialStoreHours[4].startTime,
            breakOutTime: closed,
            breakInTime: closed,
            endTime: this._initialStoreHours[4].endTime,
          },
          {
            dayOfWeekId: 6,
            startTime: this._initialStoreHours[5].startTime,
            breakOutTime: closed,
            breakInTime: closed,
            endTime: this._initialStoreHours[5].endTime,
          },
          {
            dayOfWeekId: 7,
            startTime: this._initialStoreHours[6].startTime,
            breakOutTime: closed,
            breakInTime: closed,
            endTime: this._initialStoreHours[6].endTime,
          },
        ],
      });

      let maxResourcesReached = false;

      if (updatedResources.length >= this._maxResources) {
        maxResourcesReached = true;
      }

      return {
        areResourcesDirty: true,
        canSaveResources: false,
        canSetupVacation: false,
        maxResourcesReached: maxResourcesReached,
        resources: updatedResources,
      };
    });
  };

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  _onCloseResNameTooltip = () => {
    if (this.state.showResNameTooltip) {
      this.setState(() => ({ showResNameTooltip: false }));
    }
  };

  _onClosePublicViewTooltip = () => {
    if (this.state.showPublicViewTooltip) {
      this.setState(() => ({ showPublicViewTooltip: false }));
    }
  };

  _onCloseSaveAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showSaveAlert: false }));
  };

  _onEditResource = (id, path, value) => {
    this.setState((prevState) => {
      const { resources } = prevState;
      let areResourcesDirty = false;
      const updatedResources = resources.map((res) => {
        if (res.id === id) {
          areResourcesDirty = true;
          Utils.update(res, path, value);
        }

        return res;
      });

      const canSaveResources = this._verifyResources(updatedResources);

      return {
        areResourcesDirty: areResourcesDirty,
        canSaveResources: canSaveResources,
        resources: updatedResources,
      };
    });
  };

  _onEnableResource = (resource) => {
    if (resource) {
      let editedResource;
      this.setState((prevState) => {
        const updatedResources = prevState.resources.map((res) => {
          if (res.id === resource.id) {
            res.isEditing = true;
            editedResource = res;
          }

          return res;
        });

        const label = `${editedResource.displayName};${editedResource.htmlColor}`;

        eBuilder
          .withAction(eBuilder.Action.Settings.Click.editResource)
          .withLabel(eBuilder.Label.alternateLabel, label)
          .post();

        eBuilder.withLabel(eBuilder.Label.practiceIdentifier).post();

        return {
          areResourcesDirty: true,
          resources: updatedResources,
        };
      });
    }
  };

  _onMoveResource = (currIndex, newIndex) => {
    let resources = cloneDeep(this.state.resources);

    // Other resources are not able to be in position 0.
    // Only the default resource is allowed and is fixed.
    // No need to cause a re-render if a resources is
    // attempting to move to position 0.
    if (newIndex >= 1 && newIndex < resources.length) {
      this.setState(() => {
        const oldResource = resources.splice(currIndex, 1)[0];

        resources.splice(newIndex, 0, oldResource);
        // Sort the resources by their order.
        const updatedResources = resources.map((res, index) => {
          res.sortOrder = index;
          return res;
        });

        const canSaveResources = this._verifyResources(updatedResources);

        return {
          areResourcesDirty: true,
          canSaveResources: canSaveResources,
          resources: updatedResources,
        };
      });
    }
  };

  _onRemoveResource = async (resource) => {
    if (resource) {
      let canRemove = false;

      // If this resource has an actual id, then we can delete.
      if (resource.resourceId && resource.resourceId > 0) {
        try {
          this.setState(() => ({ showRemoveResourceModal: false }));

          await this._settingsService.deleteResource({
            resourceId: resource.resourceId,
          });

          canRemove = true;
        } catch (error) {
          this._toggleErrorModal(
            error,
            true,
            this.props.t(
              'There was an unexpected issue with removing the resource'
            )
          );
        }
      } else {
        // This is a newly added resource that has not been saved yet, so we can just remove it from state.
        canRemove = true;
      }

      if (canRemove) {
        this.setState((prevState) => {
          const { resources } = prevState;
          let updatedResources = resources.filter(
            (item) => item.resourceId !== resource.resourceId
          );
          // if we have a couple of resources that are not "created", we'll match a few of them.
          if (updatedResources.length !== resources.length - 1) {
            updatedResources = resources.filter(
              (item) => item.id !== resource.id
            );
          }

          let maxResourcesReached = false;

          if (updatedResources.length === this._maxResources) {
            maxResourcesReached = true;
          }

          const canSaveResources = this._verifyResources(updatedResources);

          return {
            canSaveResources: canSaveResources,
            maxResourcesReached: maxResourcesReached,
            resources: updatedResources,
            showRemoveResourceModal: false,
          };
        });
      }
    }
  };

  /**
   * Executes when the break block changes for a day of the week for a resource.
   * @param {Number} resourceId The resource id.
   * @param {Number} dayOfWeekId The day of the week id.
   * @param {Number} breakOutTimeBlock The start time block.
   * @param {Number} breakInTimeBlock The end time block.
   */
  onResourceBreakBlockChange = (
    resourceId,
    dayOfWeekId,
    breakOutTimeBlock,
    breakInTimeBlock
  ) => {
    this.setState((prevState) => {
      let canSave = true;
      const updatedResources = prevState.resources.map((resource) => {
        if (resource.id === resourceId) {
          resource.availableHours = resource.availableHours.map((hour) => {
            if (hour.dayOfWeekId === dayOfWeekId) {
              if (breakOutTimeBlock) {
                hour.breakOutTime = breakOutTimeBlock;
              } else {
                hour.breakInTime = breakInTimeBlock;
              }
            }

            if (
              hour.breakOutTime >= hour.breakInTime &&
              hour.breakOutTime > 0 &&
              hour.breakInTime > 0
            ) {
              canSave = false;
              hour.timeBlockError = this.props.t(
                'Break Out must be before Break In'
              );
            } else {
              hour.timeBlockError = '';
            }

            return hour;
          });
        }

        return resource;
      });

      return {
        areResourcesDirty: true,
        canSaveResources: canSave,
        resources: updatedResources,
      };
    });
  };

  /**
   * Executes when the day of the week changes for a location.
   * @param {Number} resourceId The resource id.
   * @param {Number} dayOfWeekId The day of week id.
   * @param {Boolean} isOpen A value that determines whether the day is open for the location.
   */
  onResourceDayOfWeekChange = (resourceId, dayOfWeekId, isOpen) => {
    this.setState((prevState) => {
      let canSave = true;
      const updatedResources = prevState.resources.map((resource) => {
        if (resource.id === resourceId) {
          resource.availableHours = resource.availableHours.map((hour) => {
            if (hour.dayOfWeekId === dayOfWeekId) {
              const closed = -1;

              if (isOpen) {
                const defaultMarketHoursForDay = this._defaultMarketHours.find(
                  (dmh) => dmh.dayOfWeekId === dayOfWeekId
                );

                if (defaultMarketHoursForDay) {
                  hour.startTime = defaultMarketHoursForDay.startTime;
                  hour.breakOutTime = closed;
                  hour.breakInTime = closed;
                  hour.endTime = defaultMarketHoursForDay.endTime;
                }
              } else {
                hour.startTime = closed;
                hour.breakOutTime = closed;
                hour.breakInTime = closed;
                hour.endTime = closed;
                hour.timeBlockError = '';
              }
            }

            if (hour.timeBlockError) {
              canSave = false;
            }

            return hour;
          });
        }

        return resource;
      });

      return {
        areResourcesDirty: true,
        canSaveResources: canSave,
        resources: updatedResources,
      };
    });
  };

  /**
   * Executes when the time block changes for a day of the week for a resource.
   * @param {Number} resourceId The resource id.
   * @param {Number} dayOfWeekId The day of the week id.
   * @param {Number} startTime The start time block.
   * @param {Number} endTime The end time block.
   */
  onResourceTimeBlockChange = (resourceId, dayOfWeekId, startTime, endTime) => {
    this.setState((prevState) => {
      let canSave = true;
      const updatedResources = prevState.resources.map((resource) => {
        if (resource.id === resourceId) {
          resource.availableHours = resource.availableHours.map((hour) => {
            if (hour.dayOfWeekId === dayOfWeekId) {
              if (startTime) {
                hour.startTime = startTime;
              } else {
                hour.endTime = endTime;
              }
            }

            if (
              hour.startTime >= hour.endTime &&
              hour.startTime > 0 &&
              hour.endTime > 0
            ) {
              canSave = false;
              hour.timeBlockError = this.props.t('Start must be before Finish');
            } else {
              hour.timeBlockError = '';
            }

            return hour;
          });
        }

        return resource;
      });

      return {
        areResourcesDirty: true,
        canSaveResources: canSave,
        resources: updatedResources,
      };
    });
  };

  _onSaveResources = async (resources) => {
    if (resources && resources.length > 0) {
      try {
        eBuilder
          .withAction(eBuilder.Action.Settings.Click.saveResources)
          .withLabel(eBuilder.Label.practiceIdentifier)
          .post();

        this.setState(() => ({ canSaveResources: false }));

        const updatedResources = await this._settingsService.saveResources({
          resources: resources,
        });

        Utils.formatResources(updatedResources);

        const { onResourcesChange } = this.props;
        onResourcesChange && onResourcesChange(updatedResources);

        this.setState(() => ({
          areResourcesDirty: false,
          canSetupVacation: true,
          resources: updatedResources,
          showSaveAlert: true,
        }));
      } catch (error) {
        this._onShowErrorModal(
          error,
          this.props.t('There was an unexpected issue with saving resources.')
        );
      }
    }
  };

  _onSetupVacation = (resource) => {
    if (resource) {
      this.setState(() => ({ currResource: resource }));

      const locationId = this._locationId;

      Events.emit(Constants.Events.vacationDrawerOpen, {
        locationId,
        resource,
      });
    }
  };

  _onShowErrorModal = (error, message) => {
    if (
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      console.error(error);

      this.setState(() => ({
        showErrorModal: true,
        errorModalMessage: message,
      }));
    }
  };

  _onTogglePublicViewTooltip = () => {
    this.setState((prevState) => ({
      showPublicViewTooltip: !prevState.showPublicViewTooltip,
    }));
  };

  _onToggleRemoveResourceModal = (resource) => {
    if (resource) {
      this.setState((prevState) => ({
        currResource: resource,
        showRemoveResourceModal: !prevState.showRemoveResourceModal,
      }));
    }
  };

  _onToggleResourceHoursModal = (resource) => {
    if (resource) {
      this.setState((prevState) => ({
        currResource: resource,
        showResourceHoursModal: !prevState.showResourceHoursModal,
      }));
    }
  };

  _onToggleResNameTooltip = () => {
    this.setState((prevState) => ({
      showResNameTooltip: !prevState.showResNameTooltip,
    }));
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.vacationDrawerClose, (resource) => {
      this.setState((prevState) => {
        const updatedResources = prevState.resources.map((res) => {
          if (res.id === resource.id) {
            res.isEditing = false;
          }

          return res;
        });

        const { onResourcesChange } = this.props;
        onResourcesChange && onResourcesChange(updatedResources);

        return {
          areResourcesDirty: false,
          resources: updatedResources,
        };
      });
    });

    Events.on(Constants.Events.vacationItemAdded, (args) => {
      const resource = args[0];
      const vacation = args[1];

      this.setState((prevState) => {
        const updatedResources = prevState.resources.map((res) => {
          if (
            res.id === resource.id &&
            !res.vacations.some((vac) => vac.vacationId === vacation.vacationId)
          ) {
            res.vacations = res.vacations.concat(vacation);
          } else {
            res.vacations = res.vacations.map((vac) => {
              let updatedVacation = vac;

              if (vac.vacationId === vacation.vacationId) {
                updatedVacation = { ...vacation };
              }

              return updatedVacation;
            });
          }

          return res;
        });

        const { onResourcesChange } = this.props;
        onResourcesChange && onResourcesChange(updatedResources);

        return {
          resources: updatedResources,
        };
      });
    });

    Events.on(Constants.Events.vacationItemRemoved, (args) => {
      const resource = args[0];
      const vacation = args[1];

      this.setState((prevState) => {
        const updatedResources = prevState.resources.map((res) => {
          if (res.id === resource.id) {
            res.vacations = res.vacations.filter(
              (v) => v.vacationId !== vacation.vacationId
            );
          }

          return res;
        });

        const { onResourcesChange } = this.props;
        onResourcesChange && onResourcesChange(updatedResources);

        return {
          resources: updatedResources,
        };
      });
    });
  };

  _verifyResources = (resources) => {
    let canSaveResources = true;

    if (resources?.length > 0) {
      for (let index = 0; index < resources.length; ++index) {
        const res = resources[index];

        if (!res.displayName || !res.htmlColor) {
          canSaveResources = false;
          break;
        }

        if (
          resources.find(
            (r) =>
              r.id !== res.id &&
              (r.displayName === res.displayName ||
                r.htmlColor === res.htmlColor)
          )
        ) {
          canSaveResources = false;
          break;
        }
      }
    }

    return canSaveResources;
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      areResourcesDirty,
      canSaveResources,
      currResource,
      errorModalMessage,
      maxResourcesReached,
      resources,
      showErrorModal,
      showRemoveResourceModal,
      showResNameTooltip,
      showResourceHoursModal,
      showSaveAlert,
    } = this.state;
    const { showSave, t } = this.props;
    const resourceItems = this._getResourceItems();

    return (
      <div className="res">
        <div className="res__content">
          <table className="res__table">
            <thead>
              <tr>
                <th>
                  <div>
                    {t('Resource Name')}
                    <ClickAwayListener
                      onClickAway={this._onCloseResNameTooltip}
                    >
                      <button
                        className="res__info-icon"
                        type="button"
                        onClick={this._onToggleResNameTooltip}
                      >
                        <Tooltip
                          open={showResNameTooltip}
                          placement="top-end"
                          title={t(
                            'Resource names can be defined at your discretion. Examples include: staff member, service room, equipment/machine'
                          )}
                        >
                          <span className="res__info-tooltip">i</span>
                        </Tooltip>
                      </button>
                    </ClickAwayListener>
                  </div>
                </th>
                <th>{t('Color')}</th>
                <th>{t('Hours of Operation')}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>{resourceItems}</tbody>
          </table>
        </div>
        <div className="res__footer">
          <button
            className="res__add"
            disabled={maxResourcesReached}
            onClick={this._onAddResource}
          >
            <AddIcon />
            &nbsp;{t('Add')}
          </button>
          {showSave && (
            <button
              disabled={!(canSaveResources && areResourcesDirty)}
              className="appt-types__save"
              onClick={() => this._onSaveResources(resources)}
            >
              {t('Save')}
            </button>
          )}
        </div>
        <VacationDrawer />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showSaveAlert}
          onClose={this._onCloseSaveAlert}
        >
          <Alert
            className="admin-settings__alert"
            severity="success"
            onClose={this._onCloseSaveAlert}
          >
            {t('Saved successfully')}
          </Alert>
        </Snackbar>
        <Dialog open={showRemoveResourceModal}>
          <DialogTitle>{t('Confirm')}</DialogTitle>
          <DialogContent>
            {Utils.isTurkeyMarket(Storage.getItem(Constants.langTag)) ? (
              <p className="appt-types__modal-body">
                <span>
                  {t(
                    'Deleting resource will transfer all appointments. Do you want to proceed?'
                  )}
                </span>
              </p>
            ) : (
              <p className="appt-types__modal-body">
                <span>
                  {t('Deleting')}
                  {` ${currResource.displayName} `}
                  {t('will transfer all appointments to')}
                  {` ${t(this._defaultResource.displayName)}. `}
                  {t('Do you want to proceed?')}
                </span>
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <button
              className="appt-types__modal-button appt-types__modal-button--cancel"
              onClick={() => this._onToggleRemoveResourceModal(currResource)}
            >
              {t('Cancel')}
            </button>
            <button
              className="appt-types__modal-button appt-types__modal-button--confirm"
              onClick={() => this._onRemoveResource(currResource)}
            >
              {t('Remove')}
            </button>
          </DialogActions>
        </Dialog>
        <Dialog open={showResourceHoursModal} maxWidth={false}>
          <DialogTitle disableTypography>
            <div className="res__modal-title">
              <h3 className="res__modal-h3">{t('Hours of Operation')}</h3>
              <div>
                <span className="res__res-label">{t('Resource Name')}:</span>
                &nbsp;&nbsp;
                <span className="res__res-name">
                  {currResource.displayName}
                </span>
                <button
                  className="res__modal-close"
                  onClick={() => this._onToggleResourceHoursModal(currResource)}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            <ResourceHours
              resourceId={currResource.id}
              availableHours={currResource.availableHours}
              onResourceDayOfWeekChange={this.onResourceDayOfWeekChange}
              onResourceBreakBlockChange={this.onResourceBreakBlockChange}
              onResourceTimeBlockChange={this.onResourceTimeBlockChange}
            />
          </DialogContent>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="admin-settings__modal-btn"
              onClick={this._onCloseErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Resources.propTypes = {
  locationId: PropTypes.number.isRequired,
  resources: PropTypes.array.isRequired,
  storeHours: PropTypes.array.isRequired,
  showSave: PropTypes.bool,
  onResourcesChange: PropTypes.func,
};

Resources.defaultProps = {
  showSave: true,
};

export default withTranslation()(Resources);
