import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CancelledIconPng from '../../Assets/Images/PerformanceDashboard/Cancelled-Icon.png';
import NoShowIconPng from '../../Assets/Images/PerformanceDashboard/No-Show-Icon.png';
import TotalApptsIconPng from '../../Assets/Images/PerformanceDashboard/Total-Appts-Icon.png';
import KpiSummary from '../../Components/PerformanceDashboard/KpiSummary';
import ServiceTypeSummary from '../../Components/PerformanceDashboard/ServiceTypeSummary';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import DashboardService from '../../Services/dashboardService';
import AppointmentDetails from './AppointmentDetails';
import Constants from '../../constants';
import Storage from '../../storage';
import Events from '../../events';
import './Appointments.scss';

/**
 * Represents the Appointmnet Performance Dashboard.
 * @param {Object} props The component properties.
 */
class Appointments extends Component {
  /**
   * Initializes a new instance of the Appointments component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);
    const firstOfTheMonth = moment().startOf('month').toDate();
    const now = moment().startOf('day').toDate();
    const locId = Storage.getItem(Constants.currLocIdKey);

    this.state = {
      numNoShows: 0,
      numCanceled: 0,
      totalAppointments: 0,
      locationId: locId,
      start: firstOfTheMonth,
      end: now,
      appointmentCountsByType: [],
    };
    this._dashboardService = new DashboardService();
  }

  async componentDidMount() {
    const { locationId, start, end } = this.state;
    this._refreshKpis({
      locationId: locationId,
      start: start,
      end: end,
    });
    this._setupSubscriptions();
  }

  _setupSubscriptions = () => {
    Events.on(Constants.Events.noShowDashboardClicked, async () => {
      this.setState({
        currentlySelectedKPIEvent: Constants.Events.noShowDashboardClicked,
      });
    });

    Events.on(Constants.Events.cancelledDashboardClicked, async () => {
      this.setState({
        currentlySelectedKPIEvent: Constants.Events.cancelledDashboardClicked,
      });
    });

    Events.on(Constants.Events.totalsDashboardClicked, async () => {
      this.setState({
        currentlySelectedKPIEvent: Constants.Events.totalsDashboardClicked,
      });
    });

    Events.on(Constants.Events.typesDashboardClicked, async (params) => {
      this.setState({
        currentlySelectedKPIEvent: Constants.Events.typesDashboardClicked,
      });
    });
  };

  async _refreshKpis(params) {
    const kpiSummaryData = await this._dashboardService.getDashboardSummary(
      params
    );
    Events.emit(Constants.Events.dashboardDateChange);
    this.setState({
      kpiSummaryData: kpiSummaryData,
      start: params.start,
      end: params.end,
      numNoShows: kpiSummaryData.numNoShows,
      numCanceled: kpiSummaryData.numCanceled,
      totalAppointments: kpiSummaryData.totalAppointments,
      appointmentCountsByType: kpiSummaryData.appointmentCountsByType,
    });
  }

  /**
   * Renders the component.
   */
  render() {
    const { t } = this.props;
    const {
      numNoShows,
      numCanceled,
      totalAppointments,
      appointmentCountsByType,
      start,
      end,
      locationId,
      currentlySelectedKPIEvent,
    } = this.state;

    return (
      <div className="appointment-dashboard">
        <div className="appointment-dashboard__heading">
          <h2>{t('Appointment Performance')}</h2>
          <div className="appointment-dashboard__date-range">
            {t('Date Range')}
            <MuiPickersUtilsProvider
              libInstance={momentLocaleWrapper}
              utils={MomentUtils}
            >
              <DatePicker
                cancelLabel={t('Cancel')}
                className="appointment-dashboard__date"
                disableToolbar
                format="ddd, L"
                id="start-date"
                okLabel={t('OK')}
                required
                value={start}
                onChange={(date) =>
                  this._refreshKpis({
                    locationId: locationId,
                    start: date.toDate(),
                    end: end,
                  })
                }
              />
            </MuiPickersUtilsProvider>
            {t('TO')}
            <MuiPickersUtilsProvider
              libInstance={momentLocaleWrapper}
              utils={MomentUtils}
            >
              <DatePicker
                cancelLabel={t('Cancel')}
                className="appointment-dashboard__date"
                disableToolbar
                format="ddd, L"
                id="end-date"
                okLabel={t('OK')}
                required
                value={end}
                onChange={(date) =>
                  this._refreshKpis({
                    locationId: locationId,
                    start: start,
                    end: date.toDate(),
                  })
                }
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className="appointment-dashboard__kpis">
          <KpiSummary
            iconSrc={NoShowIconPng}
            value={numNoShows}
            detailsEvent={Constants.Events.noShowDashboardClicked}
            description={t('Appointment No-shows')}
            isActive={(
              Constants.Events.noShowDashboardClicked ===
              currentlySelectedKPIEvent
            ).toString()}
          ></KpiSummary>
          <KpiSummary
            iconSrc={CancelledIconPng}
            value={numCanceled}
            detailsEvent={Constants.Events.cancelledDashboardClicked}
            description={t('Appointments Cancelled')}
            isActive={(
              Constants.Events.cancelledDashboardClicked ===
              currentlySelectedKPIEvent
            ).toString()}
          ></KpiSummary>
          <KpiSummary
            iconSrc={TotalApptsIconPng}
            value={totalAppointments}
            detailsEvent={Constants.Events.totalsDashboardClicked}
            description={t('Total Appointments')}
            isActive={(
              Constants.Events.totalsDashboardClicked ===
              currentlySelectedKPIEvent
            ).toString()}
          ></KpiSummary>
          <ServiceTypeSummary
            totalAppointments={totalAppointments}
            appointmentCountsByType={appointmentCountsByType}
            description={t('Appointments By Service Type')}
          ></ServiceTypeSummary>
        </div>
        <AppointmentDetails
          locationId={locationId}
          start={start}
          end={end}
          numNoShows={numNoShows}
          numCanceled={numCanceled}
          totalAppointments={totalAppointments}
        />
      </div>
    );
  }
}

export default withTranslation()(Appointments);
