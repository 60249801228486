import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Constants from '../../constants';
import Events from '../../events';
import MuiIconButton from '@material-ui/core/IconButton';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import './ServiceTypeSummary.scss';

const IconButton = withStyles({
  root: {
    color: '#000',
    padding: '.1em',
    borderRadius: '5px',
    borderStyle: 'solid',
    borderColor: '#ccc',
    borderWidth: '1px',
    '&:hover': {
      backgroundColor: '#d9f3ff',
    },
  },
})(MuiIconButton);

class ServiceTypeSummary extends Component {
  /**
   * Initializes a new instance of the ServiceTypeSummary component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      appointmentCountsByType: props.appointmentCountsByType,
      totalAppointments: props.totalAppointments,
    };
  }

  async componentDidMount() {
    this._setupSubscriptions();
  }

  _setupSubscriptions = () => {
    Events.on(Constants.Events.noShowDashboardClicked, async () => {
      this.setState(() => ({ activeAppointmentTypeId: null }));
    });

    Events.on(Constants.Events.cancelledDashboardClicked, async () => {
      this.setState(() => ({ activeAppointmentTypeId: null }));
    });

    Events.on(Constants.Events.totalsDashboardClicked, async () => {
      this.setState(() => ({ activeAppointmentTypeId: null }));
    });
  };

  _getServiceTypeItems = (appointmentCountsByType) => {
    if (appointmentCountsByType.length > 0) {
      appointmentCountsByType.sort(
        (a, b) =>
          parseFloat(b.appointmentCount) - parseFloat(a.appointmentCount)
      );

      return appointmentCountsByType.map((appointmentTypeInfo, key) => (
        <div key={key} className="service-type-summary__container">
          <div className="service-type-summary__container--left">
            <div className="service-type-summary__typename">
              {appointmentTypeInfo.appointmentTypeName}
            </div>
          </div>
          <div className="service-type-summary__container--center">
            <div className="service-type-summary__bars">
              <div
                className="service-type-summary__bars--item"
                style={{
                  width:
                    this.__getPercentOfTotal(
                      appointmentTypeInfo.appointmentCount,
                      this.props.totalAppointments
                    ) + '%',
                }}
                data-name={appointmentTypeInfo.appointmentCount}
              ></div>
            </div>
          </div>
          <div
            className={
              'service-type-summary__container--right' +
              (this.state.activeAppointmentTypeId ===
              appointmentTypeInfo.appointmentTypeId
                ? ' active'
                : '')
            }
          >
            <IconButton
              onClick={() =>
                this.__onAppointmentTypeItemClick(
                  appointmentTypeInfo.appointmentTypeId,
                  appointmentTypeInfo.appointmentCount,
                  appointmentTypeInfo.appointmentTypeName
                )
              }
            >
              <RemoveRedEyeIcon />
            </IconButton>
          </div>
        </div>
      ));
    }
  };

  __getPercentOfTotal = (appointmentCount, totalAppointments) => {
    if (totalAppointments === 0) {
      return 0;
    } else {
      return (appointmentCount / totalAppointments) * 100;
    }
  };

  __onAppointmentTypeItemClick = (
    appointmentTypeId,
    appointmentCount,
    appointmentTypeName
  ) => {
    this.setState(() => ({ activeAppointmentTypeId: appointmentTypeId }));
    Events.emit(Constants.Events.typesDashboardClicked, {
      appointmentTypeId,
      appointmentCount,
      appointmentTypeName,
    });
  };

  /**
   * Renders the component.
   */
  render() {
    const { t } = this.props;
    const serviceTypeItems = this._getServiceTypeItems(
      this.props.appointmentCountsByType
    );

    return (
      <div className="service-type-summary">
        <div className="service-type-summary__title">
          {this.props.description}
        </div>
        {serviceTypeItems}
      </div>
    );
  }
}

ServiceTypeSummary.propTypes = {
  totalAppointments: PropTypes.number.isRequired,
  appointmentCountsByType: PropTypes.array.isRequired,
};

export default withTranslation()(ServiceTypeSummary);
