import React, { Component } from 'react';
import MuiSelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { GroupingPanel } from '@devexpress/dx-react-scheduler-material-ui';
import Events from '../../events';
import Session from '../../session';
import Constants from '../../constants';
import './CalendarGroupingCell.scss';

const Select = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
  },
})(MuiSelect);

/**
 * Represents a calendar grouping cell for a swimlane.
 * @param {Object} props The component properties.
 */
class CalendarGroupingCell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resources: [],
    };
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  componentDidMount() {
    const resources = Session.getItem(Constants.currResources);
    const anySelected = resources?.some((res) => res.isSelected);
    let updatedResources = resources;

    // If not resources are already selected, then lets choose the default one.
    if (!anySelected) {
      updatedResources = resources?.map((res) => {
        if (res.isDefault) {
          res.isSelected = true;
        }

        return res;
      });
    }

    this.setState(() => ({ resources: updatedResources }));

    this._setupSubscriptions();
  }

  /**
   * Executes when the component is unmounted from the DOM.
   */
  componentWillUnmount() {
    Events.removeListener(Constants.Events.resourcesUpdated);
  }

  _onResourceChange = (resourceId) => {
    Events.emit(Constants.Events.resourceChange, resourceId);
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.resourcesUpdated, (updatedResources) => {
      this.setState(() => ({ resources: updatedResources }));
    });
  };

  /**
   * Renders the component.
   */
  render() {
    const { resources } = this.state;
    const { group, ...restProps } = this.props;
    const resource = resources.find((res) => res.resourceId === group.id);
    const selectedResource = resources.find((res) => res.isSelected);
    const resourceItems = resources.map((res, key) => (
      <MenuItem key={key} value={res.resourceId}>
        {res.displayName}
        <span
          className="cal-group-cell__res-color"
          style={{ backgroundColor: `${res.htmlColor}` }}
        ></span>
      </MenuItem>
    ));

    return (
      <GroupingPanel.Cell
        className="cal-group-cell"
        group={group}
        {...restProps}
      >
        <span
          className="cal-group-cell__res-color cal-group-cell__res-color--desktop"
          style={{ backgroundColor: `${resource?.htmlColor}` }}
        ></span>
        <FormControl
          className="cal-group-cell__resources"
          size="small"
          variant="outlined"
        >
          <Select
            value={selectedResource ? selectedResource.resourceId : ''}
            variant="outlined"
            onChange={(e) => this._onResourceChange(parseInt(e.target.value))}
          >
            {resourceItems}
          </Select>
        </FormControl>
      </GroupingPanel.Cell>
    );
  }
}

export default CalendarGroupingCell;
