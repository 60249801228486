import axios from 'axios';
import Session from '../session';
import Constants from '../constants';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the content management service.
 */
class ContentManagementService {
  /**
   * Loads the translation url into memory.
   * @param {String} langTag The language tag.
   */
  async loadLocalizations(langTag) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}`;
        const res = await axios.get(url);
        Session.setItem(
          Constants.currTranslationUrlKey,
          res?.data?.translation
        );

        resolve();
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the termsAndConditions url.
   * @param {String} langTag The language tag.
   */
  async getTermsAndConditions(langTag, stellestContent = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}&stellestContent=${stellestContent}`;
        const res = await axios.get(url);
        resolve(res?.data?.termsAndConditions);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}&stellestContent=${stellestContent}`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the privacy url.
   * @param {String} langTag The language tag.
   */
  async getPrivacy(langTag) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}`;
        const res = await axios.get(url);
        resolve(res?.data?.privacy);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CONTENT_API}/getLocalizations?languageTag=${langTag}`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the phone country code for the provided country code.
   * @param {String} countryCode The country code.
   * @returns A Promise.
   */
  async getPhoneCountryCode(countryCode) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getPhoneCountryCode`;
        const res = await axios.post(url, { countryCode: countryCode });

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CONTENT_API}/getPhoneCountryCode`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the formatted phone for the provided country code.
   * @param {String} phoneNumber The phone number.
   * @param {String} countryCode The country code.
   * @param {Boolean} includePhoneCountryCode Include country code in return?
   * @returns A Promise.
   */
  async getFormattedPhoneNumber(
    phoneNumber,
    countryCode,
    includePhoneCountryCode = true
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CONTENT_API}/getFormattedPhoneNumber`;
        const res = await axios.post(url, {
          phoneNumber: phoneNumber,
          countryCode: countryCode,
          includePhoneCountryCode: includePhoneCountryCode,
        });

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CONTENT_API}/getFormattedPhoneNumber`
        );
        reject(error);
      }
    });
  }
}

export default ContentManagementService;
