import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import MenuItem from '@material-ui/core/MenuItem';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Constants from '../../constants';
import Enums from '../../enums';
import Utils from '../../utils';
import EventBuilder from '../../eventBuilder';
import SettingsService from '../../Services/settingsService';
import Storage from '../../storage';
import './AppointmentTypes.scss';

const eBuilder = new EventBuilder();
const SearchTooltip = withStyles((theme) => ({
  arrow: {
    color: 'lightgrey',
  },
  tooltip: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    color: 'black',
    fontSize: 16,
    padding: 5,
  },
}))(Tooltip);

/**
 * Represents the appointment types component.
 */
class AppointmentTypes extends Component {
  /**
   * Initializes a new instance of the AppointmentTypes component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      appointmentTypes: cloneDeep(props.appointmentTypes),
      areApptTypesDirty: false,
      canSaveApptTypes: true,
      currApptType: {
        displayName: '',
      },
      errorModalMessage: '',
      maxApptTypesReached: false,
      showErrorModal: false,
      showRemoveApptTypeModal: false,
      showSaveAlert: false,
    };

    this._showStellest = this.props.locationSettings
      ? this.props.locationSettings.find(
          (e) =>
            e.displayName ===
            Constants.LocationSettings.settingIsStellestEnabled
        ).settingValue
      : false;
    if (this._showStellest === true) {
      this._maxApptTypes = 11;
    } else {
      this._maxApptTypes = 10;
    }

    this._settingsService = new SettingsService();
  }

  _getAppointmentTypeItems = () => {
    const { isOnboarded, t } = this.props;

    let retval = this.state.appointmentTypes.map((at, key) => {
      if (this._showStellest && at.isStellest) {
        return (
          <tr key={key}>
            <td>
              <input
                className="appt-types__name"
                disabled={!at.isEditing}
                type="text"
                value={t(at.displayName)}
                onChange={(e) => {
                  this._onEditAppointmentType(
                    at.id,
                    ['displayName'],
                    e.target.value
                  );
                }}
              />
            </td>
          </tr>
        );
      }
      return (
        <tr key={key}>
          <td>
            <input
              className="appt-types__name"
              disabled={!at.isEditing}
              type="text"
              value={at.displayName}
              onChange={(e) => {
                this._onEditAppointmentType(
                  at.id,
                  ['displayName'],
                  e.target.value
                );
              }}
            />
          </td>
          <td>
            <FormControl
              className="appt-types__interval"
              disabled={!at.isEditing}
              variant="outlined"
            >
              <Select
                value={at.duration}
                onChange={(e) => {
                  this._onEditAppointmentType(
                    at.id,
                    ['duration'],
                    parseInt(e.target.value)
                  );
                }}
              >
                <MenuItem value="15">15 {t('Minutes')}</MenuItem>
                <MenuItem value="30">30 {t('Minutes')}</MenuItem>
                <MenuItem value="45">45 {t('Minutes')}</MenuItem>
                <MenuItem value="60">60 {t('Minutes')}</MenuItem>
              </Select>
            </FormControl>
          </td>
          <td>
            <FormControl
              className="appt-types__interval"
              disabled={!at.isEditing}
              variant="outlined"
            >
              <Select
                value={at.buffer}
                onChange={(e) => {
                  this._onEditAppointmentType(
                    at.id,
                    ['buffer'],
                    parseInt(e.target.value)
                  );
                }}
              >
                <MenuItem value="0">{t('0 hours')}</MenuItem>
                <MenuItem value="3">{t('3 hours')}</MenuItem>
                <MenuItem value="6">{t('6 hours')}</MenuItem>
                <MenuItem value="12">{t('12 hours')}</MenuItem>
                <MenuItem value="24">{t('24 hours')}</MenuItem>
              </Select>
            </FormControl>
          </td>
          {!isOnboarded && (
            <td>
              <div className="appt-types__actions">
                {!isOnboarded && (
                  <button
                    className="appt-types__action"
                    onClick={() => this._onMoveAppointmentType(key, key - 1)}
                  >
                    <ArrowUpwardIcon />
                  </button>
                )}
                {!isOnboarded && (
                  <button
                    className="appt-types__action appt-types__action--move-down"
                    onClick={() => this._onMoveAppointmentType(key, key + 1)}
                  >
                    <ArrowUpwardIcon />
                  </button>
                )}
                {!at.isNew && (
                  <button
                    className="appt-types__action"
                    onClick={() => this._onEnableAppointmentType(at)}
                  >
                    <CreateIcon />
                  </button>
                )}
                <button
                  className="appt-types__action"
                  onClick={() => this._onToggleRemoveAppointmentTypeModal(at)}
                >
                  <CancelIcon />
                </button>
              </div>
            </td>
          )}
        </tr>
      );
    });

    return retval;
  };

  _onAddAppointmentType = () => {
    eBuilder
      .withAction(eBuilder.Action.Settings.Click.addAppointmentType)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();

    this.setState((prevState) => {
      const { appointmentTypes } = prevState;
      let newId = 0;
      let newSortOrder = 1;

      if (appointmentTypes?.length > 0) {
        // Find the max id from the appointment types id and increment by 1 and do the same for sort order.
        newId =
          prevState.appointmentTypes.reduce((a, b) => (a.id > b.id ? a : b))
            .id + 1;
        newSortOrder =
          appointmentTypes.reduce((apptType1, apptType2) =>
            apptType1.sortOrder > apptType2.sortOrder ? apptType1 : apptType2
          ).sortOrder + 1;
      }

      const updatedAppointmentTypes = appointmentTypes.concat({
        displayName: this.props.t('Service Type'),
        duration: 15,
        buffer: 0,
        id: newId,
        isChecked: true,
        isEditing: true,
        isNew: true,
        sortOrder: newSortOrder,
      });

      let maxApptTypesReached = false;

      if (updatedAppointmentTypes.length >= this._maxApptTypes) {
        maxApptTypesReached = true;
      }

      const canSaveApptTypes = this._verifyAppointmentTypes(
        updatedAppointmentTypes
      );

      return {
        appointmentTypes: updatedAppointmentTypes,
        areApptTypesDirty: true,
        canSaveApptTypes: canSaveApptTypes,
        maxApptTypesReached: maxApptTypesReached,
      };
    });
  };

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  _onCloseSaveAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showSaveAlert: false }));
  };

  _onEditAppointmentType = (id, path, value) => {
    this.setState((prevState) => {
      const updatedAppointmentTypes = prevState.appointmentTypes.map((at) => {
        if (at.id === id) {
          Utils.update(at, path, value);
        }

        return at;
      });

      const canSaveApptTypes = this._verifyAppointmentTypes(
        updatedAppointmentTypes
      );

      return {
        appointmentTypes: updatedAppointmentTypes,
        areApptTypesDirty: true,
        canSaveApptTypes: canSaveApptTypes,
      };
    });
  };

  _onEnableAppointmentType = (apptType) => {
    if (apptType) {
      this.setState((prevState) => {
        let editedAppointmentType;
        const appointmentTypes = prevState.appointmentTypes.map((at) => {
          if (at.id === apptType.id) {
            at.isEditing = true;
            editedAppointmentType = at;
          }

          return at;
        });

        const label = `${editedAppointmentType.displayName};${editedAppointmentType.duration}`;

        eBuilder
          .withAction(eBuilder.Action.Settings.Click.editAppointmentType)
          .withLabel(eBuilder.Label.alternateLabel, label)
          .post();

        eBuilder.withLabel(eBuilder.Label.practiceIdentifier).post();

        return {
          areApptTypesDirty: true,
          appointmentTypes: appointmentTypes,
        };
      });
    }
  };

  _onMoveAppointmentType = (currIndex, newIndex) => {
    let appointmentTypes = cloneDeep(this.state.appointmentTypes);

    const typeToReplace = appointmentTypes[newIndex];
    if (
      newIndex >= 0 &&
      newIndex < appointmentTypes.length &&
      typeToReplace.sortOrder >= 0
    ) {
      this.setState(() => {
        const oldAppointmentType = appointmentTypes.splice(currIndex, 1)[0];

        appointmentTypes.splice(newIndex, 0, oldAppointmentType);
        // Sort the appointment types by their order.
        const updatedAppointmentTypes = appointmentTypes.map(
          (apptType, index) => {
            if (apptType.sortOrder >= 0) {
              apptType.sortOrder = index;
            }
            return apptType;
          }
        );

        const canSaveApptTypes = this._verifyAppointmentTypes(
          updatedAppointmentTypes
        );

        return {
          appointmentTypes: updatedAppointmentTypes,
          areApptTypesDirty: true,
          canSaveApptTypes: canSaveApptTypes,
        };
      });
    }
  };

  _onRemoveAppointmentType = async (apptType) => {
    if (apptType) {
      let canRemove = false;

      // If this appointemnt type has an actual id, then we can delete.
      if (
        apptType.locationAppointmentTypeId &&
        apptType.locationAppointmentTypeId > 0
      ) {
        try {
          this.setState(() => ({ showRemoveApptTypeModal: false }));

          await this._settingsService.deleteAppointmentType({
            appointmentType: apptType,
          });

          canRemove = true;
        } catch (error) {
          this._onShowErrorModal(
            error,
            this.props.t(
              'There was an unexpected issue with removing the appointment type.'
            )
          );
        }
      } else {
        // This is a newly added appointment type that has not been saved yet, so we can just remove it from state.
        canRemove = true;
      }

      if (canRemove) {
        this.setState((prevState) => {
          const updatedAppointmentTypes = prevState.appointmentTypes.filter(
            (item) => item.id !== apptType.id
          );

          let maxApptTypesReached = false;

          if (updatedAppointmentTypes.length === this._maxApptTypes) {
            maxApptTypesReached = true;
          }

          const canSaveApptTypes = this._verifyAppointmentTypes(
            updatedAppointmentTypes
          );
          const { onAppointmentTypesChange } = this.props;
          onAppointmentTypesChange &&
            onAppointmentTypesChange(updatedAppointmentTypes);

          return {
            appointmentTypes: updatedAppointmentTypes,
            canSaveApptTypes: canSaveApptTypes,
            maxApptTypesReached: maxApptTypesReached,
            showRemoveApptTypeModal: false,
          };
        });
      }
    }
  };

  _onSaveAppointmentTypes = async (appointmentTypes) => {
    try {
      eBuilder
        .withAction(eBuilder.Action.Settings.Click.saveAppointmentTypes)
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      this.setState(() => ({ canSaveApptTypes: false }));

      const updatedAppointmentTypes =
        await this._settingsService.saveAppointmentTypes({
          appointmentTypes: appointmentTypes,
        });

      Utils.formatAppointmentTypes(updatedAppointmentTypes);

      const { onAppointmentTypesChange } = this.props;
      onAppointmentTypesChange &&
        onAppointmentTypesChange(updatedAppointmentTypes);

      this.setState(() => ({
        appointmentTypes: updatedAppointmentTypes,
        areApptTypesDirty: false,
        showSaveAlert: true,
      }));
    } catch (error) {
      this._onShowErrorModal(
        error,
        this.props.t(
          'There was an unexpected issue with saving appointment types'
        )
      );
    }
  };

  _onShowErrorModal = (error, message) => {
    if (
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      console.error(error);

      this.setState(() => ({
        showErrorModal: true,
        errorModalMessage: message,
      }));
    }
  };

  _onToggleRemoveAppointmentTypeModal = (apptType) => {
    if (apptType) {
      this.setState((prevState) => ({
        currApptType: apptType,
        showRemoveApptTypeModal: !prevState.showRemoveApptTypeModal,
      }));
    }
  };

  _verifyAppointmentTypes = (appointmentTypes) => {
    let canSaveApptTypes = true;

    if (appointmentTypes?.length > 0) {
      for (let index = 0; index < appointmentTypes.length; ++index) {
        const apptType = appointmentTypes[index];

        if (!apptType.displayName) {
          canSaveApptTypes = false;
          break;
        }
      }
    }
    return canSaveApptTypes;
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      appointmentTypes,
      areApptTypesDirty,
      canSaveApptTypes,
      currApptType,
      errorModalMessage,
      maxApptTypesReached,
      showErrorModal,
      showRemoveApptTypeModal,
      showSaveAlert,
    } = this.state;
    const { showAdd, showSave, t } = this.props;

    const appointmentTypeItems = this._getAppointmentTypeItems();

    return (
      <div className="appt-types">
        <table className="appt-types__table">
          <thead>
            <tr>
              <th>{t('Service Type')}</th>
              <th>{t('Length/Minutes')}</th>
              <th>
                {t('Buffer')}
                <SearchTooltip
                  className="tooltip appt-types__tooltip"
                  title={
                    <>
                      <span className="tooltipText">
                        {t(
                          'Delays the available timeslots for each service by 0/3/6/12/24 hours for your customer as they schedule their visit with your location'
                        )}
                      </span>
                    </>
                  }
                  leaveDelay={300}
                >
                  <InfoOutlinedIcon />
                </SearchTooltip>
              </th>
            </tr>
          </thead>
          <tbody>{appointmentTypeItems}</tbody>
        </table>
        <div className="appt-types__footer">
          {showAdd && (
            <button
              className="appt-types__add"
              disabled={maxApptTypesReached}
              onClick={this._onAddAppointmentType}
            >
              <i className="material-icons">add</i>&nbsp;{t('Add')}
            </button>
          )}
          {showSave && (
            <button
              disabled={!(canSaveApptTypes && areApptTypesDirty)}
              className="appt-types__save"
              onClick={() => this._onSaveAppointmentTypes(appointmentTypes)}
            >
              {t('Save')}
            </button>
          )}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showSaveAlert}
          onClose={this._onCloseSaveAlert}
        >
          <Alert
            className="admin-settings__alert"
            severity="success"
            onClose={this._onCloseSaveAlert}
          >
            {t('Saved successfully')}
          </Alert>
        </Snackbar>
        <Dialog open={showRemoveApptTypeModal}>
          <DialogTitle>{t('Confirm')}</DialogTitle>
          <DialogContent>
            {Utils.isTurkeyMarket(Storage.getItem(Constants.langTag)) ? (
              <p className="appt-types__modal-body">
                <span className="appt-types__appt-type">
                  {currApptType.displayName}
                </span>
                &nbsp;{t('Are you sure to delete the service type?')}
              </p>
            ) : (
              <p className="appt-types__modal-body">
                {t('Remove?')}&nbsp;
                <span className="appt-types__appt-type">
                  {currApptType.displayName}?
                </span>
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <button
              className="appt-types__modal-button appt-types__modal-button--cancel"
              onClick={() =>
                this._onToggleRemoveAppointmentTypeModal(currApptType)
              }
            >
              {t('Cancel')}
            </button>
            <button
              className="appt-types__modal-button appt-types__modal-button--confirm"
              onClick={() => this._onRemoveAppointmentType(currApptType)}
            >
              {t('Remove')}
            </button>
          </DialogActions>
        </Dialog>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="admin-settings__modal-btn"
              onClick={this._onCloseErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AppointmentTypes.propTypes = {
  appointmentTypes: PropTypes.array.isRequired,
  isOnboarded: PropTypes.bool.isRequired,
  showAdd: PropTypes.bool,
  showSave: PropTypes.bool,
  onAppointmentTypesChange: PropTypes.func,
};

AppointmentTypes.defaultProps = {
  showAdd: true,
  showSave: true,
};

export default withTranslation()(AppointmentTypes);
