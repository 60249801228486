import React, { Component } from 'react';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import MomentUtils from '@date-io/moment';
import { withTranslation } from 'react-i18next';
import { StellestEthnicityOptions, StellestGenderOptions } from '../../enums';

//MUI form elements
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import './PatientDetailsEdit.scss';

class PatientDetailsEdit extends Component {
  onFieldChange = (field, value) => {
    this.props.onFieldChange(field, value);
  };

  handleDobChange = (date) => {
    this.props.handleDobChange(date);
  };

  handleDatePickerError = (picker, error, value) => {
    this.props.handleDatePickerError(picker, error, value);
  };

  render() {
    const { parentState, t } = this.props;

    const {
      dateFormat,
      dob,
      email,
      ethnicity,
      errors,
      firstName,
      gender,
      guardian,
      lastName,
      locationConfig,
      phone,
      phoneCountryCode,
      requireGuardian,
    } = parentState;

    return (
      <div className="stellest-appointment-form-section patient-details-edit">
        <div className="practice-details">
          <h2>{t('Practice Details')}</h2>
          <p>{locationConfig ? locationConfig.storeInformation.name : ''}</p>
          <p>{locationConfig ? locationConfig.stellestCustomerCode : ''}</p>
        </div>
        <h2>
          {t('Customer Details')}{' '}
          <span className="required">
            <span className="astrisk">*</span> {t('REQUIRED')}
          </span>
        </h2>
        <div className="inputs">
          <TextField
            error={errors.firstName}
            label={t('Customer First Name')}
            id="firstName"
            onChange={(e) => this.onFieldChange('firstName', e.target.value)}
            required
            value={firstName}
            variant="outlined"
            className="print"
          />
          <TextField
            error={errors.lastName}
            label={t('Customer Last Name')}
            id="lastName"
            onChange={(e) => this.onFieldChange('lastName', e.target.value)}
            required
            value={lastName}
            variant="outlined"
            className="print"
          />
          <MuiPickersUtilsProvider
            libInstance={momentLocaleWrapper}
            utils={MomentUtils}
          >
            <KeyboardDatePicker
              className="date-picker"
              error={errors.dob}
              format={dateFormat}
              id="dob-picker"
              label={t('Customer Date of Birth')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              onChange={this.handleDobChange}
              onError={(error, value) =>
                this.handleDatePickerError('dob', error, value)
              }
              required
              value={dob}
              inputVariant="outlined"
            />
          </MuiPickersUtilsProvider>
          <FormControl className="ethnicity row-end" variant="outlined">
            <InputLabel>{t('Ethnicity')}</InputLabel>
            <Select
              error={errors.ethnicity}
              id="ethnicity"
              labelId="ethnicity-label"
              onChange={(e) => this.onFieldChange('ethnicity', e.target.value)}
              value={ethnicity}
              variant="outlined"
            >
              <MenuItem
                value={-1}
                disabled={ethnicity !== -1}
                selected={ethnicity === -1}
              >
                {t('Select')}
              </MenuItem>
              <MenuItem value={StellestEthnicityOptions.Chinese}>
                {t('Chinese')}
              </MenuItem>
              <MenuItem value={StellestEthnicityOptions.Malay}>
                {t('Malay')}
              </MenuItem>
              <MenuItem value={StellestEthnicityOptions.Indian}>
                {t('Indian')}
              </MenuItem>
              <MenuItem value={StellestEthnicityOptions.Other}>
                {t('Others')}
              </MenuItem>
            </Select>
          </FormControl>
          {requireGuardian && (
            <TextField
              error={errors.guardian}
              label={t('Parent or Guardian')}
              id="guardian"
              onChange={(e) => this.onFieldChange('guardian', e.target.value)}
              required
              value={guardian}
              variant="outlined"
            />
          )}
          <TextField
            error={errors.email}
            id="email"
            label={t('Parent or Guardian Email')}
            onChange={(e) => this.onFieldChange('email', e.target.value)}
            required
            type="email"
            value={email}
            variant="outlined"
          />
          <TextField
            error={errors.phone}
            id="phone"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {phoneCountryCode}
                </InputAdornment>
              ),
            }}
            label={t('Parent or Guardian Phone')}
            onChange={(e) => this.onFieldChange('phone', e.target.value)}
            required
            type="tel"
            value={phone}
            variant="outlined"
            className="print"
          />
          <FormControl className="gender row-end" variant="outlined">
            <InputLabel>{t('Gender')}</InputLabel>
            <Select
              error={errors.gender}
              id="gender"
              labelId="gender-label"
              onChange={(e) => this.onFieldChange('gender', e.target.value)}
              value={gender}
              variant="outlined"
            >
              <MenuItem
                value={-1}
                disabled={gender !== -1}
                selected={gender === -1}
              >
                {t('Select')}
              </MenuItem>
              <MenuItem key={1} value={StellestGenderOptions.Male}>
                {t('Male')}
              </MenuItem>
              <MenuItem key={2} value={StellestGenderOptions.Female}>
                {t('Female')}
              </MenuItem>
              <MenuItem
                key={3}
                value={StellestGenderOptions.PreferNotToSpecify}
              >
                {t('Prefer not to answer')}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PatientDetailsEdit);
