import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  StellestOrderType,
  StellestEdgingOptions,
  StellestCoatingOptions,
} from '../../enums';
import StellestLogoBlack from '../../Assets/Images/Stellest/logos/transparent/stellest-black.png';
import './StellestOrderPrint.scss';

class StellestOrderPrint extends Component {
  _getOrderTypeName = (orderType) => {
    switch (orderType) {
      case StellestOrderType.StandardLens:
        return 'Stellest Lenses (Standalone)';
      case StellestOrderType.CarePackage:
        return 'Stellest Care Program';
      case StellestOrderType.CarePackageSecondPair:
        return 'Stellest Care Program Second Pair';
      case StellestOrderType.Assurance:
        return 'Assurance for Stellest';
      default:
        return '';
    }
  };

  _getLensCoatingName = (lensType) => {
    switch (lensType) {
      case StellestCoatingOptions.Prevencia:
        return 'Crizal Prevencia';
      case StellestCoatingOptions.CrizalAlizeUv:
        return 'Crizal Alize UV';
      default:
        return '';
    }
  };

  _getEdgingName = (edgingType) => {
    switch (edgingType) {
      case StellestEdgingOptions.NoEdging:
        return 'No Edging';
      case StellestEdgingOptions.Edging:
        return 'With Edging';
      default:
        return '';
    }
  };

  render() {
    const { parentState, orderDate, t } = this.props;
    const {
      locationConfig,
      firstName,
      lastName,
      phone,
      phoneCountryCode,
      orderType,
      orderReferenceNumber,
      lensCoating,
      edgingOption,
      orderSubmissionMedium,
      prescriptionImage,
      axisR,
      axisL,
      cylR,
      cylL,
      htL,
      htR,
      pdR,
      pdL,
      sphereR,
      sphereL,
      frameModel,
      comments,
      prescriptionImageUrl,
      sasToken,
    } = parentState;

    const orderTypeName = this._getOrderTypeName(orderType);
    const lensCoatingName = this._getLensCoatingName(lensCoating);
    const edgingName = this._getEdgingName(edgingOption);
    const showFrameModelText =
      edgingOption == StellestEdgingOptions.Edging && frameModel;
    const imageUrl =
      prescriptionImageUrl + sasToken?.substring(sasToken.indexOf('?'));

    return (
      <div className="stellest-order-content">
        <div className="navbar-print">
          <div className="stellest-brand">
            <img
              src={StellestLogoBlack}
              className="stellest-logo"
              alt="Stellest Logo"
            />
          </div>
          <span className="text">{t('Order Form')}</span>
        </div>
        <div className="body">
          <h3>{t('Practice Details')} </h3>
          <div className="stellest-appointment-form-section practice-details-view">
            <div className="fields fields-row2">
              <div className="field print">
                <div className="field-label">{t('Practice Name')}</div>
                <div className="field-value">
                  {locationConfig.storeInformation.name}
                </div>
              </div>

              <div className="field print">
                <div className="field-label">{t('Practice Code')}</div>
                <div className="field-value">
                  {locationConfig.stellestCustomerCode}
                </div>
              </div>
            </div>
          </div>
          <h3>{t('Customer Details')} </h3>
          <div className="stellest-appointment-form-section patient-details-view">
            <div className="fields">
              <div className="field print">
                <div className="field-label">{t('Customer First Name')}</div>
                <div className="field-value">{firstName}</div>
              </div>

              <div className="field print">
                <div className="field-label">{t('Customer Last Name')}</div>
                <div className="field-value">{lastName}</div>
              </div>

              <div className="field print">
                <div className="field-label">
                  {t('Parent or Guardian Phone')}
                </div>
                <div className="field-value">
                  {phoneCountryCode} {phone}
                </div>
              </div>
            </div>
          </div>
          <h3>{t('New Order')} </h3>
          <div className="stellest-appointment-form-section order-details-view">
            <div className="fields">
              <div className="field print">
                <div className="field-label">{t('Essilor Order Type')}</div>
                <div className="field-value">{orderTypeName}</div>
              </div>

              <div className="field print">
                <div className="field-label">{t('Order Reference Number')}</div>
                <div className="field-value">{orderReferenceNumber}</div>
              </div>

              <div className="field print">
                <div className="field-label">{t('Order Date')}</div>
                <div className="field-value">{orderDate}</div>
              </div>
            </div>
            <div className="fields fields-row2">
              <div className="field print">
                <div className="field-label">{t('Lens Coating')}</div>
                <div className="field-value">{lensCoatingName}</div>
              </div>

              <div className="field print">
                <div className="field-label">{t('Edging Option')}</div>
                <div className="field-value">{edgingName}</div>
              </div>
            </div>
          </div>
          {prescriptionImageUrl && (
            <div className="prescription-image">
              <div className="field-label">{t('Prescription Details')}</div>
              <div className="field-value-img">
                <img src={imageUrl} />
              </div>
            </div>
          )}
          {prescriptionImage && (
            <div className="prescription-image">
              <div className="field-label">{t('Prescription Details')}</div>
              <div className="field-value-img">
                <img src={prescriptionImage} />
              </div>
            </div>
          )}
          {orderSubmissionMedium == 1 && (
            <div className="prescription-table">
              <span className="label-text">{t('Prescription Details')}</span>
              <div className="label">
                <span className="product-label">{t('Rx')}</span>
                <span>{t('Sph')}</span>
                <span>{t('Cyl')}</span>
                <span>{t('Axis')}</span>
                <span>{t('Pd')}</span>
                <span>{t('Height')}</span>
              </div>
              <div className="fields">
                <div className="field-row">
                  <span>
                    <b>{t('R(OD)')}</b>
                  </span>
                  <span>
                    <b>{sphereR}</b>
                  </span>
                  <span>
                    <b>{cylR}</b>
                  </span>
                  <span>
                    <b>{axisR}</b>
                  </span>
                  <span>
                    <b>{pdR}</b>
                  </span>
                  <span>
                    <b>{htR}</b>
                  </span>
                </div>
                <div className="field-row">
                  <span>
                    <b>{t('L(OS)')}</b>
                  </span>
                  <span>
                    <b>{sphereL}</b>
                  </span>
                  <span>
                    <b>{cylL}</b>
                  </span>
                  <span>
                    <b>{axisL}</b>
                  </span>
                  <span>
                    <b>{pdL}</b>
                  </span>
                  <span>
                    <b>{htL}</b>
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="stellest-appointment-form-section optional-details-view">
            <div className=" fields optional-fields">
              {showFrameModelText && (
                <div className="field print">
                  <div className="field-label">{t('Frame Model')}</div>
                  <div className="field-value">{frameModel.substr(0, 500)}</div>
                </div>
              )}
              {comments && (
                <div className="field print">
                  <div className="field-label">{t('CS Comments')}</div>
                  <div className="field-value">{comments.substr(0, 500)}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(StellestOrderPrint);
