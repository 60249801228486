import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import QRLogo from '../../Assets/Images/QRLogo.png';
import './QRCodeContent.scss';

/**
 * Represents the QR Code content for a store poster.
 */
const QRCodeContent = (props, ref) => {
  const { storeName, uniqueLocationUrl } = props;
  const { t } = useTranslation();
  const link = `${uniqueLocationUrl}?utm_source=qr_code&utm_medium=poster&utm_campaign=booking`;

  return (
    <div className="qr-code-content">
      <div className="qr-code-content__logo-cont">
        <div>
          <img
            alt={t('QR Code Store Poster')}
            className="qr-code-content__logo"
            src={QRLogo}
          />
        </div>
        <span className="qr-code-content__logo-name">{t('EyeBookNow')}</span>
      </div>
      <h3 className="qr-code-content__heading">
        {ReactHtmlParser(t('Schedule Your Safe'))}
      </h3>
      <div className="qr-code-content__body">
        <h4 className="qr-code-content__subheading">
          {ReactHtmlParser(t('Visit Now'))}
        </h4>
        {link && <QRCode className="qr-code-content__qr" value={link} />}
        <p className="qr-code-content__verbiage">
          {ReactHtmlParser(
            t(
              'Scan the QR Code to choose your preferred time to schedule a visit.'
            )
          )}
        </p>
      </div>
      <div className="qr-code-content__footer">
        <p className="qr-code-content__store-label">{t('Store Name')}</p>
        <p className="qr-code-content__store">{storeName}</p>
        <p className="qr-code-content__divider"></p>
        <p className="qr-code-content__sponsor">{t('Powered by Essilor')}</p>
      </div>
    </div>
  );
};

QRCodeContent.propTypes = {
  storeName: PropTypes.string.isRequired,
  uniqueLocationUrl: PropTypes.string.isRequired,
};

export default QRCodeContent;
