import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Enums from '../../enums';
import Storage from '../../storage';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import Illustration from '../../Assets/Images/Login-Screen-Art.jpg';
import './TemporaryLogin.scss';

const eBuilder = new EventBuilder();
eBuilder.withCategory(eBuilder.Category.Onboarding.registration);
const pBuilder = new PageviewBuilder();

/**
 * Represents the temporar login component.
 * @remarks This component is first viewed on initial login of the admin dashboard.
 */
class TempLogin extends Component {
  constructor(props) {
    super(props);

    this._formErrorSetClean = {
      isValid: true,
      username: '',
      tempPassword: '',
      serverError: '',
      unauthorized: '',
    };
    this.state = {
      disableLoginBtn: false,
      formErrors: cloneDeep(this._formErrorSetClean),
      isVisible: false,
      username: '',
      tempPassword: '',
      redirectToPasswordReset: false,
      showPasswordUnmasked: false,
    };

    this._isFormValid = this._isFormValid.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);

    eBuilder.withLabel(eBuilder.Label.practiceIdentifier);
    pBuilder.pageview(pBuilder.Page.Registration.initialLogin);
  }

  _isFormValid() {
    let isValid = true;
    const { username, tempPassword } = this.state;

    const formErrors = cloneDeep(this._formErrorSetClean);

    if (!username) {
      isValid = false;
      formErrors.username = 'Email is required';
    }

    if (!tempPassword) {
      isValid = false;
      formErrors.tempPassword = 'Temporary Password is required';
    }

    this.setState(() => ({ formErrors: formErrors }));

    return isValid;
  }

  /**
   * Executes when a key is pressed.
   * @param {Object} e The event data.
   */
  onKeyPress(e) {
    const { key } = e;

    if (key === Enums.KeyboardKeys.enterKey) {
      this.onLogin();
    }
  }

  /**
   * Executes when the user logs in.
   */
  async onLogin() {
    if (this._isFormValid()) {
      try {
        this.setState(() => ({ disableLoginBtn: true }));

        const url = `${process.env.REACT_APP_ADMIN_API}/authorizeTempPassword`;
        const { username, tempPassword } = this.state;
        const data = {
          username: username,
          password: tempPassword,
        };
        const res = await axios.post(url, data);
        let user = Storage.getItem(Constants.currUserKey);

        if (!user) {
          user = {
            jwt: '',
            agreementSigned: '',
          };
        }

        const jwt = res.data;
        user.jwt = jwt;
        Storage.setItem(Constants.currUserKey, user);

        const { onRedirectToPasswordReset } = this.props;

        eBuilder
          .withAction(eBuilder.Action.Onboarding.Click.loginInitial)
          .post();

        onRedirectToPasswordReset(username);
      } catch (error) {
        if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusUnauthorizedError)
        ) {
          this.setState(() => ({
            disableLoginBtn: false,
            formErrors: {
              serverError: 'Invalid email or temporary password',
            },
          }));
        } else if (
          (error && !error.response) ||
          (error &&
            error.response.status ===
              Enums.HttpStatusCodes.httpStatusInternalServerError)
        ) {
          this.setState(() => ({
            disableLoginBtn: false,
            formErrors: { serverError: 'Unable to login at this time' },
          }));
        }
      }
    }
  }

  /**
   * Executes when the password changes.
   * @param {Object} e The event data.
   */
  onPasswordChange(e) {
    const { value } = e.target;
    this.setState(() => ({ tempPassword: value }));
  }

  _onTogglePasswordMask = () => {
    this.setState((prevState) => ({
      showPasswordUnmasked: !prevState.showPasswordUnmasked,
    }));
  };

  /**
   * Executes when the username changes.
   * @param {Object} e The event data.
   */
  onUsernameChange(e) {
    const { value } = e.target;
    this.setState(() => ({ username: value }));
  }

  render() {
    const {
      disableLoginBtn,
      formErrors,
      username,
      tempPassword,
      redirectToPasswordReset,
      showPasswordUnmasked,
    } = this.state;

    if (redirectToPasswordReset) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    const { t } = this.props;

    return (
      <div className="login-temp">
        <h1 className="login-temp__title">
          {t('Thank you for choosing EyeBookNow')}
        </h1>
        <p className="login-temp__statement1">
          {t('Login using your email and temporary password')}
        </p>
        <p className="login-temp__statement2">
          {t('You will be able to set a new password on the next screen')}
        </p>
        <form className="login-temp__form">
          <TextField
            className="login-temp__input"
            inputProps={{
              style: {
                padding: '12px 14px',
              },
            }}
            label={t('Email')}
            value={username}
            variant="outlined"
            onChange={this.onUsernameChange}
            onKeyPress={this.onKeyPress}
          />
          <span
            className={`login-temp__error ${
              formErrors.username ? 'login-temp__error--visible' : ''
            }`}
          >
            {t(formErrors.username)}
          </span>
          <div className="login-temp__group">
            <TextField
              className="login-temp__input"
              inputProps={{
                style: {
                  padding: '12px 14px',
                },
              }}
              label={t('Temporary Password')}
              type={`${!showPasswordUnmasked ? 'password' : 'text'}`}
              variant="outlined"
              value={tempPassword}
              onChange={this.onPasswordChange}
              onKeyPress={this.onKeyPress}
            />
            <button
              className="login-norm__show-pw"
              type="button"
              onClick={this._onTogglePasswordMask}
            >
              <VisibilityIcon
                className={`login-norm__icon ${
                  showPasswordUnmasked ? 'login-norm__icon--hide' : ''
                }`}
              />
              <VisibilityOffIcon
                className={`login-norm__icon ${
                  !showPasswordUnmasked ? 'login-norm__icon--hide' : ''
                }`}
              />
            </button>
          </div>
          <span
            className={`login-temp__error login-temp__error--no-margin ${
              formErrors.tempPassword ? 'login-temp__error--visible' : ''
            }`}
          >
            {t(formErrors.tempPassword)}
          </span>
          <span
            className={`login-temp__error login-temp__error--no-margin ${
              formErrors.unauthorized ? 'login-temp__error--visible' : ''
            }`}
          >
            {t(formErrors.unauthorized)}
          </span>
          <span
            className={`login-temp__error ${
              formErrors.serverError ? 'login-temp__error--visible' : ''
            }`}
          >
            {t(formErrors.serverError)}
          </span>
          <button
            className="login-temp__login"
            disabled={disableLoginBtn}
            type="button"
            onClick={this.onLogin}
          >
            {t('Login')}
          </button>
        </form>
        <div className="login-temp__illustration-cont">
          <img
            alt="Illustration"
            className="login-temp__illustration"
            src={Illustration}
          />
        </div>
      </div>
      // <div className="login-cont">
      //   <div className="login">
      //     <h1 className="login__title">{t('Thank you for choosing EyeBookNow')}</h1>
      //     <h2 className="login__statement1">
      //       <span>{t('Login using your email and temporary password')}</span><br />
      //     </h2>
      //     <h3 className="login__statement2">
      //       <span>{t('You will be able to set a new password on the next screen')}</span>
      //     </h3>
      //     <form className="login__form">
      //       <input className="login__input" placeholder={t("Email")} type="text" value={username} onChange={this.onUsernameChange} onKeyPress={this.onKeyPress} />
      //       <span className={`login__error ${formErrors.username ? 'login__error--visible' : ''}`}>{t(formErrors.username)}</span>
      //       <input className="login__input" placeholder={t("Temporary Password")} type="password" value={tempPassword} onChange={this.onPasswordChange} onKeyPress={this.onKeyPress} />
      //       <span className={`login__error ${formErrors.tempPassword ? 'login__error--visible' : ''}`}>{t(formErrors.tempPassword)}</span>
      //       <span className={`login__error ${formErrors.unauthorized ? 'login__error--visible' : ''}`}>{t(formErrors.unauthorized)}</span>
      //       <span className={`login__error ${formErrors.serverError ? 'login__error--visible' : ''}`}>{t(formErrors.serverError)}</span>
      //       <button className="login__btn" disabled={disableLoginBtn} type="button" onClick={this.onLogin}>{t('Login')}</button>
      //     </form>
      //   </div>
      // </div>
    );
  }
}

TempLogin.propTypes = {
  onRedirectToPasswordReset: PropTypes.func.isRequired,
};

export default withTranslation()(TempLogin);
