import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import moment from 'moment';
import axios from 'axios';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Events from '../../events';
import Constants from '../../constants';
import Storage from '../../storage';
import LocationConfigService from '../../Services/locationConfigService';
import StellestService from '../../Services/stellestService';
import Enums, { OrderType, OrderStatus, ConfirmationStatus } from '../../enums';

import './StellestOrderTracker.scss';
import StellestLogo from '../../Assets/Images/Stellest/logos/transparent/stellest-blue.png';
import Utils from '../../utils';

const SearchTooltip = withStyles((theme) => ({
  arrow: {
    color: 'lightgrey',
  },
  tooltip: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    color: 'black',
    fontSize: 16,
    padding: 5,
  },
}))(Tooltip);

class StellestOrderTracker extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      columns: [
        { title: t('Customer First Name'), field: 'firstName' },
        { title: t('Customer Last Name'), field: 'lastName' },
        { title: t('Order Type'), field: 'orderType' },
        { title: t('Order Status'), field: 'orderStatus', searchable: false },
        {
          title: t('Order Date'),
          field: 'orderDate',
          type: 'date',
          defaultSort: 'desc',
          searchable: false,
          render: (rowData) => (
            <div>{momentLocaleWrapper(rowData.orderDate).format('L')}</div>
          ),
        },
        { title: t('Order Ref. Number'), field: 'orderRefNumber' },
        {
          title: t('Order Tracking Number'),
          field: 'orderTrackingNumber',
          searchable: false,
        },
        {
          title: t('Follow-up Appt Date/Time'),
          field: 'followUpDateTime',
          type: 'datetime',
          searchable: false,
          render: (rowData) => (
            <div
              className={
                moment(rowData.followUpDateTime).diff(moment(), 'days') <= 15 &&
                moment(rowData.followUpDateTime).diff(moment(), 'days') >= 0 &&
                rowData.followUpStatus === 1
                  ? 'red-highlight'
                  : ''
              }
            >
              {rowData.followUpDateTime ? (
                <button
                  className="customerButton--link"
                  onClick={() => {
                    this._onCustomerAppointmentButtonClick(rowData);
                  }}
                >{`${momentLocaleWrapper(rowData.followUpDateTime).format(
                  'L'
                )} - ${momentLocaleWrapper(rowData.followUpDateTime).format(
                  'LT'
                )}`}</button>
              ) : (
                'N/A'
              )}
            </div>
          ),
        },
        {
          title: t('Follow-up Appt Status'),
          field: 'followUpStatus',
          searchable: false,
          render: (rowData) => (
            <div
              className={
                moment(rowData.followUpDateTime).diff(moment(), 'days') <= 15 &&
                moment(rowData.followUpDateTime).diff(moment(), 'days') >= 0 &&
                rowData.followUpStatus === 1
                  ? 'red-highlight'
                  : ''
              }
            >
              {rowData.orderTypeInt === 1
                ? this._getFollowUpApptStatus(
                    rowData.followUpStatus,
                    rowData.followUpCurrentStatus
                  )
                : t('N/A')}
            </div>
          ),
        },
        {
          title: t('Parent or Guardian Phone'),
          field: 'customerPhone',
          render: (rowData) => (
            <a href={`tel:${rowData.customerPhone}`}>{rowData.customerPhone}</a>
          ),
        },
        {
          title: t('Customer Data / Lens Order'),
          field: 'customerData',
          sorting: false,
          searchable: false,
          render: (rowData) => (
            <button
              className="customerDataButton"
              onClick={() => {
                this._onCustomerDataButtonClick(rowData);
              }}
            >
              View Data/Order Lens
            </button>
          ),
        },
      ],
      data: [],
      error: false,
      isLoading: false,
      searchTooltip:
        'Search by name, phone number, order reference number or order type',
    };
    this._locationConfigService = new LocationConfigService();
    this._stellestService = new StellestService();
  }

  componentDidMount() {
    this._getOrders();
    this._setupSubscriptions();
    this._handleQueryParams();
  }

  _setupSubscriptions = () => {
    Events.on(Constants.Events.locationChanged, async (args) => {
      await this._getOrders();

      if (args != null) {
        const { data } = this.state;

        const { orderReferenceNumber, patientKey } = args;
        const rowData = data.find(
          (x) =>
            x.orderRefNumber !== null &&
            x.orderRefNumber.toLowerCase() ===
              orderReferenceNumber.toLowerCase() &&
            x.customerData.patientKey.toLowerCase() === patientKey.toLowerCase()
        );

        if (rowData) {
          Events.emit(Constants.Events.onToggleAddStellest, { rowData, data });
        }
      }
    });
  };

  _handleQueryParams = () => {
    const newLocationId = Utils.getUrlParam('locationId');
    const orderReferenceNumber = Utils.getUrlParam('orderReferenceNumber');
    const patientKey = Utils.getUrlParamWithSymbols('patientKey');

    if (newLocationId && orderReferenceNumber && patientKey) {
      Storage.setItem(Constants.currLocIdKey, newLocationId);
      Events.emit(Constants.Events.locationChanged, {
        patientKey,
        orderReferenceNumber,
      });
    }
  };

  _onCustomerAppointmentButtonClick = (rowData) => {
    const { data } = this.state;

    window.location = `/schedule?date=${rowData.followUpDateTime}`;
  };

  _onCustomerDataButtonClick = (rowData) => {
    const { data } = this.state;

    Events.emit(Constants.Events.onToggleAddStellest, { rowData, data });
  };

  _getOrders = async () => {
    const tkn = await this._stellestService.getPhotoToken();
    const locationId = this._locationConfigService.getLocationConfigId();
    this.setState({
      error: false,
      isLoading: true,
    });
    await axios
      .get(
        `${process.env.REACT_APP_DASHBOARD_API}/getStellestDashboard/${locationId}`
      )
      .then((response) => {
        this._formatOrderData(response.data, tkn);
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          error: true,
          isLoading: false,
        });
      });
  };

  _formatOrderData = (data, token) => {
    const formattedData = [];
    data.forEach((order, i) => {
      formattedData.push({
        firstName: order.firstName,
        lastName: order.lastName,
        orderType: this._getOrderType(order.essilorOrderType),
        orderTypeInt: order.essilorOrderType,
        orderStatus: order.orderStatus,
        orderDate: order.firstOrderDate,
        orderRefNumber: order.orderReferenceNumber,
        orderTrackingNumber: order.orderTrackingNumber,
        prescriptionImageUrl: order.prescriptionImageUrl,
        followUpDateTime: order.followUpStartTime,
        followUpStatus: order.confirmationStatus,
        followUpCurrentStatus: order.currentStatus,
        coating: order.coating,
        edging: order.edging,
        frameModel: order.frameModel,
        csComments: order.csComments,
        customerPhone: order.phone,
        sasToken: token,
        customerData: {
          dob: order.dateOfBirth,
          email: order.email,
          guardian: order.guardianFullName,
          patientKey: order.patientKey,
          leftEye: order.leftEye,
          rightEye: order.rightEye,
          gender: order.gender,
          ethnicity: order.ethnicity,
        },
      });
    });

    this.setState({
      data: formattedData,
      isLoading: false,
    });
  };

  _getOrderType(id) {
    switch (id) {
      case OrderType.stellestStandAloneLens:
        return 'Stellest Lenses (Standalone)';
      case OrderType.stellestCarePackage:
        return 'Stellest Care Program';
      case OrderType.stellestAssurance:
        return 'Assurance for Stellest';
      default:
        break;
    }
  }

  _getOrderStatus(id) {
    switch (id) {
      case OrderStatus.submitted:
        return 'Sumbitted';
      case OrderStatus.delivered:
        return 'Delivered';
      case OrderStatus.canceled:
        return 'Canceled';
      default:
        break;
    }
  }

  _getConfirmationStatus(id) {
    switch (id) {
      case ConfirmationStatus.confirmed:
        return 'Confirmed';
      case ConfirmationStatus.unconfirmed:
        return 'Unconfirmed';
      default:
        break;
    }
  }

  _getAppointmentStatus(id) {
    switch (id) {
      case Enums.AppointmentStatus.noShow:
        return 'No Show';
      case Enums.AppointmentStatus.cancelled:
        return 'Canceled';
      default:
        break;
    }
  }

  _getFollowUpApptStatus(confirmationStatus, currentStatus) {
    var result = '';
    switch (currentStatus) {
      case Enums.AppointmentStatus.noShow:
      case Enums.AppointmentStatus.cancelled:
        result = this._getAppointmentStatus(currentStatus);
        break;
      default:
        result = this._getConfirmationStatus(confirmationStatus);
        break;
    }
    return result;
  }

  render() {
    const { columns, data, error, isLoading, searchTooltip, sasToken } =
      this.state;

    if (error) {
      return (
        <div className="dashboard__stellest-order-tracker">
          <div className="error">
            <h2>An error was encountered</h2>
            <p>Please refresh the page and try again.</p>
          </div>
        </div>
      );
    }
    return (
      <div className="dashboard__stellest-order-tracker">
        <MaterialTable
          columns={columns}
          components={{
            Actions: (props) => (
              <SearchTooltip
                className="search-tooltip"
                title={searchTooltip}
                arrow
                placement="top-end"
                leaveDelay={300}
              >
                <InfoOutlinedIcon />
              </SearchTooltip>
            ),
          }}
          data={data}
          isLoading={isLoading}
          options={{
            headerStyle: {
              borderTop: '1px solid #979797',
            },
            paging: false,
            rowStyle: (rowData) => ({
              border: 'none',
              borderTop: '2px solid #979797',
            }),
          }}
          title={
            <div className="stellest-dashboard__heading">
              <img src={StellestLogo} alt="Stellest Management" />
            </div>
          }
          style={{
            boxShadow: 'none',
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(StellestOrderTracker);
