import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withTranslation } from 'react-i18next';
import Utils from '../../utils';
import './ResourceHours.scss';

/**
 * Represents the resource hours.
 * @param {Object} props The component properties.
 */
const ResourceHours = (props) => {
  const {
    resourceId,
    availableHours,
    onResourceBreakBlockChange,
    onResourceDayOfWeekChange,
    onResourceTimeBlockChange,
    t,
  } = props;
  const closed = -1;

  const daysOfWeek = availableHours.map((hour, key) => {
    const isOpen = hour.startTime !== closed && hour.endTime !== closed;
    return (
      <th key={key} scope="col">
        <label className={`res-hours__checkbox`}>
          &nbsp;&nbsp;&nbsp;{t(Utils.getDayOfWeek(hour.dayOfWeekId))}
          <input
            checked={isOpen}
            type="checkbox"
            onChange={(e) =>
              onResourceDayOfWeekChange(
                resourceId,
                hour.dayOfWeekId,
                e.target.checked
              )
            }
          />
          <span className="checkbox"></span>
        </label>
      </th>
    );
  });

  const _getResourceTimeBlockHours = (
    dayOfWeekId,
    defaultValue,
    isStartTime
  ) => (
    <FormControl className="res-hours__timeblock-cont" variant="outlined">
      <Select
        value={defaultValue}
        onChange={(e) =>
          onResourceTimeBlockChange(
            resourceId,
            dayOfWeekId,
            isStartTime ? parseInt(e.target.value) : null,
            !isStartTime ? parseInt(e.target.value) : null
          )
        }
      >
        <MenuItem disabled={defaultValue !== closed} value="-1">
          {t('Closed')}
        </MenuItem>
        <MenuItem value="72">{t('6:00 AM')}</MenuItem>
        <MenuItem value="78">{t('6:30 AM')}</MenuItem>
        <MenuItem value="84">{t('7:00 AM')}</MenuItem>
        <MenuItem value="90">{t('7:30 AM')}</MenuItem>
        <MenuItem value="96">{t('8:00 AM')}</MenuItem>
        <MenuItem value="102">{t('8:30 AM')}</MenuItem>
        <MenuItem value="108">{t('9:00 AM')}</MenuItem>
        <MenuItem value="114">{t('9:30 AM')}</MenuItem>
        <MenuItem value="120">{t('10:00 AM')}</MenuItem>
        <MenuItem value="126">{t('10:30 AM')}</MenuItem>
        <MenuItem value="132">{t('11:00 AM')}</MenuItem>
        <MenuItem value="138">{t('11:30 AM')}</MenuItem>
        <MenuItem value="144">{t('12:00 PM')}</MenuItem>
        <MenuItem value="150">{t('12:30 PM')}</MenuItem>
        <MenuItem value="156">{t('1:00 PM')}</MenuItem>
        <MenuItem value="162">{t('1:30 PM')}</MenuItem>
        <MenuItem value="168">{t('2:00 PM')}</MenuItem>
        <MenuItem value="174">{t('2:30 PM')}</MenuItem>
        <MenuItem value="180">{t('3:00 PM')}</MenuItem>
        <MenuItem value="186">{t('3:30 PM')}</MenuItem>
        <MenuItem value="192">{t('4:00 PM')}</MenuItem>
        <MenuItem value="198">{t('4:30 PM')}</MenuItem>
        <MenuItem value="204">{t('5:00 PM')}</MenuItem>
        <MenuItem value="210">{t('5:30 PM')}</MenuItem>
        <MenuItem value="216">{t('6:00 PM')}</MenuItem>
        <MenuItem value="222">{t('6:30 PM')}</MenuItem>
        <MenuItem value="228">{t('7:00 PM')}</MenuItem>
        <MenuItem value="234">{t('7:30 PM')}</MenuItem>
        <MenuItem value="240">{t('8:00 PM')}</MenuItem>
        <MenuItem value="246">{t('8:30 PM')}</MenuItem>
        <MenuItem value="252">{t('9:00 PM')}</MenuItem>
        <MenuItem value="258">{t('9:30 PM')}</MenuItem>
        <MenuItem value="264">{t('10:00 PM')}</MenuItem>
      </Select>
    </FormControl>
  );

  const _getResourceBreakBlockHours = (
    dayOfWeekId,
    defaultValue,
    isBreakOutTimeBlock
  ) => (
    <FormControl className="res-hours__timeblock-cont" variant="outlined">
      <Select
        value={defaultValue}
        onChange={(e) =>
          onResourceBreakBlockChange(
            resourceId,
            dayOfWeekId,
            isBreakOutTimeBlock ? parseInt(e.target.value) : null,
            !isBreakOutTimeBlock ? parseInt(e.target.value) : null
          )
        }
      >
        <MenuItem disabled={defaultValue !== closed} value="-1">
          {t('Closed')}
        </MenuItem>
        <MenuItem value="72">{t('6:00 AM')}</MenuItem>
        <MenuItem value="78">{t('6:30 AM')}</MenuItem>
        <MenuItem value="84">{t('7:00 AM')}</MenuItem>
        <MenuItem value="90">{t('7:30 AM')}</MenuItem>
        <MenuItem value="96">{t('8:00 AM')}</MenuItem>
        <MenuItem value="102">{t('8:30 AM')}</MenuItem>
        <MenuItem value="108">{t('9:00 AM')}</MenuItem>
        <MenuItem value="114">{t('9:30 AM')}</MenuItem>
        <MenuItem value="120">{t('10:00 AM')}</MenuItem>
        <MenuItem value="126">{t('10:30 AM')}</MenuItem>
        <MenuItem value="132">{t('11:00 AM')}</MenuItem>
        <MenuItem value="138">{t('11:30 AM')}</MenuItem>
        <MenuItem value="144">{t('12:00 PM')}</MenuItem>
        <MenuItem value="150">{t('12:30 PM')}</MenuItem>
        <MenuItem value="156">{t('1:00 PM')}</MenuItem>
        <MenuItem value="162">{t('1:30 PM')}</MenuItem>
        <MenuItem value="168">{t('2:00 PM')}</MenuItem>
        <MenuItem value="174">{t('2:30 PM')}</MenuItem>
        <MenuItem value="180">{t('3:00 PM')}</MenuItem>
        <MenuItem value="186">{t('3:30 PM')}</MenuItem>
        <MenuItem value="192">{t('4:00 PM')}</MenuItem>
        <MenuItem value="198">{t('4:30 PM')}</MenuItem>
        <MenuItem value="204">{t('5:00 PM')}</MenuItem>
        <MenuItem value="210">{t('5:30 PM')}</MenuItem>
        <MenuItem value="216">{t('6:00 PM')}</MenuItem>
        <MenuItem value="222">{t('6:30 PM')}</MenuItem>
        <MenuItem value="228">{t('7:00 PM')}</MenuItem>
        <MenuItem value="234">{t('7:30 PM')}</MenuItem>
        <MenuItem value="240">{t('8:00 PM')}</MenuItem>
        <MenuItem value="246">{t('8:30 PM')}</MenuItem>
        <MenuItem value="252">{t('9:00 PM')}</MenuItem>
        <MenuItem value="258">{t('9:30 PM')}</MenuItem>
        <MenuItem value="264">{t('10:00 PM')}</MenuItem>
      </Select>
    </FormControl>
  );

  const startTimes = availableHours.map((hour, key) => (
    <td key={key}>
      {_getResourceTimeBlockHours(hour.dayOfWeekId, hour.startTime, true)}
    </td>
  ));

  const breakOutTimeBlocks = availableHours.map((hour, key) => (
    <td key={key}>
      {_getResourceBreakBlockHours(hour.dayOfWeekId, hour.breakOutTime, true)}
    </td>
  ));

  const breakInTimeBlocks = availableHours.map((hour, key) => (
    <td key={key}>
      {_getResourceBreakBlockHours(hour.dayOfWeekId, hour.breakInTime, false)}
    </td>
  ));

  const endTimes = availableHours.map((hour, key) => (
    <td key={key}>
      {_getResourceTimeBlockHours(hour.dayOfWeekId, hour.endTime, false)}
    </td>
  ));

  const timeBlockErrors = availableHours.map((hour, key) => (
    <td key={key}>
      <span
        className={`res-hours__timeblock-error ${
          hour.timeBlockError ? 'res-hours__timeblock-error--visible' : ''
        }`}
      >
        {hour.timeBlockError}
      </span>
    </td>
  ));

  return (
    <div className="res-hours">
      <table className="res-hours__table">
        <thead>
          <tr>
            <th scope="col">{t('Days of Week')}</th>
            {daysOfWeek}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('Start')}</td>
            {startTimes}
          </tr>
          <tr>
            <td>{t('Break Out')}</td>
            {breakOutTimeBlocks}
          </tr>
          <tr>
            <td>{t('Break In')}</td>
            {breakInTimeBlocks}
          </tr>
          <tr>
            <td>{t('End')}</td>
            {endTimes}
          </tr>
          <tr>
            <td></td>
            {timeBlockErrors}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ResourceHours.propTypes = {
  resourceId: PropTypes.number.isRequired,
  availableHours: PropTypes.array.isRequired,
  onResourceBreakBlockChange: PropTypes.func.isRequired,
  onResourceDayOfWeekChange: PropTypes.func.isRequired,
  onResourceTimeBlockChange: PropTypes.func.isRequired,
};

export default withTranslation()(ResourceHours);
