import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import Utils from '../../utils';
import Events from '../../events';
import Storage from '../../storage';
import Constants from '../../constants';
import TempLogin from './TemporaryLogin';
import NormalLogin from './NormalLogin';
import PasswordReset from './PasswordReset';
import LoginHeader from './LoginHeader';
import LoginFooter from './LoginFooter';
import Overlay from '../../Components/Overlay/Overlay';
import ContentManagementService from '../../Services/contentManagementService';
import './Login.scss';

/**
 * Represents the main login component for the admin dashboard.
 */
class Login extends Component {
  /**
   * Initializes a new instance of the Login component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      redirectToTempLogin: false,
      redirectToNormalLogin: false,
      redirectToAdminSettings: false,
      redirectToDashboard: false,
      redirectToAdminSchedule: false,
      redirectToPasswordReset: false,
      redirectToTermsCond: false,
      showLoadingOverlay: false,
      username: '',
    };
    this._contentManagementService = new ContentManagementService();

    this.onRedirectToPasswordReset = this.onRedirectToPasswordReset.bind(this);
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const username = Utils.getUrlParam('u');

    await this._getSiteConfig();
    await this._setI18nLanguage();

    // Determine if the user is logging in for the first time
    // or if the they're resetting their current password.
    if (username || Utils.isOnboardingUrl()) {
      this.setState(() => ({
        isVisible: true,
        redirectToTempLogin: true,
      }));
    } else {
      this.setState(() => ({ showLoadingOverlay: true }));

      const user = Storage.getItem(Constants.currUserKey);

      if (user && user.jwt) {
        try {
          const url = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;

          await axios.get(url);

          const jwtData = Utils.decodeJwt(user.jwt);
          const onboardingInfoUrl = `${process.env.REACT_APP_SETTINGS_API}/getLocationConfig/${jwtData.defaultLocation}`;
          const onboardingInfoRes = await axios.get(onboardingInfoUrl);
          let onboardingInfo = null;

          if (onboardingInfoRes && onboardingInfoRes.data) {
            onboardingInfo = onboardingInfoRes.data;
          }

          if (onboardingInfo.agreementSigned) {
            const returnUrl = Utils.getFullReturnUrl();

            const stellestSettings = onboardingInfo.locationSettings.find(
              (e) =>
                e.displayName ===
                Constants.LocationSettings.settingIsStellestEnabled
            );
            const isStellestEnabled = stellestSettings
              ? stellestSettings.settingValue
              : false;

            const isColumbia =
              onboardingInfo.storeInformation.countryCode === 'CO';

            if (returnUrl) {
              window.location = returnUrl;
            } else if (isStellestEnabled || isColumbia) {
              this.setState(() => ({
                redirectToDashboard: true,
                showLoadingOverlay: false,
              }));
            } else {
              this.setState(() => ({
                redirectToAdminSchedule: true,
                showLoadingOverlay: false,
              }));
            }
          } else {
            this.setState(() => ({
              redirectToAdminSettings: true,
              showLoadingOverlay: false,
            }));
          }
        } catch {
          this.setState(() => ({
            isVisible: true,
            redirectToNormalLogin: true,
            showLoadingOverlay: false,
          }));
        }
      } else {
        this.setState(() => ({
          isVisible: true,
          redirectToNormalLogin: true,
          showLoadingOverlay: false,
        }));
      }
    }

    Events.emit(Constants.Events.pageReady);

    this._setupSubscriptions();
  }

  componentWillUnmount() {
    Events.removeAllListeners(Constants.Events.tempLogin);
  }

  _setI18nLanguage = async () => {
    const siteConfig = Storage.getItem(Constants.siteConfig);
    const { languageTag } = siteConfig;

    await this._contentManagementService.loadLocalizations(languageTag);
    await this.props.i18n.changeLanguage(languageTag);
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.tempLogin, (username) => {
      this.setState(() => ({
        redirectToPasswordReset: true,
        username: username,
        redirectToNormalLogin: false,
      }));
    });
  };

  _getSiteConfig = async () => {
    const url = `${
      process.env.REACT_APP_SETTINGS_API
    }/getSiteConfigByUrl?url=${encodeURI(window.location.href)}`;
    const res = await axios.get(url);

    if (res?.data) {
      const data = { ...res.data };
      document.title = `${this.props.t(data.siteName)}`;
      Storage.setItem(Constants.siteConfig, data);
    }
  };

  onRedirectToPasswordReset(name) {
    this.setState(() => ({ redirectToPasswordReset: true, username: name }));
  }

  /**
   * Renders the component.
   */
  render() {
    const {
      isVisible,
      redirectToTempLogin,
      redirectToNormalLogin,
      redirectToAdminSettings,
      redirectToDashboard,
      redirectToAdminSchedule,
      redirectToPasswordReset,
      redirectToTermsCond,
      showLoadingOverlay,
    } = this.state;

    if (redirectToAdminSettings) {
      return <Redirect to={{ pathname: Constants.Routes.onboarding }} />;
    }

    if (redirectToTermsCond) {
      return <Redirect to={{ pathname: `/termsuse` }} />;
    }

    if (redirectToAdminSchedule) {
      return <Redirect to={{ pathname: `/schedule` }} />;
    }

    if (redirectToDashboard) {
      return <Redirect to={{ pathname: `/dashboard` }} />;
    }

    let currComponent = null;

    if (redirectToTempLogin) {
      currComponent = (
        <TempLogin onRedirectToPasswordReset={this.onRedirectToPasswordReset} />
      );
    }

    if (redirectToPasswordReset) {
      currComponent = <PasswordReset username={this.state.username} />;
    }

    if (redirectToNormalLogin) {
      currComponent = <NormalLogin />;
    }

    return (
      <div className="login">
        {isVisible && (
          <section className="login__header">
            <LoginHeader />
          </section>
        )}
        {isVisible && (
          <section className="login__content">{currComponent}</section>
        )}
        {isVisible && (
          <section className="login__footer">
            <LoginFooter />
          </section>
        )}
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(Login);
