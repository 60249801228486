import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import './CreateQuoteDialog.scss';
import CloseIcon from '@material-ui/icons/Close';
import TwoWayConversationService from '../../Services/twoWayConversationService';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Events from '../../events';
import Constants from '../../constants';
export class CreateQuoteDialog extends Component {
  /**
   * Initializes a new instance of the CreateQuoteDialog component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      quoteText: '',
    };
    this._twoWayConversationService = new TwoWayConversationService();
  }

  componentDidMount() {
    this.setupSubscriptions();
  }

  componentWillUnmount() {
    Events.removeAllListeners(Constants.Events.ConversationNewTemplateLoaded);
  }

  handleChange = (e, key) => {
    this.setState({ [key]: e.target.value });
  };

  setupSubscriptions = () => {
    Events.on(Constants.Events.ConversationNewTemplateLoaded, () => {
      const { firstName, lastName, storeName, storeAddress, storePhone, t } =
        this.props;

      const text = `${momentLocaleWrapper().format('LL')}

${t('quoteTitle', { firstName: firstName, lastName: lastName })}

${storeName}
${storeAddress}
${storePhone}

${t('Dear')} ${firstName}
${t(
  'Thanks for your visit today. Please see below for the details of the products shared today. Please let us know if you have any questions.'
)}

--${t('Begin typing or paste quote text here')}--


${t('Thank You!')}

${t(
  'Disclaimer: This quote is valid for 7 days and is the sole responsibility of the sending location.'
)}`;

      this.setState({
        quoteText: text,
      });
    });
  };

  handleSave = () => {
    const { quoteText } = this.state;
    const { handleSave } = this.props;

    const data = {
      quoteText: quoteText,
    };

    handleSave(data);
    this.handleClose();
  };

  handleCancel() {
    const { handleClose } = this.props;
    handleClose();
  }

  handleClose() {
    const {
      handleClose,
      firstName,
      lastName,
      storeName,
      storeAddress,
      storePhone,
      t,
    } = this.props;

    const text = `${momentLocaleWrapper().format('LL')}

${t('quoteTitle', { firstName: firstName, lastName: lastName })}

${storeName}
${storeAddress}
${storePhone}

${t('Dear')} ${firstName}
${t(
  'Thanks for your visit today. Please see below for the details of the products shared today. Please let us know if you have any questions.'
)}

<<${t('Begin typing or paste quote text here')}>>


${t('Thank You!')}

${t(
  'Disclaimer: This quote is valid for 7 days and is the sole responsibility of the sending location.'
)}`;

    this.setState({
      quoteText: text,
    });

    handleClose();
  }

  disableSave = () => {
    const { quoteText } = this.state;

    if (!quoteText) {
      return true;
    }
    return false;
  };

  render() {
    const { shouldDisplayModal, t } = this.props;

    const { quoteText } = this.state;

    return (
      <Dialog
        onClose={() => this.handleClose()}
        aria-labelledby="simple-dialog-title"
        open={shouldDisplayModal}
        className="create-quote-dialog"
        disableBackdropClick
      >
        <DialogTitle className="create-quote-dialog--title">
          <div className="create-quote-dialog--title--container">
            <h3 className="create-quote-dialog--title--container--modal-title">
              {t('Create a Customer Quote')}
            </h3>
            <div
              className="create-quote-dialog--title--container--close-btn"
              onClick={() => this.handleClose()}
            >
              <CloseIcon></CloseIcon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="create-quote-dialog--content">
          <Paper>
            <p class="instructions">
              {t(
                'Use the form below to generate a quote. A PDF version of the quote will automatically be attached to your customer message.'
              )}
            </p>
            <div class="input-container">
              <textarea
                maxLength="5000"
                onChange={(e) => this.handleChange(e, 'quoteText')}
                placeholder={t('Begin typing or paste quote text here')}
                value={quoteText}
              ></textarea>
            </div>
          </Paper>
        </DialogContent>
        <DialogActions>
          <button
            className="cancel-btn"
            color="primary"
            onClick={() => this.handleClose()}
          >
            {t('Close')}
          </button>
          <button
            className="accept-btn"
            onClick={() => this.handleSave()}
            disabled={this.disableSave()}
          >
            {t('Save')}
          </button>
          <div className="prescription-preview__controls"></div>
        </DialogActions>
      </Dialog>
    );
  }
}
