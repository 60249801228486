import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { PrescriptionPreview } from '../PrescriptionPreview/PrescriptionPreview';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import StellestUploadExample from '../../Assets/Images/Stellest/UploadExample.png';
import './PrescriptionUpload.scss';

const SearchTooltip = withStyles((theme) => ({
  arrow: {
    color: 'lightgrey',
  },
  tooltip: {
    backgroundColor: 'white',
    boxShadow: theme.shadows[1],
    color: 'black',
    fontSize: 16,
    padding: 5,
  },
}))(Tooltip);

/**
 * Represents a photo cropper.
 */
export default class PrescriptionUpload extends Component {
  MODES = {
    AWAITING: 'AWAITING',
    EDITING: 'EDITING',
    PREVIEWING: 'PREVIEWING',
    ACCEPTED: 'ACCEPTED',
  };

  /**
   * Initializes a new instance of the PrescriptionUpload component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      wasCanvasCleared: false,
      mode: this.MODES.AWAITING,
    };
    this._canvas = React.createRef();
    this._cropper = null;
    this._modalPreviewImg = React.createRef();
  }

  componentDidMount = async () => {
    const { photo, mode, sasToken } = this.props;

    if (photo && sasToken) {
      //handle sas token
      //retrieve image
      //load image cropper

      //const params = (new URLSearchParams(sasToken)).toString();
      const params = sasToken.substring(sasToken.indexOf('?'));
      const url = photo + params;
      this._handleAcceptPreview(url);
    }

    if (mode) {
      this.setState({ mode: mode });
    }
  };

  _handleClickSave = () => {
    if (this._cropper) {
      const dataUrl = this._cropper.getCroppedCanvas().toDataURL();
      this.setState({ imageUrlData: dataUrl, mode: this.MODES.PREVIEWING });
    }
  };

  _handleAcceptPreview = async (dataUrl) => {
    const { onPhotoCropped } = this.props;
    onPhotoCropped && onPhotoCropped(dataUrl);
    this._cropper && this._cropper.destroy();
    const canvas = await this._loadCanvasFromDataUrl(
      this._canvas.current,
      dataUrl
    );
    canvas.classList.add('photo');
    this.setState({ mode: this.MODES.ACCEPTED });
  };

  _handleRejectPreview = () => {
    this.setState({ mode: this.MODES.EDITING, imageUrlData: undefined });
  };

  _handleClickRestart = () => {
    if (this._cropper) {
      this._cropper.destroy();
      this._cropper = null;
      const canvas = this._canvas.current;
      canvas.classList.remove('photo');
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);

      this.setState(() => ({
        wasCanvasCleared: true,
      }));
    }

    const { onPhotoDeleted } = this.props;
    onPhotoDeleted && onPhotoDeleted();
    this.setState({ mode: this.MODES.AWAITING, imageUrlData: undefined });
  };

  _handlePhotoUploaded = async (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        this._canvas.current.classList.remove('photo');
        const dataUrl = e.target.result;
        this._cropper = await this._createCropperFromDataUrl(
          this._canvas.current,
          dataUrl
        );
      };
      fileReader.readAsDataURL(file);

      this.setState(() => ({ showDropzone: false, mode: this.MODES.EDITING }));
    }
  };

  _createCropperFromDataUrl = (canvas, dataUrl) => {
    const result = new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const context = canvas.getContext('2d');
        context.canvas.height = img.height;
        context.canvas.width = img.width;
        context.drawImage(img, 0, 0);
        const cropper = new Cropper(canvas, {
          checkCrossOrigin: false,
        });
        resolve(cropper);
      };
      img.src = dataUrl;
    });

    return result;
  };

  _loadCanvasFromDataUrl = (canvas, dataUrl) => {
    const result = new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const context = canvas.getContext('2d');
        context.canvas.height = img.height;
        context.canvas.width = img.width;
        context.drawImage(img, 0, 0);
        resolve(canvas);
      };
      img.src = dataUrl;
    });

    return result;
  };

  /**
   * Renders the component.
   */
  render() {
    const { imageUrlData, mode } = this.state;
    const { accept, id, maxSize, t, photo, sasToken, error } = this.props;
    return (
      <div
        className={`prescription-upload ${
          photo && sasToken ? 'prescription-upload--with-image' : ''
        }${error ? ' error' : ''}`}
      >
        <div className="prescription-upload__workspace">
          <div className="prescription-upload__workspace-label">
            {t('Prescription Photo Upload')}
            <span className="required MuiFormLabel-root MuiFormLabel-asterisk">
              *
            </span>
          </div>
          <div className="MuiPaper-root MuiCard-root MuiPaper-outlined MuiPaper-rounded prescription-upload__workspace-surface">
            {mode === this.MODES.AWAITING && (
              <Dropzone
                accept={accept}
                maxSize={maxSize}
                onDrop={this._handlePhotoUploaded}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  rejectedFiles,
                }) => {
                  const isFileTooLarge =
                    rejectedFiles &&
                    rejectedFiles.length > 0 &&
                    rejectedFiles[0].size > maxSize;

                  return (
                    <section className="dropzone" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="dropzone-instructions">
                        <div>
                          {t('Drag and drop photo or')} <br></br>
                          <AddAPhotoIcon></AddAPhotoIcon>
                          <br></br>
                          {t(
                            'click anywhere in the box to upload prescription'
                          )}
                        </div>
                      </div>
                      {isFileTooLarge && (
                        <p className="error">{t('File is too large')}</p>
                      )}
                    </section>
                  );
                }}
              </Dropzone>
            )}
            <canvas className="cropper-canvas" ref={this._canvas}></canvas>
          </div>
          {!photo && !sasToken && (
            <div className="prescription-upload__workspace-instructions">
              <p>
                <b>
                  {t('Acceptable formats: .JPG or .PNG. No larger than 20 MB')}
                </b>
              </p>
              <p>
                {t(
                  'To upload a photo of the prescription click anywhere in the box above.  Then crop image around the Prescription information (by resizing the box) shown in the example.'
                )}
                <SearchTooltip
                  className="tooltip"
                  title={
                    <>
                      <span className="tooltipText">
                        {t('Crop image similar to this example')}
                      </span>
                      <img
                        className="tooltipImg"
                        src={StellestUploadExample}
                        alt="Stellest upload example"
                      />
                    </>
                  }
                  leaveDelay={300}
                >
                  <InfoOutlinedIcon />
                </SearchTooltip>
              </p>
              <p>
                {t('Once you are satisfied with your crop, SAVE and FINISH.')}
              </p>
            </div>
          )}
        </div>
        {!photo && !sasToken && (
          <div className="prescription-upload__controls">
            <button
              className="mb-2 button button-primary"
              id={`prescription-upload__restart${id}`}
              type="button"
              onClick={this._handleClickRestart}
              disabled={mode === this.MODES.AWAITING}
            >
              {t('Restart')}
            </button>
            <button
              className="button button-primary"
              id={`prescription-upload__save${id}`}
              type="button"
              onClick={this._handleClickSave}
              disabled={mode !== this.MODES.EDITING}
            >
              {t('Save')}
            </button>
          </div>
        )}

        <PrescriptionPreview
          handleRejectPreview={this._handleRejectPreview}
          handleAcceptPreview={this._handleAcceptPreview}
          shouldDisplayModal={mode === this.MODES.PREVIEWING}
          imageUrlData={imageUrlData}
          t={t}
        ></PrescriptionPreview>
      </div>
    );
  }
}

// Default prop types
PrescriptionUpload.defaultProps = {
  accept: 'image/jpeg, image/jpg, image/png',
  maxSize: 1048576 * 20, // 20MB
};

// Strongly typed properties that this component will receive.
PrescriptionUpload.propTypes = {
  accept: PropTypes.string,
  maxSize: PropTypes.number,
  photo: PropTypes.string,
  onPhotoDeleted: PropTypes.func,
  onPhotoCropped: PropTypes.func,
  mode: PropTypes.string,
};
