import Storage from '../storage';
import { StellestOrderType } from '../enums';

/**
 * Represents the site configuration service.
 */
class FeatureConfigurationService {
  async getFeatureConfiguration(environment, market, country, site, location) {
    //static data store
    const configurationStore = {
      environments: {
        development: {
          EnableAddPlusMenu: false,
          EnableEditPatientMessagePlatform: true,
          CustomerQuoteEnabled: true,
          CreateCustomerQuoteButtonEnabled: true,
          FileAttachmentEnabled: true,
          EnabledStellestOrderTypes: [
            StellestOrderType.StandardLens,
            StellestOrderType.Assurance,
          ],
        },
        qa: {
          EnableAddPlusMenu: false,
          EnableEditPatientMessagePlatform: true,
          CustomerQuoteEnabled: true,
          CreateCustomerQuoteButtonEnabled: true,
          FileAttachmentEnabled: true,
          EnabledStellestOrderTypes: [
            StellestOrderType.StandardLens,
            StellestOrderType.Assurance,
          ],
        },
        staging: {
          EnableAddPlusMenu: false,
          EnableEditPatientMessagePlatform: true,
          CustomerQuoteEnabled: true,
          CreateCustomerQuoteButtonEnabled: true,
          FileAttachmentEnabled: true,
          EnabledStellestOrderTypes: [
            StellestOrderType.StandardLens,
            StellestOrderType.Assurance,
          ],
        },
        production: {
          EnableAddPlusMenu: false,
          EnableEditPatientMessagePlatform: true,
          CustomerQuoteEnabled: true,
          CreateCustomerQuoteButtonEnabled: true,
          FileAttachmentEnabled: true,
          EnabledStellestOrderTypes: [
            StellestOrderType.StandardLens,
            StellestOrderType.Assurance,
          ],
        },
      },
      markets: {
        latam: {},
      },
      countries: {
        BR: {},
        CO: {},
      },
      sites: {
        'eyebooknow.com': {},
        'citaconmisojos.com': {},
      },
      locations: {},
    };

    let configuration = {};

    // build configuration
    Object.assign(configuration, configurationStore.environments[environment]);
    Object.assign(configuration, configurationStore.markets[market]);
    Object.assign(configuration, configurationStore.countries[country]);
    Object.assign(configuration, configurationStore.sites[site]);
    Object.assign(configuration, configurationStore.locations[location]);

    const featureOverrides = Storage.getItem('featureConfiguration');
    if (featureOverrides) {
      Object.assign(configuration, featureOverrides);
    }

    return Promise.resolve(configuration);
  }
}

export default FeatureConfigurationService;
