import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { withStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Utils from '../../utils';
import Enums from '../../enums';
import Events from '../../events';
import Storage from '../../storage';
import Constants from '../../constants';
import Overlay from '../../Components/Overlay/Overlay';
import StoreInformation from './StoreInformation';
import StoreHours from './StoreHours';
import AppointmentTypes from './AppointmentTypes';
import { withTranslation } from 'react-i18next';
import Logger from '../../logger';
import TermsConditionsModal from '../TermsConditions/TermsConditionsModal';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import ContentManagementService from '../../Services/contentManagementService';
import './Onboarding.scss';

const eBuilder = new EventBuilder();
const pBuilder = new PageviewBuilder();

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const ExpansionPanel = withStyles({
  root: {
    '&:before': {
      background: 'none',
    },
    boxShadow: 'none',
  },
  expanded: {
    '&$expanded': {
      boxShadow: 'none',
      margin: '0',
    },
  },
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    minHeight: 0,
    '&$expanded': {
      minHeight: 0,
    },
    padding: 0,
  },
  content: {
    borderBottom: '1px solid #979797',
    justifyContent: 'space-between',
    margin: 0,
    padding: '0.5em 1.5em 0.5em 2em',
    '&$expanded': {
      margin: 0,
      borderBottom: 'none',
    },
    expanded: {},
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    display: 'block',
    padding: '0',
  },
})(MuiExpansionPanelDetails);

const Dialog = withStyles({
  root: {
    maxWidth: 'auto',
  },
})(MuiDialog);

/**
 * Represents the admin onboarding dashboard.
 */
class Onboarding extends Component {
  /**
   * Initializes a new instance of the Onboarding component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this._isOnboarded = false;
    this._maxApptTypes = 10;
    this._supportInfo = {
      phone: '',
      email: '',
    };
    this.state = {
      canSubmit: true,
      currApptType: {
        displayName: '',
      },
      currResource: {
        id: -1,
        displayName: '',
        availableHours: [],
      },
      errorMessage: '',
      isVisible: false,
      maxApptTypesReached: false,
      onboardingInfo: {
        storeHours: [],
        appointmentTypes: [],
        agreementSigned: '',
        storeInformation: {
          name: '',
          address: '',
          phone: '',
          website: '',
        },
        resources: [],
      },
      redirectToLogin: false,
      redirectToScheduler: false,
      showLoadingOverlay: false,
      toggleRemoveApptTypeModal: false,
      toggleRemoveResourceModal: false,
      toggleStaffMemberHoursModal: false,
    };
    this._contentManagementService = new ContentManagementService();

    this.logoUrl = Utils.getLogoUrl();

    eBuilder.withCategory(eBuilder.Category.Onboarding.registration);

    pBuilder.pageview(pBuilder.Page.Registration.settings);
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    const user = Storage.getItem(Constants.currUserKey);

    if (user && user.jwt) {
      try {
        const jwtCheckUrl = `${process.env.REACT_APP_ADMIN_API}/jwtCheck`;
        await axios.get(jwtCheckUrl);

        if (!user.agreementSigned) {
          window.location.href = '/termsuse';
        }

        try {
          this.setState(() => ({ showLoadingOverlay: true }));

          const locationId = Storage.getItem(Constants.currLocIdKey);
          const locationConfigurationApiUrl = `${process.env.REACT_APP_SETTINGS_API}/getLocationConfig/${locationId}`;
          const res = await axios.get(locationConfigurationApiUrl);
          let onboardingInfo = null;

          if (res && res.data) {
            onboardingInfo = res.data;
          }

          Utils.formatLocationInfoApptTypes(onboardingInfo);
          Utils.formatLocationInfoResources(onboardingInfo);

          // Make a copy of the market hours for later setting.
          //get market defaults
          if (onboardingInfo.storeHours) {
            this._defaultMarketHours = cloneDeep(onboardingInfo.storeHours);
          } else {
            this._defaultMarketHours = Utils.getDefaultFormattedStoreHours();
          }

          this._defaultResourceHours = Utils.getDefaultFormattedResourceHours();

          await this._setI18nLanguage(
            onboardingInfo.storeInformation.languageTag
          );

          const { resources } = onboardingInfo;
          const defaultResource = resources[0];

          if (defaultResource.displayName === '') {
            const { t } = this.props;
            defaultResource.displayName = t('Default Resource Display Name');
          }

          let isOnboarded = false;

          // If the location is onboarded, then the admin is unable to make any more edits.
          if (onboardingInfo.agreementSigned) {
            isOnboarded = true;
          }

          this._isOnboarded = isOnboarded;

          let supportInfo = Storage.getItem(Constants.supportInfoKey);

          const supportInfoUrl = `${process.env.REACT_APP_CONTENT_API}/getSupportInfo`;
          const data = {
            countryCode: onboardingInfo.storeInformation.countryCode,
          };
          const supportInfoRes = await axios.post(supportInfoUrl, data);

          if (supportInfoRes && supportInfoRes.data) {
            supportInfo = { ...supportInfoRes.data };
          }

          this._supportInfo = { ...supportInfo };

          Storage.setItem(Constants.supportInfoKey, this._supportInfo);

          Events.emit(Constants.Events.pageReady);

          this.setState(() => ({
            isVisible: true,
            onboardingInfo: onboardingInfo,
            showLoadingOverlay: false,
          }));
        } catch (error) {
          const { t } = this.props;
          if (
            (error && !error.response) ||
            (error &&
              error.response.status ===
                Enums.HttpStatusCodes.httpStatusInternalServerError)
          ) {
            this.setState(() => ({
              isVisible: true,
              showLoadingOverlay: false,
              errorMessage: t(
                'An unexpected issue occurred while retrieving the onboarding data'
              ),
            }));
          }
        }
      } catch {
        this.setState(() => ({
          redirectToLogin: true,
        }));
      }
    } else {
      this.setState(() => ({ redirectToLogin: true }));
      return;
    }
  }

  _setI18nLanguage = async (langTag) => {
    await this._contentManagementService.loadLocalizations(langTag);
    return this.props.i18n.changeLanguage(langTag);
  };

  _formatLocationInfoStoreHours = (onboardingInfo) => {
    if (onboardingInfo) {
      // A location does not have store hours during onboarding, so check for the
      // presence of them first and then setup the default hours if not available.
      if (onboardingInfo.storeHours && onboardingInfo.storeHours.length > 0) {
        onboardingInfo.storeHours = [];
        const sundayId = 1;

        for (let index = 1; index <= 7; ++index) {
          if (index === sundayId) {
            onboardingInfo.storeHours.push({
              dayOfWeekId: index,
              startTime: 144,
              endTime: 240,
            });
          } else {
            onboardingInfo.storeHours.push({
              dayOfWeekId: index,
              startTime: 120,
              endTime: 252,
            });
          }
        }
      } else {
        onboardingInfo.storeHours = onboardingInfo.schedule.schedules.map(
          (s) => ({
            dayOfWeekId: s.dayOfWeekId,
            startTime: s.timeBlocks[0].start,
            endTime: s.timeBlocks[0].end,
          })
        );
      }

      delete onboardingInfo.schedule;
    }
  };

  /**
   * Executes when a new appointment type is added.
   */
  onAddAppointmentType = () => {
    this.setState((prevState) => {
      const { onboardingInfo } = prevState;

      const { t } = this.props;

      let newId = 0;
      let newSortOrder = 0;
      // Find the max id from the appointment types id and increment by 1.
      if (
        onboardingInfo.appointmentTypes &&
        onboardingInfo.appointmentTypes.length > 0
      ) {
        newId =
          onboardingInfo.appointmentTypes.reduce((a, b) =>
            a.id > b.id ? a : b
          ).id + 1;
        newSortOrder =
          onboardingInfo.appointmentTypes.reduce((apptType1, apptType2) =>
            apptType1.sortOrder > apptType2.sortOrder ? apptType1 : apptType2
          ).sortOrder + 1;
      }

      onboardingInfo.appointmentTypes = onboardingInfo.appointmentTypes.concat({
        displayName: t(`Service Type`),
        duration: 15,
        id: newId,
        isEditing: true,
        isNew: true,
        sortOrder: newSortOrder,
      });

      let maxApptTypesReached = false;

      if (onboardingInfo.appointmentTypes.length === this._maxApptTypes) {
        maxApptTypesReached = true;
      }

      eBuilder
        .withAction(eBuilder.Action.Onboarding.Click.appointmentTypesAdd)
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      return {
        maxApptTypesReached: maxApptTypesReached,
        onboardingInfo: onboardingInfo,
      };
    });
  };

  /**
   * Executes when an appointment type is edited.
   * @param {Number} id The id of the appointment type.
   * @param {Array} path The path of the property to set.
   * @param {Any} value The value.
   */
  onEditAppointmentType = (id, path, value) => {
    this.setState((prevState) => {
      const { onboardingInfo } = prevState;
      onboardingInfo.appointmentTypes = onboardingInfo.appointmentTypes.map(
        (at) => {
          if (at.id === id) {
            Utils.update(at, path, value);
          }

          return at;
        }
      );

      return {
        onboardingInfo: onboardingInfo,
      };
    });
  };

  /**
   * Executes when an appointment type is enabled.
   * @param {Object} apptType The appointment type being edited.
   */
  onEnableAppointmentType = (apptType) => {
    if (apptType) {
      this.setState((prevState) => {
        const { onboardingInfo } = prevState;
        onboardingInfo.appointmentTypes = onboardingInfo.appointmentTypes.map(
          (at) => {
            if (at.id === apptType.id) {
              at.isEditing = true;
            }

            return at;
          }
        );

        return {
          onboardingInfo: onboardingInfo,
        };
      });

      eBuilder
        .withAction(eBuilder.Action.Onboarding.Click.appointmentTypesEdit)
        .withLabel(
          eBuilder.Label.alternateLabel,
          `${apptType.displayName};${apptType.duration}`
        )
        .post();

      eBuilder.withLabel(eBuilder.Label.practiceIdentifier).post();
    }
  };

  /**
   * Executes when an appointment type is moved.
   * @param {Number} currIndex The current index the item to move from.
   * @param {Number} newIndex The new index the item to move to.
   */
  onMoveAppointmentType = (currIndex, newIndex) => {
    let appointmentTypes = cloneDeep(
      this.state.onboardingInfo.appointmentTypes
    );

    const typeToReplace = appointmentTypes[newIndex];
    if (
      newIndex >= 0 &&
      newIndex < appointmentTypes.length &&
      typeToReplace.sortOrder >= 0
    ) {
      this.setState((prevState) => {
        const { onboardingInfo } = prevState;
        const oldAppointmentType = appointmentTypes.splice(currIndex, 1)[0];

        appointmentTypes.splice(newIndex, 0, oldAppointmentType);
        // Sort the appointment types by their order.
        appointmentTypes = appointmentTypes.map((apptType, index) => {
          if (apptType.sortOrder >= 0) {
            apptType.sortOrder = index;
          }
          return apptType;
        });
        onboardingInfo.appointmentTypes = appointmentTypes;

        return {
          onboardingInfo: onboardingInfo,
        };
      });
    }
  };

  /**
   * Executes when an appointment type is being removed.
   * @param {Object} apptType The appointment type being removed.
   */
  onRemoveAppointmentType = (apptType) => {
    if (apptType) {
      this.setState((prevState) => {
        const { onboardingInfo } = prevState;
        onboardingInfo.appointmentTypes =
          onboardingInfo.appointmentTypes.filter(
            (item) => item.id !== apptType.id
          );

        let maxApptTypesReached = false;

        if (onboardingInfo.appointmentTypes.length === this._maxApptTypes) {
          maxApptTypesReached = true;
        }

        return {
          onboardingInfo: onboardingInfo,
          maxApptTypesReached: maxApptTypesReached,
          toggleRemoveApptTypeModal: !prevState.toggleRemoveApptTypeModal,
        };
      });
    }
  };

  /**
   * Executes whenn the day of the week changes for a location.
   * @param {Number} dayOfWeekId The day of week id.
   * @param {Boolean} isOpen A value that determines whether the day is open for the location.
   */
  onStoreDayOfWeekChange = (dayOfWeekId, isOpen) => {
    this.setState((prevState) => {
      const { onboardingInfo } = prevState;
      let canSubmit = true;
      onboardingInfo.storeHours = onboardingInfo.storeHours.map((sh) => {
        if (sh.dayOfWeekId === dayOfWeekId) {
          if (isOpen) {
            const defaultMarketHoursForDay = this._defaultMarketHours.find(
              (dmh) => dmh.dayOfWeekId === dayOfWeekId
            );
            Logger.log(defaultMarketHoursForDay);

            if (defaultMarketHoursForDay) {
              sh.startTime = defaultMarketHoursForDay.startTime;
              sh.endTime = defaultMarketHoursForDay.endTime;
            }
          } else {
            const closed = -1;
            sh.startTime = closed;
            sh.endTime = closed;
            sh.timeBlockError = '';
          }
        }

        if (sh.timeBlockError) {
          canSubmit = false;
        }

        return sh;
      });

      eBuilder
        .withAction(eBuilder.Action.Onboarding.Click.editStoreHours)
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      return {
        canSubmit: canSubmit,
        onboardingInfo: onboardingInfo,
      };
    });
  };

  /**
   * Executes when the time block changes for a day of the week for a location.
   * @param {Number} dayOfWeekId The day of the week id.
   * @param {Number} startTime The start time block.
   * @param {Number} endTime The end time block.
   */
  onStoreTimeBlockChange = (dayOfWeekId, startTime, endTime) => {
    this.setState((prevState) => {
      let isValid = true;
      const { onboardingInfo } = prevState;

      const { t } = this.props;
      onboardingInfo.storeHours = onboardingInfo.storeHours.map((sh) => {
        if (sh.dayOfWeekId === dayOfWeekId) {
          if (startTime) {
            sh.startTime = startTime;
          } else {
            sh.endTime = endTime;
          }
        }

        if (sh.startTime >= sh.endTime && sh.startTime > 0 && sh.endTime > 0) {
          isValid = false;
          sh.timeBlockError = t('Start must be before Finish');
        } else {
          sh.timeBlockError = '';
        }

        return sh;
      });

      eBuilder
        .withAction(eBuilder.Action.Onboarding.Click.editStoreHours)
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      return {
        canSubmit: isValid,
        onboardingInfo: onboardingInfo,
      };
    });
  };

  _onSubmitOnboardingInfo = async () => {
    try {
      this.setState(() => ({
        errorMessage: '',
        canSubmit: false,
      }));

      const { onboardingInfo } = this.state;
      const user = Storage.getItem(Constants.currUserKey);
      const jwtData = Utils.decodeJwt(user.jwt);
      const curLoc = Storage.getItem(Constants.currLocIdKey);

      const locationInfoUrl = `${process.env.REACT_APP_SETTINGS_API}/saveLocationConfig/${curLoc}`;
      onboardingInfo.agreementSigned = new Date().toISOString();

      if (onboardingInfo.locationId === 0) {
        onboardingInfo.locationId = parseInt(jwtData.defaultLocation); //may be a problem down the road
      }

      await axios.post(locationInfoUrl, onboardingInfo);

      eBuilder.withAction(eBuilder.Action.Onboarding.Click.submit).post();

      const isStellestEnabled = onboardingInfo.locationSettings
        ? onboardingInfo.locationSettings.find(
            (e) =>
              e.displayName ===
              Constants.LocationSettings.settingIsStellestEnabled
          ).settingValue
        : false;

      if (isStellestEnabled) {
        this.setState(() => ({ redirectToDashboard: true }));
      } else {
        this.setState(() => ({ redirectToScheduler: true }));
      }
    } catch (error) {
      const { t } = this.props;
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        this.setState(() => ({
          canSubmit: true,
          errorMessage: t('An unexpected issue occurred while submitting'),
        }));
      }
    }
  };

  /**
   * Executes when its time to toggle the remove appointment type modal.
   * @param {Object} apptType The appointment type to remove.
   */
  onToggleRemoveAppointmentTypeModal = (apptType) => {
    if (apptType) {
      this.setState((prevState) => ({
        currApptType: apptType,
        toggleRemoveApptTypeModal: !prevState.toggleRemoveApptTypeModal,
      }));
    }
  };

  /**
   * Executes when its time to toggle the staff member hours modal.
   * @param {Object} resource The resource to toggle staff member hours for.
   */
  onToggleStaffMemberHoursModal = (resource) => {
    if (resource) {
      this.setState((prevState) => ({
        currResource: resource,
        toggleStaffMemberHoursModal: !prevState.toggleStaffMemberHoursModal,
      }));
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      canSubmit,
      currApptType,
      errorMessage,
      isVisible,
      onboardingInfo,
      redirectToLogin,
      redirectToScheduler,
      redirectToDashboard,
      maxApptTypesReached,
      showLoadingOverlay,
      toggleRemoveApptTypeModal,
    } = this.state;
    const { appointmentTypes, storeHours } = onboardingInfo;
    const { email } = this._supportInfo;

    if (redirectToLogin) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    if (redirectToScheduler) {
      return <Redirect to={{ pathname: `/schedule` }} />;
    }

    if (redirectToDashboard) {
      return <Redirect to={{ pathname: Constants.Routes.dashboard }} />;
    }

    const { t } = this.props;

    return (
      <div className="admin-onboarding">
        {isVisible && (
          <section className="admin-brand">
            <img
              alt="EyeBookNow Logo"
              className="admin-brand__logo"
              src={this.logoUrl}
            />
          </section>
        )}
        {isVisible && (
          <section className="admin-header">
            <h1 className="admin-header__title">
              {t('Registration & Onboarding Setup')}
            </h1>
            <h5 className="cal-adminsettings__supportInfo">
              {`${t('Have Support Questions?')}`}{' '}
              <a href={`mailto:${email}`}>{`${email}`}</a>
            </h5>
          </section>
        )}
        {isVisible && (
          <section className="admin-content">
            <StoreInformation onboardingInfo={onboardingInfo} />
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary>
                <h2 className="admin-content__title">
                  {t('Hours of Operation')}
                </h2>
                <div className="admin-content__summary">
                  <i className="admin-content__edit material-icons">
                    arrow_drop_down
                  </i>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <StoreHours
                  isOnboarded={this._isOnboarded}
                  locationHours={storeHours}
                  showSave={false}
                  onStoreDayOfWeekChange={this.onStoreDayOfWeekChange}
                  onStoreTimeBlockChange={this.onStoreTimeBlockChange}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary>
                <h2 className="admin-content__title">{t('Service Types')}</h2>
                <div className="admin-content__summary">
                  <i className="admin-content__edit material-icons">
                    arrow_drop_down
                  </i>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <AppointmentTypes
                  appointmentTypes={appointmentTypes}
                  isOnboarded={this._isOnboarded}
                  maxApptTypesReached={maxApptTypesReached}
                  showAdd={!this._isOnboarded}
                  showSave={false}
                  onAddAppointmentType={this.onAddAppointmentType}
                  onEditAppointmentType={this.onEditAppointmentType}
                  onEnableAppointmentType={this.onEnableAppointmentType}
                  onMoveAppointmentType={this.onMoveAppointmentType}
                  onToggleAppointmentTypeModal={
                    this.onToggleRemoveAppointmentTypeModal
                  }
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </section>
        )}
        {isVisible && (
          <section className="admin-footer">
            <TermsConditionsModal
              isFromSettings={true}
              eventBuilder={eBuilder}
            />
            <div>
              <span className="admin-footer__error-message">
                {t(errorMessage)}
              </span>
              {!this._isOnboarded && (
                <span className="admin-footer__divider"></span>
              )}
              <span className="admin-footer__sponsor">
                {t('Powered by Essilor')}
              </span>
              {!this._isOnboarded && (
                <button
                  className="admin-footer__submit"
                  disabled={!canSubmit}
                  onClick={this._onSubmitOnboardingInfo}
                >
                  {t('Submit')}
                </button>
              )}
            </div>
          </section>
        )}
        <Dialog open={toggleRemoveApptTypeModal}>
          <DialogTitle>{t('Confirm')}</DialogTitle>
          <DialogContent>
            {Utils.isTurkeyMarket(Storage.getItem(Constants.langTag)) ? (
              <p className="appt-types__modal-body">
                <span className="appt-types__appt-type">
                  {currApptType.displayName}
                </span>
                &nbsp;{t('Are you sure to delete the service type?')}
              </p>
            ) : (
              <p className="appt-types__modal-body">
                {t('Remove?')}&nbsp;
                <span className="appt-types__appt-type">
                  {currApptType.displayName}?
                </span>
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <button
              className="appt-types__modal-button appt-types__modal-button--cancel"
              onClick={() => {
                this.onToggleRemoveAppointmentTypeModal(currApptType);
              }}
            >
              {t('Cancel')}
            </button>
            <button
              className="appt-types__modal-button appt-types__modal-button--confirm"
              onClick={() => {
                this.onRemoveAppointmentType(currApptType);
              }}
            >
              {t('Remove')}
            </button>
          </DialogActions>
        </Dialog>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(Onboarding);
