import Storage from './storage';
import Constants from './constants';
import GoogleAnalytics from './googleAnalytics';

class EventCategory {
  Onboarding = {
    registration: 'Admin_Registration',
  };
  Scheduler = {
    experience: 'Admin_Experience',
    bookAppointment: 'Admin_Experience_Book_Appt',
    clickAppointment: 'Admin_Experience_Click_Appointment',
  };
  settings = 'Admin_Settings';
  Chat = 'Admin_Chat';
}

Object.freeze(EventCategory.Onboarding);
Object.freeze(EventCategory.Scheduler);
Object.freeze(EventCategory.Settings);
Object.freeze(EventCategory.Chat);
Object.freeze(EventCategory);

class EventAction {
  Onboarding = {
    Click: {
      editStoreHours: 'Click_Edit_Store_Hours',
      termsOfService: 'Click_Terms_Of_Service',
      appointmentTypesAdd: 'Click_Appointment_Types_Add',
      appointmentTypesEdit: 'Click_Appointment_Types_Edit',
      submit: 'Click_Submit',
      agreeTos: 'Click_Agree_TOS',
      loginInitial: 'Click_Login_Initial',
      resetPassword: 'Click_Reset_Password',
    },
  };
  Scheduler = {
    Click: {
      login: 'Click_Login',
      logout: 'Click_Logout',
      toggleDrawer: 'Click_Toggle_Drawer',
      forgotPassword: 'Click_Forgot_Password',
      Waitlist: {
        confirm: 'Click_Confirm_Walk_In_Appointment',
        appointment: 'Click_Walk_In_Appointment',
        editAppointment: 'Click_Edit_Walk_In_Appointment',
        noShowAppointment: 'Click_No_Show_Walk_In_Appointment',
        deleteAppointment: 'Click_Reject_Walk_In_Appointment',
      },
      AddAppointment: {
        add: 'Click_Add_Appointment',
        reason: 'Click_Reason_', // append appt type
        providerName: 'Click_Provider_Name',
        waitlistToggle: 'Wait_List_Toggle_', // append on or off
        confirmBook: 'Click_Confirm_Booking_Book',
        confirmWaitlist: 'Click_Confirm_Booking_Waitlist',
      },
      AddStellest: {
        add: 'Click_Add_Stellest',
        formToggle: 'Stellest_Registration_Form_Toggle',
      },
      ExistingAppointment: {
        existingAppointment: 'Click_Existing_Appointment',
        edit: 'Click_Edit_Appt',
        delete: 'Click_Delete',
        noShow: 'Click_No_Show',
      },
      appointmentTypesFilter: 'Click_Appointment_Types_Filter',
      changeLocation: 'Click_ChangeLocation_Dropdown',
    },
  };
  Settings = {
    Click: {
      qrCodePoster: 'Click_QR_Code_Poster',
      printQrCodePoster: 'Click_Print_QR_Code_Poster',
      viewConsumerStorefront: 'Click_View_Consumer_Storefront',
      editStoreHours: 'Click_Edit_Store_Hours',
      saveStoreHours: 'Click_Save_Store_Hours',
      addAppointmentType: 'Click_Appointment_Types_Add',
      editAppointmentType: 'Click_Appointment_Type_Edit',
      saveAppointmentTypes: 'Click_Appointment_Types_Save',
      addResource: 'Click_Resources_Add',
      saveResources: 'Click_Resources_Save',
      editResource: 'Click_Resource_Edit',
      getConsumerData: 'Click_ConsumerData_Get',
    },
  };
  Chat = {
    Click: {
      clickChatFeature: 'Click_Chat_Feature',
      clickNewChatMessage: 'Click_New_Chat_Message',
      clickSendChatButton: 'Click_Send_Chat_Button',
      sendChatAttachment: 'Click_Send_Chat_Button_Send_Attachment'
    },
  };
}

Object.freeze(EventAction.Onboarding);
Object.freeze(EventAction.Scheduler);
Object.freeze(EventAction.Settings);
Object.freeze(EventAction.Chat);
Object.freeze(EventAction);

class EventLabel {
  labelOptions = {
    practiceIdentifier: false,
  };

  practiceIdentifier = () => {
    if (!this.labelOptions.practiceIdentifier) {
      this.init();
    }
    return this.labelOptions.practiceIdentifier;
  };

  practiceIdentifierAndMessagingTypeSMS = () => {
    if (!this.labelOptions.practiceIdentifier) {
      this.init();
    }
    return `${this.practiceIdentifier()};SMS`;
  };

  practiceIdentifierAndMessagingTypeWhatsApp = () => {
    if (!this.labelOptions.practiceIdentifier) {
      this.init();
    }
    return `${this.practiceIdentifier()};WhatsApp`;
  };

  practiceIdentifierWithArgs = (args) => {
    return `${this.practiceIdentifier()};${args}`;
  };

  alternateLabel = (options) => `${options}`;

  init = () => {
    const currUser = Storage.getItem(Constants.currUserKey);

    if (currUser && currUser.uniqueLocationId) {
      this.labelOptions = {
        practiceIdentifier: currUser.uniqueLocationId,
      };
    }
  };
}

Object.freeze(EventLabel);

/**
 * Wrapper class around GA Events.
 */
class EventBuilder {
  constructor() {
    this.category = 'default category';
    this.action = 'default action';
    this.label = 'default label';
    this.nonInteraction = false;

    this.Action = new EventAction();
    this.Label = new EventLabel();
    this.Category = new EventCategory();
  }
  /**
   * Sets the category of the event.
   * @param {string} category Set with this.Category
   */
  withCategory = (category) => {
    this.category = category;
    return this;
  };
  /**
   * Sets the Action of the event.
   * @param {function} action Set with this.Action
   * @param {string} label Optional string to append for this.Action
   */
  withAction = (action, label) => {
    if (label) {
      this.action = `${action}${label}`;
    } else {
      this.action = `${action}`;
    }
    return this;
  };
  /**
   * Sets the Label of the event.
   * @param {function} label Set with this.Label
   * @param {Object} args Optional argument to append.
   */
  withLabel = (label, args) => {
    this.label = label(args);
    return this;
  };

  /**
   * Use this to call the GA Wrapper and post this to GA.
   * This violates SRP (kind of?) but it's real convenient.
   */
  post = () => {
    GoogleAnalytics.postEvent({
      category: this.category,
      action: this.action,
      label: this.label,
      nonInteraction: this.nonInteraction,
    });
  };
}

Object.freeze(EventBuilder);

export default EventBuilder;
