import axios from 'axios';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the notifications component service.
 */
export default class NotificationsService {
  /**
   * Returns a value that determines whether there are unread notifications.
   * @param {Object} data The data.
   * @param data.locationId The location id.
   * @returns A Promise.
   */
  async checkForUnreadNotifications(data) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CALENDAR_API}/checkForUnreadNotifications`;
        const res = await axios.post(url, data);

        resolve(res?.data?.hasUnreadNotifications);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CALENDAR_API}/checkForUnreadNotifications`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns an array of notifications.
   * @param {Object} params The get params.
   * @param params.locationId The location id.
   * @param params.skip The skip value.
   * @param params.take The take value.
   */
  async getNotifications(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CALENDAR_API}/getNotifications`;
        const res = await axios.get(url, { params: { ...params } });

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CALENDAR_API}/getNotifications`
        );
        reject(error);
      }
    });
  }

  /**
   * Saves the provided notification.
   * @param {Object} data The post data.
   * @param data.locationId The location id.
   * @param data.resourceId The resource id.
   * @param data.message The notification message.
   * @param data.isRead A value that determines whether the notification has been read.
   * @param data.originator The user whole booked the appointment.
   * @param data.createdDate The created date of the notification.
   */
  async saveNotification(data) {
    const url = `${process.env.REACT_APP_CALENDAR_API}/saveNotification`;
    return axios.post(url, { notification: data });
  }
}
