import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the location configuration service.
 */
class LocationConfigService {
  /**
   * Returns the location configuration.
   */
  async getLocationConfig() {
    return new Promise(async (resolve, reject) => {
      try {
        const locId = Storage.getItem(Constants.currLocIdKey);
        const url = `${process.env.REACT_APP_SETTINGS_API}/getLocationConfig/${locId}`;
        const res = await axios.get(url);
        let locationConfig = null;

        if (res?.data) {
          locationConfig = { ...res.data };
        }

        resolve(locationConfig);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        const locId = Storage.getItem(Constants.currLocIdKey);
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_SETTINGS_API}/getLocationConfig/${locId}`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the location configuration id.
   */
  getLocationConfigId() {
    return Storage.getItem(Constants.currLocIdKey);
  }
}

export default LocationConfigService;
