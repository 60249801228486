import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EventBuilder from '../../eventBuilder';
import QRCodeContent from '../../Components/QRCodeContent/QRCodeContent';
import './QRCodePoster.scss';

/**
 * Represents a QR Code poster for a store location.
 * @param {Object} props The component properties.
 */
const QRCodePoster = (props) => {
  const { storeName, uniqueLocationUrl } = props;
  const { t } = useTranslation();

  const onPrintQrCodePoster = () => {
    const eBuilder = new EventBuilder();
    eBuilder
      .withCategory(eBuilder.Category.settings)
      .withAction(eBuilder.Action.Settings.Click.printQrCodePoster)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();

    window.print();
  };

  return (
    <div className="qr-code-poster">
      <h2 className="qr-code-poster__heading">
        {t('Print out your Customer QR Code')}
      </h2>
      <QRCodeContent
        storeName={storeName}
        uniqueLocationUrl={uniqueLocationUrl}
      />
      <div className="qr-code-poster__print-cont">
        <button
          className="qr-code-poster__print"
          type="button"
          onClick={onPrintQrCodePoster}
        >
          {t('Print Poster')}
        </button>
      </div>
    </div>
  );
};

QRCodePoster.propTypes = {
  storeName: PropTypes.string.isRequired,
  uniqueLocationUrl: PropTypes.string.isRequired,
};

export default QRCodePoster;
