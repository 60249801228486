import axios from 'axios';
import moment from 'moment';
import Utils from '../utils';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the voucher service.
 */
class VoucherService {
  /**
   * Returns the support information for the app.
   * @param {Object} params The params.
   * @param {String} params.voucherCode The voucher code.
   * @param {String} params.locationGroup The location group.
   * @param {String} params.appointmentTime The appointment time.
   * @returns A Promise.
   */
  async verifyVoucher(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_CALENDAR_API}/verifyVoucher`;
        const data = { ...params };
        const res = await axios.post(url, data);

        resolve(res?.data?.success);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(error);
        reject(error);
      }
    });
  }

  /**
   * Determines whether the voucher text field should be displayed.
   * @param {Object} vouchers
   * @param {String} locationGroup
   * @returns {Boolean}
   */
  showVoucherField(vouchers, locationGroup) {
    let showVoucherField = true;

    if (vouchers) {
      if (vouchers.length <= 0) showVoucherField = false;

      if (!Utils.isVoucherAllowed(locationGroup)) showVoucherField = false;

      vouchers.forEach((voucher) => {
        if (
          !moment()
            .local()
            .isBetween(voucher.activeDuring.start, voucher.activeDuring.end)
        )
          showVoucherField = false;
      });
    } else {
      showVoucherField = false;
    }

    return showVoucherField;
  }
}

export default VoucherService;
