import React from 'react';
import PropTypes from 'prop-types';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useTranslation } from 'react-i18next';
import EventBuilder from '../../eventBuilder';
import Events from '../../events';
import Constants from '../../constants';
import './CalendarQueueItemTooltip.scss';

const eBuilder = new EventBuilder();

/**
 * Represents the tooltip for a queued item.
 * @param {Object} props The component properties.
 */
const CalendarQueueItemTooltip = (props) => {
  const { countrySettings, item, locationSettings, onCloseTooltip } = props;
  const { created } = item;

  eBuilder
    .withCategory(eBuilder.Category.Scheduler.experience)
    .withLabel(
      eBuilder.Label.practiceIdentifierWithArgs,
      momentLocaleWrapper(created).format('h:mm A')
    );

  const editAppointment = () => {
    Events.emit(Constants.Events.onToggleAddAppointment, item);

    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.Waitlist.editAppointment)
      .post();

    onCloseTooltip();
  };

  const deleteAppointment = () => {
    Events.emit(Constants.Events.deleteAppointment, item);

    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.Waitlist.deleteAppointment)
      .post();

    onCloseTooltip();
  };

  const noShowAppointment = () => {
    Events.emit(Constants.Events.noShowAppointment, item);

    eBuilder
      .withAction(eBuilder.Action.Scheduler.Click.Waitlist.noShowAppointment)
      .post();

    onCloseTooltip();
  };

  const { appointmentType, patient } = item;
  const { firstName, lastName, phone, email } = patient;
  const { t } = useTranslation();
  const isWhatsAppPhoneLinksEnabled =
    countrySettings.some(
      (s) =>
        s.settingName ===
          Constants.CountrySettings.settingIsWhatsAppB2CEnabled &&
        s.settingValue === true
    ) &&
    locationSettings.some(
      (s) =>
        s.displayName ===
          Constants.LocationSettings.settingIsWhatsAppB2CEnabled &&
        s.settingValue === true
    );

  return (
    <div className="cal-queuetooltip__content">
      <div className="cal-queuetooltip__header">
        <div className="cal-queuetooltip__action">
          <IconButton onClick={() => editAppointment()}>
            <EditIcon />
          </IconButton>
          <span>{t('Edit')}</span>
        </div>
        <div className="cal-queuetooltip__action">
          <IconButton onClick={() => deleteAppointment()}>
            <DeleteIcon />
          </IconButton>
          <span>{t('Delete')}</span>
        </div>
        <div className="cal-queuetooltip__action">
          <IconButton onClick={() => noShowAppointment()}>
            <NotInterestedIcon />
          </IconButton>
          <span>{t('No Show')}</span>
        </div>
        <span className="cal-queuetooltip__divider"></span>
        <div className="cal-queuetooltip__action cal-queuetooltip__action--close">
          <IconButton onClick={onCloseTooltip}>
            <ClearIcon />
          </IconButton>
        </div>
      </div>
      <div className="cal-queuetooltip__body">
        <span className="cal-queuetooltip__appt-color"></span>
        <div className="cal-queuetooltip__body-right">
          <p>
            {firstName}&nbsp;{lastName}
          </p>
          <p>{appointmentType.displayName}</p>
          {isWhatsAppPhoneLinksEnabled && (
            <a
              className="cal-queuetooltip__link"
              href={`${process.env.REACT_APP_WHATSAPP_API}/send?phone=${phone}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {`+${phone}`}
            </a>
          )}
          {!isWhatsAppPhoneLinksEnabled && <p>{`+${phone}`}</p>}
          <p>
            <a href={`mailto:${email}`}>{email}</a>
          </p>
          <p>
            {momentLocaleWrapper(created, '(YYYY, MM, DD, HH, mm)').format(
              'LL'
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

CalendarQueueItemTooltip.propTypes = {
  countrySettings: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  locationSettings: PropTypes.array.isRequired,
  onCloseTooltip: PropTypes.func.isRequired,
};

export default CalendarQueueItemTooltip;
