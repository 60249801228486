import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import MuiCheckbox from '@material-ui/core/Checkbox';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import EventBuilder from '../../eventBuilder';
import './CalendarResources.scss';

const eBuilder = new EventBuilder();

const Checkbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#979797',
    },
  },
  checked: {},
})(MuiCheckbox);

const FormControlLabel = withStyles({
  label: {
    fontSize: '0.8rem',
  },
})(MuiFormControlLabel);

/**
 * Represents the calendar resources list.
 */
class CalendarResources extends Component {
  /**
   * Initializes a new instance of the CalendarResources component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      showMoreResources: false,
    };
    this._maxResToShow = 4;

    eBuilder
      .withCategory(eBuilder.Category.Scheduler.experience)
      .withAction(eBuilder.Action.Scheduler.Click.appointmentTypesFilter);
  }

  _onToggleMoreResources = () => {
    this.setState((prevState) => ({
      showMoreResources: !prevState.showMoreResources,
    }));
  };

  _onResourceChange = (id, path, value) => {
    this.props.onResourceChange(id, path, value);
    const appointmentLabel = `${id}_togglefilter`;
    eBuilder.withLabel(eBuilder.Label.alternateLabel, appointmentLabel).post();
  };

  /**
   * Renders the component.
   */
  render() {
    const { showMoreResources } = this.state;
    const { resources, t } = this.props;
    const hasMoreResources = resources.length > this._maxResToShow;
    const resourceItems = resources.map((r, key) => (
      <li
        className={`cal-res__item ${
          hasMoreResources && !showMoreResources && key >= this._maxResToShow
            ? 'cal-res__item--hidden'
            : ''
        }`}
        key={key}
      >
        {r.isDefault && (
          <FormControlLabel
            control={
              <Checkbox checked={true} name={`checked${key}`} disabled={true} />
            }
            label={r.displayName}
          />
        )}
        {!r.isDefault && (
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={true}
                name={`checked${key}`}
                onClick={(e) =>
                  this._onResourceChange(
                    r.resourceId,
                    ['isChecked'],
                    e.target.checked
                  )
                }
              />
            }
            label={r.displayName}
          />
        )}
      </li>
    ));

    return (
      <div className="cal-res">
        <h3 className="cal-res__title">{t('Resource Name')}</h3>
        <ul className="cal-res__list">{resourceItems}</ul>
        {showMoreResources ? (
          <button
            className="cal-res__more"
            onClick={this._onToggleMoreResources}
          >
            {t('Show Less')}
          </button>
        ) : (
          hasMoreResources && (
            <button
              className="cal-res__less"
              onClick={this._onToggleMoreResources}
            >
              {t('Show More')}
            </button>
          )
        )}
      </div>
    );
  }
}

CalendarResources.propTypes = {
  onResourceChange: PropTypes.func.isRequired,
};

export default withTranslation()(CalendarResources);
