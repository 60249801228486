import i18n from 'i18next';
import Utils from './utils';
import { v4 as uuidv4 } from 'uuid';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import Session from './session';
import Constants from './constants';

const defaultLocale = Utils.convertUrlToLocale();
const jsonVersion = `${
  process.env.REACT_APP_ENVIRONMENT === 'production' ||
  process.env.REACT_APP_ENVIRONMENT === 'qa'
    ? `?v=${uuidv4()}`
    : ''
}`;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: () => {
        let url = Session.getItem(Constants.currTranslationUrlKey);

        if (!url) {
          url = `https://s3-sa-east-1.amazonaws.com/eyebooknow.content/${process.env.REACT_APP_ENVIRONMENT}/localizations/${defaultLocale}/json/admin.translations.json`;
        }

        return `${url}${jsonVersion}`;
      },
    },
    lng: false,
    fallbackLng: defaultLocale,
    // debug: process.env.REACT_APP_ENVIRONMENT === 'development',
    debug: false,
    nsSeparator: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    whitelist: [
      'en-US',
      'en-IN',
      'es',
      'pt',
      'en-AE',
      'en-BN',
      'en-JP',
      'en-KR',
      'en-MY',
      'en-PH',
      'en-RU',
      'en-SG',
      'tr-TR',
      'tr',
      'ru',
      'en',
      'zh-TW',
      'zh-HK',
    ],
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
    },
  })
  .then((t) => {
    document.title = t('EyeBookNow');
  });

export default i18n;
