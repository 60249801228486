import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

/**
 * Represents the store information component.
 */
const StoreInformation = (props) => {
  const { onboardingInfo, t } = props;
  const { name, address, phone, website } = onboardingInfo.storeInformation;

  return (
    <div className="admin-content__store-info">
      <h2 className="admin-content__title">{t('Location Information')}</h2>
      <div className="admin-content__info-cont">
        <input
          className="admin-content__input admin-content__input--store"
          disabled
          type="text"
          value={name}
        />
        <input
          className="admin-content__input admin-content__input--address"
          disabled
          type="text"
          value={address}
        />
        <input
          className="admin-content__input admin-content__input--phone"
          disabled
          type="phone"
          value={phone}
        />
        <input
          className="admin-content__input admin-content__input--email"
          disabled
          type="url"
          value={website}
        />
      </div>
    </div>
  );
};

StoreInformation.propTypes = {
  onboardingInfo: PropTypes.object.isRequired,
};

export default withTranslation()(StoreInformation);
