import React, { useRef, Component } from 'react';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { ClickAwayListener } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation, withTranslation } from 'react-i18next';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Utils from '../../utils';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import StellestLogo from '../../Assets/Images/Stellest/SC-Icon.png';
import MuiTooltip from '@material-ui/core/Tooltip';
import './CalendarAppointment.scss';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

/**
 * Represents a calendar appointment.
 */
export const CalendarAppointment = ({
  classNames,
  data,
  isShaded,
  resources,
  ...restProps
}) => {
  let style = null;
  const currDateTime = new Date();
  let isApptNow = false;
  let unconfirmed = data.confirmationStatus !== 0;

  if (isShaded) {
    style = {
      backgroundColor: `${Utils.getPastShadedAppointmentColor(
        resources[0].color
      )}`,
    };
  } else if (data.startDate <= currDateTime && data.endDate > currDateTime) {
    isApptNow = true;
    style = { backgroundColor: resources[0].color };
    if (unconfirmed) {
      style = {
        border: `1px solid ${Utils.getPastShadedAppointmentColor(
          resources[0].color
        )}`,
        backgroundColor: '#fff',
      };
    }
  } else {
    style = {
      backgroundColor: `${Utils.getFutureShadedAppointmentColor(
        resources[0].color
      )}`,
    };
    if (unconfirmed) {
      style = {
        border: `1px solid ${Utils.getFutureShadedAppointmentColor(
          resources[0].color
        )}`,
        backgroundColor: '#fff',
      };
    }
  }

  return (
    <Appointments.Appointment
      className={`${classNames} ${isApptNow ? 'cal-appt--current' : ''}`}
      data={data}
      draggable
      isShaded={isShaded}
      style={style}
      {...restProps}
    ></Appointments.Appointment>
  );
};

/**
 * Represents calendar appointment content.
 */
class CalendarAppointmentContent extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    const { appointmentType } = data;

    this.state = {
      showStellestToolTip: false,
    };
  }

  componentDidMount() {
    const { i18n, t } = this.props;
    momentLocaleWrapper.locale(i18n.language);
  }

  _onCloseStellestToolTip = () => {
    this.setState({ showStellestToolTip: false });
  };

  _onToggleStellestToolTip = () => {
    this.setState((prevState) => ({
      showStellestToolTip: !prevState.showStellestToolTip,
    }));
  };

  render() {
    const { showStellestToolTip } = this.state;

    const { t, data } = this.props;

    const { appointmentType, patient, startDate, voucherCode, isStellest } =
      data;

    return (
      <div className="cal-appt">
        <div>
          <span>
            {patient.firstName} {patient.lastName}
          </span>
          ,&nbsp;
          <span>{momentLocaleWrapper(startDate).format('LT')}</span>,&nbsp;
          <span>{appointmentType.displayName}</span>
        </div>
        {isStellest && (
          <ClickAwayListener onClickAway={() => this._onCloseStellestToolTip()}>
            <button
              className="cal-appt__stellest-icon"
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                this._onToggleStellestToolTip();
              }}
            >
              <Tooltip
                open={showStellestToolTip}
                placement="top-end"
                title={t(
                  'This badge identifies the patient as a member of the Stellest Care program'
                )}
              >
                <img
                  className="cal-appt__stellest-logo"
                  alt="Stellest Logo"
                  src={StellestLogo}
                />
              </Tooltip>
            </button>
          </ClickAwayListener>
        )}
        {voucherCode && <LocalOfferIcon className="cal-appt__voucher" />}
      </div>
    );
  }
}

export default withTranslation()(CalendarAppointmentContent);
/**
 * Represents an appointment container.
 * @remarks This is the super parent of an appointment.
 * @param {Object} props The component properties.
 */
export const CalendarAppointmentContainer = (props) => {
  const apptCont = useRef(null);
  const inTwoSeconds = 2000;
  const _onDragEnter = () => {
    const { current } = apptCont;
    current.classList.add('cal-appt-cont--hidden');
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      current.classList.remove('cal-appt-cont--hidden');
    }, inTwoSeconds);
  };

  const _handleClick = () => {
    const eBuilder = new EventBuilder();
    eBuilder
      .withCategory(eBuilder.Category.Scheduler.experience)
      .withAction(
        eBuilder.Action.Scheduler.Click.ExistingAppointment.existingAppointment
      )
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();
  };

  const { style, children } = props;

  return (
    <div
      onClick={_handleClick}
      className="cal-appt-cont"
      ref={apptCont}
      style={style}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={_onDragEnter}
    >
      {children}
    </div>
  );
};
