import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Storage from '../../storage';
import Constants from '../../constants';
import './TermsConditions.scss';
import IframeContent from './IframeContent';
import { withTranslation } from 'react-i18next';
import Utils from '../../utils';
import EventBuilder from '../../eventBuilder';
import PageviewBuilder from '../../pageviewBuilder';
import ContentManagementService from '../../Services/contentManagementService';

const eBuilder = new EventBuilder();
const pBuilder = new PageviewBuilder();

/**
 * Represents the terms and conditions page.
 */
class TermsConditions extends Component {
  /**
   * Initializes a new instance of the TermsConditions component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      redirectToSettings: false,
    };

    this._onAgreeClick = this._onAgreeClick.bind(this);
    this._contentManagementService = new ContentManagementService();
    this.logoUrl = Utils.getTaglineLogoUrl();

    eBuilder
      .withCategory(eBuilder.Category.Onboarding.registration)
      .withLabel(eBuilder.Label.practiceIdentifier);

    pBuilder.pageview(pBuilder.Page.Registration.termsUse);
  }

  async componentDidMount() {
    await this._setI18nLanguage();
  }

  _setI18nLanguage = async () => {
    const lang = Storage.getItem(Constants.langTag);

    await this._contentManagementService.loadLocalizations(lang);

    return await this.props.i18n.changeLanguage(lang);
  };

  _onAgreeClick() {
    const user = Storage.getItem(Constants.currUserKey);

    if (user) {
      user.agreementSigned = true;

      Storage.setItem(Constants.currUserKey, user);
      eBuilder.withAction(eBuilder.Action.Onboarding.Click.agreeTos).post();
      window.location = Constants.Routes.onboarding;
    } else {
      window.location = '/';
    }
  }

  /**
   * Renders the component.
   */
  render() {
    const { t } = this.props;

    return (
      <div className="terms-cond">
        <section className="terms-cond__brand">
          <div className="terms-cond__logo-cont">
            <img alt="Site Logo" src={this.logoUrl} />
          </div>
        </section>
        <section className="terms-cond__content">
          <h1 className="terms-cond__title">
            {t('EyeBookNow Terms Of Service')}
          </h1>
          <div className="terms-cond__iframe-cont">
            <IframeContent isFromModal={false} />
          </div>
          <div className="terms-cond__cont">
            {Utils.isTurkeyMarket(Storage.getItem(Constants.langTag)) && (
              <div>
                <p>
                  {ReactHtmlParser(
                    t(
                      'By clicking I AGREE above, I declare that I have read and accepted Terms of Use, understood that personal data will be processed as explained in Privacy Policy, and consent to the processing and storage of personal data in Brazil, as well as to other cross-border transfers to service providers outside of Turkey'
                    )
                  )}
                </p>
              </div>
            )}

            <button
              className="terms-cond__confirm"
              onClick={this._onAgreeClick}
            >
              {t('I Agree')}
            </button>
          </div>
        </section>
      </div>
    );
  }
}

export default withTranslation()(TermsConditions);
