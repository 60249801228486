import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiRadio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Enums from '../../enums';
import './TwoWayAddPatientModal.scss';

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);

const Dialog = withStyles(() => ({
  paper: {
    padding: '1rem 2rem',
  },
}))(MuiDialog);

const TwoWayAddPatientModal = ({
  firstName,
  lastName,
  phone,
  show,
  showSmsWarning,
  showSmsWarningCheck,
  onSubmit,
  onCancel,
  defaultMessagingPref,
  isWhatsAppNotificationsEnabled,
  t,
}) => {
  const [messagingPref, setMessagingPref] = useState(defaultMessagingPref);

  useEffect(() => {
    setMessagingPref(defaultMessagingPref);
  }, [defaultMessagingPref]);

  const submit = () => {
    onSubmit(messagingPref);
  };

  const updateMessagingPref = (newPref) => {
    setMessagingPref(parseInt(newPref));
    showSmsWarningCheck(parseInt(newPref));
  };

  return (
    <>
      <Dialog
        disableBackdropClick={true}
        open={show}
        onClose={onCancel}
        aria-labelledby="addpatient-dialog-title"
        aria-describedby="addpatient-dialog-description"
      >
        <DialogTitle id="addpatient-dialog-title">
          {t('No Customer Found')}
          <IconButton
            aria-label="close"
            className="messages__addpatient-close"
            onClick={onCancel}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="addpatient-dialog-description"
            className="messages__addpatient-description"
          >
            {t(
              'Would you like to create a customer for: {{John Johnson 214-677-8990}}?',
              {
                'John Johnson 214-677-8990': `${firstName} ${lastName} ${phone}`,
              }
            )}
          </DialogContentText>
          {isWhatsAppNotificationsEnabled && (
            <Box mt={3}>
              <FormControl>
                <FormLabel
                  className="messages__addpatient-label"
                  component="legend"
                >
                  {t('RECEIVE MESSAGES ON:')}
                </FormLabel>
                <RadioGroup
                  row
                  value={messagingPref}
                  onChange={(e) => updateMessagingPref(e.target.value)}
                >
                  <FormControlLabel
                    control={<Radio />}
                    label={t('WhatsApp')}
                    labelPlacement="end"
                    value={Enums.PreferredPlatform.whatsApp}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label={t('SMS Texting')}
                    labelPlacement="end"
                    value={Enums.PreferredPlatform.smsText}
                  />
                  {showSmsWarning && (
                    <p className="messages_addpatient-sms-warning">
                      {t(
                        'Notice: Please note that you are choosing SMS as the preferred channel of communication for this customer, this will prohibit you from communicating back and forth with them and limits you to 1 way messages only due to local restrictions.'
                      )}
                    </p>
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <button className="messages__addpatient-btn" onClick={onCancel}>
            {t('Cancel')}
          </button>
          <button className="messages__addpatient-btn" onClick={submit}>
            {t('Submit')}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withTranslation()(TwoWayAddPatientModal);
