import Utils from '../utils';
import Events from '../events';
import Constants from '../constants';
import LocationConfigService from './locationConfigService';

/**
 * Represents the system messages services.
 */
class SystemMessageService {
  /**
   * Initializes a new instance of the SystemMessageService.
   */
  constructor() {
    this._systemMessageCount = 0;
    this._locationConfigService = new LocationConfigService();
  }

  /**
   * Checks for any system messages that need to be published throughout the application.
   */
  async checkForMessages() {
    return new Promise(async (resolve, reject) => {
      try {
        const locationConfig =
          await this._locationConfigService.getLocationConfig();
        const defaultResource = Utils.getDefaultResource(
          locationConfig?.resources
        );

        if (
          locationConfig?.resources?.length === 1 &&
          defaultResource?.displayName === Constants.defaultResourceDisplayName
        ) {
          this.incrementSystemMessageCount();
          Events.emit(
            Constants.Events.onToggleEditResourceSystemMessage,
            this._systemMessageCount
          );

          this.incrementSystemMessageCount();
          Events.emit(
            Constants.Events.onToggleEditStoreHoursSystemMessage,
            this._systemMessageCount
          );

          this.incrementSystemMessageCount();
          Events.emit(
            Constants.Events.onToggleEditServiceTypesSystemMessage,
            this._systemMessageCount
          );
        }

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  resetSystemMessageCount() {
    this._systemMessageCount = 0;
    Events.emit(
      Constants.Events.onResetSystemMessageCount,
      this._systemMessageCount
    );
  }

  /**
   * Decrements the system message count.
   */
  decrementSystemMessageCount() {
    --this._systemMessageCount;
  }

  /**
   * Increments the system message count.
   */
  incrementSystemMessageCount() {
    ++this._systemMessageCount;
  }
}

export default SystemMessageService;
