import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

/**
 * Represents the layout for the confirmation dialogue box.
 * @param {Object} props The component properties.
 */
const CalendarConfirmationModal = (props) => {
  const {
    appointment,
    appointmentResource,
    appointmentStartDate,
    confirmType,
    open,
    onDeleteAppointment,
    onMarkAppointmentAsNoShow,
    onMoveAppointmentToScheduled,
    onToggleConfirmModal,
    onUpdateAppointment,
  } = props;
  const { t } = useTranslation();
  const getConfirmationData = (calendarConfirmationType) => {
    let onConfirm;
    let message;

    switch (calendarConfirmationType) {
      case 'delete':
        onConfirm = onDeleteAppointment;
        message = 'Are you sure you want to delete this appointment?';
        break;
      case 'update':
        onConfirm = onUpdateAppointment;
        message = 'Are you sure you want to change this appointment?';
        break;
      case 'book':
        onConfirm = onMoveAppointmentToScheduled;
        message = 'Are you sure you want to book this appointment?';
        break;
      case 'noshow':
        onConfirm = onMarkAppointmentAsNoShow;
        message = `Are you sure you want to 'no show' this appointment?`;
        break;
      default:
        break;
    }

    return {
      onConfirm: onConfirm,
      message: t(message),
    };
  };
  const confirmationData = getConfirmationData(confirmType);
  const onConfirm = () => {
    confirmationData.onConfirm(
      appointment,
      appointmentStartDate,
      appointmentResource
    );
    onToggleConfirmModal();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{confirmationData.message}</DialogTitle>
      <DialogActions>
        <Button onClick={() => onToggleConfirmModal()} title={t('Reject')}>
          {t('Reject')}
        </Button>
        <Button onClick={onConfirm} title={t('Confirm')} color="primary">
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CalendarConfirmationModal.propTypes = {
  appointment: PropTypes.object.isRequired,
  appointmentResource: PropTypes.object.isRequired,
  appointmentStartDate: PropTypes.object.isRequired,
  confirmType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onDeleteAppointment: PropTypes.func.isRequired,
  onMarkAppointmentAsNoShow: PropTypes.func.isRequired,
  onMoveAppointmentToScheduled: PropTypes.func.isRequired,
  onToggleConfirmModal: PropTypes.func.isRequired,
  onUpdateAppointment: PropTypes.func.isRequired,
};

export default CalendarConfirmationModal;
