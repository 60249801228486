import React from 'react';
import PropTypes from 'prop-types';
import CreateIcon from '@material-ui/icons/Create';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withTranslation } from 'react-i18next';
import './AppointmentTypes.scss';

/**
 * Represents the appointment types component.
 */
const AppointmentTypes = (props) => {
  const {
    canSave,
    isOnboarded,
    appointmentTypes,
    maxApptTypesReached,
    showAdd,
    showSave,
    onAddAppointmentType,
    onEditAppointmentType,
    onEnableAppointmentType,
    onMoveAppointmentType,
    onSaveAppointmentTypes,
    onToggleAppointmentTypeModal,
    t,
  } = props;

  const getAppointmentTypes = () => {
    return appointmentTypes.map((at, key) => (
      <tr key={key}>
        <td>
          <input
            className="appt-types__name"
            disabled={!at.isEditing || at.isStellest}
            type="text"
            value={at.displayName}
            onChange={(e) => {
              onEditAppointmentType(at.id, ['displayName'], e.target.value);
            }}
          />
        </td>
        <td>
          <FormControl
            className="appt-types__interval"
            disabled={!at.isEditing || at.isStellest}
            variant="outlined"
          >
            <Select
              value={at.duration}
              onChange={(e) => {
                onEditAppointmentType(
                  at.id,
                  ['duration'],
                  parseInt(e.target.value)
                );
              }}
            >
              <MenuItem value="15">15 {t('Minutes')}</MenuItem>
              <MenuItem value="30">30 {t('Minutes')}</MenuItem>
              <MenuItem value="45">45 {t('Minutes')}</MenuItem>
              <MenuItem value="60">60 {t('Minutes')}</MenuItem>
            </Select>
          </FormControl>
        </td>
        {!isOnboarded && (
          <td>
            <div className="appt-types__actions">
              {!isOnboarded && (
                <button
                  className="appt-types__action"
                  disabled={at.isStellest}
                  onClick={() => onMoveAppointmentType(key, key - 1)}
                >
                  <ArrowUpwardIcon />
                </button>
              )}
              {!isOnboarded && (
                <button
                  className="appt-types__action appt-types__action--move-down"
                  disabled={at.isStellest}
                  onClick={() => onMoveAppointmentType(key, key + 1)}
                >
                  <ArrowUpwardIcon />
                </button>
              )}
              {!at.isNew && (
                <button
                  className="appt-types__action"
                  disabled={at.isStellest}
                  onClick={() => {
                    onEnableAppointmentType(at);
                  }}
                >
                  <CreateIcon />
                </button>
              )}
              <button
                className="appt-types__action"
                disabled={at.isStellest}
                onClick={() => {
                  onToggleAppointmentTypeModal(at);
                }}
              >
                <CancelIcon />
              </button>
            </div>
          </td>
        )}
      </tr>
    ));
  };

  return (
    <div className="appt-types">
      <table className="appt-types__table">
        <tbody>{getAppointmentTypes()}</tbody>
      </table>
      <div className="appt-types__footer">
        {showAdd && (
          <button
            className="appt-types__add"
            disabled={maxApptTypesReached}
            onClick={onAddAppointmentType}
          >
            <i className="material-icons">add</i>&nbsp;{t('Add')}
          </button>
        )}
        {showSave && (
          <button
            disabled={!canSave}
            className="appt-types__save"
            onClick={() => onSaveAppointmentTypes(appointmentTypes)}
          >
            {t('Save')}
          </button>
        )}
      </div>
    </div>
  );
};

AppointmentTypes.propTypes = {
  appointmentTypes: PropTypes.array,
  canSave: PropTypes.bool,
  isOnboarded: PropTypes.bool.isRequired,
  maxApptTypesReached: PropTypes.bool.isRequired,
  showAdd: PropTypes.bool,
  showSave: PropTypes.bool,
  onAddAppointmentType: PropTypes.func.isRequired,
  onEditAppointmentType: PropTypes.func.isRequired,
  onEnableAppointmentType: PropTypes.func.isRequired,
  onMoveAppointmentType: PropTypes.func.isRequired,
  onSaveAppointmentTypes: PropTypes.func,
  onToggleAppointmentTypeModal: PropTypes.func.isRequired,
};

export default withTranslation()(AppointmentTypes);
