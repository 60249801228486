import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import React, { Component } from 'react';
import './PrescriptionPreview.scss';
import CloseIcon from '@material-ui/icons/Close';
export class PrescriptionPreview extends Component {
  /**
   * Initializes a new instance of the PrescriptionUpload component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);
  }

  render() {
    const {
      handleRejectPreview,
      handleAcceptPreview,
      shouldDisplayModal,
      imageUrlData,
      t,
    } = this.props;

    return (
      <Dialog
        onClose={handleRejectPreview}
        aria-labelledby="simple-dialog-title"
        open={shouldDisplayModal}
      >
        <div className="close-btn-container">
          <div className="close-btn" onClick={() => handleRejectPreview()}>
            <CloseIcon></CloseIcon>
          </div>
        </div>
        <DialogTitle id="simple-dialog-title">{t('Photo Preview')}</DialogTitle>
        <DialogContent>
          <img className="prescription-preview__image" src={imageUrlData}></img>
          <p className="prescription-preview__message">
            {t(
              'Please ensure that the area cropped displays all of the Prescription information required by the lens manufacturer.'
            )}
          </p>
        </DialogContent>
        <DialogActions>
          <button
            className="cancel-btn"
            color="primary"
            onClick={() => handleRejectPreview()}
          >
            {t('Cancel')}
          </button>
          <button
            className="accept-btn"
            onClick={() => handleAcceptPreview(imageUrlData)}
          >
            {t('Finish')}
          </button>
          <div className="prescription-preview__controls"></div>
        </DialogActions>
      </Dialog>
    );
  }
}
