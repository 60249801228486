import React, { Component } from 'react';
import './TwoWayConversationSkeleton.scss';

const TwoWayConversationSkeleton = () => {
  return (
    <span className="message--conversation--skeleton">
      <div className="sent">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="receive">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="sent">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="receive">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="sent">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="receive">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="sent">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="receive">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="sent">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="receive">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="sent">
        <span></span>
        <p></p>
        <span></span>
      </div>
      <div className="receive">
        <span></span>
        <p></p>
        <span></span>
      </div>
    </span>
  );
};

export default TwoWayConversationSkeleton;
