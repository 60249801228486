import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import MuiCheckbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import ReactHtmlParser from 'react-html-parser';
import Enums from '../../enums';
import Utils from '../../utils';
import Constants from '../../constants';
import SettingsService from '../../Services/settingsService';
import './StoreInformation.scss';

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Checkbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#979797',
    },
  },
  checked: {},
})(MuiCheckbox);

const FormControlLabel = withStyles({
  root: {
    marginBottom: 0,
  },
})(MuiFormControlLabel);

/**
 * Represents the store information component.
 */
class StoreInformation extends Component {
  /**
   * Initializes a new instance of the StoreInformation component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    const { locationSettings, storeInformation, googleReviewInfo } = props;

    this.state = {
      canEnableWhatsApp: true,
      canSaveStoreInfomation: true,
      isLocationSettingsChanged: false,
      isStoreInfoChanged: false,
      isGoogleInfoChanged: false,
      errorModalMessage: '',
      isStoreInformationDirty: false,
      locationSettings: cloneDeep(locationSettings),
      showErrorModal: false,
      showSaveAlert: false,
      storeInformation: cloneDeep(storeInformation),
      googleReviewInfo: cloneDeep(googleReviewInfo),
    };
    this._settingsService = new SettingsService();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  componentDidMount() {
    this.setState(() => ({
      canEnableWhatsApp: this.props.storeInformation.smsPhone !== '',
    }));
  }

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  _onCloseSaveAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showSaveAlert: false }));
  };

  _onLocationSettingsChange = (name, value) => {
    this.setState((prevState) => {
      let canSaveStoreInfomation = true;
      const updateLocationSettings = prevState.locationSettings.map((locationSetting) => {
        if (locationSetting.displayName === name) {
          locationSetting.settingValue = value;

          if (
            name === Constants.LocationSettings.settingIsWhatsAppB2CEnabled &&
            prevState.storeInformation.smsPhone === ''
          ) {
            canSaveStoreInfomation = false;
          }
        }

        return locationSetting;
      });

      return {
        canSaveStoreInfomation: canSaveStoreInfomation,
        isLocationSettingsChanged: canSaveStoreInfomation,
        isStoreInformationDirty: true,
        locationSettings: updateLocationSettings,
      };
    });
  };

  _onSaveStoreInformation = async () => {
    try {
      this.setState(() => ({ canSaveStoreInfomation: false }));

      const {
        locationSettings,
        storeInformation,
        googleReviewInfo,
        isLocationSettingsChanged,
        isStoreInfoChanged,
        isGoogleInfoChanged,
      } = this.state;

      const { onLocationSettingsChange, onStoreInformationChange, onGoogleReviewInfoChange } =
        this.props;

      const modifiedLocationSettings = locationSettings.filter(
        (locationSetting) =>
          locationSetting.displayName === Constants.LocationSettings.settingIsWhatsAppB2CEnabled
      );

      if (isLocationSettingsChanged) {
        await this._settingsService.saveLocationSettings({
          locationSettings: modifiedLocationSettings,
        });

        onLocationSettingsChange && onLocationSettingsChange(locationSettings);
      }

      if (isStoreInfoChanged) {
        await this._settingsService.saveLocationMobileNumber({
          smsPhone: storeInformation.smsPhone,
        });
        onStoreInformationChange && onStoreInformationChange(storeInformation);

        //If SMS phone is set to empty, automatically disable settingIsWhatsAppB2CEnabled
        if (storeInformation.smsPhone === '') {
          modifiedLocationSettings.forEach((s) => (s.settingValue = false));

          await this._settingsService.saveLocationSettings({
            locationSettings: modifiedLocationSettings,
          });
          onLocationSettingsChange && onLocationSettingsChange(locationSettings);
        }
      }

      if (isGoogleInfoChanged) {
        const updatedGoogleReviewInfo = {
          placeId: googleReviewInfo.viewReviewUrl
            .split('https://search.google.com/local/reviews?placeid=')
            .pop(),
          isDefinedByECP: true,
        };

        await this._settingsService.saveGoogleReviewInfo({
          updatedGoogleReviewInfo: updatedGoogleReviewInfo,
        });
        onGoogleReviewInfoChange && onGoogleReviewInfoChange(googleReviewInfo);
      }

      this.setState(() => ({
        isStoreInformationDirty: false,
        showSaveAlert: true,
      }));
    } catch (error) {
      if (
        (error && !error.response) ||
        (error && error.response.status === Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        this._onShowErrorModal(
          error,
          this.props.t('There was an unexpected issue with saving the store information.')
        );
      }
    }
  };

  _onShowErrorModal = (error, message) => {
    if (
      (error && !error.response) ||
      (error && error.response.status === Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      console.error(error);

      this.setState(() => ({
        showErrorModal: true,
        errorModalMessage: message,
      }));
    }
  };

  _onStoreInformationChange = (path, value) => {
    this.setState((prevState) => {
      const updatedStoreInformation = cloneDeep(prevState.storeInformation);
      let canEnableWhatsApp = true;
      let isStoreInfoChanged = false;

      if (path[0] === 'smsPhone') {
        let smsPhone = parseInt(value).toString();
        smsPhone = isNaN(smsPhone) ? '' : smsPhone;

        if (smsPhone === '') {
          canEnableWhatsApp = false;
        }

        Utils.update(updatedStoreInformation, path, smsPhone);
        isStoreInfoChanged = true;
      } else {
        Utils.update(updatedStoreInformation, path, value);
        isStoreInfoChanged = true;
      }

      return {
        canEnableWhatsApp: canEnableWhatsApp,
        canSaveStoreInfomation: true,
        isStoreInformationDirty: true,
        isStoreInfoChanged: isStoreInfoChanged,
        storeInformation: updatedStoreInformation,
      };
    });
  };

  _onGoogleReviewInfoChange = (path, value) => {
    this.setState((prevState) => {
      const updatedGoogleReviewInfo = cloneDeep(prevState.googleReviewInfo);

      let canEnableWhatsApp = true;
      let isGoogleInfoChanged = false;

      if (path[0] === 'viewReviewUrl') {
        const updatedPlaceId = updatedGoogleReviewInfo.viewReviewUrl
          .split('https://search.google.com/local/reviews?placeid=')
          .pop();

        Utils.update(updatedGoogleReviewInfo, path, value);
        Utils.update(updatedGoogleReviewInfo, 'placeId', updatedPlaceId);
        isGoogleInfoChanged = true;
      } else {
        Utils.update(updatedGoogleReviewInfo, path, value);
        isGoogleInfoChanged = true;
      }

      return {
        canEnableWhatsApp: canEnableWhatsApp,
        canSaveStoreInfomation: true,
        isStoreInformationDirty: true,
        isGoogleInfoChanged: isGoogleInfoChanged,
        googleReviewInfo: updatedGoogleReviewInfo,
      };
    });
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      canEnableWhatsApp,
      canSaveStoreInfomation,
      errorModalMessage,
      isStoreInformationDirty,
      locationSettings,
      showErrorModal,
      showSaveAlert,
      storeInformation,
      googleReviewInfo,
    } = this.state;
    const { countrySettings, t } = this.props;
    const { name, address, phone, smsPhone, website } = storeInformation;
    const { viewReviewUrl } = googleReviewInfo;
    const unformattedSmsPhone = smsPhone.replaceAll('-', '');
    const whatsAppB2CCountryEnabled = countrySettings.some(
      (countrySetting) =>
        countrySetting.settingName === Constants.CountrySettings.settingIsWhatsAppB2CEnabled &&
        countrySetting.settingValue === true
    );
    const whatsAppB2CLocationEnabled =
      canEnableWhatsApp &&
      locationSettings.some(
        (locationSetting) =>
          locationSetting.displayName === Constants.LocationSettings.settingIsWhatsAppB2CEnabled &&
          locationSetting.settingValue === true
      );
    const trainingVideoLink = Utils.getGooglePlaceIdTrainingVideoLink();

    return (
      <div className="store-info">
        <div className="store-info__row">
          <div className="store-info__group">
            <label>{t('Name')}</label>
            <input className="store-info__input" value={name} disabled type="text" />
          </div>
          <div className="store-info__group">
            <label>{t('Address')}</label>
            <input className="store-info__input" value={address} disabled type="text" />
          </div>
          <div className="store-info__group">
            <label>{t('Website')}</label>
            <input className="store-info__input" value={website} disabled type="url" />
          </div>
        </div>
        <div className="store-info__row store-info__row--mt">
          <div className="store-info__group store-info__group--phone">
            <label>{t('Phone Number')}</label>
            <input className="store-info__input" value={phone} disabled type="phone" />
          </div>
          <div className="store-info__group store-info__group--mobile">
            <label>{t('Mobile')}</label>
            <input
              className="store-info__input"
              value={unformattedSmsPhone}
              type="phone"
              onChange={(e) => this._onStoreInformationChange(['smsPhone'], e.target.value)}
            />
          </div>
          {whatsAppB2CCountryEnabled && (
            <div className="store-info__group store-info__group--whatsapp">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={whatsAppB2CLocationEnabled}
                    disabled={!canEnableWhatsApp}
                    onChange={(e) =>
                      this._onLocationSettingsChange(
                        Constants.LocationSettings.settingIsWhatsAppB2CEnabled,
                        e.target.checked
                      )
                    }
                  />
                }
                label={t('Enable WhatsApp?')}
              />
            </div>
          )}
        </div>
        <div className="store-info__row">
          <div className="store-info__group  store-info__group--google">
            <label>{t('Google Place ID URL')}</label>
            <input
              className="store-info__input"
              value={viewReviewUrl}
              type="text"
              onChange={(e) => this._onGoogleReviewInfoChange(['viewReviewUrl'], e.target.value)}
            />
          </div>
        </div>
        <div className="store-info__row">
          <div className="store-info__group store-info__group--google">
            <ol>
              <li>
                {t('Kindly watch a short')}
                &nbsp;
                <a href={trainingVideoLink} target="_blank" rel="noopener noreferrer">
                  {t('Google Place ID Training Video')}
                </a>
                &nbsp;
                {t('to get started.')}
              </li>
              <li>
                {t(
                  'Copy and paste the Google Place ID URL in your browser window to find your Google Place Listing and confirm this is correct.'
                )}
              </li>
              <li>
                {ReactHtmlParser(
                  t(
                    'If this is not the correct URL for your location, please visit this <a href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder" target="_blank" rel="noopener noreferrer">Google Place ID finder</a> to review your listing information. If no URL is populated, kindly provide your Google Place ID URL in this field.'
                  )
                )}
              </li>
            </ol>
          </div>
        </div>
        <div className="store-info__footer">
          <button
            disabled={!(canSaveStoreInfomation && isStoreInformationDirty)}
            className="store-info__save"
            onClick={this._onSaveStoreInformation}
          >
            {t('Save')}
          </button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showSaveAlert}
          onClose={this._onCloseSaveAlert}
        >
          <Alert
            className="admin-settings__alert"
            severity="success"
            onClose={this._onCloseSaveAlert}
          >
            {t('Saved successfully')}
          </Alert>
        </Snackbar>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button className="admin-settings__modal-btn" onClick={this._onCloseErrorModal}>
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

StoreInformation.propTypes = {
  countrySettings: PropTypes.array.isRequired,
  locationSettings: PropTypes.array.isRequired,
  storeInformation: PropTypes.object.isRequired,
  onLocationSettingsChange: PropTypes.func,
  onStoreInformationChange: PropTypes.func,
  onGoogleReviewInfoChange: PropTypes.func,
  googleReviewInfo: PropTypes.object.isRequired,
};

export default withTranslation()(StoreInformation);
