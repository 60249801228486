//Dependencies
import axios from 'axios';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import StellestLogoWhite from '../../Assets/Images/Stellest/logos/transparent/stellest-white.png';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Events from '../../events';
import Constants from '../../constants';
import {
  StellestOrderType,
  StellestEdgingOptions,
  StellestCoatingOptions,
} from '../../enums';
import Storage from '../../storage';
import Session from '../../session';
import LocationConfigService from '../../Services/locationConfigService';
import ContentManagementService from '../../Services/contentManagementService';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import Utils from '../../utils';
import ReactToPrint from 'react-to-print';

//Components
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PatientDetailsEdit from './PatientDetailsEdit';
import PatientDetailsView from './PatientDetailsView';
import StellestOrderPrint from '../../Components/StellestOrderPrint/StellestOrderPrint';

import OrderDetails from './OrderDetails';
import Overlay from '../Overlay/Overlay';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';

//Styles
import './StellestRegistrationForm.scss';

class StellestRegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ageOfGuardian: false,
      addR: '',
      addL: '',
      axisR: '',
      axisL: '',
      canRegister: false,
      comments: '',
      cylR: '',
      cylL: '',
      dateFormat: 'DD/MM/yyyy',
      dob: moment(),
      frameModel: '',
      firstOrderDate: moment(),
      edgingOption: StellestEdgingOptions.Edging,
      email: '',
      errors: {},
      firstName: '',
      guardian: '',
      htL: '',
      htR: '',
      isLoading: false,
      lastName: '',
      lensCoating: StellestCoatingOptions.Prevencia,
      stellestRegistrationUrl: `${process.env.REACT_APP_STELLEST_API}/register`,
      openSource: 'navbar',
      orderSubmissionMedium: 1,
      orderType: -1,
      pdL: '',
      pdR: '',
      phone: '',
      requireGuardian: true,
      sphereR: '',
      sphereL: '',
      success: false,
      trackingNo: '',
      showForm: false,
      dataPresent: false,
      history: [],
      prescriptionImage: '',
      ethnicity: -1,
      gender: -1,
      featureConfiguration: {},
    };

    this._locationConfigService = new LocationConfigService();
    this._contentManagementService = new ContentManagementService();
    this._featureConfigurationService = new FeatureConfigurationService();
  }

  componentDidMount = async () => {
    await this._getLocationConfig();
    await this._getPhoneCountryCode();

    const featureConfiguration =
      await this._featureConfigurationService.getFeatureConfiguration(
        process.env.REACT_APP_ENVIRONMENT
      );
    this.setState(() => ({
      orderType: featureConfiguration.EnabledStellestOrderTypes[0],
      featureConfiguration: featureConfiguration,
    }));

    this._setupSubscriptions();
  };

  _getPhoneCountryCode = async () => {
    try {
      const phoneCountryCode =
        await this._contentManagementService.getPhoneCountryCode(
          this.state.locationConfig.storeInformation.countryCode
        );
      this.setState(() => ({ phoneCountryCode: phoneCountryCode }));
    } catch (error) {
      console.error(error);
    }
  };

  _getLensPackagePrettyName = (packageId) => {
    switch (packageId) {
      case StellestOrderType.StandardLens:
        return 'Stellest Lenses (Standalone)';
      case StellestOrderType.CarePackage:
        return 'Stellest Care Program';
      case StellestOrderType.CarePackageSecondPair:
        return 'Stellest Care Program Second Pair';
      case StellestOrderType.Assurance:
        return 'Assurance for Stellest';
      default:
        return '';
    }
  };

  _getLocationConfig = async () => {
    try {
      const locationConfig =
        await this._locationConfigService.getLocationConfig();
      this._setupLocationConfig(locationConfig);
    } catch (error) {
      console.error(error);
    }
  };

  _setupLocationConfig = (locationConfig) => {
    if (locationConfig) {
      const countrySettings = cloneDeep(locationConfig.countrySettings);
      const guardianAge = countrySettings.find(
        (e) => e.settingName === 'AgeOfMajority'
      )?.settingValue;
      Session.setItem(Constants.currResources, locationConfig.resources);
      Session.setItem(
        Constants.currCountry,
        locationConfig.storeInformation.countryCode
      );

      this.setState(() => ({
        countrySettings: countrySettings,
        locationConfig: cloneDeep(locationConfig),
        locationId: Storage.getItem(Constants.currLocIdKey),
        locationGroup: locationConfig.locationGroup,
        guardianAge: guardianAge,
      }));
    }
  };

  _rightColInputCheck = (data) => {
    const { axisR, axisL, cylR, cylL, htL, htR, pdR, pdL, sphereR, sphereL } =
      data;

    if (
      axisR &&
      axisL &&
      cylR &&
      cylL &&
      htL &&
      htR &&
      pdR &&
      pdL &&
      sphereR &&
      sphereL
    ) {
      if (
        axisL !== '' &&
        axisR !== '' &&
        cylL !== '' &&
        cylR !== '' &&
        htL !== '' &&
        htR !== '' &&
        pdR !== '' &&
        pdL !== '' &&
        sphereL !== '' &&
        sphereR !== ''
      ) {
        return true;
      }
    }
    return false;
  };

  _setupSubscriptions = () => {
    Events.on(Constants.Events.onToggleAddStellest, async (args) => {
      this.setState({
        isLoading: true,
        showForm: true,
      });

      await this._getLocationConfig();
      await this._getPhoneCountryCode();

      //handle incoming data if present
      let dataPresent = false;
      let history = [];
      if (args != null) {
        this._resetState();
        dataPresent = true;
        const { phoneCountryCode } = this.state;

        const { rowData, data } = args;

        const phoneCountryCodeWithoutPlus = phoneCountryCode.replace('+', '');
        const phoneAlt = rowData.customerPhone.replace(
          phoneCountryCodeWithoutPlus,
          ''
        );

        const { dateFormat } = this.state;

        //what to do with regards to maintaining values and viewing data so as to maintain funtionality of maintaining values?
        //solution: convoluted logic and state mgmt; two instances of the form, but they are event driven so this is bad.

        //parse order info into objects as needed from data that matches on patient key
        const orders = data.filter(
          (e) => e.customerData.patientKey === rowData.customerData.patientKey
        );
        history = orders.map((order, i) => {
          order = { ...order, errors: {} };
          const { customerData } = order;
          const orderReformatted = {
            axisL: customerData.leftEye.axis,
            axisR: customerData.rightEye.axis,
            comments: order.csComments,
            cylL: customerData.leftEye.cylinder,
            cylR: customerData.rightEye.cylinder,
            dateFormat: dateFormat,
            edgingOption: order.edging,
            errors: {},
            firstOrderDate: moment(order.orderDate),
            frameModel: order.frameModel,
            htL: customerData.leftEye.ht,
            htR: customerData.rightEye.ht,
            lensCoating: order.coating,
            pdL: customerData.leftEye.pd,
            pdR: customerData.rightEye.pd,
            orderReferenceNumber: order.orderRefNumber,
            orderSubmissionMedium: 1,
            orderType: order.orderTypeInt,
            prescriptionImageUrl: order.prescriptionImageUrl,
            sasToken: order.sasToken,
            sphereL: customerData.leftEye.sphere,
            sphereR: customerData.rightEye.sphere,
            trackingNo: '',
          };

          //if incomplete right col + photo present, do not add to history; set current state to those values.
          if (
            !this._rightColInputCheck(orderReformatted) &&
            order.prescriptionImageUrl
          ) {
            this.setState({
              axisR: orderReformatted.axisR,
              axisL: orderReformatted.axisL,
              comments: orderReformatted.comments,
              cylR: orderReformatted.cylR,
              cylL: orderReformatted.cylL,
              edgingOption: orderReformatted.edgingOption,
              firstOrderDate: orderReformatted.firstOrderDate,
              frameModel: orderReformatted.frameModel,
              htL: orderReformatted.htL,
              htR: orderReformatted.htR,
              lensCoating: orderReformatted.lensCoating,
              orderType: orderReformatted.orderType,
              orderReferenceNumber: orderReformatted.orderReferenceNumber,
              orderSubmissionMedium: 2,
              pdL: orderReformatted.pdL,
              pdR: orderReformatted.pdR,
              prescriptionImageUrl: order.prescriptionImageUrl,
              sasToken: order.sasToken,
              sphereR: orderReformatted.sphereR,
              sphereL: orderReformatted.sphereL,
            });
            return;
          }

          return (
            <Accordion className="existing-order" key={i}>
              <AccordionSummary
                className="order-header"
                expandIcon={<ArrowDropDownIcon />}
              >
                <h2>
                  {this._getLensPackagePrettyName(order.orderTypeInt)} - Order
                  Details
                </h2>
              </AccordionSummary>
              <AccordionDetails className="stellest-appointment-form-section medical-details">
                <OrderDetails
                  dataPresent={true}
                  onFieldChange={null}
                  handleFirstOrderDateChange={null}
                  parentState={{
                    ...orderReformatted,
                  }}
                  historic={true}
                  print={false}
                  id={i}
                  enabledOrderTypes={[
                    StellestOrderType.CarePackage,
                    StellestOrderType.StandardLens,
                    StellestOrderType.Assurance,
                  ]}
                />
              </AccordionDetails>
            </Accordion>
          );
        });

        //set patient values here
        this.setState(() => ({
          email: rowData.customerData.email,
          firstName: rowData.firstName,
          guardian: rowData.customerData.guardian,
          lastName: rowData.lastName,
          openSource: 'dashboard',
          phone: phoneAlt,
          gender: rowData.customerData.gender,
          ethnicity: rowData.customerData.ethnicity,
          requireGuardian: true,
        }));

        this._handleDobChange(moment(rowData.customerData.dob));
      } else {
        history = [];
      }

      //set current html overflow to not
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      this.setState(() => ({
        showForm: true,
        dataPresent: dataPresent,
        history: history,
        isLoading: false,
      }));
    });
  };

  _handleDobChange = (date) => {
    const { guardianAge } = this.state;
    const requireGuardian = moment().diff(date, 'years') < guardianAge;
    this.setState({
      requireGuardian: requireGuardian,
      dob: date,
    });
  };

  _handleFirstOrderDateChange = (date) => {
    this.setState({ firstOrderDate: date });
  };

  _handleDatePickerError = (picker, error, value) => {
    let { errors, isLoading } = this.state;

    if (
      !isLoading &&
      !errors[picker] &&
      (error.length > 0 || !value || !value._isValid)
    ) {
      errors[picker] = true;
      this.setState({
        errors: errors,
      });
    } else if (
      !isLoading &&
      errors[picker] &&
      error.length === 0 &&
      value &&
      value._isValid
    ) {
      errors[picker] = false;
      this.setState({
        errors: errors,
      });
    }
  };

  _onFieldChange = (field, value) => {
    this.setState(() => {
      return {
        [field]: value,
      };
    });
  };

  _closeStellestForm = () => {
    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
    this.setState({ showForm: false });
  };

  _toggleSuccess = () => {
    this.setState((prevState) => ({ success: !prevState.success }));
  };

  _onAddStellest = () => {
    const { locationId, phoneCountryCode } = this.state;

    const {
      axisR,
      axisL,
      comments,
      cylR,
      cylL,
      dob,
      edgingOption,
      email,
      ethnicity,
      firstName,
      firstOrderDate,
      frameModel,
      gender,
      guardian,
      htL,
      htR,
      lastName,
      lensCoating,
      stellestRegistrationUrl,
      orderType,
      orderReferenceNumber,
      pdL,
      pdR,
      phone,
      requireGuardian,
      sphereR,
      sphereL,
      prescriptionImage,
      prescriptionImageUrl,
    } = this.state;

    const registerCheck = this._validateFields();

    if (registerCheck) {
      const payload = {
        registration: {
          locationId: locationId,
          firstName: firstName,
          lastName: lastName,
          dateOfBirth: moment(dob).toISOString(),
          ethnicity: ethnicity,
          gender: gender,
          guardianFullName: requireGuardian ? guardian : '',
          email: email,
          phone: `${phoneCountryCode}${phone}`,
          orders: [
            {
              essilorOrderType: orderType,
              orderReferenceNumber: orderReferenceNumber,
              firstOrderDate: moment(firstOrderDate).toISOString(),
              coating: lensCoating,
              edging: edgingOption,
              frameModel: edgingOption === 1 ? frameModel : '',
              CSComments: comments,
              leftEye: {
                sphere: sphereL,
                cylinder: cylL,
                axis: axisL,
                pd: pdL,
                ht: htL,
              },
              rightEye: {
                sphere: sphereR,
                cylinder: cylR,
                axis: axisR,
                pd: pdR,
                ht: htR,
              },
              prescriptionImageUrl: '',
            },
          ],
          // "patientKey": "string"
        },
      };

      if (this.state.prescriptionImageUrl) {
        payload.registration.orders[0].prescriptionImageUrl =
          this.state.prescriptionImageUrl;
      }

      this.setState({ isLoading: true });

      if (prescriptionImage && !prescriptionImageUrl) {
        const data = {
          imageData: prescriptionImage,
          locationId: locationId,
          orderNumber: orderReferenceNumber,
        };
        const uploadPrescriptionUrl = `${process.env.REACT_APP_STELLEST_API}/upload`;
        const config = {
          maxContentLength: 1048576 * 21,
          maxBodyLength: 1048576 * 21,
        };
        axios
          .post(uploadPrescriptionUrl, data, config)
          .then((res) => {
            payload.registration.orders[0].prescriptionImageUrl = res.data;
            axios
              .post(stellestRegistrationUrl, payload)
              .then((response) => {
                this.setState({ isLoading: false, success: true });
              })
              .catch((err) => {
                console.error(err);
                this._handleApiError(err);
                this.setState({ registerError: true, isLoading: false });
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post(stellestRegistrationUrl, payload)
          .then((response) => {
            this.setState({ isLoading: false, success: true });
          })
          .catch((err) => {
            console.error(err);
            this._handleApiError(err);
            this.setState({ registerError: true, isLoading: false });
          });
      }
    } else {
      const inputErrors = document.getElementsByClassName('Mui-error');
      if (inputErrors.length > 0) {
        const firstErrorPos = Math.abs(
          inputErrors[0].getBoundingClientRect().top
        );
        document
          .getElementsByClassName('stellest-form')[0]
          .scrollTo({ top: firstErrorPos, behavior: 'smooth' });
      }
    }
  };

  _handleApiError = (err) => {
    let errorsObj = {};
    if (
      err.response.data.StatusCode === 400 &&
      err.response.data.Message.startsWith('DUPLICATE ORDER REFERENCE NUMBER')
    ) {
      errorsObj['orderReferenceNumber'] = true;
    }

    this.setState({ errors: errorsObj });
  };

  _resetState = () => {
    document.documentElement.style.overflow = 'auto';
    document.body.style.overflow = 'auto';
    this.setState({
      axisL: '',
      axisR: '',
      addL: '',
      addR: '',
      comments: '',
      cylL: '',
      cylR: '',
      dob: moment(),
      edgingOption: StellestEdgingOptions.Edging,
      ethnicity: -1,
      email: '',
      errors: {},
      firstOrderDate: moment(),
      firstName: '',
      frameModel: '',
      gender: -1,
      guardian: '',
      htL: '',
      htR: '',
      lastName: '',
      isLoading: false,
      lensCoating: StellestCoatingOptions.Prevencia,
      openSource: 'navbar',
      pdL: '',
      pdR: '',
      phone: '',
      productR: '',
      productL: '',
      prescriptionImage: '',
      prescriptionImageUrl: '',
      orderReferenceNumber: '',
      orderSubmissionMedium: 1,
      orderType: this.state.featureConfiguration?.EnabledStellestOrderTypes[0],
      requireGuardian: true,
      sphereR: '',
      sphereL: '',
      success: false,
      trackingNo: '',
      showForm: false,
    });
  };

  _redirectToDashBoard = () => {
    this._resetState();
    window.location = Constants.Routes.dashboard;
  };

  _validateFields = () => {
    const { requireGuardian, edgingOption, orderSubmissionMedium } = this.state;
    var fields = [
      'firstName',
      'lastName',
      'dob',
      'guardian',
      'email',
      'phone',
      'firstOrderDate',
      'orderReferenceNumber',
    ];

    if (orderSubmissionMedium === 1) {
      fields.push('sphereR');
      fields.push('sphereL');
      fields.push('cylR');
      fields.push('cylL');
      fields.push('axisR');
      fields.push('axisL');
      fields.push('pdR');
      fields.push('pdL');
      fields.push('htL');
      fields.push('htR');
    } else if (orderSubmissionMedium === 2) {
      fields.push('prescriptionImage');
    }

    let errors = {};
    if (this.state.errors['dob'] === true) {
      errors['dob'] = true;
    }
    if (this.state.errors['firstOrderDate'] === true) {
      errors['firstOrderDate'] = true;
    }

    const fieldCheck = (field) => {
      const fieldValue = this.state[field];

      switch (field) {
        case 'guardian':
          if (requireGuardian && !fieldValue) {
            errors[field] = true;
          }
          break;
        case 'phone':
          if (isNaN(parseInt(fieldValue))) {
            errors[field] = true;
          }
          break;
        case 'email':
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(String(fieldValue).toLowerCase())) {
            errors[field] = true;
          }
          break;
        case 'frameModel':
          if (edgingOption === 1 && !fieldValue) {
            errors[field] = true;
          }
          break;
        case 'sphereR':
        case 'sphereL':
          if (
            fieldValue === '' ||
            fieldValue % 0.25 !== 0 ||
            fieldValue < -9 ||
            fieldValue > 0
          ) {
            errors[field] = true;
          }
          break;
        case 'cylR':
        case 'cylL':
          if (
            fieldValue === '' ||
            fieldValue % 0.25 !== 0 ||
            fieldValue < -4 ||
            fieldValue > 0
          ) {
            errors[field] = true;
          }
          break;
        case 'axisR':
        case 'axisL':
          if (
            fieldValue === '' ||
            fieldValue % 1 !== 0 ||
            fieldValue < 0 ||
            fieldValue > 180
          ) {
            errors[field] = true;
          }
          break;
        case 'pdR':
        case 'pdL':
        case 'htL':
        case 'htR':
          if (
            fieldValue === '' ||
            fieldValue % 0.5 !== 0 ||
            fieldValue < 10 ||
            fieldValue > 40
          ) {
            errors[field] = true;
          }
          break;
        default:
          if (
            fieldValue === null ||
            fieldValue === -1 ||
            fieldValue === undefined ||
            fieldValue === ''
          ) {
            errors[field] = true;
          }
          break;
      }
    };

    fields.forEach((field) => {
      fieldCheck(field);
    });

    if (Object.keys(errors).length > 0) {
      this.setState({
        canRegister: false,
        errors: errors,
      });
      return false;
    } else {
      this.setState({
        canRegister: true,
        errors: {},
      });
      return true;
    }
  };

  render() {
    const {
      isLoading,
      success,
      showForm,
      dataPresent,
      history,
      openSource,
      firstOrderDate,
      featureConfiguration,
    } = this.state;

    const { t } = this.props;
    if (showForm) {
      return (
        <div id="stellest-form" className="stellest-form">
          <div className="navbar">
            <div className="eyebooknow-brand">
              <img
                alt="EyeBookNow Logo"
                className="eyebooknow-logo"
                src={Utils.getLogoUrl()}
              />
            </div>
            <div className="stellest-brand">
              <img
                src={StellestLogoWhite}
                className="stellest-logo"
                alt="Stellest Logo"
              />
            </div>
            <Button
              className="back-btn"
              variant="outlined"
              onClick={
                openSource === 'navbar'
                  ? this._closeStellestForm
                  : this._resetState
              }
              startIcon={<ArrowBackIosIcon />}
            >
              {t('Back')}
            </Button>
          </div>
          <div className="body">
            <div className="header">
              <h2>
                {t('Welcome to the Stellest Care Customer Registration Form')}
              </h2>
              <p>{t('Add details, register and order for customer')}</p>
            </div>
            {!dataPresent && (
              <PatientDetailsEdit
                onFieldChange={this._onFieldChange}
                handleDobChange={this._handleDobChange}
                handleDatePickerError={this._handleDatePickerError}
                parentState={{ ...this.state }}
              />
            )}
            {dataPresent && (
              <PatientDetailsView
                onFieldChange={this._onFieldChange}
                handleDobChange={this._handleDobChange}
                parentState={{ ...this.state }}
              />
            )}
            {history}
            <OrderDetails
              onFieldChange={this._onFieldChange}
              handleFirstOrderDateChange={this._handleFirstOrderDateChange}
              handleDatePickerError={this._handleDatePickerError}
              parentState={{ ...this.state }}
              dataPresent={false}
              print={false}
              id=""
              enabledOrderTypes={featureConfiguration.EnabledStellestOrderTypes}
            />
            <div className="add-patient-container">
              <span className="message">
                {t('Thank you for using Stellest Care!')}
              </span>
              <button
                className="Stellest-form__cancel"
                onClick={this._resetState}
              >
                {t('Cancel')}
              </button>
              <button
                className="stellest-form_add-stellest"
                onClick={this._onAddStellest}
              >
                {t('Submit')}
              </button>
            </div>
          </div>
          {success && (
            <div className="success-overlay">
              <div className="success-container">
                <span onClick={this._redirectToDashBoard}>X</span>
                <h2>{t('Success!')}</h2>
                <p>
                  {t(
                    'You have successfully submitted the lens order for your customer through Stellest Care.'
                  )}
                </p>
                <hr />
                <p>
                  <small>
                    {t(
                      'Closing and leaving will redirect you back to the former page.'
                    )}
                  </small>
                </p>
                <button
                  className="close-leave"
                  onClick={this._redirectToDashBoard}
                >
                  {t('Close')}
                </button>
                <ReactToPrint
                  onBeforeGetContent={() => this._toggleSuccess}
                  onAfterPrint={() => this._toggleSuccess}
                  trigger={() => (
                    <button className="print">
                      {t('Print Order Details')}
                    </button>
                  )}
                  content={() => this._componentRef}
                />
                <div
                  className="stellest-order"
                  ref={(el) => (this._componentRef = el)}
                >
                  <StellestOrderPrint
                    parentState={{ ...this.state }}
                    orderDate={momentLocaleWrapper(firstOrderDate)
                      .format('L')
                      .toString()}
                  />
                </div>
              </div>
            </div>
          )}
          <Overlay show={isLoading}>
            <i className="spinner-eclipse"></i>
          </Overlay>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withTranslation()(StellestRegistrationForm);
