import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IframeContent from './IframeContent';
import { useTranslation } from 'react-i18next';
import EventBuilder from '../../eventBuilder';

const eventBuilder = new EventBuilder();

const TermsOfUseModal = ({ isFromSettings }) => {
  const [open, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(!open);
  };

  const { t } = useTranslation();

  return (
    <div className={isFromSettings ? `` : `cal-pane__footer`}>
      <p>
        {t('By using this platform, you agree to our')}&nbsp;
        <button
          className="terms-cond__link"
          onClick={() => {
            handleModal();
            eventBuilder
              .withLabel(eventBuilder.Label.practiceIdentifier)
              .withAction(eventBuilder.Action.Onboarding.Click.termsOfService)
              .post();
          }}
        >
          {t('Terms of Use')}
        </button>
      </p>
      <Dialog
        onClose={handleModal}
        open={open}
        fullWidth={true}
        maxWidth={'lg'}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleModal}>
          {t('Terms of Use')}
        </DialogTitle>
        <DialogContent dividers className="terms-cond__contentframe">
          <IframeContent isFromModal={true} />
        </DialogContent>
        <DialogActions>
          <button
            type="button"
            onClick={handleModal}
            className="terms-cond__btn-modal"
          >
            {t('Close')}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TermsOfUseModal;
