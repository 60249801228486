import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Enums from '../../enums';
import Utils from '../../utils';
import Storage from '../../storage';
import Constants from '../../constants';
import EventBuilder from '../../eventBuilder';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import SettingsService from '../../Services/settingsService';
import './CustomerData.scss';

/**
 * Represents the customer data component.
 * @param {Object} props The component properties.
 */
class CustomerData extends Component {
  /**
   * Initializes a new instance of the CustomerData component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      canDownloadData: true,
      errorModalMessage: '',
      showSaveAlert: false,
      showErrorModal: false,
    };
    this._settingsService = new SettingsService();
  }

  _onDownloadCustomerData = async () => {
    try {
      const eBuilder = new EventBuilder();
      eBuilder
        .withCategory(eBuilder.Category.settings)
        .withAction(eBuilder.Action.Settings.Click.getConsumerData)
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      this.setState(() => ({ canDownloadData: false }));

      const currentLocale = momentLocaleWrapper.localeData();
      const formatDate = currentLocale.longDateFormat('L');
      const formatTime = currentLocale.longDateFormat('LT');
      const { t } = this.props;
      const data = {
        content: {
          FirstName: t('First Name'),
          LastName: t('Last Name'),
          Phone: t('Phone'),
          Email: t('Email'),
          LastDate: t('Last Date of Appointment'),
          OptIn: t('Marketing Opt-In'),
          Yes: t('Yes'),
          No: t('No'),
          Source: t('Source'),
          Status: t('Status'),
          Appointment: t('Appointment'),
          Waitlist: t('Waitlist'),
          Cancelled: t('Cancelled'),
          NoShow: t('No Show'),
          Success: t('Success'),
          Pending: t('Pending'),
          VoucherCode: t('Voucher Code'),
          Rating: t('Rating'),
          Review: t('Review'),
        },
        dateTimeFormat: formatDate + ' ' + formatTime,
      };
      const locationId = Storage.getItem(Constants.currLocIdKey);
      const blob = await this._settingsService.getPatientInfoList(data);
      const fileName = `${locationId}_${momentLocaleWrapper().format(
        'MM_DD'
      )}.csv`;
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      this.setState(() => ({ canDownloadData: true }));
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        this.setState(() => ({
          canDownloadData: true,
          errorModalMessage: this.props.t(
            'There was an unexpected issue with downloading the customer data.'
          ),
          showErrorModal: true,
        }));
      }
    }
  };

  _onDownloadCustomerDataAsExcel = async () => {
    try {
      const eBuilder = new EventBuilder();
      eBuilder
        .withCategory(eBuilder.Category.settings)
        .withAction(eBuilder.Action.Settings.Click.getConsumerData)
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      this.setState(() => ({ canDownloadData: false }));

      const currentLocale = momentLocaleWrapper.localeData();
      const formatDate = currentLocale.longDateFormat('L');
      const formatTime = currentLocale.longDateFormat('LT');
      const { t } = this.props;
      const data = {
        content: {
          FirstName: t('First Name'),
          LastName: t('Last Name'),
          Phone: t('Phone'),
          Email: t('Email'),
          LastDate: t('Last Date of Appointment'),
          OptIn: t('Marketing Opt-In'),
          Yes: t('Yes'),
          No: t('No'),
          Source: t('Source'),
          Status: t('Status'),
          Appointment: t('Appointment'),
          Waitlist: t('Waitlist'),
          Cancelled: t('Cancelled'),
          NoShow: t('No Show'),
          Success: t('Success'),
          Pending: t('Pending'),
          VoucherCode: t('Voucher Code'),
          Rating: t('Rating'),
          Review: t('Review'),
        },
        dateTimeFormat: formatDate + ' ' + formatTime,
      };

      const blob = await this._settingsService.getPatientInfoListAsExcel(data);
      const locationId = Storage.getItem(Constants.currLocIdKey);
      const fileName = `${locationId}_${momentLocaleWrapper().format(
        'MM_DD'
      )}.xlsx`;
      const downloadUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);

      this.setState(() => ({ canDownloadData: true }));
    } catch (error) {
      this.setState(() => ({
        canDownloadData: true,
        errorModalMessage: this.props.t(
          'There was an unexpected issue with downloading the customer data.'
        ),
        showErrorModal: true,
      }));
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const { canDownloadData } = this.state;
    const { t } = this.props;
    const hideCsv = Utils.isCurrentStoreHkOrTw();

    return (
      <div className="cust-data">
        <h2 className="cust-data__heading">
          {t('Download customer data reports')}
        </h2>
        <h3 className="cust-data__subheading">
          {t('Download your data reports directly from this portal')}
        </h3>
        <div className="cust-data__download-cont">
          <button
            disabled={!canDownloadData}
            className="cust-data__download"
            type="button"
            onClick={this._onDownloadCustomerDataAsExcel}
          >
            {t('Export Excel')}
          </button>
          {!hideCsv && (
            <button
              disabled={!canDownloadData}
              className="cust-data__download"
              type="button"
              onClick={this._onDownloadCustomerData}
            >
              {t('Export CSV')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomerData);
