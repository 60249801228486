import React, { Component } from 'react';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Events from '../../events';
import Enums from '../../enums';
import Constants from '../../constants';
import Breakpoint from '../../breakpoint';
import VacationItem from './VacationItem';
import SettingsService from '../../Services/settingsService';
import Utils from '../../utils';
import './VacationDrawer.scss';

class VacationDrawer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVacationDrawer: false,
      resource: {},
      vacationList: [],
      savedVacationList: [],
      addEnabled: true,
      showErrorModal: false,
      errorModalMessage: '',
    };

    this._settingsService = new SettingsService();
  }

  componentDidMount() {
    this._setupSubscriptions();
  }

  _setupSubscriptions = () => {
    const { t } = this.props;
    Events.on(Constants.Events.vacationDrawerOpen, (data) => {
      if (data?.resource) {
        let vacationList = [];
        let canAddVacation = true;

        //process resource vacations
        if (
          !data.resource?.vacations ||
          data.resource?.vacations?.length === 0
        ) {
          canAddVacation = false;

          const startDate = momentLocaleWrapper();
          startDate.set('hour', 0);
          startDate.set('minute', 0);

          const endDate = momentLocaleWrapper();
          endDate.set('hour', 23);
          endDate.set('minute', 59);

          const vacation = {
            vacationId: 0,
            vacationDisplayName: t('New Vacation'),
            startDate: startDate,
            endDate: endDate,
            recurrence: Enums.VacationRecurrenceType.none,
            showSummary: false,
          };

          vacationList = vacationList.concat(vacation);
        } else {
          vacationList = data.resource.vacations.map((item) => {
            item.startDate = Utils.convertApiDateFormatToDate(item.startDate);
            item.endDate = Utils.convertApiDateFormatToDate(item.endDate);
            item.showSummary = true;
            return item;
          });
        }

        this.setState(() => ({
          showVacationDrawer: true,
          addEnabled: canAddVacation,
          resource: { ...data.resource },
          locationId: data.locationId,
          vacationList: vacationList,
          savedVacationList: vacationList,
        }));
      }
    });
  };

  /**
   * Restores the saved state of the vacation object in the list that is rendered from
   * @param {Vacation} vacation
   */
  onCancelEdit = (vacation) => {
    const { savedVacationList } = this.state;
    const savedVacation = savedVacationList.filter(
      (item) => item.vacationId === vacation?.vacationId
    )[0];
    savedVacation.showSummary = true;

    this.setState((prevState) => {
      let vacationList = prevState.vacationList.filter(
        (item) => item.vacationId !== vacation?.vacationId
      );
      vacationList = vacationList.concat(savedVacation);
      return {
        vacationList: cloneDeep(vacationList),
        addEnabled: true,
      };
    });
  };

  onHandleVacationItemRemoval = async (vacation) => {
    const { t } = this.props;
    try {
      let canRemove = false;

      if (vacation?.vacationId > 0) {
        //make call to remove via api
        const { locationId } = this.state;
        const { vacationId } = vacation;
        await this._settingsService.deleteLocationResourceVacation({
          locationId,
          vacationId,
        });
        canRemove = true;
      } else {
        canRemove = true;
      }

      if (canRemove) {
        Events.emit(Constants.Events.vacationItemRemoved, [
          this.state.resource,
          vacation,
        ]);

        this.setState((prevState) => {
          const vacationList = prevState.vacationList.filter(
            (item) => item.vacationId !== vacation.vacationId
          );

          return {
            vacationList: cloneDeep(vacationList),
            savedVacationList: cloneDeep(vacationList),
            addEnabled: true,
          };
        });
      }
    } catch (error) {
      this.setState(() => ({
        showErrorModal: true,
        errorModalMessage: t(
          'There was an unexpected issue with saving resources.'
        ),
      }));
    }
  };

  onHandleVacationItemSave = async (vacation) => {
    const { t } = this.props;
    const { resource, vacationList, locationId } = this.state;

    try {
      const vacationData = vacationList.filter(
        (item) => item.vacationId === vacation?.vacationId
      )[0];
      const data = {
        vacation: vacationData,
        locationId: locationId,
        resourceId: resource.resourceId,
      };
      const savedVacation =
        await this._settingsService.saveLocationResourceVacation(data);
      savedVacation.showSummary = true;
      savedVacation.startDate = Utils.convertApiDateFormatToDate(
        savedVacation.startDate
      );
      savedVacation.endDate = Utils.convertApiDateFormatToDate(
        savedVacation.endDate
      );

      Events.emit(Constants.Events.vacationItemAdded, [
        this.state.resource,
        savedVacation,
      ]);

      this.setState((prevState) => {
        let vacationList = prevState.vacationList.filter(
          (item) => item.vacationId !== vacation?.vacationId
        );
        vacationList = vacationList.concat(savedVacation);
        return {
          vacationList: cloneDeep(vacationList),
          savedVacationList: cloneDeep(vacationList),
          addEnabled: true,
        };
      });
    } catch (error) {
      this.setState(() => ({
        showErrorModal: true,
        errorModalMessage: t(
          'There was an unexpected issue with saving resources.'
        ),
      }));
    }
  };

  _handleClose = () => {
    Events.emit(Constants.Events.vacationDrawerClose, this.state.resource);

    this.setState(() => ({
      showVacationDrawer: false,
    }));
  };

  _addVacation = () => {
    //add item to list, in editable mode, with init values of 1 day and no recurrence
    const { t } = this.props;
    const startDate = momentLocaleWrapper();
    startDate.set('hour', 0);
    startDate.set('minute', 0);

    const endDate = momentLocaleWrapper();
    endDate.set('hour', 23);
    endDate.set('minute', 59);

    let vacation = {
      vacationId: 0,
      vacationDisplayName: t('New Vacation'),
      startDate: startDate,
      endDate: endDate,
      recurrence: Enums.VacationRecurrenceType.none,
      showSummary: false,
    };

    this.setState((prevState) => ({
      addEnabled: false,
      vacationList: prevState.vacationList.concat(vacation),
    }));
  };

  onChangeName = (vacation, name) => {
    const { vacationList } = this.state;
    const newVacation = vacationList.filter(
      (item) => item.vacationId === vacation?.vacationId
    )[0];
    newVacation.vacationDisplayName = name;

    this.setState((prevState) => {
      let vacationList = prevState.vacationList.filter(
        (item) => item.vacationId !== vacation?.vacationId
      );
      vacationList = vacationList.concat(newVacation);
      return {
        vacationList: cloneDeep(vacationList),
      };
    });
  };

  onDayChange = (vacation, date, start, dayChange) => {
    const { vacationList } = this.state;
    const newVacation = vacationList.filter(
      (item) => item.vacationId === vacation?.vacationId
    )[0];
    if (start) {
      const startDate = momentLocaleWrapper(newVacation?.startDate);
      let newStartDay = '';
      if (dayChange) {
        newStartDay = date
          ? momentLocaleWrapper(date)
              .startOf('day')
              .add(startDate.hour(), 'hours')
              .add(startDate.minutes(), 'minutes')
          : '';
      } else {
        newStartDay = date
          ? momentLocaleWrapper(startDate)
              .startOf('day')
              .add(date.hour(), 'hours')
              .add(date.minutes(), 'minutes')
          : '';
      }
      newVacation.startDate = newStartDay;
    } else {
      const end = momentLocaleWrapper(newVacation.endDate);
      let newEndDate = '';
      if (dayChange) {
        newEndDate = date
          ? momentLocaleWrapper(date)
              .startOf('day')
              .add(end.hour(), 'hours')
              .add(end.minutes(), 'minutes')
          : '';
      } else {
        newEndDate = date
          ? momentLocaleWrapper(end)
              .startOf('day')
              .add(date.hour(), 'hours')
              .add(date.minutes(), 'minutes')
          : '';
      }
      newVacation.endDate = newEndDate;
    }

    this.setState((prevState) => {
      let vacationList = prevState.vacationList.filter(
        (item) => item.vacationId !== vacation?.vacationId
      );
      vacationList = vacationList.concat(newVacation);
      return {
        vacationList: cloneDeep(vacationList),
      };
    });
  };

  onEditItemClick = (vacation) => {
    const { vacationList } = this.state;
    const newVacation = vacationList.filter(
      (item) => item.vacationId === vacation?.vacationId
    )[0];
    newVacation.showSummary = false;

    this.setState((prevState) => {
      let vacationList = prevState.vacationList.filter(
        (item) => item.vacationId !== vacation?.vacationId
      );
      vacationList = vacationList.concat(newVacation);
      return {
        vacationList: cloneDeep(vacationList),
        addEnabled: false,
      };
    });
  };

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  render() {
    const {
      showVacationDrawer,
      resource,
      vacationList,
      addEnabled,
      showErrorModal,
      errorModalMessage,
    } = this.state;

    const { t } = this.props;

    const vacationItems = vacationList
      .sort((a, b) => (a.vacationId > b.vacationId ? 1 : -1))
      .map((vacation, key) => {
        return (
          <VacationItem
            key={key}
            showSummary={vacation.showSummary}
            vacation={vacation}
            handleVacationItemRemoval={this.onHandleVacationItemRemoval}
            handleVacationItemSave={this.onHandleVacationItemSave}
            onCancelEdit={this.onCancelEdit}
            onChangeName={this.onChangeName}
            onStartDayChange={this.onDayChange}
            onStartTimeChange={this.onDayChange}
            onEndDayChange={this.onDayChange}
            onEndTimeChange={this.onDayChange}
            onEditItemClick={this.onEditItemClick}
          />
        );
      });

    return (
      <Drawer
        anchor={Breakpoint.value === 'mobile' ? 'bottom' : 'right'}
        open={showVacationDrawer}
      >
        <div className="vac-main">
          <div className="vac-main__commands">
            <h2 className="vac-main__title">{t('Vacations')}</h2>
            <button className="vac-main__close" onClick={this._handleClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="vac-main__group">
            <div className="vac-main__input">
              {t('Resource Name')}
              <input
                className="vac-main__input"
                disabled={true}
                type="text"
                value={
                  resource.isDefault &&
                  resource.displayName === Constants.defaultResourceDisplayName
                    ? t('Appointments')
                    : resource.displayName
                }
              />
            </div>
          </div>
          <div className="vac-main__group">
            <div className="vac-main__list">{vacationItems}</div>
          </div>
          <div
            className={`vac-main__group ${
              !vacationItems || vacationItems.length === 0
                ? 'vac-main__group--bt'
                : ''
            }`}
          >
            <div className="vac-main__add">
              <button
                className="vac-main__button"
                disabled={!addEnabled}
                onClick={this._addVacation}
              >
                {t('Add')}
              </button>
            </div>
          </div>
        </div>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="admin-settings__modal-btn"
              onClick={this._onCloseErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
      </Drawer>
    );
  }
}

export default withTranslation()(VacationDrawer);
