import React, { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import Constants from '../../constants';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Drawer from '@material-ui/core/Drawer';
import StarIcon from '@material-ui/icons/Star';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import HistoryIcon from '@material-ui/icons/History';
import Overlay from '../../Components/Overlay/Overlay';
import StarOutlineIcon from '@material-ui/icons/StarBorder';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Session from '../../session';
import Utils from '../../utils';
import ContentManagementService from '../../Services/contentManagementService';
import PatientService from '../../Services/patientService';
import LocationConfigService from '../../Services/locationConfigService';
import CustomerProfileDrawer from './CustomerProfileDrawer';
import './Customers.scss';

/**
 * Represents the customers (patients) page.
 */
class Customers extends Component {
  /**
   * Initializes a new instance of the Customers component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this._searchFields = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      apptDate: null,
      offset: 0,
    };
    this.state = {
      currentCustomer: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        notes: '',
      },
      customers: [],
      customerCount: 0,
      currentPage: 1,
      customerNameSortAsc: true,
      customerApptSortAsc: false,
      isReadyToRender: false,
      showAppointmentHistory: false,
      isCustomerProfileOpen: false,
      showSearchDrawer: false,
      showLoadingOverlay: false,
      phoneCountryCode: '',
      searchFields: cloneDeep(this._searchFields),
    };
    this._maxRating = 5;
    this._patientService = new PatientService();
    this._contentManagementService = new ContentManagementService();
    this._locationConfigService = new LocationConfigService();
    this._onPageChange = this._onPageChange.bind(this);
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  async componentDidMount() {
    try {
      this.setState(() => ({ showLoadingOverlay: true }));

      await this._getPhoneCountryCode();
      await this._getCustomerList(false);
      await this._getLocationConfig();

      this.setState(() => ({
        isReadyToRender: true,
        showLoadingOverlay: false,
      }));
    } catch (error) {
      // TODO: SHOW FRIENDLY ERROR MODAL.
    }
  }

  _getLocationConfig = async () => {
    try {
      const locationConfig =
        await this._locationConfigService.getLocationConfig();
      this.setState({ locationConfig: locationConfig });
    } catch (error) {
      console.error(error);
    }
  };

  _getPhoneCountryCode = async () => {
    try {
      const country = Session.getItem(Constants.currCountry);
      const phoneCountryCode =
        await this._contentManagementService.getPhoneCountryCode(country);

      this.setState(() => ({ phoneCountryCode: phoneCountryCode }));
    } catch (error) {
      console.error(error);
    }
  };

  _getAppointmentHistory = (customer) => {
    const { t } = this.props;

    if (customer.appointmentHistory?.length > 0) {
      return customer.appointmentHistory.map((appointment, key) => {
        const { firstName, lastName } = customer;
        const {
          isExpanded,
          startTime,
          voucherCode,
          appointmentId,
          feedbackRating,
          feedbackComments,
          appointmentNotes,
        } = appointment;

        return (
          <ExpansionPanel
            expanded={isExpanded}
            key={key}
            onChange={() => this._onToggleAppointmentPanel(appointmentId)}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <p className="customers__panel-title">
                <span>
                  {momentLocaleWrapper(startTime).format(
                    'ddd, MMM DD YYYY HH:mm'
                  )}
                </span>
                <br />
                <span>
                  {firstName}&nbsp;{lastName}
                </span>
              </p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="customers__panel-content">
                <TextField
                  className="customers__input"
                  inputProps={{ maxLength: 100 }}
                  label={t('Notes')}
                  multiline
                  rows={5}
                  variant="outlined"
                  value={appointmentNotes}
                />
                <div className="customers__panel-line">
                  <span className="customers__panel-label">{t('Rating')}</span>
                  <ul className="customers__rating">
                    {this._getRatingItems(feedbackRating)}
                  </ul>
                </div>
                <div className="customers__panel-line">
                  <span className="customers__panel-label">{t('Review')}</span>
                  <p className="customers__comments">{feedbackComments}</p>
                </div>
                <div className="customers__panel-line">
                  <span className="customers__panel-label customers__panel-label--long">
                    {t('Voucher Code')}
                  </span>
                  <span>{voucherCode}</span>
                </div>
                <div className="customers__panel-footer">
                  <button
                    className="customers__close-btn"
                    onClick={() =>
                      this._onToggleAppointmentPanel(appointmentId)
                    }
                  >
                    {t('Close')}
                  </button>
                  <button className="customers__save-btn">{t('Save')}</button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      });
    } else {
      return <span>{this.props.t('No appointments')}</span>;
    }
  };

  _openDrawerWithCustomer = (customer) => {
    this.setState(() => ({
      currentCustomer: customer,
      isCustomerProfileOpen: true,
    }));
  };

  _getCustomerItems = () => {
    return this.state.customers.map((customer, key) => (
      <tr key={key}>
        <td>
          <button
            className="customers__name-link"
            onClick={() => this._openDrawerWithCustomer(customer)}
          >
            {customer.firstName}&nbsp;{customer.lastName}
          </button>
        </td>
        <td>
          {customer?.latestAppointmentDate
            ? momentLocaleWrapper(customer.latestAppointmentDate).format(
                'ddd, MMM DD YYYY HH:mm'
              )
            : ''}
        </td>
        <td>
          <a href={`mailto:${customer.email}`}>{customer.email}</a>
        </td>
        <td>
          <a href={`tel:${customer.phone}`}>{customer.phone}</a>
        </td>
        <td>{customer.marketingOptIn && <CheckIcon />}</td>
        <td>
          <div className="customers__history-cell">
            <button
              className="customers__history-btn"
              onClick={() => this._onToggleAppointmentHistory(customer)}
            >
              <HistoryIcon />
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  _getRatingItems = (appointmentRating) => {
    const ratingItems = [];

    for (let index = 0; index < appointmentRating; ++index) {
      ratingItems.push(
        <li key={index}>
          <StarIcon />
        </li>
      );
    }

    const numFilledItems = ratingItems.length;

    if (numFilledItems < this._maxRating) {
      const numEmptyItems = this._maxRating - numFilledItems;

      for (let index = 0; index < numEmptyItems; ++index) {
        ratingItems.push(
          <li key={numFilledItems + index + 1}>
            <StarOutlineIcon />
          </li>
        );
      }
    }

    return ratingItems;
  };

  _getFrequencyOfUse = (preApptfrequencyOfUse) => {
    if (preApptfrequencyOfUse) {
      const { t } = this.props;
      let frequencyOfUse = '';
      if (preApptfrequencyOfUse === Constants.FrequencyOfUse.allTheTime) {
        frequencyOfUse = t('All the time');
      } else if (preApptfrequencyOfUse === Constants.FrequencyOfUse.sometimes) {
        frequencyOfUse = t('Sometimes');
      } else if (
        preApptfrequencyOfUse === Constants.FrequencyOfUse.farDistance
      ) {
        frequencyOfUse = t('Only for far distance');
      } else if (preApptfrequencyOfUse === Constants.FrequencyOfUse.reading) {
        frequencyOfUse = t('Only for reading');
      } else {
        frequencyOfUse = t('Only for the computer');
      }
      return frequencyOfUse;
    }
    return null;
  };

  _onSortCustomersByAppt = () => {
    this.setState((prevState) => {
      const { customers, customerApptSortAsc } = prevState;
      const sortAsc = !customerApptSortAsc;
      const updatedCustomers = cloneDeep(customers).sort((cust1, cust2) => {
        var dateA = new Date(cust1.latestAppointmentDate);
        var dateB = new Date(cust2.latestAppointmentDate);

        if (sortAsc) {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      return {
        customers: updatedCustomers,
        customerApptSortAsc: sortAsc,
      };
    });
  };

  _onSortCustomersByName = () => {
    this.setState((prevState) => {
      const { customers, customerNameSortAsc } = prevState;
      const sortAsc = !customerNameSortAsc;
      const updatedCustomers = cloneDeep(customers).sort((cust1, cust2) => {
        let sortValue = 0;

        if (sortAsc) {
          if (
            cust1.lastName.toLocaleLowerCase() <
            cust2.lastName.toLocaleLowerCase()
          ) {
            sortValue = -1;
          }

          if (
            cust1.lastName.toLocaleLowerCase() >
            cust2.lastName.toLocaleLowerCase()
          ) {
            sortValue = 1;
          }
        } else {
          if (
            cust1.lastName.toLocaleLowerCase() <
            cust2.lastName.toLocaleLowerCase()
          ) {
            sortValue = 1;
          }

          if (
            cust1.lastName.toLocaleLowerCase() >
            cust2.lastName.toLocaleLowerCase()
          ) {
            sortValue = -1;
          }
        }

        return sortValue;
      });

      return {
        customers: updatedCustomers,
        customerNameSortAsc: sortAsc,
      };
    });
  };

  _onToggleAppointmentHistory = (customer) => {
    this.setState((prevState) => ({
      currentCustomer: customer,
      showAppointmentHistory: !prevState.showAppointmentHistory,
    }));
  };

  _onToggleAppointmentPanel = (appointmentId) => {
    this.setState((prevState) => {
      const updatedCustomers = prevState.customers.map((customer) => {
        if (customer.appointmentHistory !== null) {
          //if a customer has no appt history, then regardless of which data is selected there would be a mapping on a null property
          customer.appointmentHistory = customer.appointmentHistory.map(
            (appointment) => {
              if (appointment.appointmentId === appointmentId) {
                appointment.isExpanded = !appointment.isExpanded;
              }
              return appointment;
            }
          );
        }
        return customer;
      });

      return {
        customers: updatedCustomers,
      };
    });
  };

  _onToggleCustomerProfile = (customer) => {
    this.setState((prevState) => ({
      currentCustomer: customer,
      isCustomerProfileOpen: !prevState.isCustomerProfileOpen,
    }));
  };

  _onToggleSearchCustomer = () => {
    this.setState((prevState) => ({
      showSearchDrawer: !prevState.showSearchDrawer,
      searchFields: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        apptDate: null,
        offset: 0,
      },
    }));
  };

  _hasVisualDemands = (preAppointmentSurvey) => {
    if (preAppointmentSurvey) {
      const currVisualDemands = [];
      preAppointmentSurvey.visualDemandDigitalDevice &&
        currVisualDemands.push(true);
      preAppointmentSurvey.visualDemandReadWrite &&
        currVisualDemands.push(true);
      preAppointmentSurvey.visualDemandNightDrive &&
        currVisualDemands.push(true);

      return currVisualDemands;
    }
    return null;
  };

  _onFormFieldChange = (path, value) => {
    this.setState((prevState) => {
      const updatedSearchFields = cloneDeep(prevState.searchFields);

      if (path[0] === 'phone') {
        value = isNaN(parseInt(value)) ? '' : parseInt(value);
        Utils.update(updatedSearchFields, path, value);
      } else {
        Utils.update(updatedSearchFields, path, value);
      }

      return {
        searchFields: updatedSearchFields,
      };
    });
  };

  _onStartDayChange = (date) => {
    this.setState((prevState) => {
      const searchFields = cloneDeep(prevState.searchFields);
      const newApptDate = date
        ? momentLocaleWrapper(date)
            .format(Constants.DateFormats.searchCustomerAppointmentDateFormat)
            .toString()
        : '';
      searchFields.apptDate = newApptDate;

      return {
        searchFields: searchFields,
      };
    });
  };

  _getCustomerList = async (searchDrawer, pageNumber = 1) => {
    const { searchFields } = this.state;
    searchFields.apptDate = searchFields.apptDate
      ? momentLocaleWrapper(searchFields.apptDate)
          .format(Constants.DateFormats.searchCustomerAppointmentDateFormat)
          .toString()
      : '';
    searchFields.email = encodeURIComponent(searchFields.email);
    searchFields.offset = 20 * (pageNumber - 1);
    const patientSearchResult = await this._patientService.getPatientList(
      searchFields
    );

    if (searchDrawer) {
      this._onToggleSearchCustomer();
    }

    this.setState(() => ({
      customers: patientSearchResult.patients,
      customerCount: patientSearchResult.total,
      currentPage: pageNumber,
      isReadyToRender: true,
      showLoadingOverlay: false,
    }));
  };

  _onPageChange = async (event) => {
    await this._getCustomerList(false, event.target.id);
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      currentCustomer,
      currentPage,
      customerCount,
      locationConfig,
      isReadyToRender,
      showAppointmentHistory,
      isCustomerProfileOpen,
      showSearchDrawer,
      showLoadingOverlay,
      phoneCountryCode,
      searchFields,
    } = this.state;
    const { firstName, lastName, phone, email, apptDate } = searchFields;
    const { t } = this.props;

    //Currently, we are limiting display to 20 patients per page.
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(customerCount / 20); i++) {
      pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li
          className={
            currentPage === number ? 'customers__page-numbers-selected' : ''
          }
          key={number}
          id={number}
          onClick={this._onPageChange}
        >
          {number}
        </li>
      );
    });

    return (
      <div className="customers">
        {isReadyToRender && (
          <section className="customers__submenu">
            <h2 className="customers__title">{t('Customers')}</h2>
            <TextField
              className="customers__search"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={t('Search by Patient/Resource')}
              size="small"
              variant="outlined"
              onClick={() => this._onToggleSearchCustomer()}
            />
          </section>
        )}
        {isReadyToRender && (
          <section className="customers__page">
            <table className="customers__table">
              <thead>
                <tr>
                  <th>
                    <div className="customers__sort-col">
                      {t('Name')}
                      <button
                        className="customers__sort-btn"
                        onClick={this._onSortCustomersByName}
                      >
                        <ImportExportIcon />
                      </button>
                    </div>
                  </th>
                  <th>
                    <div className="customers__sort-col">
                      {t('Last Appointment Date/Time')}
                      <button
                        className="customers__sort-btn"
                        onClick={this._onSortCustomersByAppt}
                      >
                        <ImportExportIcon />
                      </button>
                    </div>
                  </th>
                  <th>{t('Email')}</th>
                  <th>{t('Phone')}</th>
                  <th>{t('Marketing Opt-In')}</th>
                  <th>
                    <div className="customers__history-col">{t('History')}</div>
                  </th>
                </tr>
              </thead>
              <tbody>{this._getCustomerItems()}</tbody>
            </table>
            <div>
              <ul className="customers__page-numbers">{renderPageNumbers}</ul>
            </div>
          </section>
        )}
        <CustomerProfileDrawer
          t={t}
          isOpen={isCustomerProfileOpen}
          customer={cloneDeep(currentCustomer)}
          locationConfig={locationConfig}
          onClose={() =>
            this.setState(() => ({ isCustomerProfileOpen: false }))
          }
          onSave={async (customer) => {
            const updatedCustomer = {
              locationId: customer.locationId,
              patientKey: customer.patientKey,
              firstName: customer.firstName,
              lastName: customer.lastName,
              phone: customer.phone,
              email: customer.email,
              preferredPlatform: customer.preferredPlatform,
            };
            //update remote state
            await this._patientService.updatePatient(updatedCustomer);

            //update local state
            Object.assign(
              this.state.customers.find(
                (c) => c.patientKey === customer.patientKey
              ),
              customer
            );
            this.setState(() => ({
              currentCustomer: customer,
              customers: this.state.customers,
              isCustomerProfileOpen: false,
            }));
          }}
          onUpdate={(customer) =>
            this.setState(() => ({ currentCustomer: customer }))
          }
        ></CustomerProfileDrawer>
        <Drawer anchor="right" open={showAppointmentHistory}>
          <div className="customers__drawer">
            <div className="customers__drawer-header">
              <h3 className="customers__drawer-title">
                {t('Appointment History')}
              </h3>
              <button
                className="customers__close-drawer"
                onClick={() =>
                  this._onToggleAppointmentHistory(currentCustomer)
                }
              >
                <CloseIcon />
              </button>
            </div>
            <div className="customers__drawer-content">
              {this._getAppointmentHistory(currentCustomer)}
            </div>
          </div>
        </Drawer>
        <Drawer anchor="right" open={showSearchDrawer}>
          <div className="customers__drawer">
            <div className="customers__drawer-header">
              <h3 className="customers__drawer-title">
                {t('Search for Patient/Resource')}
              </h3>
              <button
                className="customers__close-drawer"
                onClick={() => this._onToggleSearchCustomer()}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="customers__search-group">
              <TextField
                className="customers__search-input"
                label={t('First Name')}
                variant="outlined"
                value={firstName}
                onChange={(e) =>
                  this._onFormFieldChange(['firstName'], e.target.value)
                }
              />
            </div>
            <div className="customers__search-group">
              <TextField
                className="customers__search-input"
                label={t('Last Name')}
                variant="outlined"
                value={lastName}
                onChange={(e) =>
                  this._onFormFieldChange(['lastName'], e.target.value)
                }
              />
            </div>
            <div className="customers__search-group">
              <TextField
                className="customers__search-input"
                label={t('Phone')}
                type="phone"
                variant="outlined"
                value={phone}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {phoneCountryCode}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  this._onFormFieldChange(['phone'], e.target.value)
                }
              />
            </div>
            <div className="customers__search-group">
              <TextField
                className="customers__search-input"
                label={t('Email')}
                variant="outlined"
                value={email}
                onChange={(e) =>
                  this._onFormFieldChange(['email'], e.target.value)
                }
              />
            </div>
            <div className="customers__search-group">
              <MuiPickersUtilsProvider
                libInstance={momentLocaleWrapper}
                locale={momentLocaleWrapper.locale()}
                utils={MomentUtils}
              >
                <DatePicker
                  cancelLabel={t('Cancel')}
                  disableToolbar
                  className="customers__search-input"
                  format="ddd, L"
                  label={t('Last Appointment Day/Date')}
                  margin="normal"
                  okLabel={t('OK')}
                  value={apptDate}
                  onChange={this._onStartDayChange}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="customers__profile-footer">
              <button
                className="customers__close-btn"
                onClick={() => this._onToggleSearchCustomer()}
              >
                {t('Close')}
              </button>
              <button
                className="customers__save-btn"
                onClick={() => this._getCustomerList(true)}
              >
                {t('Search')}
              </button>
            </div>
          </div>
        </Drawer>
        <Overlay show={showLoadingOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    );
  }
}

export default withTranslation()(Customers);
