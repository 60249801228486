// NOTE: I'm creating this to wrap around moment, with the locales we need.
import moment from 'moment/min/moment-with-locales';
const momentLocaleWrapper = moment;

// custom locales
momentLocaleWrapper.defineLocale('en-SA', {
  parentLocale: 'en',
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'DD.MM.YYYY',
    l: 'D.M.YYYY',
    LL: 'Do MMMM YYYY',
    ll: 'D MMM YYYY',
    LLL: 'Do MMMM YYYY LT',
    lll: 'D MMM YYYY LT',
    LLLL: 'dddd, Do MMMM YYYY LT',
    llll: 'ddd, D MMM YYYY LT',
  },
});

momentLocaleWrapper.defineLocale('en-OM', {
  parentLocale: 'en',
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'DD/MM/YYYY',
    l: 'D/M/YYYY',
    LL: 'Do MMMM YYYY',
    ll: 'D MMM YYYY',
    LLL: 'Do MMMM YYYY LT',
    lll: 'D MMM YYYY LT',
    LLLL: 'dddd, Do MMMM YYYY LT',
    llll: 'ddd, D MMM YYYY LT',
  },
});

momentLocaleWrapper.defineLocale('en-BH', {
  parentLocale: 'en',
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'DD/MM/YYYY',
    l: 'D/M/YYYY',
    LL: 'Do MMMM YYYY',
    ll: 'D MMM YYYY',
    LLL: 'Do MMMM YYYY LT',
    lll: 'D MMM YYYY LT',
    LLLL: 'dddd, Do MMMM YYYY LT',
    llll: 'ddd, D MMM YYYY LT',
  },
});

momentLocaleWrapper.defineLocale('en-SG', {
  parentLocale: 'en',
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'DD/MM/YYYY',
    l: 'D/M/YYYY',
    LL: 'Do MMMM YYYY',
    ll: 'D MMM YYYY',
    LLL: 'Do MMMM YYYY LT',
    lll: 'D MMM YYYY LT',
    LLLL: 'dddd, Do MMMM YYYY LT',
    llll: 'ddd, D MMM YYYY LT',
  },
});

momentLocaleWrapper.updateLocale('tr', {
  weekdaysShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
});

export default momentLocaleWrapper;
