import ReactGA from 'react-ga';

/**
 * Represents a helper class around Analytics.
 * See: https://github.com/react-ga/react-ga/blob/master/README.md
 */
class GoogleAnalytics {
  /**
   * Initialize the GA instance.
   * This should only be run once.
   */
  static init = () => {
    const key = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY;
    const options = {
      debug: process.env.REACT_APP_ENVIRONMENT === 'development',
    };

    ReactGA.initialize(key, options);
  };

  /**
   * Posts an event to analytics.
   * @param {ReactGA.EventArgs} event An event.
   */
  static postEvent = (event) => {
    ReactGA.event(event);
  };

  /**
   * Posts a pageview to analytics.
   * @param {String} url The url, e.g. '/get-involved/other-ways-to-help' .
   */
  static pageview = (url) => {
    ReactGA.pageview(url);
  };

  /**
   * Posts a modalview to analytics.
   * @param {String} modalName The modal name, e.g. 'login', 'read-terms-and-conditions'
   */
  static modalview = (modalName) => {
    ReactGA.modalview(modalName);
  };
}

// Lock object to prevent modification (true static).
Object.freeze(GoogleAnalytics);

export default GoogleAnalytics;
