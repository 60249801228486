import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import MuiDrawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Utils from '../../utils';
import Events from '../../events';
import Constants from '../../constants';
import Breakpoint from '../../breakpoint';
import './GenericFooter.scss';

const Drawer = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: '10 !important',
    position: 'absolute',
  },
  paper: {
    borderRadius: '15px 15px 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '150px',
    padding: '2em 0',
    width: '100%',
    zIndex: '11',
    left: 'auto',
  },
})(MuiDrawer);

/**
 * Represents the calendar footer.
 */
class GenericFooter extends Component {
  /**
   * Initializes a new instance of the GenericFooter component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      disableDrawerButton: false,
      showDrawer: false,
      showButtons: false,
    };
    this._adminFooter = createRef();
  }

  /**
   * Executes when the component has mounted to the DOM.
   */
  componentDidMount() {
    this._setupEventHandlers();
  }

  /**
   * Executes when the component has unmounted from the DOM.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this._safeWindowResize);
  }

  _onAddAppointment = () => {
    Events.emit(Constants.Events.onToggleAddAppointment);
  };

  _onAddStellest = () => {
    Events.emit(Constants.Events.onToggleAddStellest);
  };

  _onToggleButtonExpansion = () => {
    this.setState((prevState) => ({ showButtons: !prevState.showButtons }));
  };

  _onToggleDrawer = (isClosing) => {
    // Disable the drawer button to prevent quick
    // clicks. We need to give the drawer time
    // to close and time for us to change the
    // z-index back to normal for the menu.
    if (isClosing) {
      this.setState(() => ({ disableDrawerButton: true }));
    }

    this.setState((prevState) => {
      // We need to delay changing the z-index
      // of the footer, so that there is not a
      // flash on the UI.
      if (isClosing) {
        const timeout = setTimeout(() => {
          clearTimeout(timeout);

          this._adminFooter.current.style.zIndex = 1;

          this.setState(() => ({ disableDrawerButton: false }));
        }, 1000);
      } else {
        this._adminFooter.current.style.zIndex = 5;
      }

      return {
        showDrawer: !prevState.showDrawer,
      };
    });
  };

  _setupEventHandlers = () => {
    const quarterOfASecond = 250;
    this._safeWindowResize = Utils.debounce(() => {
      if (
        Breakpoint.value === Constants.Breakpoints.tablet ||
        Breakpoint.value === Constants.Breakpoints.desktop
      ) {
        this.setState(() => ({ showFilters: false }));
      }
    }, quarterOfASecond);

    window.addEventListener('resize', this._safeWindowResize);
  };

  /**
   * Renders the component.
   */
  render() {
    const { showButtons } = this.state;
    const { t, settingIsStellestEnabled } = this.props;

    const isStellestEnabled = settingIsStellestEnabled
      ? settingIsStellestEnabled.settingValue
      : false;

    return (
      <div className="generic-footer" ref={this._adminFooter}>
        <div
          className="generic-footer__button-expansion"
          ref={(node) => {
            this.container = node;
          }}
        >
          <button
            className={`generic-footer__expand`}
            onClick={() => this._onToggleButtonExpansion()}
          >
            <CloseIcon className={`generic-footer__expand-icon`} />
          </button>
          {showButtons && (
            <Drawer
              anchor="bottom"
              open={showButtons}
              className="generic-footer__btn-container"
            >
              <button
                className={`generic-footer__expand ${
                  showButtons ? 'generic-footer__expand--active' : ''
                }`}
                onClick={() => this._onToggleButtonExpansion()}
              >
                <CloseIcon
                  className={`generic-footer__expand-icon ${
                    showButtons ? 'generic-footer__expand-icon--active' : ''
                  }`}
                />
              </button>
              <button
                className="generic-footer__btn"
                onClick={this._onAddAppointment}
              >
                {t('Add Appointment')}
              </button>
              {isStellestEnabled && (
                <button
                  className="generic-footer__btn"
                  onClick={() => this._onAddStellest()}
                >
                  {t('Stellest')}
                </button>
              )}
            </Drawer>
          )}
        </div>
      </div>
    );
  }
}

GenericFooter.propTypes = {};

export default withTranslation()(GenericFooter);
