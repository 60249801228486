import React, { useEffect, useRef, useState } from 'react';
import './TwoWayConversation.scss';
import TwoWayConversationService from '../../Services/twoWayConversationService';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Logger from '../../logger';
import TwoWayConversationSkeleton from './TwoWayConversationSkeleton';
import Constants from '../../constants';
import momentLocaleWrapper from '../../momentLocaleWrapper';
import { useHistory } from 'react-router-dom';
import TwoWayConversationNewTemplate from '../TwoWayConversationNew/TwoWayConversationNewTemplate';
import Overlay from '../../Components/Overlay/Overlay';
import { withTranslation } from 'react-i18next';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Enums from '../../enums';

const TwoWayConversation = ({
  t,
  conversation,
  refreshConversationList,
  locationConfigName,
  locationConfigAddress,
  locationConfigPhone,
  templateList,
  errorModal,
  conversationOptOut,
}) => {
  const twoWayConversationService = new TwoWayConversationService();
  const conversationListRef = useRef(null);
  const [loadingConversationDetails, setLoadingConversationDetails] = useState(true);

  const [messages, setMessages] = useState([]);
  const [patient, setPatient] = useState({
    firstName: '',
    lastName: '',
    formattedPhone: '',
  });
  const [smsToList, setSmsToList] = useState([]);
  const [closeConversation, setCloseConversation] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [text, setText] = useState('');
  const [isWithinReplyWindow, setIsWithinReplyWindow] = useState(false);
  const history = useHistory();
  const scrollToRef = (ref) => ref.current.scrollTo(0, ref.current.scrollHeight);

  useEffect(() => {
    setSelectedTemplateId(null);
    setText('');
    setIsWithinReplyWindow(true);
    getNewConversation(conversation);
  }, [conversation]);

  const getNewConversation = async (showloader = true) => {
    if (showloader) {
      setLoadingConversationDetails(true);
    }
    try {
      const data = await twoWayConversationService.getMessages(conversation);
      setLoadingConversationDetails(false);
      if (data && data.messages) {
        Logger.log('getMessages', data);
        setMessages(data.messages);

        let preferredPlatform = t('SMS Texting');
        if (data.preferredPlatform === Enums.PreferredPlatform.whatsApp) {
          preferredPlatform = t('WhatsApp');
        }
        const patient = {
          patientKey: data.patientKey,
          firstName: data.firstName,
          lastName: data.lastName,
          formattedPhone: data.formattedPhone,
          preferredPlatform: preferredPlatform,
        };
        setSmsToList([patient]);
        setPatient(patient);
        scrollToRef(conversationListRef);
        setIsWithinReplyWindow(data.replyWindow && new Date(data.replyWindow) > new Date());
      }
    } catch (err) {
      setLoadingConversationDetails(false);
      Logger.error('getConversation', err, 'error');
    }
  };

  const handleCloseConversation = () => {
    setCloseConversation(true);
    history.push('/chat/');
  };

  const handleSend = async (text) => {
    setIsLoading(true);
    try {
      let result = null;
      if (selectedTemplateId) {
        result = await twoWayConversationService.sendTemplatedMessage(
          selectedTemplateId,
          patient.patientKey,
          text
        );
      } else {
        result = await twoWayConversationService.postMessage(conversation.conversationKey, text);
      }
      if (result.success === false && result.reason === Constants.TwoWayMessages.optOutReason) {
        errorModal(
          'No message sent.  Customer opted out of messaging.',
          t('No message sent.  Customer opted out of messaging.')
        );
        Logger.log('No message sent.  Customer opted out of messaging.');
        conversationOptOut(patient.patientKey);
      } else if (result.success === false) {
        errorModal('Unable to send message', t('Unable to send message'));
        Logger.log('Unable to send message');
      }
      refreshConversationList();
    } catch (err) {
      errorModal(err, t('Unable to send message'));
      Logger.error('handleSend', err, 'error');
    }
    setIsLoading(false);
  };

  const handleSendWithAttachment = async (text, file) => {
    setIsLoading(true);
    try {
      let result = null;
      if (
        selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms ||
        selectedTemplateId === null ||
        selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote
      ) {
        result = await twoWayConversationService.sendAttachmentMessage(
          conversation.conversationKey,
          patient.patientKey,
          file,
          text,
          selectedTemplateId
        );
      } else {
        result = await twoWayConversationService.sendTemplatedMessage(
          selectedTemplateId,
          patient.patientKey,
          text
        );
      }
      if (result.success === false && result.reason === Constants.TwoWayMessages.optOutReason) {
        errorModal(
          'No message sent.  Customer opted out of messaging.',
          t('No message sent.  Customer opted out of messaging.')
        );
        Logger.log('No message sent.  Customer opted out of messaging.');
        conversationOptOut(patient.patientKey);
      } else if (result.success === false) {
        errorModal('Unable to send message', t('Unable to send message'));
        Logger.log('Unable to send message');
      }
      refreshConversationList();
    } catch (err) {
      errorModal(err, t('Unable to send message'));
      Logger.error('handleSend', err, 'error');
    }
    setIsLoading(false);
  };

  const handleSendWithAutoquote = async (chatAutoquoteRequest) => {
    setIsLoading(true);
    await twoWayConversationService.sendAutoquoteMessage(chatAutoquoteRequest);
    setIsLoading(false);
  };

  return (
    <div
      className={
        !closeConversation
          ? 'conversation__holder'
          : 'conversation__holder conversation__holder--hide'
      }
    >
      <div className="conversation__header">
        <div className="row row__start">
          <a className="btn-back" onClick={handleCloseConversation}>
            <ArrowBackIosIcon fontSize="small" />
          </a>
          <h1>
            <span className="txt--caps">{patient.firstName}</span>{' '}
            <span className="txt--caps">{patient.lastName}</span>
          </h1>
        </div>
        <p>{patient.formattedPhone}</p>
        <p>{patient.preferredPlatform}</p>
      </div>

      <Overlay show={isLoading}>
        <i className="spinner-eclipse"></i>
      </Overlay>

      <div className="conversation__list" ref={conversationListRef}>
        {!loadingConversationDetails &&
          messages.map((item, key) => {
            const stateFrom = item.smsFrom === '4PC' ? 'sent' : 'receive';
            const sysMessage = item.systemTemplate > 0 ? 'system' : 'user';
            return (
              <div key={key} className={stateFrom}>
                <span>
                  {item.smsFrom === '4PC'
                    ? locationConfigName
                    : `${patient.firstName} ${patient.lastName}`}
                </span>
                <p className={`message ${sysMessage}`}>{item.message}</p>
                <div className="twoWayConversation--message--footer">
                  <span>{`${momentLocaleWrapper(item.created).format('L')} ${momentLocaleWrapper(
                    item.created
                  ).format('LT')}`}</span>
                  {item.systemTemplate > 0 && (
                    <span className="twoWayConversation--message--status">
                      {' '}
                      {t('EBN Generated Message')}
                    </span>
                  )}
                  {item.messageStatus === Constants.TwoWayMessages.messageStatusFailed && (
                    <span className="twoWayConversation--message--status--error">
                      {' '}
                      {t('Undelivered')} <ErrorOutlineIcon />
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        {loadingConversationDetails && <TwoWayConversationSkeleton />}
      </div>
      <TwoWayConversationNewTemplate
        smsToList={smsToList}
        onSend={handleSend}
        onSendWithAttachment={handleSendWithAttachment}
        onSendWithAutoquote={handleSendWithAutoquote}
        templateList={templateList}
        selectedTemplateId={selectedTemplateId}
        setSelectedTemplateId={setSelectedTemplateId}
        text={text}
        setText={setText}
        isWithinReplyWindow={isWithinReplyWindow}
        conversationKey={conversation.conversationKey}
        patientKey={conversation.patientKey}
        patientFirstName={patient.firstName}
        patientLastName={patient.lastName}
        patientPlatformPreference={patient.preferredPlatform}
        locationName={locationConfigName}
        locationAddress={locationConfigAddress}
        locationPhone={locationConfigPhone}
      />
    </div>
  );
};

export default withTranslation()(TwoWayConversation);
