import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import MuiCheckbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Enums from '../../enums';
import Constants from '../../constants';
import SettingsService from '../../Services/settingsService';
import './CustomerFeedback.scss';

// The MUI componens use JSS (https://material-ui.com/styles/basics/)
// via React hooks for styles, so we need to override the styles here
// instead of from the stylesheet.

const Checkbox = withStyles({
  root: {
    color: '#979797',
    '&$checked': {
      color: '#979797',
    },
  },
  checked: {},
})(MuiCheckbox);

/**
 * Represents the customer feedback component.
 */
class CustomerFeedback extends Component {
  /**
   * Initializes a new instance of the CustomerFeedback component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    const { countrySettings, locationSettings } = props;

    this.state = {
      countrySettings: cloneDeep(countrySettings),
      errorModalMessage: '',
      locationSettings: cloneDeep(locationSettings),
      showErrorModal: false,
      showSaveAlert: false,
    };
    this._settingsService = new SettingsService();
  }

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  _onCloseSaveAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showSaveAlert: false }));
  };

  _onLocationSettingsChange = (name, value) => {
    this.setState((prevState) => {
      const updatedLocationSettings = prevState.locationSettings.map(
        (locSetting) => {
          if (locSetting.displayName === name) {
            locSetting.settingValue = value;
          }

          return locSetting;
        }
      );

      return {
        locationSettings: updatedLocationSettings,
      };
    });
  };

  _onSaveLocationSettings = async () => {
    try {
      const { locationSettings } = this.state;

      await this._settingsService.saveLocationSettings({
        locationSettings: locationSettings,
      });

      const { onLocationSettingsChange } = this.props;
      onLocationSettingsChange && onLocationSettingsChange(locationSettings);

      this.setState(() => ({
        showSaveAlert: true,
      }));
    } catch (error) {
      if (
        (error && !error.response) ||
        (error &&
          error.response.status ===
            Enums.HttpStatusCodes.httpStatusInternalServerError)
      ) {
        this.setState(() => ({
          errorModalMessage: this.props.t(
            'There was an unexpected issue with saving location settings.'
          ),
          showErrorModal: true,
        }));
      }
    }
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      countrySettings,
      errorModalMessage,
      locationSettings,
      showErrorModal,
      showSaveAlert,
    } = this.state;
    const { t } = this.props;
    const isRequestCustomerFeedbackEnabled =
      countrySettings.some(
        (c) =>
          c.settingName ===
            Constants.CountrySettings.settingIsRequestCustomerFeedbackEnabled &&
          c.settingValue === true
      ) &&
      locationSettings.some(
        (ls) =>
          ls.displayName ===
            Constants.LocationSettings
              .settingIsRequestCustomerFeedbackEnabled &&
          ls.settingValue === true
      );

    return (
      <div className="cust-feedback">
        <h2 className="cust-feedback__heading">
          {t('Manage Ratings/Reviews')}
        </h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequestCustomerFeedbackEnabled}
              onChange={(e) =>
                this._onLocationSettingsChange(
                  Constants.LocationSettings
                    .settingIsRequestCustomerFeedbackEnabled,
                  e.target.checked
                )
              }
            />
          }
          label={t('Request Customer Feedback')}
        />
        <div className="cust-feedback__footer">
          <button
            className="cust-feedback__save"
            onClick={this._onSaveLocationSettings}
            type="button"
          >
            {t('Save')}
          </button>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showSaveAlert}
          onClose={this._onCloseSaveAlert}
        >
          <Alert
            className="admin-settings__alert"
            severity="success"
            onClose={this._onCloseSaveAlert}
          >
            {t('Saved successfully')}
          </Alert>
        </Snackbar>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="loc-settings__modal-button"
              onClick={this._onCloseErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CustomerFeedback.propTypes = {
  countrySettings: PropTypes.array.isRequired,
  locationSettings: PropTypes.array.isRequired,
  onLocationSettingsChange: PropTypes.func,
};

export default withTranslation()(CustomerFeedback);
