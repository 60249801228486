import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Utils from '../../utils';
import { withTranslation } from 'react-i18next';
import './StoreHours.scss';

/**
 * Represents the store hours of operation component.
 */
const StoreHours = (props) => {
  const {
    canSave,
    isOnboarded,
    locationHours,
    showSave,
    onSaveStoreHours,
    onStoreDayOfWeekChange,
    onStoreTimeBlockChange,
    t,
  } = props;
  const closed = -1;

  const daysOfWeek = locationHours.map((lh, key) => {
    const isOpen = lh.startTime !== closed && lh.endTime !== closed;
    return (
      <th key={key} scope="col">
        <label
          className={`admin-content__checkbox ${isOnboarded ? 'disabled' : ''}`}
        >
          &nbsp;&nbsp;&nbsp;{t(Utils.getDayOfWeek(lh.dayOfWeekId))}
          <input
            checked={isOpen}
            disabled={isOnboarded}
            type="checkbox"
            onChange={(e) => {
              onStoreDayOfWeekChange(lh.dayOfWeekId, e.target.checked);
            }}
          />
          <span className="checkbox"></span>
        </label>
      </th>
    );
  });

  const getLocationHours = (dayOfWeekId, defaultValue, isStartTime) => (
    <FormControl
      className="store-hours__timeblock-cont"
      disabled={isOnboarded || defaultValue === closed}
      variant="outlined"
    >
      <Select
        value={defaultValue}
        onChange={(e) => {
          onStoreTimeBlockChange(
            dayOfWeekId,
            isStartTime ? parseInt(e.target.value) : null,
            !isStartTime ? parseInt(e.target.value) : null
          );
        }}
      >
        <MenuItem disabled={defaultValue !== closed} value="-1">
          {t('Closed')}
        </MenuItem>
        <MenuItem value="72">{t('6:00 AM')}</MenuItem>
        <MenuItem value="78">{t('6:30 AM')}</MenuItem>
        <MenuItem value="84">{t('7:00 AM')}</MenuItem>
        <MenuItem value="90">{t('7:30 AM')}</MenuItem>
        <MenuItem value="96">{t('8:00 AM')}</MenuItem>
        <MenuItem value="102">{t('8:30 AM')}</MenuItem>
        <MenuItem value="108">{t('9:00 AM')}</MenuItem>
        <MenuItem value="114">{t('9:30 AM')}</MenuItem>
        <MenuItem value="120">{t('10:00 AM')}</MenuItem>
        <MenuItem value="126">{t('10:30 AM')}</MenuItem>
        <MenuItem value="132">{t('11:00 AM')}</MenuItem>
        <MenuItem value="138">{t('11:30 AM')}</MenuItem>
        <MenuItem value="144">{t('12:00 PM')}</MenuItem>
        <MenuItem value="150">{t('12:30 PM')}</MenuItem>
        <MenuItem value="156">{t('1:00 PM')}</MenuItem>
        <MenuItem value="162">{t('1:30 PM')}</MenuItem>
        <MenuItem value="168">{t('2:00 PM')}</MenuItem>
        <MenuItem value="174">{t('2:30 PM')}</MenuItem>
        <MenuItem value="180">{t('3:00 PM')}</MenuItem>
        <MenuItem value="186">{t('3:30 PM')}</MenuItem>
        <MenuItem value="192">{t('4:00 PM')}</MenuItem>
        <MenuItem value="198">{t('4:30 PM')}</MenuItem>
        <MenuItem value="204">{t('5:00 PM')}</MenuItem>
        <MenuItem value="210">{t('5:30 PM')}</MenuItem>
        <MenuItem value="216">{t('6:00 PM')}</MenuItem>
        <MenuItem value="222">{t('6:30 PM')}</MenuItem>
        <MenuItem value="228">{t('7:00 PM')}</MenuItem>
        <MenuItem value="234">{t('7:30 PM')}</MenuItem>
        <MenuItem value="240">{t('8:00 PM')}</MenuItem>
        <MenuItem value="246">{t('8:30 PM')}</MenuItem>
        <MenuItem value="252">{t('9:00 PM')}</MenuItem>
        <MenuItem value="258">{t('9:30 PM')}</MenuItem>
        <MenuItem value="264">{t('10:00 PM')}</MenuItem>
      </Select>
    </FormControl>
  );

  const startTimes = locationHours.map((lh, key) => (
    <td key={key}>
      <div className="select-cont">
        {getLocationHours(lh.dayOfWeekId, lh.startTime, true)}
      </div>
    </td>
  ));

  const endTimes = locationHours.map((lh, key) => (
    <td key={key}>
      <div className="select-cont">
        {getLocationHours(lh.dayOfWeekId, lh.endTime, false)}
      </div>
    </td>
  ));

  const timeBlockErrors = locationHours.map((lh, key) => (
    <td key={key}>
      <span
        className={`store-hours__timeblock-error ${
          lh.timeBlockError ? 'store-hours__timeblock-error--visible' : ''
        }`}
      >
        {lh.timeBlockError}
      </span>
    </td>
  ));

  return (
    <div className="admin-content__store-hours">
      <table className="admin-content__table">
        <thead>
          <tr>
            <th scope="col">{t('Days of Week')}</th>
            {daysOfWeek}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t('Start')}</td>
            {startTimes}
          </tr>
          <tr>
            <td>{t('Finish')}</td>
            {endTimes}
          </tr>
          <tr>
            <td></td>
            {timeBlockErrors}
          </tr>
        </tbody>
      </table>
      <div className="store-hours__footer">
        {showSave && (
          <button
            disabled={!canSave}
            className="store-hours__save"
            onClick={() => onSaveStoreHours(locationHours)}
          >
            {t('Save')}
          </button>
        )}
      </div>
    </div>
  );
};

StoreHours.propTypes = {
  canSave: PropTypes.bool,
  isOnboarded: PropTypes.bool.isRequired,
  locationHours: PropTypes.array.isRequired,
  showSave: PropTypes.bool,
  onSaveStoreHours: PropTypes.func,
  onStoreDayOfWeekChange: PropTypes.func.isRequired,
  onStoreTimeBlockChange: PropTypes.func.isRequired,
};

export default withTranslation()(StoreHours);
