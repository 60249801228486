import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@material-ui/icons/Close';
import InputBase from '@material-ui/core/InputBase';
import { withTranslation } from 'react-i18next';
import Constants from '../../constants';

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: '0.8em',
    padding: '10px 26px 10px 12px',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
    width: '100%',
  },
}))(InputBase);

const TwoWayMessageSelectTemplate = ({
  t,
  templateList,
  templateId,
  setTemplateId,
  attachmentsDisabled
}) => {
  const [open, setOpen] = useState(false);
  const [templates, setTemplates] = useState(templateList);

  useEffect(() => {
    if (attachmentsDisabled) {
      setTemplates(templateList.filter((item) => item.chatTemplateType !== Constants.TwoWayMessages.messageTemplates.customerQuote));
    }
    else {
      setTemplates(templateList);
    }
  }, [attachmentsDisabled, templateList]);

  const handleChange = (event) => {
    setTemplateId(Number(event.target.value));
    handleCloseSelect();
  };

  const handleCloseSelect = () => setOpen(false);

  const handleOpenSelect = () => setOpen(true);

  return (
    <FormControl className="select-container" onClick={handleCloseSelect}>
      <div
        className={
          open
            ? 'select-container-mobile--close visible'
            : 'select-container-mobile--close'
        }
      >
        <CloseIcon fontSize="small" />
      </div>
      <InputLabel
        htmlFor="demo-customized-select-native"
        className="select-label"
        shrink
      >
        {t('Text Templates')}
      </InputLabel>
      <Select
        displayEmpty
        id="demo-customized-select-native"
        name="select-from-template"
        input={<BootstrapInput className="select-input" />}
        value={templateId || ''}
        onChange={handleChange}
        onOpen={handleOpenSelect}
        open={open}
      >
        <MenuItem value="" disabled>
          {t('Select')}
        </MenuItem>
        {templates.map((template) => (
          <MenuItem
            value={template.chatTemplateType}
            key={template.chatTemplateType}
          >
            {t(template.title)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withTranslation()(TwoWayMessageSelectTemplate);
