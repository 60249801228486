import React, { useEffect, useRef, useState } from 'react';
import { Divider, Button, ClickAwayListener } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';
import './TwoWayConversationNewTemplate.scss';
import { withTranslation } from 'react-i18next';
import TwoWayMessageSelect from '../TwoWayMessageInput/TwoWayMessageSelect';
import TwoWayEmoji from '../TwoWayEmoji/TwoWayEmoji';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TwoWayConversationService from '../../Services/twoWayConversationService';
import FeatureConfigurationService from '../../Services/featureConfigurationService';
import EventBuilder from '../../eventBuilder';
import Overlay from '../../Components/Overlay/Overlay';
import Logger from '../../logger';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Constants from '../../constants';
import Events from '../../events';
import IconButton from '@material-ui/core/IconButton';
import { CreateQuoteDialog } from '../CreateQuoteDialog/CreateQuoteDialog';
import moment from 'moment';
import Enums from '../../enums';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    arrow: {
      color: '#fff',
    },
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(MuiTooltip);

const MAX_TEXT_ALLOWED = 320;
const TwoWayConversationNewTemplate = ({
  t,
  isWithinReplyWindow,
  onSend,
  onSendWithAttachment,
  onSendWithAutoquote,
  templateList,
  text,
  setText,
  smsToList,
  selectedTemplateId,
  setSelectedTemplateId,
  conversationKey,
  patientKey,
  patientFirstName,
  patientLastName,
  patientPlatformPreference,
  locationName,
  locationAddress,
  locationPhone,
}) => {
  const twoWayConversationService = new TwoWayConversationService();
  const featureConfigurationService = new FeatureConfigurationService();
  const eBuilder = new EventBuilder();

  const [isOverlay, setIsOverlay] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    name: '',
    size: 0,
    type: '',
  });
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const [showFileTooltip, setFileToolTip] = useState(false);
  const [featureConfig, setFeatureConfig] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isFileAttachmentEnabled, setIsFileAttachmentEnabled] = useState(false);
  const [shouldDisplayCreateQuoteDialog, setShouldDisplayCreateQuoteDialog] = useState(false);
  const [disableSendState, setDisableSendState] = useState(true);
  const [autoquoteData, setAutoquoteData] = useState(null);
  const [autoquoteFileName, setAutoquoteFileName] = useState('');

  const handleTextChange = (e) => {
    validateAndChangeText(e.target.value);
  };

  const handleEmoji = (e) => {
    validateAndChangeText(text + e);
  };

  const validateAndChangeText = (text) => {
    if (!isReadOnly) {
      if (selectedTemplateId !== -1) {
        setSelectedTemplateId(null);
        setIsReadOnly(true);
      }
      const validatedText = text.length <= 320 ? text : text.substring(0, 320);
      setText(validatedText);
    }
  };

  const handleSend = async (e) => {
    eBuilder
      .withCategory(eBuilder.Category.Chat)
      .withAction(eBuilder.Action.Chat.Click.clickSendChatButton)
      .withLabel(eBuilder.Label.practiceIdentifier)
      .post();

    if (autoquoteData !== null) {
      //send as autoquote
      await onSendWithAutoquote({
        text,
        patientKey,
        chatTemplateType: selectedTemplateId,
        createQuoteRequest: autoquoteData,
      });
      removeAttachedFile();
    } else if (selectedFile.name !== '') {
      //send as file upload
      if (
        selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms ||
        selectedTemplateId === null
      ) {
        if (
          selectedFile.size > 0 &&
          selectedFile.size <= 1048576 * 5 &&
          (selectedFile.type.match('.jpg') ||
            selectedFile.type.match('.jpeg') ||
            selectedFile.type.match('.png') ||
            selectedFile.type.match('.pdf'))
        ) {
          await onSendWithAttachment(text, selectedFile);
          removeAttachedFile();
          eBuilder
            .withCategory(eBuilder.Category.Chat)
            .withAction(eBuilder.Action.Chat.Click.sendChatAttachment)
            .withLabel(eBuilder.Label.practiceIdentifier)
            .post();
        } else {
          setFileErrorMessage(t('Invalid File type or size.'));
        }
      }
      if (selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote) {
        if (
          selectedFile.size > 0 &&
          selectedFile.size <= 1048576 * 5 &&
          selectedFile.type.match('.pdf')
        ) {
          await onSendWithAttachment(text, selectedFile);
          removeAttachedFile();
          eBuilder
            .withCategory(eBuilder.Category.Chat)
            .withAction(eBuilder.Action.Chat.Click.sendChatAttachment)
            .withLabel(eBuilder.Label.practiceIdentifier)
            .post();
        } else {
          setFileErrorMessage(t('Invalid File type or size.'));
        }
      }
    } else {
      await onSend(text);
      removeAttachedFile();
    }

    setSelectedTemplateId(null);
    setIsReadOnly(true);
    setText('');
  };

  useEffect(() => {
    if (selectedTemplateId) {
      setTemplatecontent();
      removeAttachedFile();
    }
  }, [selectedTemplateId]);

  useEffect(() => {
    if (smsToList.length === 1 && selectedTemplateId && selectedTemplateId >= 0) {
      getTemplateForUniquePatient(selectedTemplateId, smsToList[0].firstName);
    }
    if (smsToList.length > 1 && selectedTemplateId && selectedTemplateId >= 0) {
      setTemplatecontent();
    }
    if (smsToList.length === 0 && selectedTemplateId && selectedTemplateId >= 0) {
      setTemplatecontent();
    }
  }, [smsToList]);

  useEffect(() => {
    Events.on(Constants.Events.clickConversation, () => {
      removeAttachedFile();
    });

    async function getFeatureConfiguration() {
      let config = await featureConfigurationService.getFeatureConfiguration(
        process.env.REACT_APP_ENVIRONMENT
      );
      setFeatureConfig(config);
    }
    if (!featureConfig) {
      getFeatureConfiguration();
    }

    const isSmsMessage =
      selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms ||
      patientPlatformPreference == Enums.PreferredPlatform.smsText;

    const isFreeFormTemplate =
      selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms ||
      selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote ||
      selectedTemplateId === null;

    const isCustomerQuoteTemplate =
      selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote;

    setIsReadOnly(
      !isSmsMessage && (!isWithinReplyWindow || (isWithinReplyWindow && !isFreeFormTemplate))
    );

    setIsFileAttachmentEnabled(
      featureConfig?.FileAttachmentEnabled &&
        (isSmsMessage || isFreeFormTemplate || isCustomerQuoteTemplate)
    );

    //set disableSendState
    setDisableSendState(disableSend());
    Events.emit(Constants.Events.ConversationNewTemplateLoaded);

    return function cleanup() {
      Events.removeAllListeners(Constants.Events.clickConversation);
    };
  }, [featureConfig, selectedTemplateId, isWithinReplyWindow, featureConfigurationService]);

  const setTemplatecontent = () => {
    const [getTemplateById] = templateList.filter(
      (template) => template.chatTemplateType === selectedTemplateId
    );
    const isEmptyPatientList = smsToList.length === 0;
    const isUniquePatientList = smsToList.length === 1;
    const isPatientList = smsToList.length > 1;
    if (getTemplateById) {
      if (isEmptyPatientList || isPatientList) {
        setText(getTemplateById.templateContent);
      }
      if (isUniquePatientList) {
        getTemplateForUniquePatient(selectedTemplateId, smsToList[0].firstName);
      }
    }
  };

  const getTemplateForUniquePatient = (chatTemplateType, firstName) => {
    setIsOverlay(true);
    twoWayConversationService
      .getTemplateForPatient(chatTemplateType, firstName)
      .then((content) => {
        setText(content);
      })
      .catch((err) => {
        Logger.error('ConversationNewSelect', err, 'error');
      })
      .finally(() => setIsOverlay(false));
  };

  const inputFile = useRef(null);
  const onFileAdd = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  const handleSaveAutoquote = (autoquoteData) => {
    removeAttachedFile();
    setAutoquoteData(autoquoteData);
    setAutoquoteFileName(
      `quote-${patientFirstName}-${patientLastName}-${moment().format('DDMMYY')}`
    );
  };

  const handleFileChange = () => {
    setAutoquoteData(null);

    const inputFiles = inputFile.current.files;
    if (inputFiles && inputFiles[0]) {
      if (inputFiles[0].name !== '') {
        if (
          selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms ||
          selectedTemplateId === null
        ) {
          if (
            inputFiles[0].size > 0 &&
            inputFiles[0].size <= 1048576 * 5 &&
            (inputFiles[0].type.match('.jpg') ||
              inputFiles[0].type.match('.jpeg') ||
              inputFiles[0].type.match('.png') ||
              inputFiles[0].type.match('.pdf'))
          ) {
            setSelectedFile(inputFiles[0]);
            setFileErrorMessage('');
          } else {
            setFileErrorMessage(
              t(
                'File type must be PDF, JPG, or PNG, and not to exceed 5 MB. Please reselect the attach icon to attach a new file.'
              )
            );
            removeAttachedFile(true);
          }
        }
        if (selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote) {
          if (
            inputFiles[0].size > 0 &&
            inputFiles[0].size <= 1048576 * 5 &&
            inputFiles[0].type.match('.pdf')
          ) {
            setSelectedFile(inputFiles[0]);
            setFileErrorMessage('');
          } else {
            setFileErrorMessage(
              t(
                'File type must be PDF and not to exceed 5 MB. Please reselect the attach icon to attach a new file.'
              )
            );
            removeAttachedFile(true);
          }
        }
      }
    }
  };

  const removeAttachedFile = (keepError) => {
    if (inputFile.current) {
      inputFile.current.files = null;
      inputFile.current.value = '';
      setSelectedFile({
        name: '',
        size: 0,
        type: '',
      });
      if (!keepError) {
        setFileErrorMessage('');
      }
      setAutoquoteData(null);
    }
  };

  const onCloseFileTooltip = () => {
    setFileToolTip(false);
  };

  const onToggleFileTooltip = () => {
    setFileToolTip(!showFileTooltip);
  };

  const isCreateQuoteButtonActive =
    featureConfig?.CreateCustomerQuoteButtonEnabled &&
    (selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms ||
      selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote ||
      !isReadOnly);

  const attachmentsDisabled = smsToList.length !== 1;

  const disableSend = () => {
    const hasFileErrorMessage = !!fileErrorMessage;
    const hasNoText = !text;
    const hasNoRecipients = smsToList.length === 0;
    const isCustomerQuoteButNoFileOrAutoquote =
      selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote &&
      selectedFile.name === '' &&
      autoquoteData === null;

    return (
      hasFileErrorMessage || hasNoText || hasNoRecipients || isCustomerQuoteButNoFileOrAutoquote
    );
  };

  return (
    <section className="conversation-template">
      <div className="conversation-template__container">
        <TwoWayMessageSelect
          templateList={templateList}
          templateId={selectedTemplateId}
          setTemplateId={setSelectedTemplateId}
          attachmentsDisabled={attachmentsDisabled}
        />
        <Divider
          orientation="vertical"
          flexItem
          className="conversation-template__container-divider"
        />
        <div className="conversation-template__container-message">
          <div className="conversation-template__container-message-textarea-container">
            {isReadOnly ? (
              <textarea
                className="conversation-template__container-message-textarea"
                value={text}
                readOnly
              />
            ) : (
              <textarea
                className="conversation-template__container-message-textarea"
                value={text}
                onChange={handleTextChange}
              />
            )}
            {isReadOnly && <LockOutlinedIcon fontSize="small" className="textarea-icon" />}
          </div>
          <div className="conversation-template__container-message-subtext">
            <div
              className={
                text
                  ? 'conversation-template__container-message-characters disabled'
                  : 'conversation-template__container-message-characters'
              }
            >
              {text?.length ?? 0}/{MAX_TEXT_ALLOWED}
            </div>
            {featureConfig?.FileAttachmentEnabled && fileErrorMessage && (
              <div className="conversation-template__container-message-subtext-error">
                {fileErrorMessage}
              </div>
            )}
            {featureConfig?.FileAttachmentEnabled && selectedFile.size > 0 && (
              <div className="conversation-template__container-message-subtext-fileinfo">
                {selectedFile.name} <RemoveCircleIcon onClick={removeAttachedFile} />
              </div>
            )}
            {featureConfig?.FileAttachmentEnabled && autoquoteData !== null && (
              <div className="conversation-template__container-message-subtext-fileinfo">
                {autoquoteFileName}{' '}
                <RemoveCircleIcon
                  onClick={() => {
                    handleSaveAutoquote(null);
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="conversation-template__container-side-bar">
          <TwoWayEmoji
            className="conversation-template__container-side-bar-icon"
            isReadOnly={isReadOnly}
            emojiAdded={handleEmoji}
          />

          {featureConfig?.FileAttachmentEnabled && isFileAttachmentEnabled && (
            <>
              <IconButton
                aria-label="attach file"
                onClick={onFileAdd}
                disabled={!isFileAttachmentEnabled}
                className="conversation-template__container-side-bar-icon"
              >
                <AttachFileIcon />
              </IconButton>
              <input
                type="file"
                id="file"
                ref={inputFile}
                onChange={(e) => handleFileChange()}
                style={{ display: 'none' }}
              />

              {(selectedTemplateId === null ||
                selectedTemplateId === Constants.TwoWayMessages.messageTemplates.freeFormSms) && (
                <ClickAwayListener onClickAway={onCloseFileTooltip}>
                  <Tooltip
                    className="conversation-template__container-side-bar-icon"
                    open={showFileTooltip}
                    placement="top-end"
                    title={t('File type must be PDF, JPG, or PNG and not to exceed 5 MB.')}
                  >
                    <IconButton
                      onClick={(e) => onToggleFileTooltip()}
                      className="conversation-template__container-side-bar-file-icon"
                    >
                      <InfoOutlinedIcon></InfoOutlinedIcon>
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              )}

              {selectedTemplateId === Constants.TwoWayMessages.messageTemplates.customerQuote && (
                <ClickAwayListener onClickAway={onCloseFileTooltip}>
                  <Tooltip
                    className="conversation-template__container-side-bar-icon"
                    open={showFileTooltip}
                    placement="top-end"
                    title={t('File type must be PDF and not to exceed 5 MB.')}
                  >
                    <IconButton
                      onClick={(e) => onToggleFileTooltip()}
                      className="conversation-template__container-side-bar-file-icon"
                    >
                      <InfoOutlinedIcon></InfoOutlinedIcon>
                    </IconButton>
                  </Tooltip>
                </ClickAwayListener>
              )}
            </>
          )}
          {isCreateQuoteButtonActive && (
            <>
              <Button
                className="create-a-quote-btn"
                variant="outlined"
                disabled={attachmentsDisabled}
                onClick={(e) => setShouldDisplayCreateQuoteDialog(true)}
              >
                {t('Create a Quote')}
              </Button>
              <CreateQuoteDialog
                t={t}
                firstName={patientFirstName}
                lastName={patientLastName}
                storeName={locationName}
                storeAddress={locationAddress}
                storePhone={locationPhone}
                shouldDisplayModal={shouldDisplayCreateQuoteDialog}
                handleClose={() => {
                  setShouldDisplayCreateQuoteDialog(false);
                }}
                handleSave={(autoquoteData) => {
                  handleSaveAutoquote(autoquoteData);
                }}
              ></CreateQuoteDialog>
            </>
          )}
        </div>
        <div className="conversation-template__container-send">
          <Button
            className={
              disableSendState
                ? 'conversation-template__container-send--button'
                : 'conversation-template__container-send--button active'
            }
            variant="contained"
            disableElevation
            disabled={disableSendState}
            onClick={handleSend}
          >
            {t('SEND')}
          </Button>
        </div>

        <Overlay show={isOverlay}>
          <i className="spinner-eclipse"></i>
        </Overlay>
      </div>
    </section>
  );
};

export default withTranslation()(TwoWayConversationNewTemplate);
