import axios from 'axios';
import moment from 'moment';
import Storage from '../storage';
import Constants from '../constants';
import momentLocaleWrapper from '../momentLocaleWrapper';
import ErrorReportingService from './errorReportingService';
import cloneDeep from 'lodash.clonedeep';

/**
 * Represents the appointment service.
 */
class AppointmentService {
  /**
   * Returns an array of appointments for the provided day.
   * @param {Object} currDate The current moment date.
   * @returns A Promise.
   */
  async getAppointments(currDate = moment().local()) {
    return new Promise(async (resolve, reject) => {
      try {
        const locationId = Storage.getItem(Constants.currLocIdKey);
        const url = `${process.env.REACT_APP_CALENDAR_API}/getAppointments`;
        const data = {
          numDaysToRetrieve: 1,
          locationId: parseInt(locationId),
          startDay: currDate.format('YYYY-MM-DD'),
        };
        const res = await axios.post(url, data);
        const appointments = res?.data?.appointments.map((appointment) => ({
          ...appointment,
          id: appointment.appointmentId,
          title: `${appointment.patient.firstName} ${appointment.patient.lastName}`,
          created: appointment.created
            ? momentLocaleWrapper
                .utc(appointment.created, '(YYYY, MM, DD, HH, mm)')
                .toDate()
            : null,
          endDate: appointment.endTime
            ? momentLocaleWrapper(
                appointment.endTime,
                '(YYYY, MM, DD, HH, mm)'
              ).toDate()
            : null,
          startDate: appointment.startTime
            ? momentLocaleWrapper(
                appointment.startTime,
                '(YYYY, MM, DD, HH, mm)'
              ).toDate()
            : null,
          resourceId: appointment.resource.resourceId,
        }));

        resolve(appointments);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CALENDAR_API}/getAppointments`
        );
        reject(error);
      }
    });
  }

  /**
   * Saves the provided appointment.
   * @param {Object} appointment The appointment.
   * @returns A Promise.
   */
  async saveAppointment(appointment) {
    return new Promise(async (resolve, reject) => {
      if (appointment) {
        try {
          const url = `${process.env.REACT_APP_CALENDAR_API}/saveAppointment`;

          const data = { appointment: cloneDeep(appointment) };
          data.appointment.created = momentLocaleWrapper
            .utc(appointment.created)
            .format('(YYYY, MM, DD, HH, mm)');

          data.appointment.startTime = appointment.startDate
            ? momentLocaleWrapper(appointment.startDate).format(
                '(YYYY, MM, DD, HH, mm)'
              )
            : null;

          data.appointment.endTime = appointment.endDate
            ? momentLocaleWrapper(appointment.endDate).format(
                '(YYYY, MM, DD, HH, mm)'
              )
            : null;

          const res = await axios.post(url, data);

          resolve(res?.data?.appointment);
        } catch (error) {
          const errorReporting = new ErrorReportingService();
          errorReporting.reportError(
            error,
            `${process.env.REACT_APP_CALENDAR_API}/saveAppointment`
          );
          reject(error);
        }
      }
    });
  }

  /**
   * Saves the provided appointment.
   * @param {Object} appointment The appointment.
   * @returns A Promise.
   */
  async UpdateConfirmation(appointmentId) {
    return new Promise(async (resolve, reject) => {
      if (appointmentId) {
        try {
          const url = `${process.env.REACT_APP_CALENDAR_API}/SaveAppointmentConfirmation`;
          const data = {
            modality: 'ECP',
            result: 'Confirmed',
            confirmationReceivedTime: momentLocaleWrapper.utc(),
            appointmentId: appointmentId,
          };
          const res = await axios.post(url, data);

          resolve(res?.data?.appointment);
        } catch (error) {
          const errorReporting = new ErrorReportingService();
          errorReporting.reportError(
            error,
            `${process.env.REACT_APP_CALENDAR_API}/SaveAppointmentConfirmation`
          );
          reject(error);
        }
      }
    });
  }
}

export default AppointmentService;
