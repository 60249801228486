import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import Utils from '../../utils';
import Enums from '../../enums';
import EventBuilder from '../../eventBuilder';
import SettingsService from '../../Services/settingsService';
import './StoreHours.scss';
import Constants from '../../constants';

const eBuilder = new EventBuilder();

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);

/**
 * Represents the store hours of operation component.
 */
class StoreHours extends Component {
  /**
   * Initializes a new instance of the StoreHours component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      areStoreHoursDirty: false,
      canSaveStoreHours: true,
      errorModalMessage: '',
      storeHours: cloneDeep(props.storeHours),
      showErrorModal: false,
      showSaveAlert: false,
      appointmentInterval:
        props.appointmentInterval ?? Enums.AppointmentIntervals.quarterHour,
    };
    this._defaultMarketHours = cloneDeep(Utils.getDefaultFormattedStoreHours());
    this._settingsService = new SettingsService();
  }

  _getDaysOfWeekItems = (storeHours, isOnboarded) => {
    const closed = -1;

    return storeHours.map((lh, key) => {
      const isOpen = lh.startTime !== closed && lh.endTime !== closed;
      return (
        <th key={key} scope="col">
          <label
            className={`admin-content__checkbox ${
              isOnboarded ? 'disabled' : ''
            }`}
          >
            &nbsp;&nbsp;&nbsp;{this.props.t(Utils.getDayOfWeek(lh.dayOfWeekId))}
            <input
              checked={isOpen}
              disabled={isOnboarded}
              type="checkbox"
              onChange={(e) => {
                this._onStoreDayOfWeekChange(lh.dayOfWeekId, e.target.checked);
              }}
            />
            <span className="checkbox"></span>
          </label>
        </th>
      );
    });
  };

  _getEndTimeItems = (storeHours, isOnboarded) => {
    return storeHours.map((lh, key) => (
      <td key={key}>
        <div className="select-cont">
          {this._getStoreHourValues(
            lh.dayOfWeekId,
            lh.endTime,
            false,
            isOnboarded
          )}
        </div>
      </td>
    ));
  };

  _getStartTimeItems = (storeHours, isOnboarded) => {
    return storeHours.map((lh, key) => (
      <td key={key}>
        <div className="select-cont">
          {this._getStoreHourValues(
            lh.dayOfWeekId,
            lh.startTime,
            true,
            isOnboarded
          )}
        </div>
      </td>
    ));
  };

  _getStoreHourValues = (
    dayOfWeekId,
    defaultValue,
    isStartTime,
    isOnboarded
  ) => {
    const { t } = this.props;
    const closed = -1;

    return (
      <FormControl
        className="store-hours__timeblock-cont"
        disabled={isOnboarded || defaultValue === closed}
        variant="outlined"
      >
        <Select
          value={defaultValue}
          onChange={(e) => {
            this._onStoreTimeBlockChange(
              dayOfWeekId,
              isStartTime ? parseInt(e.target.value) : null,
              !isStartTime ? parseInt(e.target.value) : null
            );
          }}
        >
          <MenuItem disabled={defaultValue !== closed} value="-1">
            {this.props.t('Closed')}
          </MenuItem>
          <MenuItem value="72">{t('6:00 AM')}</MenuItem>
          <MenuItem value="78">{t('6:30 AM')}</MenuItem>
          <MenuItem value="84">{t('7:00 AM')}</MenuItem>
          <MenuItem value="90">{t('7:30 AM')}</MenuItem>
          <MenuItem value="96">{t('8:00 AM')}</MenuItem>
          <MenuItem value="102">{t('8:30 AM')}</MenuItem>
          <MenuItem value="108">{t('9:00 AM')}</MenuItem>
          <MenuItem value="114">{t('9:30 AM')}</MenuItem>
          <MenuItem value="120">{t('10:00 AM')}</MenuItem>
          <MenuItem value="126">{t('10:30 AM')}</MenuItem>
          <MenuItem value="132">{t('11:00 AM')}</MenuItem>
          <MenuItem value="138">{t('11:30 AM')}</MenuItem>
          <MenuItem value="144">{t('12:00 PM')}</MenuItem>
          <MenuItem value="150">{t('12:30 PM')}</MenuItem>
          <MenuItem value="156">{t('1:00 PM')}</MenuItem>
          <MenuItem value="162">{t('1:30 PM')}</MenuItem>
          <MenuItem value="168">{t('2:00 PM')}</MenuItem>
          <MenuItem value="174">{t('2:30 PM')}</MenuItem>
          <MenuItem value="180">{t('3:00 PM')}</MenuItem>
          <MenuItem value="186">{t('3:30 PM')}</MenuItem>
          <MenuItem value="192">{t('4:00 PM')}</MenuItem>
          <MenuItem value="198">{t('4:30 PM')}</MenuItem>
          <MenuItem value="204">{t('5:00 PM')}</MenuItem>
          <MenuItem value="210">{t('5:30 PM')}</MenuItem>
          <MenuItem value="216">{t('6:00 PM')}</MenuItem>
          <MenuItem value="222">{t('6:30 PM')}</MenuItem>
          <MenuItem value="228">{t('7:00 PM')}</MenuItem>
          <MenuItem value="234">{t('7:30 PM')}</MenuItem>
          <MenuItem value="240">{t('8:00 PM')}</MenuItem>
          <MenuItem value="246">{t('8:30 PM')}</MenuItem>
          <MenuItem value="252">{t('9:00 PM')}</MenuItem>
          <MenuItem value="258">{t('9:30 PM')}</MenuItem>
          <MenuItem value="264">{t('10:00 PM')}</MenuItem>
        </Select>
      </FormControl>
    );
  };

  _getTimeBlockErrors = (storeHours) => {
    return storeHours.map((lh, key) => (
      <td key={key}>
        <span
          className={`store-hours__timeblock-error ${
            lh.timeBlockError ? 'store-hours__timeblock-error--visible' : ''
          }`}
        >
          {lh.timeBlockError}
        </span>
      </td>
    ));
  };

  _onCloseSaveAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState(() => ({ showSaveAlert: false }));
  };

  _onCloseErrorModal = () => {
    this.setState(() => ({ showErrorModal: false }));
  };

  _onSaveStoreHours = async () => {
    try {
      eBuilder
        .withAction(eBuilder.Action.Settings.Click.saveStoreHours)
        .withLabel(eBuilder.Label.practiceIdentifier)
        .post();

      this.setState(() => ({ canSaveStoreHours: false }));

      const { storeHours, appointmentInterval } = this.state;

      await this._settingsService.saveStoreHours({
        storeHours: storeHours,
        appointmentInterval: appointmentInterval,
      });

      const { onStoreHoursChange } = this.props;
      onStoreHoursChange && onStoreHoursChange(storeHours);

      const { onAppointmentIntervalChange } = this.props;
      onAppointmentIntervalChange &&
        onAppointmentIntervalChange(appointmentInterval);

      this.setState(() => ({
        areStoreHoursDirty: false,
        showSaveAlert: true,
      }));
    } catch (error) {
      this._onShowErrorModal(
        error,
        this.props.t('There was an unexpected issue with saving store hours.')
      );
    }
  };

  _onShowErrorModal = (error, message) => {
    if (
      (error && !error.response) ||
      (error &&
        error.response.status ===
          Enums.HttpStatusCodes.httpStatusInternalServerError)
    ) {
      this.setState(() => ({
        showErrorModal: true,
        errorModalMessage: message,
      }));
    }
  };

  _onStoreDayOfWeekChange = (dayOfWeekId, isOpen) => {
    this.setState((prevState) => {
      let canSaveStoreHours = true;
      const updatedStoreHours = prevState.storeHours.map((sh) => {
        if (sh.dayOfWeekId === dayOfWeekId) {
          if (isOpen) {
            const defaultMarketHoursForDay = this._defaultMarketHours.find(
              (dmh) => dmh.dayOfWeekId === dayOfWeekId
            );

            if (defaultMarketHoursForDay) {
              sh.startTime = defaultMarketHoursForDay.startTime;
              sh.endTime = defaultMarketHoursForDay.endTime;
            }
          } else {
            const closed = -1;
            sh.startTime = closed;
            sh.endTime = closed;
            sh.timeBlockError = '';
          }
        }

        if (sh.timeBlockError) {
          canSaveStoreHours = false;
        }

        return sh;
      });

      return {
        areStoreHoursDirty: true,
        canSaveStoreHours: canSaveStoreHours,
        storeHours: updatedStoreHours,
      };
    });
  };

  _onStoreTimeBlockChange = (dayOfWeekId, startTime, endTime) => {
    this.setState((prevState) => {
      let canSaveStoreHours = true;
      const storeHours = prevState.storeHours.map((sh) => {
        if (sh.dayOfWeekId === dayOfWeekId) {
          if (startTime) {
            sh.startTime = startTime;
          } else {
            sh.endTime = endTime;
          }
        }

        if (sh.startTime >= sh.endTime && sh.startTime > 0 && sh.endTime > 0) {
          canSaveStoreHours = false;
          sh.timeBlockError = this.props.t('Start must be before Finish');
        } else {
          sh.timeBlockError = '';
        }

        return sh;
      });

      return {
        areStoreHoursDirty: true,
        canSaveStoreHours: canSaveStoreHours,
        storeHours: storeHours,
      };
    });
  };

  _onAppointmentIntervalFieldChange = (value) => {
    this.setState(() => ({
      appointmentInterval: parseInt(value),
      canSaveStoreHours: true,
      areStoreHoursDirty: true,
    }));
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      areStoreHoursDirty,
      canSaveStoreHours,
      errorModalMessage,
      showErrorModal,
      showSaveAlert,
      storeHours,
      appointmentInterval,
    } = this.state;
    const { isOnboarded, showSave, t } = this.props;

    const daysOfWeek = this._getDaysOfWeekItems(storeHours, isOnboarded);
    const startTimes = this._getStartTimeItems(storeHours, isOnboarded);
    const endTimes = this._getEndTimeItems(storeHours, isOnboarded);
    const timeBlockErrors = this._getTimeBlockErrors(storeHours);

    return (
      <div className="store-hours">
        <table className="store-hours__table">
          <thead>
            <tr>
              <th scope="col">{t('Days of Week')}</th>
              {daysOfWeek}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('Start')}</td>
              {startTimes}
            </tr>
            <tr>
              <td>{t('Finish')}</td>
              {endTimes}
            </tr>
            <tr>
              <td></td>
              {timeBlockErrors}
            </tr>
          </tbody>
        </table>
        <div className="store-hours__divider2"> </div>
        <div className="store-hours__interval">
          <label className="store-hours__interval-label">
            {t(
              'How do you want your daily appointment calendar slots to be displayed to your customer?'
            )}
          </label>
          <RadioGroup
            row
            value={appointmentInterval}
            onChange={(e) =>
              this._onAppointmentIntervalFieldChange(parseInt(e.target.value))
            }
          >
            <FormControlLabel
              control={<Radio />}
              label={ReactHtmlParser(t('Quarter Hour (15 Minutes)'))}
              labelPlacement="end"
              value={Enums.AppointmentIntervals.quarterHour}
            />
            <FormControlLabel
              control={<Radio />}
              label={ReactHtmlParser(t('Half Hour (30 Minutes)'))}
              labelPlacement="end"
              value={Enums.AppointmentIntervals.halfHour}
            />
            <FormControlLabel
              control={<Radio />}
              label={ReactHtmlParser(t('Full Hour (60 Minutes)'))}
              labelPlacement="end"
              value={Enums.AppointmentIntervals.fullHour}
            />
          </RadioGroup>
        </div>
        <div className="store-hours__divider1"> </div>
        <div className="store-hours__footer">
          {showSave && (
            <button
              disabled={!(canSaveStoreHours && areStoreHoursDirty)}
              className="store-hours__save"
              onClick={this._onSaveStoreHours}
            >
              {t('Save')}
            </button>
          )}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          open={showSaveAlert}
          onClose={this._onCloseSaveAlert}
        >
          <Alert
            className="store-hours__alert"
            severity="success"
            onClose={this._onCloseSaveAlert}
          >
            {t('Saved successfully')}
          </Alert>
        </Snackbar>
        <Dialog aria-labelledby="customized-dialog-title" open={showErrorModal}>
          <DialogTitle>{t('Error')}</DialogTitle>
          <DialogContent>{errorModalMessage}</DialogContent>
          <DialogActions>
            <button
              className="store-hours__modal-btn"
              onClick={this._onCloseErrorModal}
            >
              {t('Ok')}
            </button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

StoreHours.propTypes = {
  canSave: PropTypes.bool,
  isOnboarded: PropTypes.bool.isRequired,
  storeHours: PropTypes.array.isRequired,
  showSave: PropTypes.bool,
  onStoreHoursChange: PropTypes.func,
  appointmentInterval: PropTypes.number,
  onAppointmentIntervalChange: PropTypes.func,
};

export default withTranslation()(StoreHours);
