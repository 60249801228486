import axios from 'axios';
import moment from 'moment';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the dashboard service.
 */
class DashboardService {
  _utcStartOfDay = (start) => {
    const startOfDay = moment(start).utc().startOf('day').toDate();
    return startOfDay;
  };

  _utcEndOfDay = (end) => {
    const endOfDay = moment(end).utc().endOf('day').toDate();
    return endOfDay;
  };

  _constructCommonDashboardApiUrl(endpoint) {
    const url = `${process.env.REACT_APP_DASHBOARD_API}/${endpoint}`;
    return url;
  }

  /**
   * Gets the Performance Dashboard KPI Summary values
   * @param {Object} params The params.
   * @returns A Promise.
   */
  async getDashboardSummary(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonDashboardApiUrl('getDashboardSummary');
        const data = {
          locationId: params.locationId,
          start: this._utcStartOfDay(params.start),
          end: this._utcEndOfDay(params.end),
        };

        const res = await axios.post(url, data);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonDashboardApiUrl('getDashboardSummary')
        );
        reject(error);
      }
    });
  }

  async getDashboardDetailsForNoShows(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonDashboardApiUrl(
          'getDashboardDetailsForNoShows'
        );
        const payload = {
          locationId: params.locationId,
          start: this._utcStartOfDay(params.start),
          end: this._utcEndOfDay(params.end),
          take: params.take,
          skip: params.skip,
          targetSource: params.targetSource,
        };
        const res = await axios.post(url, payload);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonDashboardApiUrl('getDashboardDetailsForNoShows')
        );
        reject(error);
      }
    });
  }

  async getDashboardDetailsForCanceled(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonDashboardApiUrl(
          'getDashboardDetailsForCancelled'
        );
        const payload = {
          locationId: params.locationId,
          start: this._utcStartOfDay(params.start),
          end: this._utcEndOfDay(params.end),
          take: params.take,
          skip: params.skip,
          targetSource: params.targetSource,
        };
        const res = await axios.post(url, payload);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonDashboardApiUrl(
            'getDashboardDetailsForCancelled'
          )
        );
        reject(error);
      }
    });
  }

  async getDashboardDetailsForAll(params) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonDashboardApiUrl(
          'getDashboardDetailsForAll'
        );
        const payload = {
          locationId: params.locationId,
          start: this._utcStartOfDay(params.start),
          end: this._utcEndOfDay(params.end),
          take: params.take,
          skip: params.skip,
          targetSource: params.targetSource,
        };
        const res = await axios.post(url, payload);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonDashboardApiUrl('getDashboardDetailsForAll')
        );
        reject(error);
      }
    });
  }

  async getDashboardDetailsByServiceType(params, appointmentTypeId) {
    return new Promise(async (resolve, reject) => {
      try {
        const url = this._constructCommonDashboardApiUrl(
          'getDashboardDetailsByServiceType'
        ).concat(`/${appointmentTypeId}`);
        const payload = {
          locationId: params.locationId,
          start: this._utcStartOfDay(params.start),
          end: this._utcEndOfDay(params.end),
          take: params.take,
          skip: params.skip,
          targetSource: params.targetSource,
        };
        const res = await axios.post(url, payload);

        resolve(res?.data);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          this._constructCommonDashboardApiUrl(
            'getDashboardDetailsByServiceType'
          )
        );
        reject(error);
      }
    });
  }
}

export default DashboardService;
