import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the location configuration service.
 */
class StellestService {
  /**
   * Returns the location configuration.
   */
  async submitOrder() {}

  async getPhotoToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_STELLEST_API}/image/token`;
        const res = await axios.get(url);
        let sasToken = null;

        if (res?.data) {
          sasToken = res.data;
        }

        resolve(sasToken);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_STELLEST_API}/image/token`
        );
        reject(error);
      }
    });
  }

  /**
   * Returns the location configuration id.
   */
  getLocationConfigId() {
    return Storage.getItem(Constants.currLocIdKey);
  }
}

export default StellestService;
