import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import Enums from '../../enums';
import './PatientComponent.scss';

const Radio = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&$checked': {
      color: '#000',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
  checked: {},
})(MuiRadio);
const PatientComponent = (props) => {
  const {
    onPatientUpdateValues,
    phoneCountryCode,
    patient,
    isEditing,
    t,
    isWhatsAppNotificationsEnabled,
  } = props;

  return (
    <div className="patient-component">
      <section>
        <div className="input-group">
          <div className="primary-input">
            <TextField
              required
              id="patient-first-name"
              label="First Name"
              variant="outlined"
              autoComplete="off"
              value={patient.firstName}
              onChange={(e) =>
                onPatientUpdateValues({ firstName: e.target.value })
              }
            />
          </div>
          <div className="secondary-input"></div>
        </div>

        <div className="input-group">
          <div className="primary-input">
            <TextField
              required
              id="patient-last-name"
              label="Last Name"
              variant="outlined"
              autoComplete="off"
              value={patient.lastName}
              onChange={(e) =>
                onPatientUpdateValues({ lastName: e.target.value })
              }
            />
          </div>
          <div className="secondary-input"></div>
        </div>

        <div className="input-group">
          <div className="primary-input">
            <TextField
              type="phone"
              required
              id="patient-phone"
              label="Phone"
              variant="outlined"
              autoComplete="off"
              value={patient.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {phoneCountryCode}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => onPatientUpdateValues({ phone: e.target.value })}
            />
          </div>
          <div className="secondary-input"></div>
        </div>

        {isWhatsAppNotificationsEnabled && (
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {t('Receive reminders on')}:
              </FormLabel>
              <RadioGroup
                row
                value={patient.preferredPlatform}
                onChange={(e) =>
                  onPatientUpdateValues({
                    preferredPlatform: parseInt(e.target.value),
                  })
                }
              >
                <FormControlLabel
                  control={<Radio />}
                  label={t('WhatsApp')}
                  labelPlacement="end"
                  value={Enums.PreferredPlatform.whatsApp}
                  disabled={isEditing}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={t('SMS Texting')}
                  labelPlacement="end"
                  value={Enums.PreferredPlatform.smsText}
                  disabled={isEditing}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}

        <TextField
          id="patient-email"
          label="Email"
          variant="outlined"
          autoComplete="off"
          type="email"
          noValidate
          value={patient.email}
          onChange={(e) => onPatientUpdateValues({ email: e.target.value })}
        />
      </section>
    </div>
  );
};

export default PatientComponent;
