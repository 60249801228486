import React, { useState } from 'react';
import './TwoWayEmoji.scss';
import { FiChevronDown, FiSmile } from 'react-icons/all';
import { IconButton } from '@material-ui/core';

const TwoWayEmoji = ({ emojiAdded, isReadOnly }) => {
  const [showSheet, setShowSheet] = useState(false);

  const handleShowSheet = () => {
    if (!isReadOnly) {
      setShowSheet((showSheet) => !showSheet);
    }
  };

  const handleEmojiClick = (e) => {
    handleShowSheet();
    emojiAdded(e);
  };

  return (
    <div className="emoji--holder conversation-template__container-side-bar-icon">
      <IconButton onClick={handleShowSheet}>
        <FiSmile />
      </IconButton>
      <div
        className="emoji--sheet noselect"
        style={showSheet ? { display: 'grid' } : { display: 'none' }}
      >
        <div className="btn-close" onClick={handleShowSheet}>
          <FiChevronDown />
        </div>
        <div
          onClick={(e) => handleEmojiClick('😎')}
          className="emoji--mark"
          aria-label="SMILING FACE WITH SUNGLASSES"
        >
          &#x1F60E;
        </div>
        <div
          onClick={(e) => handleEmojiClick('😀')}
          className="emoji--mark"
          aria-label="GRINNING FACE"
        >
          &#x1F600;
        </div>
        <div
          onClick={(e) => handleEmojiClick('😁')}
          className="emoji--mark"
          aria-label="GRINNING FACE WITH SMILING EYES"
        >
          &#x1F601;
        </div>
        <div
          onClick={(e) => handleEmojiClick('😂')}
          className="emoji--mark"
          aria-label="FACE WITH TEARS OF JOY"
        >
          &#x1F602;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🤗')}
          className="emoji--mark"
          aria-label="HUGGING FACE"
        >
          &#x1F917;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🤔')}
          className="emoji--mark"
          aria-label="THINKING FACE"
        >
          &#x1F914;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🤓')}
          className="emoji--mark"
          aria-label="NERD FACE"
        >
          &#x1F913;
        </div>
        <div
          onClick={(e) => handleEmojiClick('😍')}
          className="emoji--mark"
          aria-label="SMILING FACE WITH HEART-SHAPED EYES"
        >
          &#x1F60D;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🤣')}
          className="emoji--mark"
          aria-label="ROLLING ON THE FLOOR LAUGHING"
        >
          &#x1F923;
        </div>
        <div
          onClick={(e) => handleEmojiClick('👏')}
          className="emoji--mark"
          aria-label="CLAPPING HANDS SIGN"
        >
          &#x1F44F;
        </div>
        <div
          onClick={(e) => handleEmojiClick('👍')}
          className="emoji--mark"
          aria-label="THUMBS UP SIGN"
        >
          &#x1F44D;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🎉')}
          className="emoji--mark"
          aria-label="PARTY POPPER"
        >
          &#x1F389;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🎊')}
          className="emoji--mark"
          aria-label="CONFETTI BALL"
        >
          &#x1F38A;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🌻')}
          className="emoji--mark"
          aria-label="SUNFLOWER"
        >
          &#x1F33B;
        </div>
        <div
          onClick={(e) => handleEmojiClick('👓')}
          className="emoji--mark"
          aria-label="EYEGLASSES"
        >
          &#x1F453;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🕶')}
          className="emoji--mark"
          aria-label="DARK SUNGLASSES "
        >
          &#x1F576;
        </div>
        <div
          onClick={(e) => handleEmojiClick('📅')}
          className="emoji--mark"
          aria-label="CALENDAR"
        >
          &#x1F4C5;
        </div>
        <div
          onClick={(e) => handleEmojiClick('📝')}
          className="emoji--mark"
          aria-label="MEMO"
        >
          &#x1F4DD;
        </div>
        <div
          onClick={(e) => handleEmojiClick('⚡')}
          className="emoji--mark"
          aria-label="HIGH VOLTAGE SIGN"
        >
          &#x26A1;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🎁')}
          className="emoji--mark"
          aria-label="WRAPPED PRESENT"
        >
          &#x1F381;
        </div>
        <div
          onClick={(e) => handleEmojiClick('✔')}
          className="emoji--mark"
          aria-label="HEAVY CHECK MARK"
        >
          &#x2714;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🔥')}
          className="emoji--mark"
          aria-label="FIRE"
        >
          &#x1F525;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🚀')}
          className="emoji--mark"
          aria-label="ROCKET"
        >
          &#x1F680;
        </div>
        <div
          onClick={(e) => handleEmojiClick('🌟')}
          className="emoji--mark"
          aria-label="GLOWING STAR"
        >
          &#x1F31F;
        </div>
      </div>
    </div>
  );
};

export default TwoWayEmoji;
