import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { withTranslation } from 'react-i18next';

/**
 * Represents a queued calendar item.
 */
class CalendarQueueItem extends Component {
  /**
   * Executes when the component has mounted to the DOM.
   */
  componentDidMount() {
    const everyMinute = 60000;
    this._interval = setInterval(() => {
      //minutes passed needs to be calculated every render, every minute versus on mount.
      this.forceUpdate();
    }, everyMinute);
  }

  calculateMinutesPassed(created) {
    let minutesPassed = Math.floor(
      (new Date(new Date(Date.now()).toUTCString()) - created) / 1000 / 60
    );

    // Our formula above will generate a negative 1 if the created date is seconds
    // from this code running, so we'll guard against that and assign zero until
    // an actual minute has passed.
    if (minutesPassed < 0) {
      minutesPassed = 0;
    }

    return minutesPassed;
  }

  /**
   * Executes when the component is about to unmount from the DOM.
   */
  componentWillUnmount() {
    clearInterval(this._interval);
  }

  _onMoveAppointment = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { appointment, onMoveAppointment } = this.props;
    onMoveAppointment && onMoveAppointment(appointment);
  };

  /**
   * Renders the component.
   */
  render() {
    const { appointment, onClick, t } = this.props;
    const { title, appointmentType } = appointment;
    const minutesPassed = this.calculateMinutesPassed(appointment.created);

    const _handleDragStart = (e) => {
      e.dataTransfer.setData('text', appointment.id);
    };

    return (
      <div
        className="cal-queue__item"
        draggable
        onClick={(e) => onClick(e.target, appointment)}
        onDragStart={(e) => {
          _handleDragStart(e);
        }}
      >
        <div className="cal-queue__time">
          <HourglassEmptyIcon />
          {minutesPassed}&nbsp;{t('Mins')}
        </div>
        <div className="cal-queue__details">
          <span>{title}</span>,&nbsp;
          <span>{appointmentType.displayName}</span>,&nbsp;
          <span>
            {appointmentType.length} {t('Mins')}
          </span>
          <div className="cal-queue__action-cont">
            <button
              className="cal-queue__move"
              onClick={(e) => this._onMoveAppointment(e)}
            >
              {t('Move')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

CalendarQueueItem.propTypes = {
  appointment: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onMoveAppointment: PropTypes.func,
};

export default withTranslation()(CalendarQueueItem);
