import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import { withTranslation } from 'react-i18next';
import Popover from '@material-ui/core/Popover';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Enums from '../../enums';
import Session from '../../session';
import Constants from '../../constants';
import CalendarQueueItem from './CalendarQueueItem';
import CalendarQueueItemTooltip from './CalendarQueueItemTooltip';
import './CalendarQueueMobile.scss';

/**
 * Represents the mobile appointment queue.
 */
class CalendarQueueMobile extends Component {
  /**
   * Initializes a new instance of the CalendarQueueMobile component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      currSelectedAppt: {},
      currSelectedApptElem: null,
      inMoveMode: false,
      showApptTooltip: false,
      showQueue: false,
    };
  }

  _getQueueItems = () => {
    return this.props.appointments.map(
      (appt, key) =>
        appt.currentStatus === Enums.AppointmentStatus.queued && (
          <CalendarQueueItem
            appointment={appt}
            key={key}
            onClick={this.onShowTooltip}
            onMoveAppointment={this.onMoveAppointment}
          />
        )
    );
  };

  /**
   * Executes when the appointment tooltip is closed.
   */
  onCloseTooltip = () => {
    this.setState(() => ({ showApptTooltip: false }));
  };

  /**
   * Executes when it's time to move an appointment to the scheduler.
   * @param {Object} appt The appointment to move.
   */
  onMoveAppointment = (appt) => {
    if (appt) {
      Session.setItem(Constants.currSelectedQueueAppt, appt);

      this.setState(() => ({
        showQueue: false,
      }));
    }
  };

  /**
   * Executes when its time to show the appointment tooltip.
   * @param {Object} target The target element in the DOM.
   * @param {Object} appt The appointment.
   */
  onShowTooltip = (target, appt) => {
    this.setState((prevState) => ({
      currSelectedAppt: cloneDeep(appt),
      currSelectedApptElem: target,
      showApptTooltip: !prevState.showApptTooltip,
    }));
  };

  _onToggleQueue = () => {
    this.setState((prevState) => ({ showQueue: !prevState.showQueue }));
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      currSelectedAppt,
      currSelectedApptElem,
      inMoveMode,
      showApptTooltip,
      showQueue,
    } = this.state;
    const { countrySettings, locationSettings, t } = this.props;

    return (
      <div
        className={`cal-queue-mobile ${
          showQueue ? 'cal-queue-mobile--expand' : ''
        }`}
      >
        <div className="cal-queue-mobile__toggler">
          <button
            className="cal-queue-mobile__show"
            onClick={this._onToggleQueue}
          >
            <ChevronLeftIcon
              className={`cal-queue-mobile__icon ${
                showQueue ? 'cal-queue-mobile__icon--flip' : ''
              }`}
            />
          </button>
          <span
            className={`cal-queue-mobile__title ${
              showQueue ? 'cal-queue-mobile__title--visible' : ''
            }`}
          >
            {t('Waitlist')}
          </span>
        </div>
        <div
          className={`cal-queue-mobile__queue ${
            showQueue ? 'cal-queue-mobile__queue--expanded' : ''
          } ${inMoveMode ? 'cal-queue-mobile__queue--move-mode' : ''}`}
        >
          {this._getQueueItems()}
        </div>
        <Popover anchorEl={currSelectedApptElem} open={showApptTooltip}>
          <CalendarQueueItemTooltip
            countrySettings={countrySettings}
            item={currSelectedAppt}
            locationSettings={locationSettings}
            onCloseTooltip={this.onCloseTooltip}
          />
        </Popover>
      </div>
    );
  }
}

CalendarQueueMobile.propTypes = {
  appointments: PropTypes.array.isRequired,
  countrySettings: PropTypes.array.isRequired,
  locationSettings: PropTypes.array.isRequired,
};

export default withTranslation()(CalendarQueueMobile);
