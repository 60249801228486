import axios from 'axios';
import Storage from '../storage';
import Constants from '../constants';
import ErrorReportingService from './errorReportingService';

/**
 * Represents the support information service.
 */
class SupportInfoService {
  /**
   * Returns the support information for the app.
   * @param {String} countryCode The country code.
   * @returns A Promise.
   */
  async getSupportInfo(countryCode) {
    return new Promise(async (resolve, reject) => {
      try {
        let supportInfo = Storage.getItem(Constants.supportInfoKey);

        const url = `${process.env.REACT_APP_CONTENT_API}/getSupportInfo`;
        const data = {
          countryCode: countryCode,
        };
        const res = await axios.post(url, data);
        supportInfo = res?.data;

        Storage.setItem(Constants.supportInfoKey, supportInfo);

        resolve(supportInfo);
      } catch (error) {
        const errorReporting = new ErrorReportingService();
        errorReporting.reportError(
          error,
          `${process.env.REACT_APP_CONTENT_API}/getSupportInfo`
        );
        reject(error);
      }
    });
  }
}

export default SupportInfoService;
